import React, { useState, useEffect } from 'react';
import Chart from 'chart.js/auto';
import 'semantic-ui-css/components/accordion.min.css';
//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import constant from '../../constants';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import constants from '../../constants';
import { getDashboardMatrix } from '../../actions/dashboard';
import RadioDropDown from '../../components/RadioDropDown';
import { getChartData, getDateRange } from '../../selectors/index';
import { getAuthoringFieldData } from 'actions/saasFormAuthoring';
import CampaignSummary from 'components/reporting-dashboard/CampaignSummary';
import BrandSummary from 'components/reporting-dashboard/BrandSummary';
import ConsumerInsight from 'components/reporting-dashboard/ConsumerInsight';
import UserSummary from 'components/reporting-dashboard/UserSummary';
import PerformanceAndHealth from 'components/reporting-dashboard/PerformanceAndHealth';
import ReportTabPanelContent from 'components/ReportTabPanelContent';
import '../../sass/react-tabs-2.scss';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';

var _startDate = null;

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  if (value !== index) {
    return null;
  }

  return (
    <div
      role="tabpanel"
      className="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

const ReportingDashboard = props => {
  const dispatch = useDispatch();

  const [value, setValue] = React.useState(0);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  let getcurentMonthDateRange = getDateRange('this and last year');
  const [lastUpdatedData, setLastUpdatedData] = useState(
    constants.lastUpdatedData
  );
  const [lastUpdated, setLastUpdated] = useState({
    value: getcurentMonthDateRange,
    label: 'This year + last calendar year'
  });

  const handleLastUpdatefilter = selectedOption => {
    setLastUpdated(selectedOption);
    dashboardApiCall(selectedOption);
  };

  const dashboardApiCall = lastUpdatedDate => {
    let startDate = '';
    let endDate = '';
    if (lastUpdatedDate && lastUpdatedDate['value'] !== 'All time') {
      let dateArr =
        lastUpdatedDate &&
        lastUpdatedDate['value'] &&
        lastUpdatedDate['value'].split('-');
      startDate =
        dateArr && dateArr.length
          ? moment(dateArr[0].trim(), 'DD/MM/YY').format('DD-MM-YYYY')
          : '';
      _startDate =
        startDate == '' ? null : moment(dateArr[0].trim(), 'DD/MM/YY');
      endDate =
        dateArr && dateArr.length
          ? moment(dateArr[1].trim(), 'DD/MM/YY').format('DD-MM-YYYY')
          : '';
    } else {
      _startDate = null;
    }
    dispatch(getDashboardMatrix(startDate, endDate));
  };

  useEffect(() => {
    dashboardApiCall(lastUpdated);
    dispatch(getAuthoringFieldData('EN', 'Direct messaging', 'GB'));
  }, []);

  const dashboardProps = useSelector(state => ({
    campaignMatrix: state.dashboard.campaignMatrix
  }));

  let { campaignMatrix } = dashboardProps;

  const role =
    props.authorizedUserData && props.authorizedUserData.group
      ? props.authorizedUserData.group
      : 'unknown';

  const tabStyle = { fontFamily: 'Open Sans', textTransform: 'none' };
  console.log(Chart);

  return (
    <div className="cw-section cw-overview cw-report-dashboard-wrapper ml-20">
      <div className="col-sm-12">
        <NavLink
          className="cw-color--primary cw-breadcrum"
          to={'/campaign-wizard'}
        >
          <i className="fas fa-angle-left"></i> Campaigns
        </NavLink>
      </div>
      <div className="col-sm-12">
        <div className="d-flex align-items-center mb-20">
          <h2
            className="cw-heading--secondary d-flex align-items-center mb-10"
            style={{ flexBasis: '100%' }}
          >
            <span className="mr-3">Reports</span>
          </h2>
          <RadioDropDown
            isMulti={false}
            className={'cw-dashboard-reports-filter report-updated-filtersdsfd'}
            name={'lastUpdated'}
            label={'Date of completion'}
            hideSelectedOptions={false}
            placeholder={'Date of completion'}
            value={lastUpdated}
            onChange={handleLastUpdatefilter}
            options={lastUpdatedData}
            isClearable={false}
          />
        </div>
        <div className="col-sm-12 p-0">
          {/* sx={{ bgcolor: 'background.paper' }} */}

          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            disableRipple
            style={{ borderBottom: '1px solid #aaa' }}
          >
            <Tab disableRipple style={tabStyle} label="Campaigns" />
            <Tab disableRipple style={tabStyle} label="Brands" />
            <Tab disableRipple style={tabStyle} label="Users" />
            <Tab disableRipple style={tabStyle} label="Consumer data" />
            <Tab
              disableRipple
              style={tabStyle}
              label="Performance and health"
            />
            {['admin', 'masterdata'].includes(role) ? (
              <Tab style={tabStyle} label="Downloadable reports" />
            ) : null}
          </Tabs>
          <TabPanel value={value} index={0}>
            <CampaignSummary campaignMatrix={campaignMatrix} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <BrandSummary campaignMatrix={campaignMatrix} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <UserSummary campaignMatrix={campaignMatrix} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ConsumerInsight campaignMatrix={campaignMatrix} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <PerformanceAndHealth campaignMatrix={campaignMatrix} />
          </TabPanel>
          {['admin', 'masterdata'].includes(role) ? (
            <TabPanel value={value} index={5}>
              <ReportTabPanelContent panelType="reports" role={role} />
            </TabPanel>
          ) : null}
        </div>
        <div className="col-sm-12 p-0">
          {/* <Tabs
            selectedTabClassName="react-tabs__tab custom-selected"
            scrollable={true}
          >
            <TabList className="custom-tab-list">
              <Tab>Campaigns</Tab>
              <Tab>Brands</Tab>
              <Tab>Users</Tab>
              <Tab>Consumer data</Tab>
              <Tab>Performance and health</Tab>
              {role === 'admin' ? <Tab>Downloadable reports</Tab> : null}
            </TabList>
            <TabPanel>
              <CampaignSummary campaignMatrix={campaignMatrix} />
            </TabPanel>
            <TabPanel>
              <BrandSummary campaignMatrix={campaignMatrix} />
            </TabPanel>
            <TabPanel>
              <UserSummary campaignMatrix={campaignMatrix} />
            </TabPanel>
            <TabPanel>
              <ConsumerInsight campaignMatrix={campaignMatrix} />
            </TabPanel>
            <TabPanel>
              <PerformanceAndHealth campaignMatrix={campaignMatrix} />
            </TabPanel>
            {role === 'admin' ? (
              <TabPanel>
                <ReportTabPanelContent panelType="reports" />
              </TabPanel>
            ) : null}
          </Tabs> */}
        </div>
      </div>
    </div>
  );
};
export default ReportingDashboard;
