import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormBuilder } from '../../components/organisms/FormBuilder';
import Loader from '../../components/Loader';
import formSchema from './emailTypeFormSchema';
import { getEmailType } from 'actions/emailbriefing/emailType';
import { getBrandDetails } from 'actions/emailbriefing/emailBrandDetails';
import ProgressBar from 'components/molecules/ProgressBar';
import { Redirect } from 'react-router-dom';
import { CustomButtons } from 'pages/EmailCampaignAudience';
import constants from '../../constants/config';

const EmailType = props => {
  const { isLoading, emailType, uid } = useSelector(state => state.emailType);
  const { authorizedUserData } = useSelector(state => state.authorized);
  const { country, brand, language, forceEmailType } = useSelector(
    state => state.emailBrandDetails
  );
  const emailDesign = useSelector(state => state.emailDesign);

  const [isEdit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const pathUid = props.match.params.id;

  useEffect(() => {
    if (pathUid) {
      setEdit(true);
    }

    if (pathUid) {
      dispatch(getEmailType(pathUid));
      dispatch(getBrandDetails(pathUid));
    }
  }, []);

  const compProps = {
    history: props.history,
    match: props.match,
    emailDesign,
    defaultValues: {
      emailType: forceEmailType
        ? constants.emailType.newsletter.toLowerCase()
        : emailType
    }
  };

  if (!pathUid && (!country || !brand || !language)) {
    return <Redirect to="new-email-campaign" />;
  }

  return (
    <div className="col-sm-12 skypink">
      <div className="cw-section">
        <ProgressBar
          customClass={'cw-email-progress-custom'}
          pageNo={constants.EMAIL_JOURNEY_PAGE_NUMBERS.EMAIL_TYPE}
        />
        <div className="cw-section--title mb-30">
          <h2 className="cw-heading--secondary cw-email-heading">
            Select email type
          </h2>
        </div>
        <div className="row">
          {isLoading ? <Loader /> : null}
          <div className="col-md-8">
            <FormBuilder
              schema={formSchema(dispatch, compProps)}
              customButtonsComp={CustomButtons}
              user={authorizedUserData}
              isEdit={isEdit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailType;
