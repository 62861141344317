import React, { useState } from 'react';
import Modal from 'react-modal';
import constants from 'constants/config';
import RequestMoreInfoIconTransparent from 'images/requestMoreInfoIconTransparent';
import SmsCampaignRequestInfo from 'pages/SmsCampaignRequestInfo';

const SmsRequestInfoModel = ({
  modalIsOpen,
  closeModal,
  smsDetails,
  pathUid
}) => {
  const [isActive, setIsActive] = useState(false);

  const dismissModal = () => {
    setIsActive(false);
    closeModal(false);
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={dismissModal}
        className="cw-modal cw-modal--schedule-email-model"
        contentLabel="Schedule Modal"
        style={{
          overlay: {
            backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
          }
        }}
      >
        <button
          className="cw-modal--close"
          title="Close"
          onClick={dismissModal}
        >
          <i className="fas fa-times"></i>
        </button>

        <div className="d-flex email-request-more-info-modal-wrapper">
          <div className="email-request-more-info-icon send-test-email__icon">
            <RequestMoreInfoIconTransparent />
          </div>
          <div>
            <h3 className="cw-heading--senary">Request more info</h3>
            <p className="mb-10 mt-10 email-summary-font ">
              {' '}
              Please describe the information you need in detail
            </p>

            <div className="form-group d-flex requester-switch">
              <label className="switch-saas">
                <input
                  type="checkbox"
                  name={`assignBackToRequestor`}
                  checked={isActive}
                  onChange={() => {
                    setIsActive(!isActive);
                  }}
                />
                <div className="slider-saas round"></div>
              </label>
              <label className="ml-10" htmlFor={`assignBackToRequestor`}>
                <span>Assign campaign back to requestor</span>{' '}
              </label>
            </div>

            <SmsCampaignRequestInfo
              pathUid={pathUid}
              closeModal={closeModal}
              smsDetails={smsDetails}
              toggle={isActive}
              setToggle={setIsActive}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SmsRequestInfoModel;
