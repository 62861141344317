import EmailTemplateList from 'components/organisms/EmailTemplateList';
import EmailTemplateSearch from 'components/organisms/EmailTemplateSearch';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEmailCoreTemplates } from 'actions/emailbriefing/emailCoreTemplates';
import { getEmailBriefing } from 'actions/emailbriefing/emailDesign';
import _ from 'lodash';
import { getEmailRowBlock } from 'actions/emailbriefing/emailCoreRaws';
import EmailTemplateRawList from 'components/organisms/EmailTemplateRow';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Select from 'react-select';
import PropTypes from 'prop-types';
import TemplatePreviewOverlay from 'pages/EmailCampaignTemplate/templatePreviewOverlay';
import Loader from 'components/Loader';
import AppliedFilters from 'components/organisms/Filters/AppliedFilters/AppliedFilters';
import classNames from 'classnames';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function CollectionPageWrapper({
  isTabView = false,
  children,
  TemplatePreviewOverlay
}) {
  return isTabView ? (
    <>
      {children} {TemplatePreviewOverlay}
    </>
  ) : (
    <div className="skypink ml-5 mr-5">
      <div className="cw-section cw-overview">
        <div className="cw-listing--header mb-30 d-flex align-items-center justify-content-between">
          <h2 className="cw-heading--secondary mr-10 d-inline-block">
            Email collections
          </h2>
        </div>
        {children}
      </div>
      {TemplatePreviewOverlay}
    </div>
  );
}
const tabOptions = [
  {
    value: 0,
    label: 'Email templates'
  },
  {
    value: 1,
    label: 'Email tiles'
  }
];

const EmailCollection = ({
  match,
  history,
  userEmail,
  userName,
  isTabView = false
}) => {
  const dispatch = useDispatch();
  const { emailCoreTemplates, emailCoreRaws } = useSelector(state => ({
    emailCoreTemplates: state.emailCoreTemplates,
    emailCoreRaws: state.emailCoreRaws
  }));

  const [isTemplatePreviewOpen, setIsTemplatePreviewOpen] = useState(false);
  const [selectedTemplateForPreview, setSelectedTemplateForPreview] = useState(
    {}
  );
  const [isReset, setIsReset] = useState(false);
  const [enteredText, setEnteredText] = useState('');
  const [value, setValue] = React.useState(0);
  const [toggleVisible, setIsToggleVisible] = useState(true);

  const handleChange = (e, newValue) => {
    setValue(newValue);
    setUserInfo({
      emailType: [],
      countries: [],
      brands: [],
      templateContext: [],
      templateAttributes: [],
      isFirstLoad: true,
      rawCategories: [],
      isEmailCollection: true
    });
    setFilterPills({
      emailType: [],
      countries: [],
      brands: [],
      templateContext: [],
      templateAttributes: [],
      rawCategories: []
    });
    setEnteredText('');
    dispatch(getEmailCoreTemplates());
    dispatch(getEmailRowBlock());
    if (match.params.id) {
      dispatch(getEmailBriefing(match.params.id));
    }
  };

  const [userinfo, setUserInfo] = useState({
    emailType: [],
    countries: [],
    brands: [],
    templateContext: [],
    templateAttributes: [],
    isFirstLoad: true,
    rawCategories: [],
    isEmailCollection: true
  });
  const [filterPills, setFilterPills] = useState({
    emailType: [],
    countries: [],
    brands: [],
    templateContext: [],
    templateAttributes: [],
    rawCategories: []
  });
  /**
   * Filter checkbox change handler
   * @param {*} e
   */
  const handleFilterChange = (e, data) => {
    const { value, checked, name } = e.target;
    const {
      emailType,
      countries,
      brands,
      rawCategories,
      templateContext,
      templateAttributes
    } = userinfo;

    if (checked) {
      setUserInfo({
        emailType: name === 'emailType' ? [...emailType, value] : emailType,
        countries: name === 'countries' ? [...countries, value] : countries,
        brands: name === 'brands' ? [...brands, value] : brands,
        templateContext:
          name === 'templateContext'
            ? [...templateContext, value]
            : templateContext,
        templateAttributes:
          name === 'templateAttributes'
            ? [...templateAttributes, value]
            : templateAttributes,
        rawCategories:
          name === 'rawCategories' ? [...rawCategories, value] : rawCategories,
        isEmailCollection: true
      });
      setFilterPills({
        emailType:
          name === 'emailType'
            ? [...filterPills.emailType, { value: value, label: data.label }]
            : filterPills.emailType,
        brands:
          name === 'brands'
            ? [...filterPills.brands, { value: value, label: data.name }]
            : filterPills.brands,
        countries:
          name === 'countries'
            ? [...filterPills.countries, { value: value, label: data.name }]
            : filterPills.countries,
        templateContext:
          name === 'templateContext'
            ? [
                ...filterPills.templateContext,
                { value: value, label: data.label }
              ]
            : filterPills.templateContext,
        templateAttributes:
          name === 'templateAttributes'
            ? [
                ...filterPills.templateAttributes,
                { value: value, label: data.label }
              ]
            : filterPills.templateAttributes,
        rawCategories:
          name === 'rawCategories'
            ? [
                ...filterPills.rawCategories,
                { value: value, label: data.label }
              ]
            : filterPills.rawCategories
      });
    } else {
      setUserInfo({
        emailType: emailType.filter(e => e !== value),
        countries: countries.filter(e => e !== value),
        brands: brands.filter(e => e !== value),
        templateContext: templateContext.filter(e => e !== value),
        templateAttributes: templateAttributes.filter(e => e !== value),
        rawCategories: rawCategories.filter(e => e !== value),
        isEmailCollection: true
      });
      setFilterPills({
        emailType: filterPills.emailType.filter(e => e.value !== value),
        countries: filterPills.countries.filter(e => e.value !== value),
        brands: filterPills.brands.filter(e => e.value !== value),
        templateContext: filterPills.templateContext.filter(
          e => e.value !== value
        ),
        templateAttributes: filterPills.templateAttributes.filter(
          e => e.value !== value
        ),
        rawCategories: filterPills.rawCategories.filter(e => e.value !== value)
      });
    }
  };
  const emailProps = useSelector(state => ({
    emailBriefing: state.emailDesign.emailBriefing
  }));
  const emailType =
    emailProps &&
    emailProps.emailBriefing &&
    emailProps.emailBriefing.emailType;
  /**
   * Setting default email type conditionally
   */
  useEffect(() => {
    let userInfo = {
      emailType: [],
      countries: [],
      brands: [],
      templateContext: [],
      templateAttributes: [],
      rawCategories: [],
      isFirstLoad: false,
      isEmailCollection: true
    };
    setUserInfo(userInfo);
  }, []);

  useEffect(() => {
    dispatch(getEmailCoreTemplates());
    dispatch(getEmailRowBlock());
    if (match.params.id) {
      dispatch(getEmailBriefing(match.params.id));
    }
  }, []);

  const onPageChange = pageNumber => {
    dispatch(
      getEmailCoreTemplates({ filter: userinfo, text: enteredText }, pageNumber)
    );
  };

  const onPageChangeCollection = pageNumber => {
    dispatch(
      getEmailRowBlock({ filter: userinfo, text: enteredText }, pageNumber)
    );
  };

  const handleTemplatePreview = template => {
    setIsTemplatePreviewOpen(true);
    setSelectedTemplateForPreview(template);
  };

  const onReset = () => {
    dispatch(getEmailCoreTemplates());
    let userInfo = {
      emailType: [],
      countries: [],
      brands: [],
      templateContext: [],
      templateAttributes: [],
      rawCategories: [],
      isFirstLoad: false,
      isEmailCollection: true
    };
    setUserInfo(userInfo);
    setIsReset(true);
  };

  const resetFilterPills = () => {
    setFilterPills({
      emailType: [],
      countries: [],
      brands: [],
      templateContext: [],
      templateAttributes: [],
      rawCategories: []
    });
  };

  const handleCloseTemplatePreview = () => {
    setIsTemplatePreviewOpen(false);
    setSelectedTemplateForPreview({});
  };

  const getFiltersCount = givenInfo => {
    const filters = [
      'countries',
      'brands',
      'emailType',
      'templateContext',
      'templateAttributes',
      'rawCategories'
    ];

    let filterCount = filters.reduce(
      (acc, filterKey) => acc + givenInfo[filterKey].length,
      0
    );

    // if (userinfo.lastUpdated !== '') {
    //   filterCount += 1;
    // }
    return filterCount;
  };

  const removeFilterPill = (value, filterKey) => {
    setFilterPills({
      ...filterPills,
      [filterKey]: filterPills[filterKey].filter(e => e.value !== value)
    });
    setUserInfo({
      ...userinfo,
      [filterKey]: userinfo[filterKey].filter(e => e !== value)
    });
  };

  const { limit, page, total } =
    (emailCoreTemplates &&
      emailCoreTemplates.data &&
      emailCoreTemplates.data.data &&
      emailCoreTemplates.data.data.pagination) ||
    {};

  const recordShowStart = (page - 1) * limit + 1;
  const recordShowto = _.min([recordShowStart + limit - 1, total]);

  const isLoading = emailCoreTemplates.isLoading;
  const { isLoading: isLoadingRows } = emailCoreRaws;

  const { limit: rawLimit, page: rawPage, total: rawTotal } =
    (emailCoreRaws && emailCoreRaws.data && emailCoreRaws.data.pagination) ||
    {};

  const recordRawShowStart = (rawPage - 1) * rawLimit + 1;
  const recordRawShowto = _.min([recordRawShowStart + rawLimit - 1, rawTotal]);

  const tabStyle = { fontFamily: 'Open Sans', textTransform: 'none' };

  return (
    <div className="skypink ml-5 mr-5">
      <div className="cw-section cw-overview">
        <div className="cw-listing--header mb-30 d-flex align-items-center justify-content-between">
          <h2 className="cw-heading--secondary mr-10 d-inline-block">
            Email collections
          </h2>
        </div>
        <div
          className={`email-campaigns-navigation-wrapper ${
            isTabView ? 'mt-20' : ''
          }`}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            disableRipple
          >
            <Tab disableRipple style={tabStyle} label="Email templates" />
            <Tab disableRipple style={tabStyle} label="Email tiles" />
          </Tabs>

          <div className="email-campaigns-navigations">
            <a
              href="/campaign-wizard/email-dashboard"
              className="d-flex align-items-center"
            >
              Go to campaigns dashboard
            </a>
            {/* <a
              className="btn btn-primary btn-newcampaign"
              href="/campaign-wizard/new-email-campaign"
            >
              Create templates
            </a> */}
          </div>
        </div>

        {isLoading || isLoadingRows ? (
          <Loader
            style={{
              position: 'fixed',
              height: '100vh',
              left: 0,
              background: 'white',
              zIndex: 1000
            }}
          />
        ) : null}
        <TabPanel value={value} index={0}>
          <p className="email-summary-font mb-20 email-tab-text">
            {
              'Browse through our curated gallery of saved email templates for inspiration. These will be available to choose as a starting point while you create your email campaigns so you don’t have to start from scratch'
            }
          </p>
          <AppliedFilters
            removeFilter={removeFilterPill}
            filters={filterPills}
            filtersCnt={getFiltersCount(userinfo)}
            _clearAllFilters={() => {
              onReset();
              resetFilterPills();
              setEnteredText('');
            }}
            className="mb-10"
          />
          <div className="row select-template-page">
            <div style={{ flex: 3 }}>
              <EmailTemplateList
                emailCoreTemplates={emailCoreTemplates}
                onReset={() => {
                  onReset();
                  resetFilterPills();
                }}
                userEmail={userEmail}
                userName={userName}
                total={total}
                recordShowStart={recordShowStart}
                recordShowto={recordShowto}
                handleTemplatePreview={handleTemplatePreview}
                page={page}
                limit={limit}
                onPageChange={onPageChange}
                // hideOverflow
                // hideOverlay={true}
                hideSelectBtn={true}
                hideOverflowSelectBtn={true}
                hideBlankTemplate={true}
                hideResumeTemplate={true}
                // hideNameDescirption={true}
              />
            </div>

            <div
              className={classNames('email-template-search-wrapper col-md-3', {
                'template-filter-visible': toggleVisible,
                'template-filter-hidden': !toggleVisible
              })}
            >
              <EmailTemplateSearch
                visible={toggleVisible}
                collapsible
                enteredText={enteredText}
                setEnteredText={setEnteredText}
                userinfo={userinfo}
                setUserInfo={setUserInfo}
                handleChange={handleFilterChange}
                emailCoreTemplates={emailCoreTemplates}
                isReset={isReset}
                resetTrigger={() => {
                  setIsReset(false);
                }}
                setIsVisible={setIsToggleVisible}
                emailBriefing={emailProps.emailBriefing}
                isEmailCollection={true}
                resetFilterPills={resetFilterPills}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <p className="email-summary-font mb-20 email-tab-text">
            {
              'Browse through our curated gallery of saved email tiles or mini templates. These will be readily available when your create your email design to expedite your email campaign setup'
            }
          </p>
          <AppliedFilters
            removeFilter={removeFilterPill}
            filters={filterPills}
            filtersCnt={getFiltersCount(userinfo)}
            _clearAllFilters={() => {
              onReset();
              resetFilterPills();
              setEnteredText('');
            }}
            className="mb-10"
          />
          <div className="row select-template-page">
            <div style={{ flex: 3 }}>
              <EmailTemplateRawList
                emailCoreRaws={emailCoreRaws}
                onReset={() => {
                  onReset();
                  resetFilterPills();
                }}
                userEmail={userEmail}
                userName={userName}
                total={rawTotal}
                recordShowStart={recordRawShowStart}
                recordShowto={recordRawShowto}
                handleTemplatePreview={handleTemplatePreview}
                showLoader={isLoading}
                page={rawPage}
                limit={rawLimit}
                onPageChange={onPageChangeCollection}
              />
            </div>

            <div
              className={classNames('email-template-search-wrapper col-md-3', {
                'template-filter-visible': toggleVisible,
                'template-filter-hidden': !toggleVisible
              })}
            >
              <EmailTemplateSearch
                visible={toggleVisible}
                collapsible
                isRawShow={true}
                enteredText={enteredText}
                setEnteredText={setEnteredText}
                emailCoreTemplates={emailCoreTemplates}
                handleChange={handleFilterChange}
                userinfo={userinfo}
                setUserInfo={setUserInfo}
                isReset={isReset}
                resetTrigger={() => {
                  setIsReset(false);
                }}
                setIsVisible={setIsToggleVisible}
                emailBriefing={emailProps.emailBriefing}
                isRowSearch
                isEmailCollection={true}
                resetFilterPills={resetFilterPills}
              />
            </div>
          </div>
        </TabPanel>
      </div>
      <TemplatePreviewOverlay
        isOpen={isTemplatePreviewOpen}
        selectedTemplateForPreview={selectedTemplateForPreview}
        handleCloseForm={handleCloseTemplatePreview}
        hideSelectBtn
      />
    </div>
  );
};

export default EmailCollection;
