import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import HistoryComponent from '../../components/history';
import { getSmsOverview } from '../../actions/communications/communicationOverview';
import { fetchHistoryLogs } from '../../actions/history';
import { openFeedbackForm } from '../../actions/feedbackForm';
import NotFound from 'components/NotFound';
import SMSSummaryCards from 'components/organisms/EmailSummaryCards/SMSSummaryCards';
import CommunicationsOverviewTables from 'components/organisms/CommunicationsOverviewTables';
import Toolbar from '../../components/communications/Toolbar';
import print_icon from '../../images/print_icon.png';
import feedback_icon from '../../images/feedback_icon.png';
import MarkScheduledIcon from '../../images/markScheduleIcon';
import RequestMoreInfoIcon from '../../images/requestMoreInfoIcon';
import Layout from 'components/organisms/Layout/Layout';
import PageHeader from 'components/organisms/PageHeader/PageHeader';
import MobileDevicePreview from 'components/organisms/MobileDevicePreview';
import { getSMSReviewData } from 'selectors';
import Alert from 'components/Alert';
import constants from 'constants/config';
import EmailApproverInfo from 'components/organisms/EmailApproverInfo';
import SmsScheduleModel from 'components/organisms/SmsScheduleModel';
import BackAndNext from 'components/organisms/BackAndNext/BackAndNext';
import SmsLiveModel from 'components/organisms/SmsLiveModel';
import NotificationMessage from 'components/Messages';
import Loader from 'components/Loader';
import EmailGuidelineText from 'components/organisms/EmailGuidelineText/EmailApproverGuideline';
import SmsRequestInfoModel from 'components/organisms/SmsRequestInfoModel';
import MasterDataGuideline from 'components/MasterDataGuideline';
import SmsCostAndRequestInfoModel from 'components/organisms/SmsCostAndRequestInfoModel';
import { get } from 'lodash';
import DownloadIcon from '../../images/downloadIcon';
import {
  downloadSMSPurchaseOrder,
  downloadSMSCostEstimate
} from '../../services/communicationService';
import SmsActionBar from 'components/organisms/SmsActionBar/SmsActionBar';

const SMSExecution = props => {
  const { history, match } = props;
  const [uid, setUid] = useState(match.params.id);
  const [modalSchduleOpen, setModalScheduleOpen] = useState(false);
  const [modalLiveOpen, setModalLiveOpen] = useState(false);
  const [acsGuideModel, setAcsGuideModel] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [requestModelInfoOpen, setRequestModelInfoOpen] = useState(false);
  const [smsCostMoreInfoOpen, setSmsCostMoreInfoOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const uid = props.match.params.id;
    dispatch(fetchHistoryLogs(uid));
    dispatch(getSmsOverview(uid));
  }, []);

  const smsProps = useSelector(state => ({
    smsDetailsOverview: state.communicationOverview.smsDetailsOverview,
    isLoading: state.communicationOverview.isLoading,
    historyLogs: state.historyLogs,
    smsServiceError: state.communicationOverview.smsServiceError
  }));

  const { authorizedUserData } = useSelector(state => state.authorized);

  const currentUser = {
    email: get(authorizedUserData, 'emailId', ''),
    group: get(authorizedUserData, 'group', '')
  };

  if (!smsProps.smsDetailsOverview) return null;

  const {
    name,
    status,
    rejectComments,
    nmiAssignedToRequestor,
    requestInfoText,
    requestInfoAnswerText,
    shareCostEstimate = null,
    sharePurchaseOrder = {}
  } = smsProps.smsDetailsOverview;

  let isDraft = status === '1' || status === '5' ? true : false;
  let isRejected = status === '5' ? true : false;
  const isAcsOrAdmin = ['acs', 'admin'].includes(currentUser.group);
  const isAwaitingSetup =
    ((constants.SMS_STATUS.AWAITING_SETUP === String(status) && isAcsOrAdmin) ||
      (constants.SMS_STATUS.AWAITING_INFO === status &&
        nmiAssignedToRequestor === false)) &&
    shareCostEstimate;

  const showRequestMoreInfo =
    constants.SMS_STATUS.AWAITING_INFO === status &&
    nmiAssignedToRequestor === false;

  const showRequestMoreInfoRequestorFlow =
    (constants.SMS_STATUS.AWAITING_INFO === status &&
      nmiAssignedToRequestor === true) ||
    !shareCostEstimate;
  const showCostOrRequestMoreInfo =
    constants.SMS_STATUS.AWAITING_COST_ESTIMATE === status;

  let mobilePreviewData = getSMSReviewData(smsProps.smsDetailsOverview);

  const onClickEdit = () => {
    history.push({
      pathname: `${constants.SMS_BASE_ROUTE}/${uid}/${constants.SMS_ROUTES.DESIGN_SMS}`,
      state: {
        isEdited: true,
        executionRedesign: true
      }
    });
  };

  const editProps = {};
  if (isAwaitingSetup) {
    editProps.onClickEdit = onClickEdit;
  }

  const continueCampaign = () => {
    history.push(
      '/campaign-wizard/communications/' +
        this.state.uid +
        '/communicationbrandpage'
    );
  };

  const handleOnOpenFeedback = () => {
    dispatch(
      openFeedbackForm({
        campaignUid: props.match.params.id,
        userId: props.authorizedUserData._id,
        defaultCampaignRequest: 'SMS',
        showCampaignRequest: false
      })
    );
  };

  const handleMarkScheduleClick = () => {
    setModalScheduleOpen(true);
  };

  const handleRequestInfoClick = () => {
    setRequestModelInfoOpen(true);
  };

  const handleCostAndRequestInfoClick = () => {
    setSmsCostMoreInfoOpen(true);
  };

  const closeCostRequestInfoModelClick = redirect => {
    setSmsCostMoreInfoOpen(false);
    if (redirect) {
      history.push(`/campaign-wizard/communications/${uid}`);
    }
  };

  const closeRequestInfoModelClick = redirect => {
    setRequestModelInfoOpen(false);
    if (redirect) {
      history.push(`/campaign-wizard/communications/${uid}`);
    }
  };

  const closeScheduleModelClick = ({ showAlertMessage = false } = {}) => {
    setModalScheduleOpen(false);
    if (showAlertMessage) {
      setAlertMessage(
        constants.EMAIL_CAMPAIGN_APPROVAL_MESG.acsScheduled.message
      );
      setAlertType(constants.EMAIL_CAMPAIGN_APPROVAL_MESG.acsScheduled.type);
      window.scroll({
        top: -100,
        left: 0,
        behavior: 'smooth'
      });
    }
  };

  const handleMarkLiveClick = () => {
    setModalLiveOpen(true);
  };

  const closeLiveModelOnClick = () => {
    setModalLiveOpen(false);
  };

  const closeLiveModelClick = () => {
    setModalLiveOpen(false);
    history.push(`/campaign-wizard/communications/${uid}`);
  };

  const isOpenAcsGuidelineModel = () => {
    setAcsGuideModel(true);
  };

  const isCloseAcsGuidelineModel = () => {
    setAcsGuideModel(false);
  };

  const handleDownloadPOClick = setIsDownloading => {
    if (sharePurchaseOrder.poFile) {
      const link = sharePurchaseOrder.poFile.gcsPath;
      const [bucketName, ...fileNameParts] = link
        .replace('gs://', '')
        .split('/');
      const fileName = fileNameParts.join('/');
      const uid = props.match.params.id;
      if (setIsDownloading) {
        setIsDownloading(true);
      }
      //setIsDownloading(true);
      downloadSMSPurchaseOrder(uid)
        .then(response => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: 'application/pdf' })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          //setIsDownloading(false);
          if (setIsDownloading) {
            setIsDownloading(false);
          }
        })
        .catch(error => {
          //setIsDownloading(false);
          if (setIsDownloading) {
            setIsDownloading(false);
          }
          console.log('error: ', error);
        });
    }
  };

  const handleDownloadCostEstimateClick = setIsDownloading => {
    const link = shareCostEstimate.costFile.gcsPath;
    const [bucketName, ...fileNameParts] = link.replace('gs://', '').split('/');
    const fileName = fileNameParts.join('/');
    const uid = props.match.params.id;
    if (setIsDownloading) {
      setIsDownloading(true);
    }
    //setIsDownloading(true);
    downloadSMSCostEstimate(uid)
      .then(response => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        if (setIsDownloading) {
          setIsDownloading(false);
        }
        //setIsDownloading(false);
      })
      .catch(error => {
        //setIsDownloading(false);
        if (setIsDownloading) {
          setIsDownloading(false);
        }
        console.log('error: ', error);
      });
  };

  if (smsProps.smsServiceError === 404) {
    return <NotFound />;
  } else if (smsProps.isLoading || isDownloading) {
    return <Loader />;
  } else if (smsProps.smsServiceError === null) {
    return (
      <>
        <SmsScheduleModel
          modalIsOpen={modalSchduleOpen}
          closeModal={closeScheduleModelClick}
          smsDetailsOverview={smsProps && smsProps.smsDetailsOverview}
          pathUid={uid}
        />

        <SmsLiveModel
          modalIsOpen={modalLiveOpen}
          closeModal={closeLiveModelClick}
          closeModelLiveClick={closeLiveModelOnClick}
          smsDetailsOverview={smsProps && smsProps.smsDetailsOverview}
          pathUid={uid}
        />
        <SmsRequestInfoModel
          modalIsOpen={requestModelInfoOpen}
          closeModal={closeRequestInfoModelClick}
          smsDetails={smsProps && smsProps.smsDetailsOverview}
          pathUid={uid}
        />
        <SmsCostAndRequestInfoModel
          modalIsOpen={smsCostMoreInfoOpen}
          closeModal={closeCostRequestInfoModelClick}
          smsDetails={smsProps && smsProps.smsDetailsOverview}
          pathUid={uid}
        />
        <Modal
          scrollable={true}
          isOpen={acsGuideModel}
          onRequestClose={isCloseAcsGuidelineModel}
          className="cw-modal cw-modal--qnadetail"
          contentLabel="Campaign Wizard 2.0"
          style={{
            overlay: {
              backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
            },
            content: {
              maxHeight: '90vh'
            }
          }}
        >
          <MasterDataGuideline
            closeIsEditModal={isCloseAcsGuidelineModel}
            data={constants.SMS_ACS_GUIDE_LINES}
            isEmailCampaign={true}
          />
        </Modal>
        <div className="skypink ml-5 mr-5">
          <div className="cw-section cw-overview">
            <div className="draft_button_section_main">
              <Alert
                type={'warning'}
                message={rejectComments ? rejectComments : ''}
                alertType={'rejectcomment'}
                showComponent={status === '5'}
              />
              <Alert
                type={'warning'}
                className={'mt-4'}
                message={requestInfoText ? requestInfoText : ''}
                alertType={'requestMoreInfo'}
                showComponent={status === '8'}
                title={
                  nmiAssignedToRequestor
                    ? `ACS team has requested more from requestor  `
                    : `ACS team is awaiting more information internally - there is no next step on requestor `
                }
              />

              <Alert
                type={'warning'}
                className={'mb-5'}
                message={requestInfoText ? requestInfoText : ''}
                alertType={'requestMoreInfo'}
                showComponent={status === '4' && requestInfoText}
                title={`ACS team requested more information`}
              />

              <Alert
                type={'warning'}
                message={requestInfoAnswerText ? requestInfoAnswerText : ''}
                alertType={'requestMoreInfo'}
                showComponent={status === '4' && requestInfoAnswerText}
                title={`Requestor has provided more information`}
              />
              <NotificationMessage
                showcomponent={alertMessage}
                message={alertMessage}
                type={alertType}
                link={''}
                campaignname={''}
                onClose={() => {
                  setAlertMessage('');
                  setAlertType('');
                }}
              />
              <PageHeader
                heading={name || 'Untitled'}
                status={status}
                subHeading={
                  isDraft ? 'Continue creating your campaign' : undefined
                }
                review
              />
              <div className="toolbar-scroll-fix cw-print-hide">
                <Toolbar>
                  {!isDraft && (
                    <Toolbar.Item
                      icon={print_icon}
                      name="Print"
                      onClick={() => window.print()}
                    />
                  )}
                  {isDraft || isRejected ? (
                    <Toolbar.Item
                      icon={feedback_icon}
                      fontIcon="fas fa-trash toolbar-component__icon w-35"
                      name="Delete"
                      onClick={() => this.handleDelete()}
                    />
                  ) : null}
                  {isAwaitingSetup ? (
                    <Toolbar.DownloadItem
                      svgIcon={DownloadIcon}
                      name="Download purchase order"
                      onClick={(e, setDWloader) =>
                        handleDownloadPOClick(setDWloader)
                      }
                    />
                  ) : null}
                  {isAwaitingSetup ? (
                    <Toolbar.Item
                      svgIcon={MarkScheduledIcon}
                      // fontIcon="fas fa-trash toolbar-component__icon w-35"
                      name="Mark as scheduled"
                      onClick={() => handleMarkScheduleClick()}
                    />
                  ) : null}
                  {isAwaitingSetup ? (
                    <Toolbar.Item
                      svgIcon={RequestMoreInfoIcon}
                      // fontIcon="fas fa-trash toolbar-component__icon w-35"
                      name="Request more info"
                      onClick={() => handleRequestInfoClick()}
                    />
                  ) : null}
                  {showCostOrRequestMoreInfo ||
                  showRequestMoreInfoRequestorFlow ? (
                    <Toolbar.Item
                      svgIcon={RequestMoreInfoIcon}
                      // fontIcon="fas fa-trash toolbar-component__icon w-35"
                      name="Share cost or request info"
                      onClick={() => handleCostAndRequestInfoClick()}
                    />
                  ) : null}

                  {constants.SMS_STATUS.SCHEDULED === status ? (
                    <Toolbar.Item
                      svgIcon={MarkScheduledIcon}
                      // fontIcon="fas fa-trash toolbar-component__icon w-35"
                      name="Mark as live"
                      onClick={() => handleMarkLiveClick()}
                    />
                  ) : null}
                  <Toolbar.Item
                    icon={feedback_icon}
                    name="Feedback"
                    onClick={() => handleOnOpenFeedback()}
                  />
                </Toolbar>
              </div>
            </div>
            {status === constants.SMS_STATUS.AWAITING_FOR_APPROVAL ? (
              <div className="mb-30">
                <EmailApproverInfo
                  emailBriefing={smsProps && smsProps.smsDetailsOverview}
                />
              </div>
            ) : null}
            <div className="ml-2 mb-30">
              <EmailGuidelineText
                isOpenGuidelineModel={isOpenAcsGuidelineModel}
                titleText={`Please setup the SMS campaign in ACS using these `}
                guideLineName={`setup guidelines`}
              />
            </div>
            {isDraft && (
              <div className="d-flex">
                <div className="continue-section-empty"></div>
                <div className="continue-camp-btn-container">
                  <button
                    className="btn btn-primary w-100"
                    onClick={continueCampaign}
                  >
                    Continue
                  </button>
                </div>
              </div>
            )}
            <Layout
              className="mt-50"
              sidebar={
                <MobileDevicePreview
                  className="mobile-preview-overview"
                  data={mobilePreviewData}
                  campaign={smsProps.smsDetailsOverview}
                  alignHeight={!isAwaitingSetup ? true : false}
                  showActions={isAwaitingSetup}
                  {...editProps}
                />
              }
            >
              <SMSSummaryCards smsCampaign={smsProps.smsDetailsOverview} />
              <CommunicationsOverviewTables
                editable={false}
                smsCampaign={smsProps.smsDetailsOverview}
                downloadTrigger={(d1, setLoader) => {
                  console.log('d1: ', d1);
                  console.log('setLoader: ', setLoader);
                  handleDownloadCostEstimateClick(setLoader);
                }}
                downloadPOTrigger={(d2, setLoader) => {
                  console.log('d2: ', d2);
                  console.log('setLoader: ', setLoader);
                  handleDownloadPOClick(setLoader);
                }}
              />

              {constants.SMS_STATUS.SCHEDULED === status ? (
                <BackAndNext
                  backTitle=""
                  onClickBack={''}
                  onClickNext={handleMarkLiveClick}
                  nextTitle={'Mark as Live'}
                />
              ) : (
                ''
              )}
              {constants.SMS_STATUS.AWAITING_COST_ESTIMATE === status ||
              showRequestMoreInfoRequestorFlow ? (
                <BackAndNext
                  backTitle=""
                  onClickBack={''}
                  onClickNext={handleCostAndRequestInfoClick}
                  nextTitle={'Share cost or request info'}
                />
              ) : (
                ''
              )}
              {isAwaitingSetup ? (
                <div className="mb-20">
                  <BackAndNext
                    backTitle="Mark as scheduled"
                    onClickBack={handleMarkScheduleClick}
                    nextTitle="Request more info"
                    onClickNext={handleRequestInfoClick}
                    invertStyles={true}
                  />
                </div>
              ) : (
                ''
              )}
              <div className="overview-page-history mt-20">
                {smsProps.historyLogs && smsProps.historyLogs.length ? (
                  <HistoryComponent
                    historyLogs={smsProps.historyLogs}
                    isEmailBrief={true}
                  />
                ) : null}
              </div>
            </Layout>
          </div>
        </div>
      </>
    );
  }
};

export default SMSExecution;
