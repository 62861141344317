import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormBuilder } from '../../components/organisms/FormBuilder';
import Loader from '../../components/Loader';
import formSchema from './smsCampaignSummarySchema';
import ProgressBar from 'components/molecules/ProgressBar';
import { Redirect } from 'react-router-dom';
import { CustomButtons } from 'pages/EmailCampaignAudience';
import { retrieveSmsCampaignInformation } from 'actions/communications/communicationCampaignDetails.js';
import constants from 'constants/config';

const SmsCampaignSummary = props => {
  const [isEdit, setEdit] = useState(false);
  const communicationCampaignDetails = useSelector(
    state => state.communicationCampaignDetails
  );

  const { dataToSend } = useSelector(state => state.communicationBrandDetails);
  const { authorizedUserData } = useSelector(state => state.authorized);
  const { country, brand, language } = dataToSend || {};
  const dispatch = useDispatch();
  const pathUid = props.match.params.id;
  useEffect(() => {
    if (pathUid) {
      setEdit(true);
      // fetch from email details from the server
      dispatch(retrieveSmsCampaignInformation(pathUid));
    }
  }, []);
  console.log('communicationCampaignDetails: ', communicationCampaignDetails);
  const smsBrandDetails =
    communicationCampaignDetails &&
    communicationCampaignDetails.smsBrandDetails;
  const smsCampaignType =
    communicationCampaignDetails &&
    communicationCampaignDetails.smsCampaignType;
  if (!smsBrandDetails) return null;
  const { isLoading } = communicationCampaignDetails;
  const { uid } = smsBrandDetails;
  console.log('smsBrandDetails: ', smsBrandDetails);

  console.log('smsCampaignType: ', smsCampaignType);
  if (!pathUid && (!country || !brand || !language) && !smsCampaignType) {
    return (
      <Redirect to={`/campaign-wizard/${constants.SMS_ROUTES.NEW_CAMPAIGN}`} />
    );
  }
  const compProps = {
    history: props.history,
    match: props.match,
    defaultValues: uid !== pathUid ? {} : { ...smsBrandDetails } || {},
    campaignDetails: smsBrandDetails,
    campaignBrandDetails: dataToSend,
    campaignType: smsCampaignType
  };
  return (
    <div className="col-sm-12 skypink">
      <div className="cw-section">
        <ProgressBar
          customClass={'cw-email-progress-custom'}
          pageNo={constants.SMS_JOURNEY_PAGE_NUMBERS.CAMPAIGN_SUMMARY}
          totalPage={constants.SMS_PROGRESS_BAR_TOT_PAGE}
        />

        <div className="cw-section--title mb-30">
          <h2 className="cw-heading--secondary cw-email-heading">
            Campaign summary
          </h2>
        </div>
        <div className="row">
          {isLoading ? <Loader /> : null}
          <div className="col-md-8">
            <FormBuilder
              schema={formSchema(dispatch, compProps)}
              user={authorizedUserData}
              isEdit={isEdit}
              customButtonsComp={CustomButtons}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmsCampaignSummary;
