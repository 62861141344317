import { DONT_SEND } from 'components/molecules/EmailScheduleInput';
import {
  firstAndFollowUpSchema,
  firstAttemptSchema
} from 'components/organisms/BeeEmailEditor/saveSubjectAndPreheaderSchema';
import _ from 'lodash';
import SummarySchema from 'pages/EmailCampaignSummary/emailCampaignSummarySchema';
import campaignAttributes from 'pages/CampaignAttributes/campaignAttributesSchema';
import deliveryScheduleSchema from '../EmailCampaignSchedule/emailCampaignScheduleSchema';
import audienceDefinitionSchema from '../EmailCampaignAudience/emailCampaignAudienceSchema';
// import emailCollaboratorsSchema from 'pages/EmailCollaborators/emailCollaboratorsSchema';
import emailABTestsSchema from 'pages/EmailABTests/emailABTestsSchema';
import {
  transformEmailBriefingData,
  hasEmailContentABTest,
  getRowAndVariantComboInfo
} from 'selectors';

export const schemas = {
  summary: SummarySchema(null, {}).getSchema(),
  campaignAttributes: campaignAttributes(null, {}).getSchema(),
  deliverySchedule: emailBriefing =>
    deliveryScheduleSchema(null, {
      abTesting: emailBriefing.abTesting,
      emailType: emailBriefing.emailType
    }).getSchema(),
  audienceDefinition: audienceDefinitionSchema(null, {}).getSchema(),
  firstAndFollowUp: emailBriefing =>
    firstAndFollowUpSchema(null, {}, null, { emailBriefing }).getSchema(),
  firstAttempt: emailBriefing =>
    firstAttemptSchema(null, {}, null, { emailBriefing }).getSchema(),
  // emailCollaborators: emailCollaboratorsSchema(null, {}).getSchema(),
  emailABTesting: emailABTestsSchema(null, {}).getSchema()
};

const getDecodedValue = value => {
  return decodeURIComponent(value || '');
};

export const validateEmailBriefing = async emailBriefing => {
  let transformedData = transformEmailBriefingData(
    emailBriefing,
    'emailDetails',
    true
  );
  const summary = await schemas.summary.isValid({
    ...emailBriefing.summary,
    emailCollaborators: emailBriefing.emailCollaborators
  });
  const deliverySchedule = await schemas
    .deliverySchedule(transformedData)
    .isValid(transformedData.deliverySchedule);
  const campaignAttributesData = {
    emailAttributes: {
      emailCoupon: emailBriefing?.emailAttributes?.emailCoupon?.couponApplied,
      emailCouponType: emailBriefing?.emailAttributes?.emailCoupon?.type,
      shareCouponFileViaKana:
        emailBriefing?.emailAttributes?.emailCoupon?.shareCouponFileViaKana,
      couponCode: emailBriefing?.emailAttributes?.emailCoupon?.code,
      kanaTicketNumber:
        emailBriefing?.emailAttributes?.emailCoupon?.kanaTicketNumber
    }
  };
  const campaignAttributes = await schemas.campaignAttributes.isValid(
    campaignAttributesData,
    emailBriefing.abTesting
  );

  let deliveryScheduleError = null;
  try {
    await schemas
      .deliverySchedule(transformedData)
      .validate(transformedData.deliverySchedule);
  } catch (err) {
    deliveryScheduleError = err;
  }

  const audienceDefinition = await schemas.audienceDefinition.isValid(
    transformedData.audienceDefinition
  );

  let emailProperties = false;

  const isDontSend =
    emailBriefing.deliverySchedule?.sendAfter?.value === DONT_SEND;
  if (isDontSend) {
    emailProperties = await schemas.firstAttempt(emailBriefing).isValid({
      subject: getDecodedValue(emailBriefing?.firstAttempt?.subject),
      subjectVariantB: getDecodedValue(
        emailBriefing?.firstAttempt?.subjectVariantB
      ),
      subjectVariantC: getDecodedValue(
        emailBriefing?.firstAttempt?.subjectVariantC
      ),
      preheader: emailBriefing?.firstAttempt?.preheader
    });
  } else {
    emailProperties = await schemas.firstAndFollowUp(emailBriefing).isValid({
      subject: getDecodedValue(emailBriefing?.firstAttempt?.subject),
      subjectVariantB: getDecodedValue(
        emailBriefing?.firstAttempt?.subjectVariantB
      ),
      subjectVariantC: getDecodedValue(
        emailBriefing?.firstAttempt?.subjectVariantC
      ),
      firstAttemptPreheader: emailBriefing?.firstAttempt?.preheader,
      followUpSubject: getDecodedValue(emailBriefing?.followUp?.subject),
      followUpSubjectB: getDecodedValue(
        emailBriefing?.followUp?.subjectVariantB
      ),
      followUpSubjectC: getDecodedValue(
        emailBriefing?.followUp?.subjectVariantC
      ),
      followUpPreheader: emailBriefing?.followUp?.preheader
    });
  }

  // const emailCollaborators = await schemas.emailCollaborators.isValid(
  //   emailBriefing
  // );
  const emailABTesting = await schemas.emailABTesting.isValid(emailBriefing);
  const dynamicCouponError = validateDynamicCoupon(emailBriefing);
  return {
    summary: !summary,
    campaignAttributes: !campaignAttributes,
    deliverySchedule: !deliverySchedule,
    deliveryScheduleError: deliveryScheduleError,
    audienceDefinition: !audienceDefinition,
    emailProperties: !emailProperties,
    emailTemplateEditId: _.isEmpty(emailBriefing?.emailTemplateEditId),
    // emailCollaborators: !emailCollaborators,
    requestInfoAnswerText: _.isEmpty(emailBriefing?.requestInfoAnswerText),
    emailABTesting: !emailABTesting,
    dynamicCouponError: !dynamicCouponError
  };
};

export const validateEmailContent = (emailBriefing, emailEditTemplate) => {
  if (hasEmailContentABTest(emailBriefing) && emailEditTemplate) {
    const noOfABVariants = _.get(emailBriefing, 'abTesting.noOfABVariants');
    const assignedVariants = getRowAndVariantComboInfo(
      emailEditTemplate.templateObject
    );
    if (noOfABVariants === '2') {
      const missingVariants = [];
      if (!assignedVariants.variantA.isAssigned) {
        missingVariants.push('Variant A');
      }
      if (!assignedVariants.variantB.isAssigned) {
        missingVariants.push('Variant B');
      }
      if (missingVariants.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      const missingVariants = [];
      if (!assignedVariants.variantA.isAssigned) {
        missingVariants.push('Variant A');
      }
      if (!assignedVariants.variantB.isAssigned) {
        missingVariants.push('Variant B');
      }
      if (!assignedVariants.variantC.isAssigned) {
        missingVariants.push('Variant C');
      }
      if (missingVariants.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }
  return false;
};

export const validateDynamicCoupon = emailBriefing => {
  const { emailAttributes } = emailBriefing;
  if (emailAttributes) {
    if (emailAttributes?.emailCoupon?.type === 'dynamicCoupon') {
      const couponFileUpload = emailAttributes?.emailCoupon?.couponFileUpload;
      if (couponFileUpload === 'failed') {
        if (emailAttributes?.emailCoupon?.kanaTicketNumber) {
          return true;
        } else {
          return false;
        }
      }
      if (couponFileUpload === 'success') {
        return true;
      }

      if (couponFileUpload === 'pending' || couponFileUpload === '') {
        return false;
      }
    }
    return true;
  }
  return true;
};
