import axios from 'axios';
import constant from '../../constants';
import _ from 'lodash';
import {
  GET_SMS_OVERVIEW_DETAIL,
  SMS_SERVICE_ERROR,
  SMS_ASYNC_IN_PROGRESS
} from '../../actionTypes';

export const getSmsOverview = uid => {
  return function(dispatch) {
    dispatch({ type: SMS_ASYNC_IN_PROGRESS, isLoading: true });
    axios
      .get(constant.communications.GET_SMS_REVIEW_DETAIL + '/' + uid)
      .then(response => {
        let smsDetailsOverview = response.data;
        dispatch({
          type: GET_SMS_OVERVIEW_DETAIL,
          smsDetailsOverview
        });
      })
      .catch(error => {
        let errorResponse = error.response && error.response.status;
        dispatch({
          type: SMS_SERVICE_ERROR,
          smsServiceError: errorResponse
        });
      })
      .finally(() => {
        dispatch({ type: SMS_ASYNC_IN_PROGRESS, isLoading: false });
      });
  };
};
