import React from 'react';
import { FormInput } from '../FormInput';
import constants from 'constants/config';
import ReactTooltip from 'react-tooltip';

const customComponent = () => {
  return (
    <span className="float-right">
      <a
        className="cw-qna-faq-link"
        href={constants.PRA_LEARN_MORE_LINK}
        target="_blank"
        rel="noopener noreferrer"
      >
        {'Learn more on PRA'} &nbsp;
        <i className="fas fa-external-link-alt"></i>
      </a>
    </span>
  );
};

export const SmsPraInput = props => {
  return (
    <>
      <div className="cw-form--legend mb-10">
        <label>
          Privacy Risk Assessment Confirmation
          <div className="description mb-10">
            {`Confirm you have completed privacy risk assessment.`}
            <ReactTooltip
              id={'sms_dpc_pra_tooltip'}
              place="right"
              type="info"
              multiline={true}
              className="cw-tooltip cw-email-tooltip"
            />
            <span
              className="float-right  cw-icon--help"
              data-for="sms_dpc_pra_tooltip"
              data-tip="You need to have completed the privacy risk assessment in Scout before you begin collecting consumer data. Enter your PRA ID from Scout here to confirm. Please ensure you enter an authentic ID as your campaign requests may be subject to audit by Unilever's privacy teams."
            >
              <i
                style={{ color: '#3c373a' }}
                className="fas fa-question-circle"
              ></i>
            </span>
          </div>
        </label>
      </div>
      <FormInput
        id={props.id}
        type={props.type}
        name={props.name}
        label={props.label}
        labelWrapperClass={'justify-content-between'}
        placeholder={props.placeholder}
        register={props.register}
        rules={props.rules}
        errors={props.errors}
        key={props.id}
        defaultValue={props.defaultValue}
        maxLength={props.maxLength}
        setValue={props.setValue}
        disabled={props.disabled}
        customComponent={customComponent}
      />
    </>
  );
};
