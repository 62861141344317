import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import TabPanelContent from '../TabPanelContent';
import Modal from 'react-modal';
import { NavLink } from 'react-router-dom';

import constant from '../../constants';
import CheckboxDropDown from '../CheckboxDropDown';
import { getSplitDashboardArray } from '../../selectors';
import {
  fetchUserData,
  editUserData,
  addUserData,
  deleteUserData,
  checkDeletionStatus,
  updateApproverCountry,
  addReleaseNoteData,
  fetchApproverFilterData,
  updateBusinessGroup,
  resetAllFlags
} from '../../actions/user-management';
import { fetchDomainData } from '../../actions/user-management/requesterTab';
import RequesterTab from 'components/user-management/RequesterTab';
import ReleaseUpdateOverlay from 'pages/ReleaseNoteCommunication/ReleaseUpdateOverlay';

import DateTimeField from 'react-datetime';
import { Icon } from 'semantic-ui-react';
import RichTextEditor from '../richTextEditor';
import DeleteIcon from '../../images/times.png';
import ApproverContentTable from '../ApproverContentTable';

class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.activeEmailErrorRef = React.createRef();
    this.state = {
      modalIsOpen: false,
      modalUserType: '',
      activeModal: '',
      tabIndex: 0,
      deleteUsername: '',
      deleteUsergroup: '',
      selectedCountry: [],
      selectedCampaign: [],
      formElements: {
        name: '',
        email: '',
        emailaccess: '',
        loginaccess: '',
        isdatacampaign: '',
        isemailcampaign: '',
        issmscampaign: '',
        group: '',
        country: '',
        businessGroup: ''
      },
      disableCheckbox: false,
      releaseDescChars: 0,
      selectedCountryForBu: [],
      businessGroupMapError: false,
      selectedBusinessFilter: [],
      searchText: ''
    };
    this.formElements = {
      name: '',
      email: '',
      emailaccess: '',
      loginaccess: '',
      isdatacampaign: '',
      isemailcampaign: '',
      issmscampaign: '',
      group: '',
      country: '',
      releasedateText: '',
      featureSummaryText: '',
      descriptionText: '',
      businessGroup: '',
      selectedCountryForBu: ''
    };
    this.errorFields = [];
    this.isValid = false;
    this.formfieldsValid = true;
    this.sortKey = 'lastUpdatedDate';
    this.sortBy = 'desc';

    this.activeEmailErrorRef = React.createRef();
    this.marketErrorRef = React.createRef();
    this._validateField = this._validateField.bind(this);
    this._openModal = this._openModal.bind(this);
    this._closeModal = this._closeModal.bind(this);
    this._addUser = this._addUser.bind(this);
    this._validateForm = this._validateForm.bind(this);
    this._addErrorFields = this._addErrorFields.bind(this);
    this._filterErrorFields = this._filterErrorFields.bind(this);
    this._handleInputChange = this._handleInputChange.bind(this);
    this._editUser = this._editUser.bind(this);
    this._deleteUser = this._deleteUser.bind(this);
    this._updateUserData = this._updateUserData.bind(this);
    this._sortUserData = this._sortUserData.bind(this);
    this._checkDeletionStatus = this._checkDeletionStatus.bind(this);
    this._resetFormElements = this._resetFormElements.bind(this);
    this._handleCountries = this._handleCountries.bind(this);
    this._validateDropDownField = this._validateDropDownField.bind(this);
    this._addReleaseNote = this._addReleaseNote.bind(this);
    this._handleReleaseDate = this._handleReleaseDate.bind(this);
    this._releaseNoteRichTextData = this._releaseNoteRichTextData.bind(this);
    this._handleBusinessGroup = this._handleBusinessGroup.bind(this);
    this._clearAllBuFilters = this._clearAllBuFilters.bind(this);
    this._removeBuFilter = this._removeBuFilter.bind(this);
    this._addCountryBusinessGroup = this._addCountryBusinessGroup.bind(this);
    this.handleBusinessGroupClick = this.handleBusinessGroupClick.bind(this);
    this._removeCoutryBusinessGroup = this._removeCoutryBusinessGroup.bind(
      this
    );
  }
  componentDidMount() {
    this.props.resetAllFlags();
    this.props.fetchUserData('approver');
    this.sortKey = 'lastUpdatedDate';
    this.sortBy = 'desc';
    document
      .querySelector('[data-type="lastUpdatedDate"]')
      .classList.add('current-sorted');
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.tabIndex !== this.state.tabIndex &&
      document.querySelector('[data-type="lastUpdatedDate"]')
    ) {
      document
        .querySelector('[data-type="lastUpdatedDate"]')
        .classList.add('current-sorted');
    }
  }

  _emailHasValidDomain(value) {
    return constant.EMAIL_SENDTEST_VALID_DOMAINS.includes(value.split('@')[1]);
  }

  _validateField(event) {
    if (event.target) {
      if (
        event.target.name !== 'emailaccess' &&
        event.target.name !== 'loginaccess' &&
        event.target.name !== 'isdatacampaign' &&
        event.target.name !== 'isemailcampaign' &&
        event.target.name !== 'issmscampaign'
      ) {
        if (
          document
            .querySelector("[name='" + event.target.name + "']")
            .parentElement.classList.contains('error')
        ) {
          document
            .querySelector("[name='" + event.target.name + "']")
            .parentElement.classList.remove('error');
        }
        if (
          !document
            .querySelector("[data-control='" + event.target.name + "']")
            .classList.contains('cw-hidden')
        ) {
          document
            .querySelector("[data-control='" + event.target.name + "']")
            .classList.add('cw-hidden');
        }
      } else {
        if (
          document
            .querySelector("[name='emailaccess']")
            .parentElement.classList.contains('error')
        ) {
          document
            .querySelector("[name='emailaccess']")
            .parentElement.classList.remove('error');
        }
        if (
          !document
            .querySelector("[data-control='emailaccess']")
            .classList.contains('cw-hidden')
        ) {
          document
            .querySelector("[data-control='emailaccess']")
            .classList.add('cw-hidden');
        }
      }
    }
  }

  _validateForm(event) {
    let formElements = this.formElements;
    for (var key in formElements) {
      if (
        (key !== 'id' && key !== 'country') ||
        (key === 'country' && this.state.modalUserType === 'approver')
      ) {
        if (formElements.hasOwnProperty(key)) {
          let selector = document.querySelector("[name='" + key + "']");
          if (selector) {
            if (
              formElements[key] &&
              formElements[key] !== false &&
              (key !== 'country' || formElements[key].length != 0)
            ) {
              if (selector.parentElement.classList.contains('error')) {
                selector.parentElement.classList.remove('error');
              }
              // if (
              //   document
              //     .getElementById(`${key}`)
              //     .classList.contains('cw-error-focus')
              // ) {
              //   console.log(">>C")
              //   document
              //     .getElementById(`${key}`)
              //     .classList.remove('cw-error-focus');
              // }

              if (document.querySelector("[data-control='" + key + "']")) {
                if (
                  !document
                    .querySelector("[data-control='" + key + "']")
                    .classList.contains('cw-hidden')
                ) {
                  document
                    .querySelector("[data-control='" + key + "']")
                    .classList.add('cw-hidden');

                  document
                    .getElementById(`${key}`)
                    .classList.contains('cw-error-focus') &&
                    document
                      .getElementById(`${key}`)
                      .classList.remove('cw-error-focus');
                }
              }
              this.errorFields = this._filterErrorFields(this.errorFields, key);
            } else {
              if (
                key !== 'emailaccess' &&
                key !== 'loginaccess' &&
                key !== 'isdatacampaign' &&
                key !== 'isemailcampaign' &&
                key !== 'businessGroup' &&
                key !== 'issmscampaign'
              ) {
                if (!selector.parentElement.classList.contains('error')) {
                  selector.parentElement.classList.add('error');
                }

                if (document.querySelector("[data-control='" + key + "']")) {
                  if (
                    document
                      .querySelector("[data-control='" + key + "']")
                      .classList.contains('cw-hidden')
                  ) {
                    document
                      .querySelector("[data-control='" + key + "']")
                      .classList.remove('cw-hidden');

                    document.getElementById(`${key}`) &&
                      (document
                        .getElementById(`${key}`)
                        .classList.contains('cw-error-focus') ||
                        document
                          .getElementById(`${key}`)
                          .classList.add('cw-error-focus'));
                  }
                }
                this.errorFields = this._addErrorFields(this.errorFields, key);
              } else {
                if (
                  (this.state.modalUserType === 'approver' ||
                    this.state.modalUserType === 'acs') &&
                  (this.formElements['emailaccess'] === '' ||
                    this.formElements['emailaccess'] === false) &&
                  (this.formElements['loginaccess'] === '' ||
                    this.formElements['loginaccess'] === false)
                ) {
                  if (
                    !document
                      .querySelector("[name='emailaccess']")
                      .parentElement.classList.contains('error')
                  ) {
                    document
                      .querySelector("[name='emailaccess']")
                      .parentElement.classList.add('error');
                  }

                  if (document.querySelector("[data-control='emailaccess']")) {
                    if (
                      document
                        .querySelector("[data-control='emailaccess']")
                        .classList.contains('cw-hidden')
                    ) {
                      document
                        .querySelector("[data-control='emailaccess']")
                        .classList.remove('cw-hidden');
                    }
                  }
                  this.errorFields = this._addErrorFields(
                    this.errorFields,
                    'emailaccess'
                  );
                }
                if (
                  (this.state.modalUserType === 'approver' ||
                    this.state.modalUserType === 'acs') &&
                  (this.formElements['isdatacampaign'] === '' ||
                    this.formElements['isdatacampaign'] === false) &&
                  (this.formElements['isemailcampaign'] === '' ||
                    this.formElements['isemailcampaign'] === false) &&
                  (this.formElements['issmscampaign'] === '' ||
                    this.formElements['issmscampaign'] === false)
                ) {
                  if (
                    !document
                      .querySelector("[name='isdatacampaign']")
                      .parentElement.classList.contains('error')
                  ) {
                    document
                      .querySelector("[name='isdatacampaign']")
                      .parentElement.classList.add('error');
                  }

                  if (
                    document.querySelector("[data-control='isdatacampaign']")
                  ) {
                    if (
                      document
                        .querySelector("[data-control='isdatacampaign']")
                        .classList.contains('cw-hidden')
                    ) {
                      document
                        .querySelector("[data-control='isdatacampaign']")
                        .classList.remove('cw-hidden');
                    }
                  }
                  this.errorFields = this._addErrorFields(
                    this.errorFields,
                    'isdatacampaign'
                  );
                }
              }
            }
          }
        }
      }
    }

    if (this.errorFields.length === 0 && this.formfieldsValid) {
      this.isValid = true;
    }
  }

  _addErrorFields(array, itemToAdd) {
    return array.filter(field => field !== itemToAdd).concat([itemToAdd]);
  }

  _filterErrorFields(array, itemToRemove) {
    return array.filter(item => item !== itemToRemove);
  }

  _handleInputChange(event) {
    let detailsToUpdate = {
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    };
    if (document.getElementById(`${event.target.name}`)) {
      document
        .getElementById(`${event.target.name}`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`${event.target.name}`)
          .classList.remove('cw-error-focus');
    }

    this.formElements[event.target.name] =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;

    if (event.target.name === 'descriptionText') {
      this.setState({ releaseDescChars: event.target.value.length });
    }
    this.setState({ formElements: this.formElements });
    if (event.target.dataset.customError === 'true') {
      // Email Format Validtion
      let regexPattern = constant.regex[event.target.dataset.customErrorType];
      if (regexPattern.test(event.target.value) || event.target.value === '') {
        document
          .querySelector('[data-custom-msg=' + event.target.name + ']')
          .classList.add('cw-hidden');
        this.formfieldsValid = true;
      } else {
        this.formfieldsValid = false;
        document
          .getElementById(event.target.name)
          .classList.add('cw-error-focus');
        document
          .querySelector('[data-custom-msg=' + event.target.name + ']')
          .classList.remove('cw-hidden');
      }

      // email doamin validation
      if (
        regexPattern.test(event.target.value) &&
        !this._emailHasValidDomain(event.target.value)
      ) {
        document
          .querySelector('[data-email-domain=' + event.target.name + ']')
          .classList.remove('cw-hidden');
        document
          .getElementById(event.target.name)
          .classList.add('cw-error-focus');
        document
          .getElementById(event.target.name)
          .parentElement.classList.add('error');
        this.formfieldsValid = false;
      } else {
        this.formfieldsValid = true;
        document
          .querySelector('[data-email-domain=' + event.target.name + ']')
          .classList.add('cw-hidden');
        document
          .getElementById(event.target.name)
          .parentElement.classList.remove('error');
      }

      if (
        regexPattern.test(event.target.value) &&
        this._emailHasValidDomain(event.target.value)
      ) {
        document
          .getElementById(event.target.name)
          .classList.remove('cw-error-focus');
      }
    }

    if (event.target.dataset && event.target.dataset.required === 'true') {
      this._validateField(event);
    }
    if (event.target.name === 'email') {
      this.activeEmailErrorRef.current.classList.add('cw-hidden');
    }
    if (
      event.target.name === 'isemailcampaign' ||
      event.target.name === 'issmscampaign' ||
      event.target.name === 'isdatacampaign'
    ) {
      this.errorFields = this._filterErrorFields(
        this.errorFields,
        'isdatacampaign'
      );
      if (document.querySelector("[data-control='isdatacampaign']")) {
        if (
          !document
            .querySelector("[data-control='isdatacampaign']")
            .classList.contains('cw-hidden')
        ) {
          document
            .querySelector("[data-control='isdatacampaign']")
            .classList.add('cw-hidden');
        }
      }
    }
  }

  _getCountries() {
    let { countries } = this.props;
    if (Object.keys(countries).length > 0) {
      return countries.map(function(brand) {
        return { value: brand.code + '-' + brand._id, label: brand.name };
      });
    }
  }

  _getBusinessGroup() {
    let { businessGroup } = this.props;
    if (businessGroup && Object.keys(businessGroup).length > 0) {
      return businessGroup.map(function(bu) {
        return { value: bu._id, label: bu.name };
      });
    }
  }

  _sortUserData(
    e,
    type,
    selectedCountryArr,
    selectedCampaignFilter,
    selectedBusinessFilter,
    searchText
  ) {
    let classname = e.target.className;
    let updatedClassName;
    document
      .querySelectorAll('.current-sorted')
      .forEach(el => el.classList.remove('current-sorted'));
    if (classname.indexOf('--asc') >= 0) {
      this.sortBy = 'asc';
      updatedClassName = 'cell cw-sort cw-sort--desc current-sorted';
    } else {
      this.sortBy = 'desc';
      updatedClassName = 'cell cw-sort cw-sort--asc current-sorted';
    }
    this.sortKey = e.currentTarget.getAttribute('data-type');
    const filter = {
      selectedCountry: selectedCountryArr,
      selectedCampaign: selectedCampaignFilter,
      selectedBusiness: selectedBusinessFilter,
      sortBy: this.sortBy,
      sortKey: this.sortKey
    };
    if (
      type === 'approver' &&
      (selectedCountryArr.length ||
        selectedCampaignFilter.length ||
        selectedBusinessFilter.length)
    ) {
      this.props.fetchApproverFilterData('approver', filter, searchText);
    } else {
      type === 'requester'
        ? this.props.fetchDomainData(this.sortBy, this.sortKey)
        : this.props.fetchUserData(
            type,
            this.sortKey,
            this.sortBy,
            '',
            searchText
          );
    }
    e.target.className = updatedClassName;
  }

  _resetFormElements() {
    this.formElements['name'] = '';
    this.formElements['email'] = '';
    this.formElements['emailaccess'] = '';
    this.formElements['loginaccess'] = '';
    this.formElements['id'] = '';
    this.formElements['country'] = '';
    this.formElements['releasedateText'] = '';
    this.formElements['featureSummaryText'] = '';
    this.formElements['descriptionText'] = '';
    this.setState({ formElements: this.formElements });
    this.errorFields = [];
    this.isValid = false;
    this.formfieldsValid = true;
  }

  _validateDropDownField(field) {
    if (
      document
        .querySelector("[name='" + field + "']")
        .parentElement.classList.contains('error')
    ) {
      document
        .querySelector("[name='" + field + "']")
        .parentElement.classList.remove('error');
    }
    if (
      !document
        .querySelector("[data-control='" + field + "']")
        .classList.contains('cw-hidden')
    ) {
      document
        .querySelector("[data-control='" + field + "']")
        .classList.add('cw-hidden');
    }
  }

  _handleCountries(selectedOption) {
    document.getElementById(`country`).classList.contains('cw-error-focus') &&
      document.getElementById(`country`).classList.remove('cw-error-focus');

    this.formElements['country'] = selectedOption;
    this._validateDropDownField('country');
    this.marketErrorRef.current.classList.add('cw-hidden');
    this.props.updateApproverCountry({ country: selectedOption });
  }

  _handleBusinessGroup(selectedOption) {
    this.formElements['businessGroup'] = selectedOption;
    this._validateDropDownField('businessGroup');
    this.marketErrorRef.current.classList.add('cw-hidden');
    this.props.updateBusinessGroup({ businessGroup: selectedOption });
  }

  _addUser(event) {
    event.preventDefault();
    this._validateForm(event);
    if (this.marketErrorRef.current && this.marketErrorRef.current.classList) {
      this.marketErrorRef.current.classList.add('cw-hidden');
    }
    if (
      this.activeEmailErrorRef.current &&
      this.activeEmailErrorRef.current.classList
    ) {
      this.activeEmailErrorRef.current.classList.add('cw-hidden');
    }

    let newBugroupArrWithErr = [];
    let bugroupValid = false;
    if (
      this.state.selectedCountryForBu &&
      this.state.selectedCountryForBu.length
    ) {
      let newSelectedBgCountryArr = [];
      for (const cou of this.state.selectedCountryForBu) {
        if (cou && cou.value) {
          let newObje = {
            countryId: cou.value.split('-')[1],
            businessGroup: cou.selectedBusinessGroup
              ? cou.selectedBusinessGroup
              : []
          };
          if (newObje && newObje.businessGroup.length === 0) {
            cou['error'] = true;
            newBugroupArrWithErr.push(cou);
            bugroupValid = true;
          } else {
            cou['error'] = false;
            newBugroupArrWithErr.push(cou);
          }
          newSelectedBgCountryArr.push(newObje);
        }
      }
      this.formElements['userRole'] = newSelectedBgCountryArr;
    }
    this.setState({ selectedCountryForBu: newBugroupArrWithErr });
    if (
      this.formElements['country'] &&
      this.formElements['country'].length &&
      this.state.selectedCountryForBu &&
      this.state.selectedCountryForBu.length === 0
    ) {
      this.setState({
        businessGroupMapError: true
      });
      this.isValid = false;
    } else {
      this.setState({
        businessGroupMapError: false
      });
    }

    if (this.isValid && !bugroupValid) {
      this.formElements['group'] = this.state.modalUserType;
      if (this.formElements['country'] && this.formElements['country'].length) {
        let countryid = [];
        countryid = getSplitDashboardArray(this.formElements['country']);
        this.formElements['country'] = countryid['id'];
      }

      this.setState({ formElements: this.formElements });
      const sortByKey =
        this.state.modalUserType === 'approver' ? this.sortKey : null;
      this.props
        .addUserData(this.state.formElements, sortByKey)
        .then(response => {
          if (!this.props.newUserAdded && this.props.emailAlreadyUse) {
            this.activeEmailErrorRef.current.classList.remove('cw-hidden');
            document
              .getElementById(`email`)
              .classList.contains('cw-error-focus') ||
              document.getElementById(`email`).classList.add('cw-error-focus');
          } else if (
            this.props.approverValidation &&
            this.props.approverValidation.approverValidation &&
            this.props.approverValidation.approverValidation === true
          ) {
            this.marketErrorRef.current.classList.remove('cw-hidden');
          } else {
            document
              .getElementById(`email`)
              .classList.contains('cw-error-focus') &&
              document
                .getElementById(`email`)
                .classList.remove('cw-error-focus');
            this._resetFormElements();
            this._closeModal();
            const filter = {
              selectedCountry: this.state.selectedCountry,
              selectedCampaign: this.state.selectedCampaign,
              selectedBusiness: this.state.selectedBusinessFilter,
              sortBy: this.sortBy,
              sortKey: this.sortKey
            };
            if (
              this.state.modalUserType === 'approver' &&
              (this.state.selectedCountry.length ||
                this.state.selectedCampaign.length ||
                this.state.selectedBusinessFilter.length ||
                this.state.searchText)
            ) {
              this.props.fetchApproverFilterData(
                'approver',
                filter,
                this.state.searchText
              );
              this.setState({
                selectedCountry: [],
                selectedCampaign: []
              });
            }
          }
        });
    }
  }

  _editUser(
    type,
    modaltype,
    userdata,
    selectedCountryArr,
    selectedCampaignFilter,
    userRole,
    selectedBusinessFilter,
    searchText
  ) {
    this.formElements['name'] = userdata['name'];
    this.formElements['email'] = userdata['emailId'];
    this.formElements['emailaccess'] = userdata['emailNotificationAccess'];
    this.formElements['loginaccess'] = userdata['loginAccess'];
    if (userdata && userdata['accessTo'] && userdata['accessTo'].length) {
      for (const ele of userdata['accessTo']) {
        if (ele['emailCampaign']) {
          this.formElements['isemailcampaign'] = ele['emailCampaign'];
        }
        if (ele['formCampaign']) {
          this.formElements['isdatacampaign'] = ele['formCampaign'];
        }
        if (ele['smsCampaign']) {
          this.formElements['issmscampaign'] = ele['smsCampaign'];
        }
      }
    }

    this.formElements['id'] = userdata['_id'];
    this.formElements['country'] = userdata['country'].length
      ? userdata['country']
      : '';
    if (userdata['country'].length) {
      let approverCountry = userdata['country'].map(countrydata => {
        return {
          value: countrydata['code'] + '-' + countrydata['_id'],
          label: countrydata['name']
        };
      });
      this.formElements['country'] = approverCountry;
      this.props.updateApproverCountry({ country: approverCountry });
    }

    if (
      this.props.userData.length === 1 &&
      userdata['emailNotificationAccess'] &&
      userdata['loginAccess']
    ) {
      this.setState({ disableCheckbox: true });
    } else {
      this.setState({ disableCheckbox: false });
    }
    this.setState({ formElements: this.formElements });
    this._openModal(type, modaltype, '');
    let newUserBusinessGroup = [];
    let newCountry = [];
    if (userRole && userRole.length) {
      // selectedCountryForBu
      for (let ele of userRole) {
        if (ele && ele.countryId.name) {
          let newObj = {
            label: ele && ele.countryId.name,
            selectedBusinessGroup: [],
            value: `${ele.countryId.code}-${ele.countryId._id}`
          };
          let newCountryObj = {
            label: ele && ele.countryId.name,
            value: `${ele.countryId.code}-${ele.countryId._id}`
          };
          if (ele && ele.businessGroup && ele.businessGroup.length) {
            for (const bu of ele.businessGroup) {
              if (bu && bu._id) {
                newObj.selectedBusinessGroup.push(bu._id);
              }
            }
          }
          newCountry.push(newCountryObj);
          newUserBusinessGroup.push(newObj);
        }
      }
      this.props.updateApproverCountry({ country: newCountry });
    }
    this.setState({
      selectedCountry: selectedCountryArr ? selectedCountryArr : [],
      selectedCampaign: selectedCampaignFilter ? selectedCampaignFilter : [],
      selectedCountryForBu: newUserBusinessGroup ? newUserBusinessGroup : [],
      selectedBusinessFilter: selectedBusinessFilter
        ? selectedBusinessFilter
        : [],
      searchText: searchText
    });
  }
  _updateUserData(event) {
    event.preventDefault();
    this._validateForm(event);

    if (!this._emailHasValidDomain(this.formElements['email'])) {
      let element = document.querySelector("[name='email']");
      if (element) {
        element.parentElement.classList.add('error');
        element.classList.add('cw-error-focus');
      }
      document
        .querySelector('[data-email-domain="email"]')
        .classList.remove('cw-hidden');
      this.isValid = false;
    }

    if (this.marketErrorRef.current && this.marketErrorRef.current.classList) {
      this.marketErrorRef.current.classList.add('cw-hidden');
    }
    if (
      this.activeEmailErrorRef.current &&
      this.activeEmailErrorRef.current.classList
    ) {
      this.activeEmailErrorRef.current.classList.add('cw-hidden');
    }

    if (this.isValid) {
      this.formElements['group'] = this.state.modalUserType;
      if (this.formElements['country'].length) {
        let countryid = [];
        countryid = getSplitDashboardArray(this.formElements['country']);
        this.formElements['country'] = countryid['id'];
      }
      let newBugroupArrWithErr = [];
      let bugroupValid = false;
      if (
        this.state.selectedCountryForBu &&
        this.state.selectedCountryForBu.length
      ) {
        let newSelectedBgCountryArr = [];
        for (const cou of this.state.selectedCountryForBu) {
          if (cou && cou.value) {
            let newObje = {
              countryId: cou.value.split('-')[1],
              businessGroup: cou.selectedBusinessGroup
                ? cou.selectedBusinessGroup
                : []
            };
            if (newObje && newObje.businessGroup.length === 0) {
              cou['error'] = true;
              newBugroupArrWithErr.push(cou);
              bugroupValid = true;
            } else {
              cou['error'] = false;
              newBugroupArrWithErr.push(cou);
            }
            newSelectedBgCountryArr.push(newObje);
          }
        }
        this.formElements['userRole'] = newSelectedBgCountryArr;
      }

      this.setState({
        formElements: this.formElements,
        selectedCountryForBu: newBugroupArrWithErr
      });
      if (
        this.formElements['country'] &&
        this.formElements['country'].length &&
        this.state.selectedCountryForBu &&
        this.state.selectedCountryForBu.length === 0
      ) {
        this.setState({
          businessGroupMapError: true
        });
        bugroupValid = true;
      } else {
        this.setState({
          businessGroupMapError: false
        });
      }
      if (!bugroupValid) {
        this.props.editUserData(this.state.formElements).then(response => {
          if (this.props.emailAlreadyUse) {
            this.activeEmailErrorRef.current.classList.remove('cw-hidden');
          } else if (
            this.props.approverValidation &&
            this.props.approverValidation.approverValidation &&
            this.props.approverValidation.approverValidation === true
          ) {
            this.marketErrorRef.current.classList.remove('cw-hidden');
          } else {
            this._resetFormElements();
            this._closeModal();
            const filter = {
              selectedCountry: this.state.selectedCountry,
              selectedCampaign: this.state.selectedCampaign,
              selectedBusiness: this.state.selectedBusinessFilter,
              sortBy: this.sortBy,
              sortKey: this.sortKey
            };
            if (
              this.state.modalUserType === 'approver' &&
              (this.state.selectedCountry.length ||
                this.state.selectedCampaign.length ||
                this.state.selectedBusinessFilter.length)
            ) {
              this.props.fetchApproverFilterData(
                'approver',
                filter,
                this.state.searchText
              );
            } else if (this.state.modalUserType === 'approver') {
              this.props.fetchUserData(
                'approver',
                this.sortKey,
                this.sortBy,
                '',
                this.state.searchText
              );
            }
          }
        });
      }
    }
  }

  _deleteUser(type, username, userBusinessGroup, userCountry, searchText) {
    const sortByKey =
      this.state.modalUserType === 'approver' ? this.sortKey : null;
    this.props
      .deleteUserData(type, username, userBusinessGroup, userCountry, sortByKey)
      .then(response => {
        this._closeModal();
        const filter = {
          selectedCountry: this.state.selectedCountry,
          selectedCampaign: this.state.selectedCampaign,
          selectedBusiness: this.state.selectedBusinessFilter,
          sortBy: this.sortBy,
          sortKey: this.sortKey
        };
        if (
          this.state.modalUserType === 'approver' &&
          (this.state.selectedCountry.length ||
            this.state.selectedCampaign.length ||
            this.state.selectedBusinessFilter.length)
        ) {
          this.props.fetchApproverFilterData('approver', filter, searchText);
        } else if (this.state.modalUserType === 'approver') {
          this.props.fetchUserData(
            'approver',
            this.sortKey,
            this.sortBy,
            '',
            this.state.searchText
          );
        }
      });
  }

  _openModal(
    type,
    activeModal,
    username,
    selectedCountryArr,
    selectedCampaignFilter,
    userBusinessGroup,
    userCountry,
    searchText,
    selectedBusinessGroup
  ) {
    let modalType = activeModal ? activeModal : '';
    let useremail = username ? username : '';
    this.errorFields = [];
    this.isValid = false;
    this.formfieldsValid = true;

    if (activeModal !== 'editUser') {
      this.setState({ disableCheckbox: false });
    }

    if (activeModal === 'addUser') {
      this._resetFormElements();
    }

    this.setState({
      modalIsOpen: true,
      modalUserType: type,
      activeModal: modalType,
      deleteUsername: useremail ? useremail : '',
      selectedCountry: selectedCountryArr ? selectedCountryArr : [],
      selectedCampaign: selectedCampaignFilter ? selectedCampaignFilter : [],
      userBusinessGroup: userBusinessGroup ? userBusinessGroup : [],
      userCountry: userCountry ? userCountry : [],
      selectedCountryForBu: [],
      searchText: searchText,
      selectedBusinessFilter: selectedBusinessGroup ? selectedBusinessGroup : []
    });
  }

  _checkDeletionStatus(
    group,
    emailid,
    selectedCountryArr,
    selectedCampaignFilter,
    userBusinessGroup,
    userCountry,
    selectedBusinessFilter,
    searchText
  ) {
    this.props.checkDeletionStatus(group, emailid).then(result => {
      if (!this.props.asyncInProgress && this.props.deletionStatusCheck) {
        this._openModal(
          group,
          'deletionConfirmation',
          emailid,
          selectedCountryArr,
          selectedCampaignFilter,
          userBusinessGroup,
          userCountry,
          searchText,
          selectedBusinessFilter
        );
      } else {
        this._openModal(
          group,
          'cannotdeletionConfirmation',
          emailid,
          selectedCountryArr,
          selectedCampaignFilter,
          null,
          null,
          null,
          selectedBusinessFilter
        );
      }
    });
  }

  _closeModal() {
    this.formElements['name'] = '';
    this.formElements['email'] = '';
    this.formElements['emailaccess'] = '';
    this.formElements['loginaccess'] = '';
    this.formElements['isdatacampaign'] = '';
    this.formElements['isemailcampaign'] = '';
    this.formElements['issmscampaign'] = '';
    this.setState({ formElements: this.formElements });
    this.setState({
      formElements: this.formElements,
      modalIsOpen: false,
      businessGroupMapError: false
    });
    this.props.updateApproverCountry({ country: [] });
  }

  _handleReleaseDate(event) {
    if (event._isValid) {
      event.target = { value: event.utc().format() };
      this.formElements.releasedateText = event.utc().format();
    }
  }
  _addReleaseNote(event) {
    event.preventDefault();
    this._validateForm(event);
    if (this.isValid) {
      this.formElements['group'] = this.state.modalUserType;
      this.formElements['published'] = true;
      this.setState({ formElements: this.formElements });
      // this.props.addReleaseNoteData(this.state.formElements).then(response => {
      //   if (response.status === 200) {
      //     this._resetFormElements();
      //     this._closeModal();
      //   } else {
      //     this.activeEmailErrorRef.current.classList.remove('cw-hidden');
      //   }
      // });
    }
  }

  _releaseNoteRichTextData(richTextData) {
    let defaultCopy = richTextData.getContent();
    let defaultCopyText = richTextData.getContent({ format: 'text' });
    this.formElements['descriptionText'] = defaultCopy;
    this.setState({ formElements: this.formElements });
    this.setState({ releaseDescChars: defaultCopyText.length });
  }

  getBuFiltersList(dataArr, key) {
    if (dataArr && dataArr.length) {
      return dataArr.map((item, indx) => {
        return (
          <div key={indx} className="cw-dashboard-filters">
            <div className="cw-dashboard-filters-label">{item['label']}</div>
            <div
              className="cw-dashboard-filters-remove"
              onClick={() => this._removeBuFilter(item, key, dataArr)}
            >
              <img src={DeleteIcon} alt="Remove filter icon" />
            </div>
          </div>
        );
      });
    } else {
      return '';
    }
  }

  getRemovablePills(dataArr, key) {
    return dataArr && dataArr.length ? (
      <div className="row mb-10">
        <div className="col-sm-10 justify-content-between align-items-center">
          {this.getBuFiltersList(dataArr, key)}
          {dataArr && dataArr.length >= 2 ? (
            <div className="cw-dashboard-filters clearall">
              <div
                className="cw-dashboard-filters-label clearalllabel"
                onClick={() => this._clearAllBuFilters(key)}
              >
                Clear all
              </div>
            </div>
          ) : null}
        </div>
      </div>
    ) : null;
  }

  _removeBuFilter(item, key, dataArr) {
    let remainingData = dataArr.filter(e => e.value !== item.value);
    this.formElements[key] = remainingData;
    if (key === 'businessGroup') {
      this.props.updateBusinessGroup({ businessGroup: remainingData });
      this.setState({
        selectedCountryForBu: remainingData
      });
    } else if (key === 'country') {
      this.setState({
        selectedCountryForBu: remainingData
      });
      this.props.updateApproverCountry({ country: remainingData });
    }
  }

  _clearAllBuFilters(key) {
    this.formElements[key] = [];
    if (key === 'businessGroup') {
      this.props.updateBusinessGroup({ businessGroup: [] });
      this.setState({
        selectedCountryForBu: []
      });
    } else if (key === 'country') {
      this.setState({
        selectedCountryForBu: []
      });
      this.props.updateApproverCountry({ country: [] });
    }
  }

  _addCountryBusinessGroup() {
    let key = 'country';
    if (this.props.country && this.props.country.length === 0) {
      if (document.querySelector("[data-control='" + key + "']")) {
        debugger;
        if (
          document
            .querySelector("[data-control='" + key + "']")
            .classList.contains('cw-hidden')
        ) {
          document
            .querySelector("[data-control='" + key + "']")
            .classList.remove('cw-hidden');

          document
            .getElementById(`${key}`)
            .classList.contains('cw-error-focus') ||
            document.getElementById(`${key}`).classList.add('cw-error-focus');
        }
      }
    } else {
      if (
        document.getElementById(`${key}`).classList.contains('cw-error-focus')
      ) {
        document.getElementById(`${key}`).classList.remove('cw-error-focus');
      }
      if (
        !document
          .querySelector("[data-control='" + key + "']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='" + key + "']")
          .classList.add('cw-hidden');
      }

      if (
        this.state.selectedCountryForBu &&
        this.state.selectedCountryForBu.length &&
        this.props.country &&
        this.props.country.length
      ) {
        const newCountry = this.props.country.filter(
          item =>
            !this.state.selectedCountryForBu.find(
              sbu => sbu.value === item.value
            )
        );
        this.setState({
          selectedCountryForBu: [
            ...this.state.selectedCountryForBu,
            ...newCountry
          ]
        });
      } else {
        this.setState({
          selectedCountryForBu: this.props.country
        });
      }
    }
  }

  handleBusinessGroupClick(event, countryIndex, selectBg, bgIndex) {
    const temp = JSON.parse(JSON.stringify(this.state.selectedCountryForBu));
    const countryObj = temp[countryIndex];
    if (!countryObj['selectedBusinessGroup']) {
      countryObj['selectedBusinessGroup'] = [];
    }
    if (event.target.checked) {
      countryObj['selectedBusinessGroup'].push(selectBg && selectBg.value);
    } else {
      countryObj['selectedBusinessGroup'] = countryObj[
        'selectedBusinessGroup'
      ].filter(e => e !== (selectBg && selectBg.value));
    }
    countryObj['error'] = false;
    temp[countryIndex] = countryObj;
    this.setState({
      selectedCountryForBu: temp
    });
  }

  _removeCoutryBusinessGroup(ele) {
    if (
      this.state.selectedCountryForBu &&
      this.state.selectedCountryForBu.length
    ) {
      const tempBusinessGroupArr = JSON.parse(
        JSON.stringify(this.state.selectedCountryForBu)
      );
      let remainingData = tempBusinessGroupArr.filter(
        e => e.value !== ele.value
      );
      this.formElements['country'] = remainingData;
      this._validateDropDownField('country');
      this.marketErrorRef.current.classList.add('cw-hidden');
      this.props.updateApproverCountry({ country: remainingData });
      this.setState({
        selectedCountryForBu: remainingData
      });
    }
  }

  render() {
    Modal.setAppElement('#root');
    let { userData, asyncInProgress, campaignConfig } = this.props;
    const newName = this.props.addedUsername || this.props.newDomainName;
    const deletedName =
      this.props.deletedUsername || this.props.deletedDomainName;

    let businessGroupColumn = {};
    if (campaignConfig && campaignConfig.APPROVER_BUSINESS_GROUP_ENABLE) {
      businessGroupColumn = {
        name: 'Business group',
        dataType: 'businessGroup',
        sort: true
      };
    }

    const aproverColumns = [
      { name: 'Country', dataType: 'country', sort: true },
      businessGroupColumn,
      { name: 'Name', dataType: 'name', sort: true },
      { name: 'Email', dataType: 'emailId', sort: true },
      { name: 'Module', dataType: '', sort: false },
      { name: 'Permissions', dataType: '', sort: false },
      { name: 'Last updated', dataType: 'lastUpdatedDate', sort: true },
      { name: '', dataType: 'actions', sort: false }
    ];

    const commonColumns = [
      { name: 'Name', dataType: 'name', sort: true },
      { name: 'Email', dataType: 'emailId', sort: false },
      { name: 'Permissions', dataType: '', sort: false },
      { name: 'Last updated', dataType: 'lastUpdatedDate', sort: true },
      { name: '', dataType: 'actions', sort: false }
    ];

    const acsColumns = [
      { name: 'Name', dataType: 'name', sort: true },
      { name: 'Email', dataType: 'emailId', sort: false },
      { name: 'Module', dataType: '', sort: false },
      { name: 'Permissions', dataType: '', sort: false },
      { name: 'Last updated', dataType: 'lastUpdatedDate', sort: true },
      { name: '', dataType: 'actions', sort: false }
    ];

    const requestorColumns = [
      { name: 'Name', dataType: 'name', sort: true },
      { name: 'Domain', dataType: 'emailId', sort: false },
      { name: 'Permissions', dataType: '', sort: false },
      { name: 'Last updated', dataType: 'lastUpdatedDate', sort: true }
      // { name: '', dataType: 'actions',sort: false  }
    ];

    return (
      <>
        <Modal
          isOpen={
            this.state.modalIsOpen &&
            (this.state.activeModal === 'addUser' ||
              this.state.activeModal === 'editUser')
          }
          onRequestClose={this._closeModal}
          className={`cw-modal ${
            this.state.modalUserType === 'approver'
              ? 'cw-modal--newapprover'
              : 'cw-modal--newuser'
          }`}
          contentLabel="Add new user"
          style={{
            overlay: {
              backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
            }
          }}
        >
          <button
            className="cw-modal--close"
            title="Close"
            onClick={this._closeModal}
          >
            <i className="fas fa-times"></i>
          </button>
          <h3 className="cw-heading--primary mb-10">
            {this.state.activeModal === 'editUser' ? 'Update' : 'Add new'} user
          </h3>
          <p className="mb-20">
            User Type:{' '}
            {this.state.modalUserType.charAt(0).toUpperCase() +
              this.state.modalUserType.slice(1)}
          </p>
          <div className="form-group">
            <label htmlFor="description">
              <span>Name</span>
              {this.state.modalUserType === 'approver' ? (
                ''
              ) : (
                <small> (required)</small>
              )}
            </label>
            <input
              className="form-control"
              type="text"
              name="name"
              id="name"
              data-required="true"
              value={this.state.formElements['name'] || ''}
              onChange={this._handleInputChange}
              rows="3"
            />
            <span className="cw-error cw-hidden mb-10" data-control="name">
              <i className="fas fa-exclamation-triangle mr-10"></i>Please enter
              name
            </span>
          </div>
          <div className="form-group">
            <label htmlFor="description">
              <span>Email</span>
              {this.state.modalUserType === 'approver' ? (
                ''
              ) : (
                <small> (required)</small>
              )}
            </label>
            <input
              className="form-control"
              type="text"
              name="email"
              id="email"
              onChange={this._handleInputChange}
              data-required="true"
              data-custom-error="true"
              value={this.formElements['email']}
              data-custom-error-type="email"
              rows="3"
            />
            <p className="email-text-font mt-1">
              Only unilever.com emails are allowed
            </p>
            <span className="cw-error cw-hidden mb-10" data-control="email">
              <i className="fas fa-exclamation-triangle mr-10"></i>Please enter
              valid email
            </span>
            <span
              className="cw-error cw-hidden mb-10"
              data-email-domain="email"
            >
              <i className="fas fa-exclamation-triangle mr-10"></i>
              Please enter a valid email. Only emails on domain unilever.com are
              allowed
            </span>
            <span className="cw-error cw-hidden" data-custom-msg="email">
              <i className="fas fa-exclamation-triangle mr-10"></i>
              Please enter valid email
            </span>
            <span
              className="cw-error cw-hidden"
              data-type="emailValidationMessage"
              data-custom-msg="domain-exists"
              ref={this.activeEmailErrorRef}
            >
              <i className="fas fa-exclamation-triangle mr-10"></i>
              Email already in use
            </span>
          </div>
          {this.state.modalUserType === 'approver' ||
          this.state.modalUserType === 'acs' ? (
            <div className="form-group">
              <label htmlFor="description">
                <span>Campaigns applicable</span>
              </label>
              <div className="row ml-1">
                <input
                  type="checkbox"
                  name="isdatacampaign"
                  id="isdatacampaign"
                  value="1"
                  data-required="true"
                  checked={this.formElements['isdatacampaign'] ? true : false}
                  onChange={this._handleInputChange}
                  // disabled={this.state.disableCheckbox ? true : false}
                />
                <label htmlFor="isdatacampaign">Data campaigns</label>
                <input
                  type="checkbox"
                  name="isemailcampaign"
                  id="isemailcampaign"
                  checked={this.formElements['isemailcampaign'] ? true : false}
                  value="1"
                  data-required="true"
                  onChange={this._handleInputChange}
                  // disabled={this.state.disableCheckbox ? true : false}
                />
                <label htmlFor="isemailcampaign" className="ml-3">
                  Email campaigns
                </label>
                <input
                  type="checkbox"
                  name="issmscampaign"
                  id="issmscampaign"
                  checked={this.formElements['issmscampaign'] ? true : false}
                  value="1"
                  data-required="true"
                  onChange={this._handleInputChange}
                />
                <label
                  htmlFor="issmscampaign"
                  className={`${
                    this.state.modalUserType === 'approver' ? 'ml-3' : 'mt-1'
                  }`}
                >
                  SMS campaigns
                </label>
              </div>
              <span
                className="cw-error cw-hidden mb-10"
                data-control="isdatacampaign"
              >
                <i className="fas fa-exclamation-triangle mr-10"></i>Select
                atleast one campaign
              </span>
            </div>
          ) : (
            ''
          )}
          <div className="form-group">
            <label htmlFor="permissions">
              <span>Permissions</span>
              {this.state.modalUserType === 'approver' ? (
                ''
              ) : (
                <small> (required)</small>
              )}
              <br />
              <small>Select at least one permission</small>
            </label>
            <input
              type="checkbox"
              name="emailaccess"
              id="emailaccess"
              value="1"
              data-required="true"
              checked={this.formElements['emailaccess'] ? true : false}
              onChange={this._handleInputChange}
              disabled={this.state.disableCheckbox ? true : false}
            />
            <label htmlFor="emailaccess">Email notifications</label>

            <br />
            <input
              type="checkbox"
              name="loginaccess"
              id="loginaccess"
              checked={this.formElements['loginaccess'] ? true : false}
              value="1"
              data-required="true"
              onChange={this._handleInputChange}
              disabled={this.state.disableCheckbox ? true : false}
            />
            <label htmlFor="loginaccess">Log-in access</label>
            <br />
            <span
              className="cw-error cw-hidden mb-10"
              data-control="emailaccess"
            >
              <i className="fas fa-exclamation-triangle mr-10"></i>Select
              atleast one permission
            </span>
          </div>
          {this.state.modalUserType === 'approver' ? (
            <>
              <div className="form-group">
                <label htmlFor="country">
                  <span>Country</span>
                </label>
                {this.getRemovablePills(this.props.country, 'country')}
                <div className="d-flex justify-content-between">
                  <div
                    className="justify-content-between flex-grow-1 align-items-center user-country"
                    id={'country'}
                  >
                    <CheckboxDropDown
                      isMulti={true}
                      className={
                        'cw-dashboard--usercountry user-managment-usercountry'
                      }
                      name={'country'}
                      label={'Country'}
                      hideSelectedOptions={false}
                      placeholder={'Select option'}
                      isDisabled={!this.props.countriesLoaded}
                      isLoading={!this.props.countriesLoaded}
                      value={this.props.country}
                      onChange={this._handleCountries}
                      options={this._getCountries()}
                      isClearable={false}
                      closeMenuOnSelect={false}
                      blurInputOnSelect={false}
                    />
                  </div>
                  <span
                    id={`add_non_prod_url}`}
                    className={'manage-non-prod-url manage-non-url mt-1 ml-1'}
                    onClick={this._addCountryBusinessGroup}
                  ></span>
                </div>

                <span
                  className="cw-error cw-hidden mb-10"
                  data-control="country"
                >
                  <i className="fas fa-exclamation-triangle mr-10"></i>Please
                  select country
                </span>
                <span
                  className="cw-error cw-hidden"
                  data-type="marketValidationMessage"
                  data-custom-msg="domain-exists"
                  ref={this.marketErrorRef}
                >
                  <i className="fas fa-exclamation-triangle mr-10"></i>
                  {this.props.approverValidation
                    ? this.props.approverValidation.message
                    : ''}
                </span>
                {this.state.selectedCountryForBu &&
                this.state.selectedCountryForBu.length > 0 ? (
                  <>
                    {this.state.selectedCountryForBu.map((ele, index) => {
                      return (
                        <>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center user-management-country-list flex-grow-1">
                              <div className="user-management-country-label">
                                {ele.label}
                              </div>
                              <div className="d-flex flex-wrap user-management-business-grp-list">
                                {this._getBusinessGroup().map((e, i) => {
                                  return (
                                    <div
                                      className="form-group mb-0 ml-2"
                                      key={`${index}_${i}`}
                                    >
                                      <input
                                        type="checkbox"
                                        name={`${e.label}_${index}`}
                                        id={`${e.value}_${index}`}
                                        value={`${e.value}_${index}`}
                                        data-required="true"
                                        checked={
                                          ele &&
                                          ele.selectedBusinessGroup &&
                                          ele.selectedBusinessGroup.indexOf(
                                            e.value
                                          ) > -1
                                            ? true
                                            : false
                                        }
                                        onChange={event =>
                                          this.handleBusinessGroupClick(
                                            event,
                                            index,
                                            e,
                                            i
                                          )
                                        }
                                      />
                                      <label htmlFor={`${e.value}_${index}`}>
                                        <span className="user-management-business-grp-list-label">
                                          {e.label}
                                        </span>
                                      </label>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                            <span className="mr-1">
                              <i
                                className="far fa-trash-alt"
                                onClick={() =>
                                  this._removeCoutryBusinessGroup(ele)
                                }
                              ></i>
                            </span>
                          </div>
                          {ele && ele.error === true ? (
                            <span className="cw-error mb-10">
                              <i className="fas fa-exclamation-triangle mr-10"></i>
                              Please map business group
                            </span>
                          ) : (
                            ''
                          )}
                        </>
                      );
                    })}
                  </>
                ) : null}
                {this.state.businessGroupMapError ? (
                  <span className="cw-error mb-10">
                    <i className="fas fa-exclamation-triangle mr-10"></i>Please
                    map business group
                  </span>
                ) : (
                  ''
                )}
              </div>
            </>
          ) : (
            ''
          )}
          <div>
            <button
              type="submit"
              onClick={this._closeModal}
              className="btn btn-outline-primary mr-10"
            >
              Cancel
            </button>
            {this.state.activeModal === 'editUser' ? (
              <button
                className="btn btn-primary"
                onClick={this._updateUserData}
              >
                Update User
              </button>
            ) : (
              <button className="btn btn-primary" onClick={this._addUser}>
                Add User
              </button>
            )}
          </div>
        </Modal>
        <Modal
          isOpen={
            this.state.modalIsOpen &&
            this.state.activeModal === 'deletionConfirmation'
          }
          onRequestClose={this._closeModal}
          className="cw-modal cw-modal--approve"
          contentLabel="Approval"
          style={{
            overlay: {
              backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
            }
          }}
        >
          <button
            className="cw-modal--close"
            title="Close"
            onClick={this._closeModal}
          >
            <i className="fas fa-times"></i>
          </button>
          <h3 className="cw-heading--primary mb-10">Confirm delete</h3>
          <p className="mb-20">
            Confirm you want to delete{' '}
            <strong>{this.state.deleteUsername}</strong> ?
          </p>
          <div>
            <button
              type="submit"
              onClick={this._closeModal}
              className="btn btn-outline-primary mr-10"
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() =>
                this._deleteUser(
                  this.state.modalUserType,
                  this.state.deleteUsername,
                  this.state.userBusinessGroup,
                  this.state.userCountry,
                  this.state.searchText
                )
              }
            >
              Confirm
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={
            this.state.modalIsOpen &&
            this.state.activeModal === 'cannotdeletionConfirmation'
          }
          onRequestClose={this._closeModal}
          className="cw-modal cw-modal--approve"
          contentLabel="DeleteUser"
          style={{
            overlay: {
              backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
            }
          }}
        >
          <button
            className="cw-modal--close"
            title="Close"
            onClick={this._closeModal}
          >
            <i className="fas fa-times"></i>
          </button>
          <h3 className="cw-heading--primary mb-10">You cannot delete user</h3>
          <p className="mb-20">
            You must have a minimum of one user with the permissions:
          </p>
          <p>- email notifications</p>
          <p>- log-in access</p>
        </Modal>
        {/* Release Notes */}
        <ReleaseUpdateOverlay
          isOpen={
            this.state.modalIsOpen &&
            this.state.activeModal === 'addReleaseNote'
          }
          onCloseModal={this._closeModal}
        />
        <div className="cw-section cw-overview ml-20">
          <div className="col-sm-12 mb-30">
            <div className="row mb-10">
              <div className="col-sm-6">
                <NavLink
                  className="cw-color--primary cw-breadcrum"
                  to={'/campaign-wizard'}
                >
                  <i className="fas fa-angle-left"></i> Campaigns
                </NavLink>
              </div>
            </div>
            {this.props.newUserAdded || this.props.isNewDomainAdded ? (
              <div className="row">
                <div className="col-sm-12">
                  <div className="alert alert-success" role="alert">
                    <strong>
                      {this.props.isNewDomainAdded ? 'Domain' : 'User'} added{' '}
                    </strong>
                    - {newName}
                  </div>
                </div>
              </div>
            ) : null}
            {this.props.userDataUpdated || this.props.isDomainUpdated ? (
              <div className="row">
                <div className="col-sm-12">
                  <div className="alert alert-success" role="alert">
                    <strong>
                      {this.props.isDomainUpdated ? 'Domain' : 'User'} updated{' '}
                    </strong>
                    - {newName}
                  </div>
                </div>
              </div>
            ) : null}
            {this.props.userDeleted || this.props.isDomainDeleted ? (
              <div className="row">
                <div className="col-sm-12">
                  <div className="alert alert-warning" role="alert">
                    <strong>
                      {this.props.isDomainDeleted ? 'Domain' : 'User'} deleted{' '}
                    </strong>
                    - {deletedName}
                  </div>
                </div>
              </div>
            ) : null}
            {this.props.isDomainCannotDeleted ? (
              <div className="row">
                <div className="col-sm-12">
                  <div className="alert alert-warning" role="alert">
                    <strong>Problem in deleting domain </strong>- {deletedName}
                  </div>
                </div>
              </div>
            ) : null}

            <div className="row">
              <div className="col-sm-6">
                <h2 className="cw-heading--secondary d-flex align-items-center mb-10">
                  <span className="mr-3">User management</span>
                </h2>
              </div>
            </div>
            <p>
              There must be a minimum of one user per table with the permission
              of log-in access and email notifications.
            </p>
          </div>
          <div className="col-sm-12">
            <Tabs
              selectedIndex={this.state.tabIndex}
              onSelect={tabIndex => {
                this.setState({ tabIndex });
                constant.userTabIndex[tabIndex] === 'requester'
                  ? this.props.fetchDomainData()
                  : this.props.fetchUserData(
                      constant.userTabIndex[tabIndex],
                      '',
                      '',
                      'tabchange'
                    );
              }}
            >
              <TabList>
                <Tab>Approvers</Tab>
                <Tab>Master Data</Tab>
                <Tab>ACS</Tab>
                <Tab>Gigya</Tab>
                <Tab>PRM</Tab>
                <Tab>Creative Agency</Tab>
                <Tab>Execution Agency</Tab>
                <Tab>Requester</Tab>
                <Tab>Admin</Tab>
              </TabList>

              <TabPanel>
                <ApproverContentTable
                  modalClick={this._openModal}
                  panelType="approver"
                  userData={userData}
                  asyncInProgress={asyncInProgress}
                  columnHeaders={aproverColumns}
                  editUser={this._editUser}
                  sortClick={this._sortUserData}
                  deleteUser={this._checkDeletionStatus}
                  businessGroup={this.props.businessGroup}
                  sortBy={this.sortBy}
                  sortKey={this.sortKey}
                />
              </TabPanel>
              <TabPanel>
                <ApproverContentTable
                  modalClick={this._openModal}
                  panelType="masterdata"
                  columnHeaders={commonColumns}
                  userData={userData}
                  asyncInProgress={asyncInProgress}
                  editUser={this._editUser}
                  sortClick={this._sortUserData}
                  deleteUser={this._checkDeletionStatus}
                />
              </TabPanel>
              <TabPanel>
                <ApproverContentTable
                  modalClick={this._openModal}
                  panelType="acs"
                  userData={userData}
                  columnHeaders={acsColumns}
                  asyncInProgress={asyncInProgress}
                  editUser={this._editUser}
                  sortClick={this._sortUserData}
                  deleteUser={this._checkDeletionStatus}
                />
              </TabPanel>
              <TabPanel>
                <ApproverContentTable
                  modalClick={this._openModal}
                  panelType="gigya"
                  columnHeaders={commonColumns}
                  userData={userData}
                  asyncInProgress={asyncInProgress}
                  editUser={this._editUser}
                  sortClick={this._sortUserData}
                  deleteUser={this._checkDeletionStatus}
                />
              </TabPanel>
              <TabPanel>
                <ApproverContentTable
                  modalClick={this._openModal}
                  panelType="prm"
                  userData={userData}
                  columnHeaders={commonColumns}
                  asyncInProgress={asyncInProgress}
                  editUser={this._editUser}
                  sortClick={this._sortUserData}
                  deleteUser={this._checkDeletionStatus}
                />
              </TabPanel>
              <TabPanel>
                <ApproverContentTable
                  modalClick={this._openModal}
                  panelType="creativeagency"
                  userData={userData}
                  columnHeaders={commonColumns}
                  asyncInProgress={asyncInProgress}
                  editUser={this._editUser}
                  sortClick={this._sortUserData}
                  deleteUser={this._checkDeletionStatus}
                />
              </TabPanel>
              <TabPanel>
                <ApproverContentTable
                  modalClick={this._openModal}
                  panelType="executionagency"
                  userData={userData}
                  columnHeaders={commonColumns}
                  asyncInProgress={asyncInProgress}
                  editUser={this._editUser}
                  sortClick={this._sortUserData}
                  deleteUser={this._checkDeletionStatus}
                />
              </TabPanel>
              <TabPanel>
                <RequesterTab
                  modalClick={this._openModal}
                  panelType="requester"
                  userData={userData}
                  columnHeaders={requestorColumns}
                  asyncInProgress={asyncInProgress}
                  editUser={this._editUser}
                  sortClick={this._sortUserData}
                  deleteUser={this._checkDeletionStatus}
                />
              </TabPanel>
              <TabPanel>
                <ApproverContentTable
                  modalClick={this._openModal}
                  panelType="admin"
                  userData={userData}
                  columnHeaders={commonColumns}
                  asyncInProgress={asyncInProgress}
                  editUser={this._editUser}
                  sortClick={this._sortUserData}
                  deleteUser={this._checkDeletionStatus}
                />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  userData: state.user.userData || {},
  asyncInProgress: state.user.asyncInProgress,
  newUserAdded: state.user.newUserAdded,
  userDataUpdated: state.user.userDataUpdated,
  userDeleted: state.user.userDeleted,
  deletedUsername: state.user.deletedUsername,
  addedUsername: state.user.addedUsername,
  usercannotdeleted: state.user.usercannotdeleted,
  deletionStatusCheck: state.user.deletionStatusCheck,
  emailAlreadyUse: state.user.emailAlreadyUse,
  countries: state.user.countries,
  countriesLoaded: state.user.countriesLoaded,
  country: state.user.country,
  isNewDomainAdded: state.requester.isNewDomainAdded,
  isDomainUpdated: state.requester.isDomainUpdated,
  deletedDomainName: state.requester.deletedDomainName,
  isDomainDeleted: state.requester.isDomainDeleted,
  newDomainName: state.requester.newDomainName,
  isDomainCannotDeleted: state.requester.isDomainCannotDeleted,
  approverValidation: state.user.emailApproverValidation,
  businessGroup: state.user.businessGroup,
  selectedBusinessGroup:
    state.user.selectedBusinessGroup &&
    state.user.selectedBusinessGroup.businessGroup,
  campaignConfig: state.app.campaignConfig
});

export default connect(mapStateToProps, {
  fetchUserData,
  editUserData,
  addUserData,
  deleteUserData,
  checkDeletionStatus,
  updateApproverCountry,
  fetchDomainData,
  addReleaseNoteData,
  fetchApproverFilterData,
  updateBusinessGroup,
  resetAllFlags
})(UserManagement);
