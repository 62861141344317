import * as yup from 'yup';
import {
  getEmailCampaignDetails,
  updateEmailCampaignDetails
} from 'actions/emailbriefing/emailCampaignDetails';
import { getYupSchema } from 'selectors';

import { FormEmailRadioInputV2 } from 'components/molecules/FormEmailRadioInput/inputv2';
import { EmailSecondaryThemeInput } from 'components/molecules/EmailSecondaryThemeInput';
import EmailCollaboratorsInput from 'components/molecules/EmailCollaboratorsInput';
import { getIsEdited } from 'pages/NewEmailCampaign/brandDetailsformSchema';
import { transformEmailCollaborators } from 'utilities/util';
import constants from '../../constants';

const SummarySchema = (
  dispatch,
  {
    defaultValues = {},
    emailType,
    country,
    brand,
    language,
    match,
    history,
    emailDesign,
    isCrossBrand
  }
) => {
  const isEdited = getIsEdited({ history });
  const onSubmit = (data, { user }, { backToReview } = {}) => {
    const isEdit = !!match.params.id;
    const paramUid = isEdit ? match.params.id : false;
    const dataToSend = {
      userEmail: user.emailId,
      userName: user.name,
      summary: data
    };

    if (!match.params.id) {
      dataToSend.brand = Array.isArray(brand)
        ? brand.map(b => b.id)
        : [brand.id];
      dataToSend.country = country.id;
      dataToSend.language = language.id;
      dataToSend.emailType = emailType;
      dataToSend.isCrossBrand = isCrossBrand;
    }
    dataToSend.emailCollaborators = transformEmailCollaborators(data.emailCollaborators);
    delete dataToSend.summary.emailCollaborators;

    dispatch(
      updateEmailCampaignDetails(paramUid, dataToSend, (error, result) => {
        if (result) {
          const { uid } = result;
          if (backToReview && isEdited) {
            const status = emailDesign?.emailBriefing?.status;
            if (status === constants.EMAIL_STATUS.AWAITING_FOR_APPROVAL) {
              history.push(`/campaign-wizard/emailbriefing/${uid}/approval`);
            } else if (
              status === constants.EMAIL_STATUS.DRAFT ||
              status === constants.EMAIL_STATUS.REJECTED
            ) {
              history.push(`/campaign-wizard/emailbriefing/${uid}/review`);
            }
          } else {
            history.push(
              `/campaign-wizard/emailbriefing/${uid}/email-attributes`
            );
          }
        }
        if (error) {
          console.log('OnSubmit error: ', error);
        }
      })
    );
  };

  return {
    formType: 'custombuttons',
    submitBtnLabel: isEdited ? 'Save and back to review' : 'Next',
    onSecondaryClick: onSubmit,
    onSubmitSecondary: () => {},
    isEdit: isEdited,
    secondaryBtnLabel: 'Next',
    fields: [
      {
        type: 'text',
        name: 'campaignName',
        id: 'campaignName',
        label: 'Campaign name',
        maxLength: '50',
        defaultValue: defaultValues.campaignName,
        rules: yup
          .string()
          .trim()
          .required('Please enter campaign name')
      },
      {
        type: 'textarea',
        name: 'campaignDescription',
        id: 'campaignDescription',
        label: 'Campaign description',
        placeholder: 'Describe your marketing initiative and goal',
        defaultValue: defaultValues.campaignDescription,
        rules: yup
          .string()
          .trim()
          .required('Please enter campaign description'),
        maxLength: '300'
      },
      {
        type: 'custom',
        //type: 'reactselect',
        name: 'emailContentTheme',
        id: 'emailContentTheme',
        label: 'Content theme',
        component: FormEmailRadioInputV2,
        placeholder: 'Please select',
        rules: yup
          .object()
          .shape({
            label: yup.string().required(),
            value: yup.string().required()
          })
          .required('Please select email content theme')
          .default(undefined),
        defaultValue: defaultValues.emailContentTheme,
        options: constants.EMAIL_PRIMARY_THEME
      },
      {
        type: 'custom',
        name: 'emailContentSecondaryTheme',
        id: 'emailContentSecondaryTheme',
        label: 'Secondary theme',
        component: EmailSecondaryThemeInput,
        defaultValue: defaultValues.emailContentSecondaryTheme,
        rules: yup
          .array()
          .of(yup.object())
          .min(1, 'Please select email content theme')
          .required('Please select email content theme'),
        options: constants.EMAIL_SECONDARY_THEMES
      },
      {
        type: 'custom',
        name: 'emailCollaborators',
        component: EmailCollaboratorsInput,
        id: 'emailCollaborators',
        label: 'Specify collaborators',
        defaultValue: defaultValues.emailCollaborators,
        rules: yup.object({
          type: yup
            .string()
            .required('Please select email trigger')
            .nullable(),
          collaborators: yup.array().when('type', {
            is: 'requestorAndSelectedUsers',
            then: schema =>
              schema
                .test(
                  'is-min',
                  'Please enter your collaborator’s email address',
                  value => {
                    const lastElem = value[value.length - 1];
                    if (
                      value.length < 2 &&
                      (!lastElem || lastElem.value === '')
                    ) {
                      return false;
                    }
                    return true;
                  }
                )
                .test(
                  'is-email-valid',
                  'Please enter a valid email address',
                  value => {
                    const lastElem = value[value.length - 1];
                    const customValidation = constants.regex.emailAddress;
                    if (value.length >= 1) {
                      if (lastElem?.value && lastElem?.value?.trim() !== '') {
                        if (
                          customValidation &&
                          !customValidation.test(lastElem?.value)
                        ) {
                          return false;
                        }
                      }
                      return true;
                    }
                    return true;
                  }
                )
                .test(
                  'is-email-allowed',
                  'Please enter a valid email. Only emails on domain unilever.com are allowed.',
                  value => {
                    const lastElem = value[value.length - 1];
                    if (value.length >= 1) {
                      if (lastElem?.value && lastElem?.value?.trim() !== '') {
                        if (
                          !constants.EMAIL_SENDTEST_VALID_DOMAINS.includes(
                            lastElem?.value?.split('@')[1]
                          )
                        ) {
                          return false;
                        }
                      }
                      return true;
                    }
                    return true;
                  }
                )
                .test('max-5', 'Only upto 5 users are allowed', value => {
                  const lastElem = value[value.length - 1];
                  if (lastElem?.value?.trim() === '') {
                    return value.length - 1 > 5 ? false : true;
                  }
                  return value.length > 5 ? false : true;
                }),
            otherwise: schema => schema.notRequired()
          })
        })
      }
    ],
    onSubmit: (data, { user }) =>
      onSubmit(data, { user }, { backToReview: isEdited }),
    onDraft: (data, validationSchema, { user }) => {
      const paramUid = match.params.id;

      const dataToSend = {
        userEmail: user.emailId,
        userName: user.name,
        summary: data,
        status: emailDesign?.emailBriefing?.status === '8' ? '8' : '1',
        isSaveAsDraft: true
      };

      if (!match.params.id) {
        dataToSend.brand = Array.isArray(brand)
          ? brand.map(b => b.id)
          : [brand.id];
        dataToSend.country = country.id;
        dataToSend.language = language.id;
        dataToSend.emailType = emailType;
        dataToSend.isCrossBrand = isCrossBrand;
      }
      dataToSend.emailCollaborators = transformEmailCollaborators(data.emailCollaborators);
      delete dataToSend.summary.emailCollaborators;

      dispatch(
        updateEmailCampaignDetails(
          paramUid,
          dataToSend,
          (error, result) => {
            if (result) {
              history.push(`/campaign-wizard/email-dashboard`);
            }
            if (error) {
              console.log('OnSubmit error: ', error);
            }
          },
          { isSaveAsDraft: true }
        )
      );
    },
    onPrevious: () => {
      const uid = match.params.id;
      history.push(
        '/campaign-wizard' +
          (uid
            ? `/emailbriefing/${uid}/emailtype`
            : '/new-email-campaign-emailtype')
      );
    },
    getSchema() {
      if (this.fields) {
        return getYupSchema(this.fields);
      }
      return null;
    },
    previousBtnLabel: 'Back',
    draftText: 'Save and exit',
    formErrorMessage:
      'There was a problem with the form. Errors are listed below'
  };
};

export default SummarySchema;
