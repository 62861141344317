import axios from 'axios';
import constant from '../../constants';
import {
  FETCH_SMS_BRAND_DETAILS,
  FETCH_SMS_BRANDS_FOR_COUNTRY,
  FETCH_ALL_SMS_BRANDS,
  UPDATE_SMS_BRAND_DETAILS,
  FETCH_SMS_CAMPAIGN_DETAILS,
  SUBMIT_SMS_BRAND_DETAILS_INFO,
  SMS_ASYNC_IN_PROGRESS,
  SMS_SYSTEM_ERROR,
  ASYNC_IN_PROGRESS,
  GET_BRAND_COUNTRIES,
  BRAND_COUNTRY_LOADED_SUCCESS,
  BRAND_COUNTRY_LOADED,
  GET_COUNTRY_BRAND_LIST,
  RESET_SMS_BRAND_DETAILS,
  SUBMIT_SMS_CAMPAIGN_DETAILS
} from '../../actionTypes';
import _ from 'lodash';
import { setNotificationMessage } from 'actions/dashboard';

export const updateSmsDetails = smsBrandDetail => ({
  type: UPDATE_SMS_BRAND_DETAILS,
  payload: smsBrandDetail
});

export const fetchSmsBrandDetails = ({ bpos } = {}) => {
  console.log('bpos: ', bpos);
  return async dispatch => {
    dispatch({
      type: SMS_ASYNC_IN_PROGRESS,
      isLoading: false
    });
    let query = '';
    if (bpos) {
      query = '?bpos=true';
    }
    const response = await axios.get(
      constant.communications.FETCH_SMS_BRAND_DETAIL + query
    );
    let sortedCountries = _.orderBy(response.data, ['name'], ['asc']);
    dispatch({
      type: FETCH_SMS_BRAND_DETAILS,
      payload: sortedCountries
    });
  };
};

export const fetchSmsBrandList = (country, { bpos } = {}) => {
  return function(dispatch) {
    let query = '';
    if (bpos) {
      query = '&bpos=true';
    }
    axios
      .get(
        constant.communications.FETCH_SMS_BRAND_DETAIL +
          '?country=' +
          country +
          query
      )
      .then(response => {
        let brandsData = response.data;
        let smsBrandList = _.orderBy(brandsData, ['name'], ['asc']);
        dispatch({
          type: FETCH_SMS_BRANDS_FOR_COUNTRY,
          payload: smsBrandList
        });
      });
  };
};

export const fetchSmsBrandsForCountries = countries => {
  return async function(dispatch) {
    try {
      dispatch({
        type: SMS_ASYNC_IN_PROGRESS,
        isLoading: true
      });
      const responses = await Promise.all(
        countries.map(country =>
          axios.get(
            constant.communications.FETCH_SMS_BRAND_DETAIL +
              '?country=' +
              country
          )
        )
      );
      const brandsData = responses.flatMap(response => response.data);
      const smsBrandList = _.orderBy(brandsData, ['name'], ['asc']);

      dispatch({
        type: FETCH_SMS_BRANDS_FOR_COUNTRY,
        payload: smsBrandList
      });
    } catch (error) {
      dispatch({
        type: SMS_ASYNC_IN_PROGRESS,
        isLoading: false
      });
    }
  };
};

export const fetchAllSmsBrands = () => {
  return async function(dispatch) {
    try {
      dispatch({
        type: SMS_ASYNC_IN_PROGRESS,
        isLoading: true
      });
      const response = await axios.get(
        constant.communications.FETCH_SMS_BRAND_DETAIL + '/brands'
      );

      dispatch({
        type: FETCH_ALL_SMS_BRANDS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: SMS_ASYNC_IN_PROGRESS,
        isLoading: false
      });
    }
  };
};

//FETCH_SMS_CAMPAIGN_DETAILS
export const getSmsCampaignInformation = uid => {
  return function(dispatch) {
    let url = constant.communications.POST_SMS_BRAND_DETAIL; // to be moved to constant file
    axios.get(url + '/' + uid).then(response => {
      let smsCampaignDetails = response.data;
      dispatch({
        type: FETCH_SMS_CAMPAIGN_DETAILS,
        smsCampaignDetails
      });
    });
  };
};

//submitSmsBrandDetails
export const submitSmsBrandDetails = (
  uuid,
  brand,
  country,
  language,
  userName,
  userEmail,
  languageId,
  POSCheck,
  callback
) => {
  return function(dispatch) {
    dispatch({
      type: SMS_ASYNC_IN_PROGRESS,
      isLoading: true
    });
    let postUrl = constant.communications.POST_SMS_BRAND_DETAIL;
    let dataToSend = {
      uid: uuid,
      brand: brand && brand.value,
      country: country && country.value,
      brandId: brand && brand._id,
      countryId: country && country._id,
      language: language,
      userName: userName,
      userEmail: userEmail,
      languageId: languageId,
      brandPOSFlag: POSCheck
    };
    if (uuid) {
      axios
        .post(postUrl, dataToSend)
        .then(response => {
          dispatch({
            type: SUBMIT_SMS_BRAND_DETAILS_INFO,
            dataToSend,
            response
          });
          dispatch({
            type: SMS_ASYNC_IN_PROGRESS,
            isLoading: false
          });
          callback(null, dataToSend);
        })
        .catch(error => {
          dispatch({
            type: SMS_ASYNC_IN_PROGRESS,
            isLoading: false
          });
          dispatch({
            type: SMS_SYSTEM_ERROR,
            error
          });
          callback(error, null);
          return false;
        });
    } else {
      let response = {
        data: dataToSend,
        status: 201
      };
      dispatch({
        type: SUBMIT_SMS_BRAND_DETAILS_INFO,
        dataToSend,
        response
      });
      dispatch({
        type: SMS_ASYNC_IN_PROGRESS,
        isLoading: false
      });
      callback(null, dataToSend);
    }
  };
};

export const getBrandCountries = () => {
  return function(dispatch) {
    dispatch({
      type: ASYNC_IN_PROGRESS,
      isLoading: true
    });
    axios.get(constant.serviceUrls.SERVICE_URL.COUNTRIES).then(response => {
      let countryDetails = response.data;
      let countryDetailsSorted = _.orderBy(
        countryDetails.countries,
        ['name'],
        ['asc']
      );
      dispatch({
        type: GET_BRAND_COUNTRIES,
        countryDetailsSorted
      });
      dispatch({
        type: BRAND_COUNTRY_LOADED_SUCCESS,
        isLoading: false,
        payload: true
      });
    });
  };
};
export const getBrandByCountry = countryId => {
  return function(dispatch) {
    dispatch({
      type: ASYNC_IN_PROGRESS,
      isLoading: true
    });

    let country = countryId;
    axios
      .get(constant.serviceUrls.SERVICE_URL.COUNTRIES + '?country=' + country)
      .then(response => {
        let countryBrand = response.data;
        let brands = countryBrand.map(data => data.brand);
        brands = _.orderBy(brands, ['name'], ['asc']);
        let languageExists =
          countryBrand[0].language !== undefined ? true : false;
        let countrylanguage;
        if (languageExists) {
          countrylanguage = {
            label: countryBrand[0]['language']['name'],
            value:
              countryBrand[0]['language']['code'] +
              '-' +
              countryBrand[0]['language']['_id']
          };
        }
        dispatch({
          type: GET_COUNTRY_BRAND_LIST,
          brands,
          countrylanguage
        });
        dispatch({
          type: BRAND_COUNTRY_LOADED,
          countryDataLoaded: false
        });
        dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
      });
  };
};

export const resetSmsBrandDetails = () => {
  return {
    type: RESET_SMS_BRAND_DETAILS
  };
};

export const createNewSmsCampaign = (
  payload,
  callback,
  { isSaveAsDraft } = {}
) => {
  return function(dispatch) {
    dispatch({
      type: SMS_ASYNC_IN_PROGRESS,
      isLoading: true
    });
    let postUrl = constant.communications.POST_SMS_BRAND_DETAIL;

    axios
      .post(postUrl, payload)
      .then(response => {
        dispatch({
          type: SUBMIT_SMS_CAMPAIGN_DETAILS,
          payload,
          response
        });

        dispatch({
          type: SMS_ASYNC_IN_PROGRESS,
          isLoading: false
        });
        if (isSaveAsDraft) {
          console.log(response);
          const campaignName = _.get(response, 'data.name', 'Untitled');
          const uid = _.get(response, 'data.uid');
          dispatch(
            setNotificationMessage(
              constant.CAMPAIGN_SAVED_AS_DRAFT_TEXT,
              `${constant.SMS_BASE_ROUTE}/${uid}`,
              'success',
              campaignName,
              'emailCampaignDraft'
            )
          );
        }
        callback(null, response.data);
      })
      .catch(error => {
        dispatch({
          type: SMS_ASYNC_IN_PROGRESS,
          isLoading: false
        });
        dispatch({
          type: SMS_SYSTEM_ERROR,
          error
        });
        callback(error, null);
        return false;
      });
  };
};
