import React from 'react';
import { get } from 'lodash';

import { FormInput } from 'components/molecules/FormInput';
import constants from 'constants/config';

export const SMSShortURLInput = ({
  control,
  watchInput,
  errors,
  name,
  register,
  setValue,
  labelClass,
  defaultValue,
  trigger,
  communicationDetail
}) => {
  const hasMainURL = get(
    communicationDetail,
    'smsDetails.[0].URL.[0].fullURL',
    ''
  );

  const hasFollowupURL = get(
    communicationDetail,
    'smsDetails.[1].URL.[0].fullURL',
    ''
  );

  const mainSendDefaultValue =
    get(communicationDetail, 'smsDetails.[0].URL.[0].shortURL', '') !==
    constants.SAMPLE_BITLY_URL
      ? get(communicationDetail, 'smsDetails.[0].URL.[0].shortURL', '')
      : '';

  const followupDefaultValue = get(
    communicationDetail,
    'smsDetails.[1].URL.[0].shortURL',
    ''
  );

  return (
    <>
      {hasMainURL && (
        <FormInput
          id={`${name}.mainSend`}
          type="text"
          name={`${name}.mainSend`}
          label="Bitly shortened URL" // (main send)
          register={register}
          errors={errors}
          key={`${name}.mainSend`}
          defaultValue={mainSendDefaultValue}
          setValue={setValue}
        />
      )}
      {hasFollowupURL && (
        <FormInput
          id={`${name}.followup`}
          type="text"
          name={`${name}.followup`}
          label="Bitly shortened URL (follow up SMS)"
          register={register}
          errors={errors}
          key={`${name}.followup`}
          defaultValue={followupDefaultValue}
          setValue={setValue}
        />
      )}

      {(hasMainURL || hasFollowupURL) && (
        <label className="mt-n2 email-type-desc-font">
          Please enter the shortened Bitly URL you have created for the link in
          the SMS body. Ensure that you have replaced it in the actual SMS body
        </label>
      )}
    </>
  );
};
