import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import arrayMove from 'array-move';
import {
  fetchCampaignData,
  updateProfileFields,
  updateGenderValues,
  updateProvinceValues
} from '../../actions/profileFieldsSorting';
import {
  setApproverEditCampaignStatus,
  setApproverEditCampaignStatusMessage,
  setApproverEditLastUpdatedScreen
} from '../../actions/app';
import { setNotificationMessage } from '../../actions/dashboard';
import { cancelCampaignChanges } from '../../actions/overview';
import BreadCrumb from '../BreadCrumb';
import { epsilonMarket } from '../../selectors/index';
import constant from '../../constants';
import Modal from 'react-modal';
import CampaignCancelModel from '../CampaignCancelModel';
import ReactHtmlParser from 'react-html-parser';
import axios from 'axios';

export const getItemStyle = (isDragging, draggableStyle) => {
  return {
    userSelect: 'none',
    margin: `0 0 6px 0`,
    textAlign: 'left',
    borderTop: '1px solid rgba(0,0,0,.125)',
    overflow: 'hidden',
    ...draggableStyle
  };
};

export const getQuestionListStyle = isDraggingOver => ({
  border: '1px solid rgba(0, 0, 0, 0.125)',
  borderTop: 'none',
  borderRadius: '5px',
  overflow: 'hidden'
});

export const getAnswerListStyle = isDraggingOver => ({
  marginTop: '5px',
  border: '1px solid #dadada',
  borderTop: 'none',
  borderRadius: '5px',
  overflow: 'hidden'
});

function Section({ section, items, state, onAccordionOpen }) {
  if (!items) {
    return null;
  }
  return (
    <div>
      <div className="sorting-title-header">{section.title}</div>
      <Droppable droppableId={section.id}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getQuestionListStyle(snapshot.isDraggingOver)}
          >
            {items.map((field, index) => (
              <Draggable
                key={index}
                draggableId={`${field.label}` + index}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <div
                      className="sorting-menu-list-heading-icon d-flex"
                      {...provided.dragHandleProps}
                      onClick={() => onAccordionOpen(index)}
                    >
                      <span className="menu-grab-icon">
                        <i className="fas fa-align-justify cw-color--primary"></i>
                      </span>
                      <strong>
                        <span className="saas-accordion-heading-sort cw-color--primary">
                          {field.dataType === 'paragraph'
                            ? field.label
                            : field.dataType === 'heading'
                            ? field.label + ' (' + field.heading + ')'
                            : field.dataType === 'questionAnswerPair'
                            ? field.questionLabel
                            : field.fieldLabel
                            ? field.fieldLabel
                            : field.label}
                        </span>
                      </strong>
                      {field.dataType === 'gender' ||
                      field.dataType === 'paragraph' ||
                      (field.dataType === 'samplePrizeSelection' &&
                        field.answers.length > 1) ||
                      (field.dataType === 'questionAnswerPair' &&
                        field.questionType === 'Single choice') ||
                      (field.dataType === 'questionAnswerPair' &&
                        field.questionType === 'Multiple choice') ||
                      (field.dataType === 'stateOrProvince' &&
                        field.questionType === 'Single choice') ? (
                        <span
                          className="saas-accordion-arrow accordian-left"
                          type="button"
                          role="button"
                        >
                          {state.activeIndexes.includes(index) ? (
                            <i className="fas fa-chevron-up"></i>
                          ) : (
                            <i className="fas fa-chevron-down"></i>
                          )}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                    {/* questionAnswerPair */}
                    {(field.dataType === 'questionAnswerPair' &&
                      field.questionType === 'Single choice') ||
                    (field.dataType === 'questionAnswerPair' &&
                      field.questionType === 'Multiple choice' &&
                      field &&
                      field.answers &&
                      field.answers.length) ? (
                      <>
                        {state.activeIndexes.includes(index) ? (
                          <NestedSortable
                            questionNum={index}
                            fieldData={field}
                            heading={'Answers'}
                            dataKey={'answers'}
                            type={'answer'}
                          />
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      ''
                    )}
                    {/* Paragraph */}
                    {state.activeIndexes.includes(index) &&
                    field.label === 'Paragraph' ? (
                      <div className="cw-striped-row sorting-child-container m-0 sorting-list">
                        <div
                          className="row"
                          style={{
                            margin: '0 0 0 4%'
                          }}
                        >
                          <div className="col-sm-4">
                            <strong>{'Paragraph Text'}</strong>
                          </div>
                          <div className="col-sm-8 cw-text--ternary">
                            {ReactHtmlParser(field.paragraph)}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {/* Gender */}
                    {state.activeIndexes.includes(index) &&
                    field.label === 'Gender' &&
                    field &&
                    field.values &&
                    field.values.length ? (
                      <NestedSortable
                        questionNum={index}
                        fieldData={field}
                        heading={'Gender Values'}
                        dataKey={'values'}
                        type={'gender'}
                      />
                    ) : null}
                    {/* State or Province */}
                    {state.activeIndexes.includes(index) &&
                    field.label === 'State or Province' &&
                    field.questionType === 'Single choice' &&
                    field &&
                    field.answers &&
                    field.answers.length ? (
                      <NestedSortable
                        questionNum={index}
                        fieldData={field}
                        heading={'State Values'}
                        dataKey={'answers'}
                        type={'answer'}
                      />
                    ) : null}
                    {/* samplePrizeSelection */}
                    {state.activeIndexes.includes(index) &&
                    field.dataType === 'samplePrizeSelection' &&
                    field &&
                    field.answers &&
                    field.answers.length ? (
                      <NestedSortable
                        questionNum={index}
                        fieldData={field}
                        heading={'Samples'}
                        dataKey={'answers'}
                        type={'answer'}
                      />
                    ) : null}
                  </div>
                )}
              </Draggable>
            ))}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}

function isQuestionAnswerEnabled(campaign) {
  if (campaign.type === 'Sign-up with Questions & Answers') return true;
  return false;
}

class ProfileFieldsSorting extends Component {
  constructor(props) {
    super(props);
    this.campaignId = this.props.match.params.id;
    this.state = {
      activeIndex: null,
      matchProps: this.props.match,
      expand: -1,
      questionInContext: {},
      isCancelModalOpen: false,
      fields: [],
      activeIndexes: [],
      consentField: [],
      sections: {
        profile: {
          id: 'profile',
          title: 'Profile fields',
          items: this.props.fields
        },
        consent: {
          id: 'consent',
          title: 'Consents fields',
          items: this.props.consentField
        }
      },
      sectionOrder: ['profile', 'consent']
    };
    this.onDragEnd = this.onDragEnd.bind(this);
    this._openIsCancelModal = this._openIsCancelModal.bind(this);
    this._closeIsCancelModal = this._closeIsCancelModal.bind(this);
    this._cancelSaasCampaign = this._cancelSaasCampaign.bind(this);
  }

  componentDidMount() {
    this.props.fetchCampaignData(this.campaignId);
    setTimeout(() => {
      this.updateSections();
    }, 500);
  }

  updateSections() {
    const { campaign, fields, consentField } = this.props;
    const { campaignType } = campaign;

    let profileFieldTitle = 'Profile fields';
    if (isQuestionAnswerEnabled(campaign) && campaignType !== 'Incentive') {
      profileFieldTitle = 'Profile and Q&A fields';
    }
    if (isQuestionAnswerEnabled(campaign) && campaignType === 'Incentive') {
      profileFieldTitle = 'Profile, Q&A and incentive fields';
    }

    // Update the state with new sections
    this.setState({
      sections: {
        profile: {
          id: 'profile',
          title: profileFieldTitle,
          items: fields || [] // Fallback to empty array if undefined
        },
        consent: {
          id: 'consent',
          title: 'Consent fields',
          items: consentField || [] // Fallback to empty array if undefined
        }
      }
    });
  }
  componentDidUpdate(prevProps) {
    // Check if fields or consentField have changed by comparing references
    if (
      prevProps.fields !== this.props.fields ||
      prevProps.consentField !== this.props.consentField
    ) {
      console.log('Updated props:', this.props);
      this.updateSections();
    }
  }
  onDragEnd = result => {
    const { destination, source, type } = result;

    // Do nothing if no destination
    if (!destination) return;
    // Do nothing if the item is dropped in the same place
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === 'answer' || type === 'gender') {
      const startData = this.state.sections['profile'];
      let profileIndex = parseInt(destination.droppableId);
      const { items: startItem } = startData;
      if (profileIndex > -1) {
        let data =
          startItem[profileIndex][type === 'answer' ? 'answers' : 'values'];
        const newData = Array.from(data);
        const [movedItem] = newData.splice(source.index, 1);
        newData.splice(destination.index, 0, movedItem);
        startItem[profileIndex][
          type === 'answer' ? 'answers' : 'values'
        ] = newData;
        const newStart = {
          ...start,
          items: startItem
        };
        const newState = {
          sections: {
            ...this.state.sections,
            [newStart.id]: newStart
          }
        };
        this.setState(newState);
      }
      return;
    }

    const start = this.state.sections[source.droppableId];
    const finish = this.state.sections[destination.droppableId];
    // List of field labels that are allowed to be dragged between sections
    const allowedFields = []; //['Heading', 'Paragraph'];

    const startItem = start.items[source.index];

    // Moving within the same list
    if (start === finish) {
      const newItems = Array.from(start.items);
      const [movedItem] = newItems.splice(source.index, 1);
      newItems.splice(destination.index, 0, movedItem);

      const newSection = {
        ...start,
        items: newItems
      };

      const newState = {
        sections: {
          ...this.state.sections,
          [newSection.id]: newSection
        }
      };

      this.setState(newState);
      return;
    }

    // Moving from one list to another
    // Allow only specific fields to be moved between sections
    if (allowedFields.includes(startItem.label)) {
      const startItems = Array.from(start.items);
      const [movedItem] = startItems.splice(source.index, 1);
      const newStart = {
        ...start,
        items: startItems
      };

      const finishItems = Array.from(finish.items);
      finishItems.splice(destination.index, 0, movedItem);
      const newFinish = {
        ...finish,
        items: finishItems
      };

      const newState = {
        sections: {
          ...this.state.sections,
          [newStart.id]: newStart,
          [newFinish.id]: newFinish
        }
      };

      this.setState(newState);
    } else {
      // If the item is not allowed to be moved between sections, revert to the original state
      this.setState({
        sections: {
          ...this.state.sections
        }
      });
    }
  };

  onAccordionOpen = index => {
    if (this.state.activeIndexes.includes(index)) {
      this.setState({
        activeIndexes: this.state.activeIndexes.filter(e => e !== index)
      });
    } else {
      this.setState({ activeIndexes: [...this.state.activeIndexes, index] });
    }
  };
  _handlebackButton = event => {
    event.preventDefault();
    let { campaign } = this.props;
    let status = campaign && campaign.status;
    let isEditedclickevent =
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.isEdited;
    if (status === '6') {
      if (
        this.props &&
        this.props.lastUpdatedScreen &&
        (this.props.lastUpdatedScreen === 'saas-profile-details' ||
          this.props.lastUpdatedScreen === 'saas-incentive-fields')
      ) {
        this.props.history.push(
          '/campaign-wizard/campaign/' +
            this.campaignId +
            '/' +
            this.props.lastUpdatedScreen
        );
      } else {
        this.props.history.push({
          pathname:
            `/campaign-wizard/campaign/${this.campaignId}` +
            (constant.featureFlags.ENABLE_SINGLE_PAGE_FIELD_SELECTION
              ? '/configure-form-fields'
              : '/saasqna'),
          state: { isEdited: isEditedclickevent }
        });
      }
    } else {
      this.props.history.push(
        '/campaign-wizard/campaign/' +
          this.campaignId +
          (constant.featureFlags.ENABLE_SINGLE_PAGE_FIELD_SELECTION
            ? '/configure-form-fields'
            : '/saas-generic-details')
      );
    }
  };

  _handleCancel = () => {
    this.props.setApproverEditCampaignStatus(false);
    this.props.setApproverEditCampaignStatusMessage('');
    this.props.setApproverEditLastUpdatedScreen('');
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.campaignId + '/approval'
    );
  };

  _handleSubmit = async (event, isSaveAsDraft) => {
    let { campaign } = this.props;
    let country = campaign.country && campaign.country.code;
    let status = campaign && campaign.status;
    console.log('this.state', this.state.sections);
    const { profile, consent } = this.state.sections;
    let profileFieldSorted = [];
    /* const profileAndConsentFields =
      this.state.fields && this.state.fields.length
        ? this.state.fields
        : this.props.fields;*/
    profileFieldSorted = profile.items;
    // for (const e of profileAndConsentFields) {
    //   if (e.hasOwnProperty('dataType')) {
    //     profileFieldSorted.push(e);
    //   }
    // }
    let consentFieldSorted = [];
    // for (const e of profileAndConsentFields) {
    //   if (e.hasOwnProperty('optInType')) {
    //     consentFieldSorted.push(e);
    //   }
    // }
    consentFieldSorted = consent.items;
    let sortedFields = profileFieldSorted;
    event.preventDefault();
    if (this.state.values && this.state.values.length > 0) {
      let genderObj =
        this.props.fields &&
        this.props.fields.length > 0 &&
        this.props.fields.filter(e => e.dataType === 'gender');

      let genderValuesUnChecked =
        genderObj &&
        genderObj[0].values &&
        genderObj[0].values.filter(elem => !elem.isChecked);

      sortedFields.forEach((elem, index) => {
        if (elem.dataType === 'gender') {
          elem.values = this.state.values;
          genderValuesUnChecked.forEach(e => elem.values.push(e));
          sortedFields[index] = elem;
        }
      });
    }
    if (this.state.provinces && this.state.provinces.length > 0) {
      let provinceObj =
        this.props.fields &&
        this.props.fields.length > 0 &&
        this.props.fields.filter(e => e.dataType === 'stateOrProvince');

      let provinceValues = provinceObj && provinceObj[0].answers;

      sortedFields.forEach((elem, index) => {
        if (elem.dataType === 'stateOrProvince') {
          elem.answers = this.state.provinces;
          sortedFields[index] = elem;
        }
      });
    }

    let updatedData = {
      uid: this.campaignId,
      fields: sortedFields,
      consents: consentFieldSorted, //.concat(this.props.privacyAndDesclaimer),
      isEdited: campaign.isEdited
    };
    axios
      .post(constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL, updatedData)
      .then(response => {
        if (isSaveAsDraft) {
          let notificationData = {};
          notificationData.message = constant.CAMPAIGN_SAVED_AS_DRAFT_TEXT;
          notificationData.link = '';
          notificationData.type = 'warning';
          notificationData.campaign = '';

          this.props.setNotificationMessage(
            notificationData.message,
            notificationData.link,
            notificationData.type,
            notificationData.campaign
          );
          this.props.history.push('/campaign-wizard/dashboard');
        } else {
          if (status === '6') {
            this.props.setApproverEditCampaignStatus(true);
            this.props.setApproverEditCampaignStatusMessage('success');
            this.props.setApproverEditLastUpdatedScreen('');
            this.props.history.push(
              '/campaign-wizard/campaign/' + this.campaignId + '/approval'
            );
          } else {
            this.props.history.push(
              '/campaign-wizard/campaign/' +
                this.campaignId +
                `${
                  epsilonMarket(country)
                    ? `/saas-epsilon-masterdata`
                    : `/review`
                }`
            );
          }
        }
      });
  };

  _openIsCancelModal(event) {
    event.preventDefault();
    this.setState({
      isCancelModalOpen: true
    });
  }

  _closeIsCancelModal() {
    this.setState({
      isCancelModalOpen: false
    });
  }

  _cancelSaasCampaign(event) {
    event.preventDefault();
    this.setState({
      isCancelModalOpen: false
    });
    this.props.cancelCampaignChanges(this.campaignId);
    this.props.history.push('/campaign-wizard/campaign/' + this.campaignId);
  }

  render() {
    let { campaign, campaignDetails } = this.props;
    let status = campaign && campaign.status;
    let countrySelected = campaign.country && campaign.country.code;
    if (
      this.props.fields &&
      this.props.fields.length === 0 &&
      this.props.consentField &&
      this.props.consentField.length === 0
    ) {
      return null;
    }

    let data =
      this.state.fields && this.state.fields.length
        ? this.state.fields
        : this.props.fields;
    let consentField =
      this.state.consentField && this.state.consentField.length
        ? this.state.consentField
        : this.props.consentField;

    const optinsNote = (
      <>
        <span className="email-type-desc-font">
          <strong>Note:&nbsp;</strong>Privacy policy, disclaimer and submit
          button will always be at the bottom of the form and cannot be sorted
          elsewhere.
        </span>
      </>
    );
    return (
      <>
        <div className="cw-section">
          {status !== '6' ? (
            <BreadCrumb
              match={this.state.matchProps}
              hideBreadCrumb={campaign && campaign.isEdited}
              campaignType={
                this.props.campaign && this.props.campaign.campaignType
              }
              websiteType={
                this.props.campaign &&
                this.props.campaign.websiteType &&
                this.props.campaign.websiteType.name
              }
              formType={this.props.campaign && this.props.campaign.type}
              isEpsilonMarket={epsilonMarket(countrySelected)}
            />
          ) : null}
          <div className="col-sm-7">
            <div className="row mb-10">
              <div className="col-sm-12">
                <h2 className="cw-heading--secondary mb-3">
                  Choose field sort order
                </h2>
              </div>
            </div>
            <div className="cw-section--content">
              <div className="cw-campaign--review">
                <div className="cw-campaign--review-section mb-40">
                  {/* <Alert
                    alertType="saasUpdateMessage"
                    textMessage={constant.SAAS_MESSAGES.saasSortingMessage}
                  /> */}
                  <Modal
                    scrollable={true}
                    isOpen={this.state.isCancelModalOpen}
                    onRequestClose={this._closeIsCancelModal}
                    className="cw-modal cw-modal--branddetail"
                    contentLabel="Campaign Wizard 2.0"
                  >
                    <CampaignCancelModel
                      closeIsCancelModal={this._closeIsCancelModal}
                      isCancelContinue={this._cancelSaasCampaign}
                    />
                  </Modal>
                  <p> Please drag and drop to reorder your form fields.</p>
                  <>
                    <br />
                    <div className="form-group sorting-list">
                      <DragDropContext onDragEnd={this.onDragEnd}>
                        {this.state.sectionOrder.map(sectionId => {
                          const section = this.state.sections[sectionId];
                          return (
                            <Section
                              key={section.id}
                              section={section}
                              items={section.items}
                              state={this.state}
                              onAccordionOpen={this.onAccordionOpen}
                            />
                          );
                        })}
                      </DragDropContext>
                    </div>
                    <p className="mb-30">{optinsNote}</p>
                    <div className="cw-form--action-cta">
                      <button
                        type="button"
                        className="btn btn btn-outline-secondary mr-3"
                        onClick={this._handlebackButton}
                      >
                        Back
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={this._handleSubmit}
                      >
                        {status === '6' ? 'Save' : 'Next'}
                      </button>
                      {status === '6' ? (
                        <a
                          href="javascript:void(0)"
                          className="cw-save-to-draft mt-40"
                          style={{ width: '40%' }}
                          onClick={this._handleCancel}
                        >
                          Cancel
                        </a>
                      ) : campaign && campaign.isEdited ? (
                        <a
                          href="javascript:void(0)"
                          className="cw-cancel-edit mt-40"
                          onClick={event => this._openIsCancelModal(event)}
                        >
                          Cancel changes
                        </a>
                      ) : (
                        <a
                          href="javascript:void(0)"
                          className="cw-save-to-draft mt-40"
                          style={{ width: '40%' }}
                          onClick={event => this._handleSubmit(event, true)}
                        >
                          Save and exit
                        </a>
                      )}
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

/*When we need to use Nested sorting at that time this component we can use 
In that component we need to pass 4 parameter which is compulsary 
fieldData  =  which is nothing but field json
type =  which is for uniqueId for component identity
questionNum =  index of field 
heading =  heading of sub nested component 
dataKey =  that is sub key in the fieldData which we need nested */

export const NestedSortable = props => {
  const { fieldData, type, questionNum, heading, dataKey } = props;
  console.log('fieldData ==== ', fieldData);
  return (
    <>
      <div className="answer-heading">
        <strong>{heading}</strong>
      </div>
      <Droppable
        className="answers-list"
        droppableId={`${questionNum}_${type}`}
        type={`${type}`}
      >
        {(provided, snapshot) => (
          <div
            className="answers-list"
            ref={provided.innerRef}
            key={`${questionNum}_${type}`}
            style={getAnswerListStyle(snapshot.isDraggingOver)}
          >
            {fieldData[dataKey].map((field, index) => {
              return (
                <Draggable
                  key={`${questionNum}${type}${index}`}
                  draggableId={`${questionNum}${type}${index}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      className="answers-list-item d-flex"
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <span className="menu-grab-icon">
                        <i className="fas fa-align-justify cw-color--primary"></i>{' '}
                      </span>
                      <strong>
                        {field.label ||
                          field.translatedValue ||
                          field.answerLabel}
                      </strong>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </>
  );
};

const mapStateToProps = state => ({
  campaign: state.profileFieldSorting.campaign,
  campaignDetails: state.profileFieldSorting.campaignDetails || {},
  fields: state.profileFieldSorting.fields || [],
  consentField: state.profileFieldSorting.consentField || [],
  privacyAndDesclaimer: state.profileFieldSorting.privacyAndDesclaimer || [],
  asyncInProgress: state.profileFieldSorting.asyncInProgress,
  genderValues: state.profileFieldSorting.genderValues,
  provinceValues: state.profileFieldSorting.provinceValues,
  lastUpdatedScreen: state.app.lastUpdatedScreen
});
export default connect(mapStateToProps, {
  fetchCampaignData,
  updateProfileFields,
  setNotificationMessage,
  setApproverEditCampaignStatus,
  setApproverEditCampaignStatusMessage,
  setApproverEditLastUpdatedScreen,
  updateGenderValues,
  updateProvinceValues,
  cancelCampaignChanges
})(ProfileFieldsSorting);
