import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { FormInput } from '../../molecules/FormInput';
import { FormTextArea } from '../../molecules/FormTextArea';
import { FormSelect } from '../../molecules/FormSelect';
import { FormReactSelect } from '../../molecules/FormReactSelect';
import { FormRadioInput } from '../../molecules/FormRadioInput';
import { FormLabel } from '../../molecules/FormLabel';
import { FormError } from '../../molecules/FormError';
import { FormDatePicker } from 'components/molecules/FormDatePicker';
import { FormCheckBoxGroupInput } from 'components/molecules/FormCheckBoxGroupInput';
import { LinkButton } from 'components/atoms/LinkButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { getYupSchema } from 'selectors';
import classNames from 'classnames';
import GuideLinesInfo from 'components/GuideLineInfo';

const checkCallback = cb => {
  return cb ? cb : () => {};
};

const defaultOnWatch = (value, { options, disabled }, setData) => {
  return setData({ options, disabled });
};

const shouldShowFormError = (disableFormError, formState) => {
  if (!disableFormError) {
    if (formState.isSubmitted) {
      if (!formState.isValid && !formState.isSubmitSuccessful) {
        return true;
      }
      return false;
    }
    return false;
  }
  return false;
};

export const FormBuilder = ({
  schema: { title, fields, onSubmit, formType, noteComponent, ...formConfig },
  user,
  isEdit,
  isInModal,
  triggerRef,
  remoteSubmit,
  scrollToTop,
  customButtonsComp: CustomButtonComp,
  btnOrderReverse
}) => {
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const formRef = useRef();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState,
    getValues,
    reset,
    trigger,
    setError,
    clearErrors
  } = useForm({
    resolver: yupResolver(getYupSchema(fields))
  });

  const handleSetFormErrorMessage = (errMesg, options = {}) => {
    setFormErrorMessage(errMesg);
    if (options.scrollToTop) {
      if (scrollToTop) {
        scrollToTop();
      }
    }
  };

  const onSubmitHandler = handleSubmit(data => {
    setFormErrorMessage('');
    onSubmit &&
      onSubmit(data, {
        formState: formState,
        user: user,
        isEdit: isEdit,
        setFormErrorMessage,
        handleSetFormErrorMessage
      });
  });

  const onSubmitSecondary = handleSubmit(data => {
    setFormErrorMessage('');
    formConfig.onSecondaryClick &&
      formConfig.onSecondaryClick(data, {
        formState: formState,
        user: user,
        isEdit: isEdit,
        setFormErrorMessage,
        handleSetFormErrorMessage
      });
  });

  const invokeSubmit = () => {
    if (formRef.current) {
      onSubmitHandler(formRef.current);
    }
  };

  useEffect(() => {
    // init logic
    if (triggerRef) {
      triggerRef(trigger);
    }
    if (remoteSubmit) {
      remoteSubmit(invokeSubmit);
    }
    return () => {
      if (formConfig.onFormExit) {
        formConfig.onFormExit(getValues(), getYupSchema(fields), formState);
      }
      if (triggerRef) {
        triggerRef(() => {});
      }
    };
  }, []);

  useEffect(() => {
    if (formConfig.onFormStateChange) {
      formConfig.onFormStateChange(formState, getValues());
    }
  }, [formState]);

  const CustomViewComponent = formConfig.customView;

  const SubmitBtn = () => {
    return (
      <button
        className={classNames('btn btn-primary', {
          'mr-3': formConfig && formConfig.modelBtnSwap ? false : true
        })}
        type="submit"
        disabled={formState.isSubmitting}
      >
        {formState.isSubmitting
          ? 'Loading....'
          : (formConfig && formConfig.submitBtnLabel) || 'Submit'}
      </button>
    );
  };

  const CloseBtn = () => {
    return (
      <button
        className={classNames('btn btn btn-outline-secondary', {
          'mt-3': formConfig && formConfig.onSecondaryClick ? true : false,
          'mr-3': formConfig && formConfig.modelBtnSwap ? true : false
        })}
        type="button"
        onClick={() =>
          checkCallback(formConfig.onPrevious)({
            reset,
            setValue,
            getValues
          })
        }
        disabled={formState.isSubmitting}
      >
        {formConfig.previousBtnLabel || 'Cancel'}
      </button>
    );
  };

  return (
    <div className="form-builder-container">
      {title && <h2 className="mb-20">{title}</h2>}
      <form onSubmit={onSubmitHandler} ref={formRef}>
        {formConfig.debug && (
          <pre className="pre-scrollable cw-formbuilder-code">
            <code>{JSON.stringify(getValues(), null, 2)}</code>
          </pre>
        )}
        {shouldShowFormError(formConfig.disableFormError, formState) && (
          <FormError message={formConfig.formErrorMessage} />
        )}
        {formErrorMessage && <FormError message={formErrorMessage} />}
        {fields.map(field => {
          if (field.type === 'textarea' || field.type === 'textArea') {
            return (
              <FormTextArea
                {...field}
                id={field.id}
                type={field.type}
                name={field.name}
                label={field.label}
                placeholder={field.placeholder}
                register={register}
                rules={field.rules}
                rows={field.rows}
                errors={formState.errors}
                key={field.id}
                defaultValue={field.defaultValue}
                setValue={setValue}
              />
            );
          }

          if (field.type === 'select') {
            return (
              <FormSelect
                id={field.id}
                type={field.type}
                name={field.name}
                label={field.label}
                placeholder={field.placeholder}
                register={register}
                rules={field.rules}
                errors={formState.errors}
                options={field.options}
                key={field.id}
                defaultValue={field.defaultValue}
              />
            );
          }

          if (field.type === 'reactselect' || field.type === 'reactSelect') {
            return (
              <FormReactSelect
                {...field}
                id={field.id}
                type={field.type}
                name={field.name}
                label={field.label}
                placeholder={field.placeholder}
                control={control}
                rules={field.rules}
                errors={formState.errors}
                options={field.options}
                key={field.id}
                watchInput={field.watchInput}
                onWatch={field.onWatch || defaultOnWatch}
                defaultDisabled={field.defaultDisabled}
                defaultValue={field.defaultValue}
                setValue={setValue}
                isInModal={isInModal}
              />
            );
          }

          if (field.type === 'label') {
            return (
              <FormLabel
                {...field}
                id={field.id}
                label={field.label}
                key={field.id}
                className={field.labelClassName}
                toolTipText={field.toolTipText}
              />
            );
          }

          if (field.type === 'radio') {
            return (
              <FormRadioInput
                id={field.id}
                type={field.type}
                name={field.name}
                label={field.label}
                placeholder={field.placeholder}
                register={register}
                rules={field.rules}
                errors={formState.errors}
                key={field.id}
                options={field.options}
                disableError={field.disableError}
                defaultValue={field.defaultValue}
                setValue={setValue}
              />
            );
          }

          if (field.type === 'checkboxgroup') {
            return (
              <FormCheckBoxGroupInput
                id={field.id}
                type={field.type}
                name={field.name}
                label={field.label}
                placeholder={field.placeholder}
                register={register}
                rules={field.rules}
                errors={formState.errors}
                key={field.id}
                options={field.options}
                disableError={field.disableError}
                defaultValue={field.defaultValue}
                setValue={setValue}
                stackHorizontal={field.stackHorizontal}
              />
            );
          }

          if (field.type === 'datepicker') {
            return (
              <FormDatePicker
                {...field}
                key={field.id}
                errors={formState.errors}
                control={control}
                setValue={setValue}
              />
            );
          }

          if (field.type === 'custom') {
            const { component: CustomComponent, ...remainingfield } = field;
            return (
              <CustomComponent
                {...remainingfield}
                key={field.id}
                errors={formState.errors}
                control={control}
                register={register}
                setValue={setValue}
                trigger={trigger}
                isInModal={isInModal}
                user={user}
                formState={formState}
                setError={setError}
                clearErrors={clearErrors}
              />
            );
          }

          if (field.type === 'description') {
            return (
              <div
                key={field.id}
                className={classNames('mb-20', field.wrapperClass)}
              >
                <p id={field.id} className={field.className}>
                  {field.text}
                </p>
              </div>
            );
          }

          if (field.type === 'guideline') {
            return (
              <div
                key={field.id}
                className={classNames('mb-20', field.wrapperClass)}
              >
                <GuideLinesInfo
                  data={field.data}
                  liClassName="unordered-list-item"
                  noUlLineBreak={field.noUlLineBreak}
                  noSubTitleLineBreak={field.noSubTitleLineBreak}
                  defaultCollapse={field.defaultCollapse}
                />
              </div>
            );
          }

          return (
            <FormInput
              id={field.id}
              type={field.type}
              name={field.name}
              label={field.label}
              placeholder={field.placeholder}
              register={register}
              rules={field.rules}
              errors={formState.errors}
              key={field.id}
              defaultValue={field.defaultValue}
              maxLength={field.maxLength}
              setValue={setValue}
              disabled={field.disabled}
              customComponent={field.customComponent}
              readOnly={field.readOnly}
            />
          );
        })}
        {noteComponent ? noteComponent : null}
        {formType === 'simple' && (
          <div className="mt-30">
            <button
              className={classNames(
                'btn btn-primary',
                formConfig.submitBtnClass
              )}
              type="submit"
              disabled={formState.isSubmitting}
            >
              {formState.isSubmitting
                ? 'Loading....'
                : formConfig.submitBtnLabel || 'Submit'}
            </button>
          </div>
        )}
        {formType === 'wizard' && (
          <div className="mt-30">
            <button
              className="btn btn btn-outline-secondary mr-3"
              type="button"
              onClick={checkCallback(formConfig.onPrevious)}
              disabled={formState.isSubmitting}
            >
              {formConfig.previousBtnLabel || 'Previous'}
            </button>
            <button
              className="btn btn-primary"
              type="submit"
              disabled={formState.isSubmitting}
            >
              {formState.isSubmitting
                ? 'Loading....'
                : formConfig.nextBtnLabel || 'Next'}
            </button>
          </div>
        )}
        {formType === 'modal' && (
          <div
            className={
              formConfig && formConfig.btnClassName
                ? formConfig.btnClassName
                : 'mt-30 d-flex flex-wrap'
            }
          >
            {formConfig && formConfig.modelBtnSwap ? (
              <CloseBtn />
            ) : (
              <SubmitBtn />
            )}
            {formConfig.onSecondaryClick && (
              <button
                className="btn btn-primary mr-3"
                type="button"
                onClick={() => {
                  onSubmitSecondary();
                }}
                disabled={formState.isSubmitting}
              >
                {formState.isSubmitting
                  ? 'Loading....'
                  : formConfig.secondaryBtnLabel || 'Secondary'}
              </button>
            )}
            {formConfig && formConfig.modelBtnSwap ? (
              <SubmitBtn />
            ) : (
              <CloseBtn />
            )}
          </div>
        )}

        {formType === 'custombuttons' && CustomButtonComp && (
          <CustomButtonComp
            isSubmitting={formState.isSubmitting}
            submitBtnLabel={formConfig.submitBtnLabel}
            previousBtnLabel={formConfig.previousBtnLabel}
            onSubmitSecondary={onSubmitSecondary}
            onSecondaryClick={formConfig.onSecondaryClick}
            secondaryBtnLabel={formConfig.secondaryBtnLabel}
            onPrevious={formConfig.onPrevious}
            isEdit={formConfig.isEdit}
            customButtonWatch={formConfig.customButtonWatch}
            control={control}
            register={register}
            setValue={setValue}
            trigger={trigger}
          />
        )}

        {formConfig.onDraft && (
          <LinkButton
            className="cw-formdraft-link"
            onClick={() => {
              formConfig.onDraft(getValues(), getYupSchema(fields), {
                formState,
                user
              });
            }}
          >
            {formConfig.draftText ? formConfig.draftText : 'Save and exit'}
          </LinkButton>
        )}
        {formConfig.customView ? (
          <CustomViewComponent
            errors={formState.errors}
            control={control}
            register={register}
            setValue={setValue}
            trigger={trigger}
            isInModal={isInModal}
            user={user}
          />
        ) : null}
        {/* <button
              className="btn btn btn-outline-secondary mr-3"
              type="button"
              onClick={() => {trigger()}}
            >
              validate
            </button> */}
      </form>
    </div>
  );
};
