import React, { useState, useEffect } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import Select from 'react-select';
import classNames from 'classnames';
import { FieldError } from '../../atoms/FieldError';
import { get } from 'lodash';
import { OptionsWithCheckbox } from './OptionsWithCheckbox';
import { CustomOption } from './CustomOptions';

export const FormReactSelect = ({
  name,
  rules,
  control,
  label,
  errors,
  options,
  defaultValue,
  setValue,
  customHelpComponent: CustomHelpComponent,
  customHelpComponentProps,
  ...props
}) => {
  const results = useWatch({
    control,
    name: props.watchInput,
    defaultValue: props.defaultWatchValue
  });

  const [properties, setProperties] = useState({
    options,
    disabled: props.isEdit ? false : props.defaultDisabled
  });

  const { options: newOptions, disabled } = properties;
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  useEffect(() => {
    if (props.onLoad) {
      props.onLoad(properties, setProperties);
    }
  }, []);

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue, { shouldTouch: true });
      if (disabled) {
        setProperties({ ...properties, disabled: false });
      }
    }
  }, [defaultValue]);

  useEffect(() => {
    if (typeof props.isMulti !== 'undefined') {
      if (!props.isMulti) {
        setValue(
          name,
          Array.isArray(defaultValue) ? defaultValue[0] : defaultValue || ''
        );
      } else {
        setValue(name, defaultValue || '');
      }
    }
  }, [props.isMulti]);

  useEffect(() => {
    if (props.onWatch) {
      props.onWatch(results, properties, setProperties);
    }
  }, [results]);

  useEffect(() => {
    const selectOptions = props.showExtraOptions
      ? [...props.extraOptions, ...options]
      : options;

    var uniqueOptions = [];
    selectOptions.filter(item => {
      var i = uniqueOptions.findIndex(x => x.value == item.value);
      if (i <= -1) {
        uniqueOptions.push(item);
      }
      return null;
    });

    setProperties({
      ...properties,
      options: uniqueOptions
    });
  }, [props.showExtraOptions]);

  const getOptions = (optionList, isMulti, selectedValues, max) => {
    if (!isMulti) return optionList;

    return selectedValues.length >= max
      ? optionList.map(option => ({
          ...option,
          isDisabled: !selectedValues.some(f => f.value === option.value)
        }))
      : optionList;
  };

  return (
    <div className="form-group" aria-live="polite">
      <div className="d-flex justify-content-between">
        <label htmlFor={props.id}>{label}</label>
        {CustomHelpComponent ? (
          <CustomHelpComponent {...customHelpComponentProps} />
        ) : (
          ''
        )}
      </div>

      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue ? defaultValue : props.defaultSelected}
        shouldUnregister={props.shouldUnregister}
        render={({ field }) => (
          <Select
            className={classNames('react-select-container', {
              'reactselect-invalid': hasError
            })}
            classNamePrefix="react-select"
            {...field}
            options={getOptions(
              newOptions,
              props.isMulti,
              field.value,
              props.max
            )}
            placeholder={props.placeholder}
            isDisabled={disabled}
            defaultValue={defaultValue ? defaultValue : props.defaultSelected}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            menuPosition={props.isInModal ? 'fixed' : 'absolute'}
            isMulti={props.isMulti}
            closeMenuOnSelect={!props.isMulti}
            hideSelectedOptions={false}
            components={{
              Option: props.isCustomOption ? CustomOption : OptionsWithCheckbox
            }}
            onChange={val => {
              if (props.isMulti && props.max) {
                if (val?.length <= props.max) {
                  field.onChange(val);
                }
              } else {
                field.onChange(val);
              }
            }}
          />
        )}
      />
      <FieldError
        errors={errors}
        hasNestedError={props.hasNestedError}
        name={name}
      />
    </div>
  );
};
