import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

export default function EmailRequestInfoSubmitComp(props) {
  const { title, emailBriefing, handleRequestInfoAnswer, isError } = props;
  const { requestInfoText, requestInfoAnswerText } = emailBriefing;
  return (
    <>
      <div className="email-tabular">
        <div
          className="cw-campaign--review-section-header d-flex align-items-center"
          style={{ height: 'var(--space-40)' }}
        >
          <h3 className="email-tabular__heading">{title}</h3>
        </div>
        {isError && (
          <p className="error-text">
            <span>
              <i className="fas fa-exclamation-triangle mr-10"></i>
            </span>
            Information is missing in this section
          </p>
        )}
        <div className="email-tabular__row row" key={'requestedInfo'}>
          <div className="col-sm-3">
            <div className="label-long">
              <strong className="email-tabular__label cw-icon cw-icon--help">
                {`Information requested by ACS team`}
              </strong>
            </div>
          </div>
          <div className={`col-sm-9 email-tabular__value`}>
            <span style={{ whiteSpace: 'pre-line' }}>{requestInfoText}</span>
          </div>
        </div>
        <div className="email-tabular__row row" key={'infoInput'}>
          <div className="col-sm-3 ">
            <div className="label-long">
              <strong className="email-tabular__label cw-icon cw-icon--help">
                {`Please state your response to the ACS team`}
              </strong>
            </div>
          </div>
          <div className={`col-sm-9`}>
            <textarea
              className={classNames(
                `form-control email-request-info-text-area email-tabular__value w-100`,
                { 'is-invalid': props.isError }
              )}
              id={`requestinfoanswer`}
              name={'requestinfoanswer'}
              placeholder={'Enter your response here'}
              rows="4"
              onChange={handleRequestInfoAnswer}
              defaultValue={requestInfoAnswerText ? requestInfoAnswerText : ''}
            ></textarea>
            {props.isError ? (
              <span className={'cw-error'}>
                <i className="fas fa-exclamation-triangle mr-10"></i>
                {`Please describe your response to ACS execution team`}
              </span>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </>
  );
}
