import React from 'react';
import Modal from 'react-modal';
import constant from 'constants/config';

export default ({ isModalOpen, onClose }) => {
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={onClose}
      className="cw-modal cw-modal--branddetail"
      contentLabel="Can't find your brand?"
      style={{
        overlay: {
          backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
        }
      }}
    >
      <button className="cw-modal--close" title="Close" onClick={onClose}>
        <i className="fas fa-times"></i>
      </button>
      <h3 className="cw-heading--primary mb-20">
        Can't find your brand or country?
      </h3>
      <p className="mb-10">
        If you are an active brand and market combination, you should find
        yourself listed in our tool.
        <br /> If you can’t find your brand or market, raise an
        <a href={constant.KANA_URL} target="_blank" rel="noreferrer">
          {` UNA ticket `}
          <i className="fas fa-external-link-alt"></i>
        </a>
      </p>
    </Modal>
  );
};

export const CantFindContryHelpLink = ({ setIsModelOpen }) => {
  return (
    <a
      href="#modal"
      onClick={event => {
        event.preventDefault();
        setIsModelOpen(true);
      }}
      title="Can't find your brand?"
    >
      Can't find your brand or country?
    </a>
  );
};
