// SMS Brand Detail Reducer added
import {
  FETCH_SMS_BRAND_DETAILS,
  FETCH_SMS_BRANDS_FOR_COUNTRY,
  FETCH_ALL_SMS_BRANDS,
  UPDATE_SMS_BRAND_DETAILS,
  FETCH_SMS_CAMPAIGN_DETAILS,
  SUBMIT_SMS_BRAND_DETAILS_INFO,
  SMS_ASYNC_IN_PROGRESS,
  SMS_SYSTEM_ERROR,
  GET_BRAND_COUNTRIES,
  BRAND_COUNTRY_LOADED_SUCCESS,
  BRAND_COUNTRY_LOADED,
  GET_COUNTRY_BRAND_LIST,
  RESET_SMS_BRAND_DETAILS
} from '../../actionTypes';

import constant from '../../constants';
import { epsilonMarket } from '../../selectors/index';
const initialState = {
  smsBrands: [],
  smsBrandList: [],
  smsCountries: [],
  languages: [],
  asyncInProgress: false,
  isModalOpen: false,
  brand: null,
  country: null,
  language: null,
  allBrands: [],
  otherLanguage: null,
  englishSelected: true,
  otherSelected: false,
  optOutText: null,
  uuid: null,
  isLoading: false,
  response: null,
  brands: [],
  countries: [],
  POSCheck: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SMS_BRAND_DETAILS: {
      let smsBrands = {
        smsBrands: action.payload
      };

      let smsCountries = {
        smsCountries: action.payload
      };

      return {
        ...state,
        ...smsBrands,
        ...smsCountries
      };
    }

    case UPDATE_SMS_BRAND_DETAILS: {
      let country = action.payload;
      let brand = action.payload;
      let language = action.payload || 'English';
      let uuid = action.uuid;
      return {
        ...state,
        ...country,
        ...brand,
        ...uuid,
        ...language
      };
    }

    case FETCH_SMS_BRANDS_FOR_COUNTRY: {
      let smsBrandList = {
        smsBrandList: action.payload
      };
      return {
        ...state,
        ...smsBrandList,
        isLoading: false
      };
    }

    case FETCH_SMS_CAMPAIGN_DETAILS: {
      let {
        brand,
        country,
        optOutMessage,
        englishSelected,
        otherSelected,
        otherLanguage,
        language,
        optOutText,
        uuid
      } = action.smsCampaignDetails;
      let smsCampaignDetails = {
        brand: action.payload,
        country: action.payload,
        optOutText: action.payload,
        englishSelected: action.payload,
        otherSelected: action.payload,
        otherLanguage: action.payload,
        language: action.language || 'English',
        uuid: action.uuid
      };

      return {
        ...state,
        ...smsCampaignDetails
      };
    }

    case SUBMIT_SMS_BRAND_DETAILS_INFO: {
      let dataToSend = {
        dataToSend: action.dataToSend
      };
      let response = { response: action.response };

      return {
        ...state,
        ...dataToSend,
        ...response
      };
    }

    case SMS_ASYNC_IN_PROGRESS: {
      let isLoading = { isLoading: action.isLoading };
      return {
        ...state,
        ...isLoading
      };
    }

    case SMS_SYSTEM_ERROR: {
      let systemError = { systemError: action.error };
      return {
        ...state,
        ...systemError
      };
    }

    case FETCH_ALL_SMS_BRANDS: {
      return {
        ...state,
        allBrands: action.payload
      };
    }

    case GET_COUNTRY_BRAND_LIST: {
      let brands = { brands: action.brands };
      let language = { language: action.countrylanguage };
      let countrylanguage = { countrylanguage: action.countrylanguage };
      let brand = { brand: null };
      return {
        ...state,
        ...brands,
        ...language,
        ...countrylanguage,
        ...brand
      };
    }

    case BRAND_COUNTRY_LOADED: {
      return {
        ...state,
        ...{
          countryDataLoaded: action.countryDataLoaded
        }
      };
    }
    case BRAND_COUNTRY_LOADED_SUCCESS: {
      return {
        ...state,
        ...{
          countriesLoaded: action.payload,
          countryDataLoaded: action.countryDataLoaded,
          profileStoreExist: null
        }
      };
    }

    case GET_BRAND_COUNTRIES: {
      let countries = {
        // currently filtering out Epsilon market for soft launch as part of WTG-9613
        countries: constant.featureFlags.ENABLE_EPSILON_JOURNEY
          ? action.countryDetailsSorted
          : action.countryDetailsSorted.filter(
              elem => !epsilonMarket(elem.code)
            ),
        brand: null,
        country: null,
        language: null,
        websiteType: null,
        websiteExtension: null,
        countrylanguage: null
      };

      return {
        ...state,
        ...countries
      };
    }

    case RESET_SMS_BRAND_DETAILS: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
