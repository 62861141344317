import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import {
  routesConfig,
  editCampaignRoutesConfig,
  ldaproutesConfig,
  incentiveRoutesConfig,
  incentiveLdaproutesConfig,
  qnARoutingConfig,
  incentiveSimpleRoutingConfig,
  incentiveQnARoutingConfig,
  epsilonRoute,
  epsilonQARoute,
  emailBriefingRoutes
} from '../reactRouteConfig';
import { getPageBreadCrumb } from '../selectors';

export const BreadCrumbItem = props => {
  if (props.item.length) {
    if (props.item.length === 1) {
      return (
        <Fragment>
          <NavLink
            className="cw-color--primary cw-breadcrum"
            to={props.item[0].path}
          >
            <i className="fas fa-angle-left"></i>
            {' ' + props.item[0].title}
          </NavLink>
        </Fragment>
      );
    } else if (props.item.length > 1) {
      let breadCrumb = props.item.map((item, index) => {
        if (!item.path) {
          return (
            <span className="cw-color--primary cw-breadcrum">
              {' '}
              <i
                className={`fas fa-angle-right ${index > 0 ? '' : 'cw-hidden'}`}
              ></i>{' '}
              {' ' + item.title}
            </span>
          );
        }
        return (
          <NavLink
            className="cw-color--primary cw-breadcrum"
            to={item.path}
            key={index}
          >
            {' '}
            <i
              className={`fas fa-angle-right ${index > 0 ? '' : 'cw-hidden'}`}
            ></i>{' '}
            {' ' + item.title}
          </NavLink>
        );
      });
      return <Fragment>{breadCrumb}</Fragment>;
    }
  }
};

const BreadCrumb = props => {
  if (props.hideBreadCrumb) {
    return null;
  } else {
    //let routesList = props.websiteType === 'Standalone' ? ldaproutesConfig : routesConfig;
    let routesList =
      props.websiteType === 'Adobe SaaS' &&
      props.formType === 'Simple sign-up' &&
      props.isEpsilonMarket
        ? epsilonRoute
        : props.websiteType === 'Adobe SaaS' &&
          props.formType === 'Sign-up with Questions & Answers' &&
          props.isEpsilonMarket
        ? epsilonQARoute
        : props.campaignType === 'Incentive' &&
          props.formType === 'Simple sign-up'
        ? incentiveSimpleRoutingConfig
        : props.campaignType === 'Incentive' &&
          props.formType === 'Sign-up with Questions & Answers'
        ? incentiveQnARoutingConfig
        : props.websiteType === 'Adobe SaaS' &&
          props.formType === 'Sign-up with Questions & Answers'
        ? qnARoutingConfig
        : props.campaignType === 'Direct messaging' &&
          props.formType === 'Sign-up with Questions & Answers'
        ? qnARoutingConfig
        : props.campaignType === 'Incentive'
        ? props.websiteType === 'Standalone'
          ? incentiveLdaproutesConfig
          : incentiveRoutesConfig
        : props.websiteType === 'Standalone'
        ? ldaproutesConfig
        : props.emailBriefing === true
        ? emailBriefingRoutes
        : routesConfig;
    //:props.editCampaign ? editCampaignRoutesConfig : routesConfig;
    let breadcrumbdata = getPageBreadCrumb(props.match, routesList);
    const { shrink } = props;
    return (
      <div className={`row ${shrink ? 'mt-10 mb-15' : 'mt-20 mb-30'}`}>
        <div className="col-sm-12">
          {breadcrumbdata && breadcrumbdata.length && (
            <BreadCrumbItem item={breadcrumbdata} />
          )}
        </div>
      </div>
    );
  }
};
export default BreadCrumb;
