import * as yup from 'yup';

import { FormEmailRadioInput } from 'components/molecules/FormEmailRadioInput';
import { getIsEdited } from 'pages/NewEmailCampaign/brandDetailsformSchema';

import { submitSmsCampaignDetailsV2 } from 'actions/communications/communicationCampaignDetails.js';

import { getYupSchema, SMSSubmitRedirectURL } from 'selectors';
import constants from 'constants/config';

const smsTypeSchema = (dispatch, componentProps) => {
  const { history, match, defaultValues, campaignDetails } = componentProps;
  const isEdited = getIsEdited({ history });

  const onSubmit = (data, { user }, { backToReview } = {}) => {
    const isEdit = !!match.params.id;
    const uid = match.params.id;

    const dataToSend = {
      uid,
      smsType: data.smsType
    };

    dispatch(
      submitSmsCampaignDetailsV2(dataToSend, (error, result) => {
        if (error) {
          console.log('OnSubmit error: ', error);
        } else {
          if (uid) {
            history.push(
              SMSSubmitRedirectURL({
                backToReview: isEdited && backToReview,
                status: campaignDetails.status,
                uid,
                nextPage: `${constants.SMS_ROUTES.CAMPAIGN_SUMMARY}`
              })
            );
          } else {
            history.push(
              `/campaign-wizard/${constants.SMS_ROUTES.NEW_CAMPAIGN_SUMMARY}`
            );
          }
        }
      })
    );
  };

  return {
    formType: 'custombuttons',
    submitBtnLabel: isEdited ? 'Save and back to review' : 'Next',
    onSecondaryClick: onSubmit,
    onSubmitSecondary: () => {},
    isEdit: isEdited,
    secondaryBtnLabel: 'Next',
    fields: [
      {
        type: 'custom',
        name: 'smsType',
        id: 'smsType',
        disableError: true,
        component: FormEmailRadioInput,
        defaultValue: defaultValues.smsType,
        options: constants.SMS_TYPE_OPTIONS,
        rules: yup.string().required('This field Is required!!!')
      }
    ],

    onSubmit: (data, { user }) =>
      onSubmit(data, { user }, { backToReview: isEdited }),

    onPrevious: () => {
      const uid = match.params.id;
      history.push(
        uid
          ? `${constants.SMS_BASE_ROUTE}/${uid}/${constants.SMS_ROUTES.BRAND_DETAILS}`
          : `/campaign-wizard/${constants.SMS_ROUTES.NEW_CAMPAIGN}`
      );
    },
    getSchema() {
      if (this.fields) {
        return getYupSchema(this.fields);
      }
      return null;
    },

    previousBtnLabel: 'Back',
    formErrorMessage:
      'There was a problem with the form. Errors are listed below'
  };
};

export default smsTypeSchema;
