import {
  FETCH_EMAIL_BRIEFINGS,
  FETCH_EMAIL_BRIEFINGS_SUCCESS,
  FETCH_EMAIL_BRIEFINGS_FAILURE,
  SET_EMAIL_BRIEFINGS_COUNT
} from '../../actionTypes';

const initialState = {
  isLoading: false,
  data: { data: [], pagination: {} },
  count: ' ',
  error: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_EMAIL_BRIEFINGS: {
      return { ...state, isLoading: true };
    }
    case FETCH_EMAIL_BRIEFINGS_SUCCESS:
      return { ...state, isLoading: false, data: action.payload };

    case FETCH_EMAIL_BRIEFINGS_FAILURE:
      return { ...state, isLoading: false, error: action.payload };

    case SET_EMAIL_BRIEFINGS_COUNT:
      return { ...state, count: action.payload };
    default:
      return state;
  }
}
