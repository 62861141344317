import React from 'react';
import ReactTooltip from 'react-tooltip';

import MissingImage from '../../../images/template-missing-image.png';
import MissingLink from '../../../images/template-missing-link.png';
import ErrorIcon from '../../../images/template-test-error.png';
import MissingImageError from '../../../images/template-missing-image-error.png';
import MissingLinkeError from '../../../images/template-missing-link-error.png';
import ErrorImageBig from '../../../images/error-big.png';
import MissingABBlue from '../../../images/ab-blue.png';
import MissingABGrey from '../../../images/ab-grey.png';
import MissingCouponBlue from '../../../images/coupon-blue.png';
import MissingCouponGrey from '../../../images/coupon-grey.png';
import Check from '../../../images/bi_check.png';

const pluralise = (word, qty) => {
  return qty === 0 || qty > 1 ? `${word}s` : word;
};

const ErrorItem = ({ item, type }) => {
  const { validationType } = item;

  const getErrorMessages = () => {
    switch (validationType) {
      case 'requiredHref': // Image src missing
        return {
          image: MissingImageError,
          content: (
            <div>
              <p className="title">Missing Image</p>
              <p>Image is missing in an image block</p>
            </div>
          )
        };
      case 'requiredAlt': // Image alt missing
        return {
          image: item.src,
          content: (
            <div>
              <p className="title">Missing alt text</p>
              <p>
                Image URL{' '}
                <a target={'_blank'} href={item.src}>
                  {item.src.substring(0, 90)}
                  {item.src.length > 90 ? '..' : ''}
                </a>
              </p>
            </div>
          )
        };
      case 'matchHref': //Image src path not matching TAB
        return {
          image: item.src,
          content: (
            <div>
              <p className="title">Invalid image source path</p>
              <p>
                Image source URL should be from TAB&nbsp;
                <span
                  className="tooltip-icon"
                  data-tip="Campaign Wizard can only accept images uploaded in TAB. Please refer to our image guidelines to learn more"
                  data-for="image-source"
                >
                  &nbsp;<i className="fas fa-question-circle"></i>
                </span>
              </p>
              <p>
                Image URL{' '}
                <a target={'_blank'} href={item.src}>
                  {item.src.substring(0, 90)}
                  {item.src.length > 90 ? '..' : ''}
                </a>
                <br />
                <ReactTooltip
                  id="image-source"
                  place="right"
                  type="info"
                  multiline={true}
                  className="cw-tooltip cw-email-tooltip saas_new_master_tooltip"
                />
              </p>
            </div>
          )
        };
      case 'requiredLabel': // Button label missing
        return {
          image: MissingLinkeError,
          content: (
            <div>
              <p className="title">Button text missing</p>
              <p>Button text is missing in a button block</p>
            </div>
          )
        };
      case 'requiredLink': // Button href missing
        return {
          image: MissingLinkeError,
          content: (
            <div>
              <p className="title">Button URL is missing</p>
              <p>Button {item.label}</p>
            </div>
          )
        };
      case 'mirrorLink': // mirror link "click here"
        return {
          image: MissingLinkeError,
          content: (
            <div>
              <p className="title">Mirror link is missing</p>
              <p>
                Mirror links help recipients view email in browser in case email
                fails to load. Do not manually enter the mirror link and instead
                use special links available in the inbuilt text toolbar to
                auto-generate the right link.
              </p>
            </div>
          )
        };
      case 'unsubscribe': // unsubscribe link
        return {
          image: MissingLinkeError,
          content: (
            <div>
              <p className="title">Unsubscribe link is missing</p>
              <p>
                Unsubscribe link is mandatory in every email as they give
                recipients the control to opt out of marketing emails from
                Unilever brands. Do not manually enter the unsubscribe link and
                instead use special links available in the inbuilt text toolbar
                to auto-generate the right link.
              </p>
            </div>
          )
        };
      case 'brandOfOriginName': // brandOfOriginName
        return {
          image: MissingLinkeError,
          content: (
            <div>
              <p className="title">Brand of origin is missing</p>
              <p>
                Brand of origin should be included in the footer as a disclaimer
                to indicate which brand the consumer signed up to. For e.g. “You
                are receiving this email because you have consented to receive
                offers and information from Unilever brands through Knorr”. Do
                not manually enter the brand name and instead use our
                personalized field ‘Brand of origin’ available to be selected
                through the inbuilt text toolbar.
              </p>
            </div>
          )
        };
      case 'brandsSenderEmailAddress': // brandsSenderEmailAddress
        return {
          image: MissingLinkeError,
          content: (
            <div>
              <p className="title">Brand sender email is missing</p>
              <p>
                Brand sender email should be included in the footer to remind
                recipients to add the brand sender email address (E.g. “Add
                ‘brand sender email address’ to your address book”). Do not
                manually enter the brand email address and instead use our
                personalized field ‘Brand sender email available to be selected
                through the inbuilt text toolbar.
              </p>
            </div>
          )
        };
      case 'abTest':
        return {
          image: MissingABGrey,
          content: (
            <div>
              <p className="title">
                Missing AB test variants {item.missingVariant}
              </p>
              <p>
                You have chosen to configure an AB test on email content but
                have not configured any variants. Refer to the A/B test
                guidelines in the editor toolbar to learn how to create A/B test
                content variants.
              </p>
            </div>
          )
        };
      case 'abTestIncomplete':
        let incompleteVariant = item.missingVariant;
        let incompleteVariantCount = 1;
        if (
          typeof item.missingVariant === 'object' &&
          item.missingVariant.length > 1
        ) {
          incompleteVariant = item.missingVariant.join('/');
          incompleteVariantCount = item.missingVariant.length;
        }
        return {
          image: MissingABGrey,
          content: (
            <div>
              <p className="title">
                Missing AB test {pluralise('variant', incompleteVariantCount)}{' '}
                {incompleteVariant}
              </p>
              <p>
                You have chosen to configure an AB test on email content but
                have not configured {incompleteVariant}{' '}
                {pluralise('variant', incompleteVariantCount)}. Refer to the A/B
                test guidelines in the editor toolbar to learn how to create A/B
                test content variants.
              </p>
            </div>
          )
        };
      case 'abTestDuplicate':
        const duplicateVariants = item.response.reduce((acc, curr) => {
          if (curr.value > 1) {
            acc.push(curr.type);
          }
          return acc;
        }, []);

        const varientNameList = duplicateVariants.map(variant =>
          variant.charAt(variant.length - 1)
        );
        const varientName = varientNameList.join('/');
        const varientNameDash = varientNameList.join(', ');

        return {
          image: MissingABGrey,
          content: (
            <div>
              <p className="title">
                Multiple tiles have been marked as {varientName}{' '}
                {pluralise('variant', duplicateVariants.length)}
              </p>
              <p>
                Multiple tiles within your email have been marked as{' '}
                {pluralise('Variant', duplicateVariants.length)}{' '}
                {varientNameDash}. Please ensure you have not created duplicate
                variants and avoid creating too many content variables to be
                able to attribute your win to a specific test criteria. This is
                a precautionary warning and not an error.
              </p>
            </div>
          ),
          isWarning: true
        };
      case 'coupons': // coupons
        return {
          image: MissingCouponGrey,
          content: (
            <div>
              <p className="title">Dynamic coupon placeholder missing</p>
              <p>
                You have chosen to include a dynamic coupon unique to each
                consumer in the email but have not included a dynamic coupon
                placeholder.
              </p>
            </div>
          )
        };
      default:
        return {
          image: '',
          content: <p>Undefined parameter</p>
        };
        break;
    }
  };

  const { image, content, isWarning = false } = getErrorMessages();
  const previewImage = ['requiredAlt', 'matchHref'].includes(validationType);

  return image ? (
    <div
      className={`error-item ${
        isWarning ? 'warning' : ''
      } d-flex justify-content-between align-items-center mt-3`}
    >
      <div className="d-flex align-items-center mx-2">
        <div
          className={`image-container ${previewImage && 'preview'} ${
            isWarning ? 'warning' : ''
          }`}
        >
          <img
            src={image}
            alt={`error preview image for ${type}`}
            className="error-image"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = MissingImageError;
              currentTarget.parentElement.classList.add('failed-image');
            }}
          />
        </div>
        {content}
      </div>
      {isWarning ? (
        <span className="warning-icon">
          <i className="fas fa-exclamation-circle"></i>
        </span>
      ) : (
        <img src={ErrorIcon} alt="Error info" />
      )}
    </div>
  ) : null;
};

const SuccessItem = ({ img, info }) => {
  return img ? (
    <>
      <div className="error-item success d-flex justify-content-between align-items-center mt-3">
        <div className="d-flex align-items-center mx-2">
          <div className={`image-container`}>
            <img
              src={img}
              className="error-image"
              alt="missing image check success"
            />
          </div>
          {info ? (
            <span>No errors are found</span>
          ) : (
            <span>Not applicable to this email</span>
          )}
        </div>
        <img src={Check} className="check" alt="missing image check success" />
      </div>
    </>
  ) : null;
};

export const ErrorsScreenBody = ({ errorList }) => {
  const {
    imageHref,
    imageAlt,
    imageTab,
    buttonAttr,
    linkSpecial,
    abTest,
    coupons,
    info
  } = errorList; // add ABTest and coupon and missing errors here, 'missingImg' is type for 'missing image' error

  const imageErrors = [...imageHref, ...imageAlt, ...imageTab];
  const linkErrors = [...buttonAttr, ...linkSpecial];

  return (
    <div className="runtest--error">
      <div className="runtest--error-splash">
        <div className="text-center runtest--init">
          <div className="cw-color--primary runtest--error-icon" role="status">
            <img src={ErrorImageBig} alt="Error info" />
            <span className="sr-only">Loading errors...</span>
          </div>
          <div className="runtest--initmsg">
            <p>Errors are found</p>
          </div>
        </div>
      </div>

      <div className="runtest--error-list">
        <p className="mt-4">Please resolve the errors below.</p>
        <div className="error-items mt-2 pr-2">
          {/** Missing images */}
          <div className="error-category mb-2">
            <div className="d-flex align-items-center mt-3">
              <img src={MissingImage} className="mr-2" alt="missing image" />
              <span className="heading mt-2">Missing images</span>
            </div>
            {imageErrors.length === 0 && (
              <SuccessItem img={MissingLink} info={true} />
            )}

            {imageErrors &&
              imageErrors.map((item, i) => {
                return (
                  <ErrorItem
                    item={item}
                    key={`${item.validationType}-${i}`}
                    type="image error"
                  />
                );
              })}
          </div>

          {/** Missing Urls */}
          <div className="error-category mb-2">
            <div className="d-flex align-items-center mt-3">
              <img
                src={MissingLink}
                className="mr-2"
                alt="missing link image"
              />
              <span className="heading">Missing Urls</span>
            </div>
            {linkErrors.length === 0 && (
              <SuccessItem img={MissingLink} info={true} />
            )}

            {linkErrors &&
              linkErrors.map((item, i) => {
                return (
                  <ErrorItem
                    item={item}
                    key={`${item.validationType}-${i}`}
                    type="missing URLs"
                  />
                );
              })}
          </div>

          {/** AB Test */}
          <div className="error-category mb-2">
            <div className="d-flex align-items-center mt-3">
              <img
                src={MissingABBlue}
                className="mr-2"
                alt="missing abtest image"
              />
              <span className="heading">A/B tests</span>
            </div>
            {abTest.length === 0 && (
              <SuccessItem img={MissingABBlue} info={info.isABTestCampaign} />
            )}

            {abTest &&
              abTest.map((item, i) => {
                return (
                  <ErrorItem
                    item={item}
                    key={`${item.validationType}-${i}`}
                    type="AB Tests"
                  />
                );
              })}
          </div>

          {/** Coupons */}
          <div className="error-category mb-2">
            <div className="d-flex align-items-center mt-3">
              <img
                src={MissingCouponBlue}
                className="mr-2"
                alt="missign coupon image"
              />
              <span className="heading">Coupons</span>
            </div>
            {coupons.length === 0 && (
              <SuccessItem
                img={MissingCouponBlue}
                info={info.isCouponBasedCampaign}
              />
            )}
            {coupons &&
              coupons.map((item, i) => {
                return (
                  <ErrorItem
                    item={item}
                    key={`${item.validationType}-${i}`}
                    type="coupons"
                  />
                );
              })}
          </div>
        </div>

        <p className="mt-4 mb-4 font-italic template-tests-note">
          Our automated tests still cannot check if you have added privacy
          policy, cookie policy links and all the required legal information or
          not, so please ensure you are using our footer rows as reference as
          well as global and local legal guidance.
        </p>
      </div>
    </div>
  );
};

export const ErrorsScreenActions = ({ closeModal }) => (
  <div className="d-flex justify-content-center">
    <button
      className="btn btn btn btn-primary"
      onClick={() => closeModal(false)}
    >
      Close
    </button>
  </div>
);
