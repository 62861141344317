const constants = {
  HOMEPAGE: 'https://campaignwizard.unilever.com/campaign-wizard',
  REPORTS: 'https://campaignwizard.unilever.com/campaign-wizard/report-mgmt-v2',
  KANA_PAGE:
    'https://unilever.service-now.com/cex?id=csm_get_help&sys_id=990686831b3cc0d076bc6428bd4bcba2&issue=8b9f991b1bbe8010cd9b1028bd4bcb01',
  KANA_URL:
    'https://unilever.service-now.com/cex?id=csm_get_help&sys_id=fb1e37031ba13890d26d8732f54bcb98',
  serviceUrls: {
    CAMPAIGN_LIST_SERVICE_URL: '//campaignwizard.unilever.com/api/v1/campaigns',
    CAMPAIGN_DETAILS_SERVICE_URL:
      '//campaignwizard.unilever.com/api/v1/campaigns',
    CAMPAIGN_DATA_SERVICE_URL:
      '//campaignwizard.unilever.com/api/v1/campaigndetails',
    CAMPAIGN_STATUS_UPDATE_SERVICE_URL:
      '//campaignwizard.unilever.com/api/v1/campaigns/updateStatus',
    CAMPAIGN_MASTER_UPDATE_SERVICE_URL:
      '//campaignwizard.unilever.com/api/v1/campaigns',
    CAMPAIGN_MASTER_DATA_UPDATE_SERVICE_URL:
      '//campaignwizard.unilever.com/api/v1/campaigns/masterdata',
    CAMPAIGN_MASTER_SERVICE_URL:
      '//campaignwizard.unilever.com/api/v1/campaigns',
    CAMPAIGN_INFRA_API_CALL:
      '//campaignwizard.unilever.com/api/v1/campaigns/infraApiCall',
    SERVICE_URL: {
      BRAND_DETAILS: '//campaignwizard.unilever.com/api/v1/branddetails',
      PROFILE_STORE: '//campaignwizard.unilever.com/api/v1/profileStore',
      QUESTIONS: '//campaignwizard.unilever.com/api/v1/questions',
      ANSWERS: '//campaignwizard.unilever.com/api/v1/answers',
      COUNTRIES: '//campaignwizard.unilever.com/api/v1/mapBrandCountry',
      BRANDS_LIST: '//campaignwizard.unilever.com/api/v1/mapBrandCountry/brand',
      PROMOTIGO_COST_CALCULATION:
        '//campaignwizard.unilever.com/api/v1/calculateCost',
      COOKIE_PRIVACY_URL:
        '//campaignwizard.unilever.com/api/v1/campaigndetails/urls',
      CAMPAIGN_ID_NAME_URL:
        '//campaignwizard.unilever.com/api/v1/campaigns/searchData',
      CAMPAIGN_FIND_URL:
        '//campaignwizard.unilever.com/api/v1/campaigns/suggestions',
      BRAND_NAME_URL:
        '//campaignwizard.unilever.com/api/v1/mapBrandCountry/brand',
      CAMPAIGN_EDIT_LOGS_URL:
        '//campaignwizard.unilever.com/api/v1/campaignLog',
      GET_CAMPAIGN_ID:
        '//campaignwizard.unilever.com/api/v1/campaigns/getCampaignId',
      GET_TRANSLATED_QUESTIONS:
        '//campaignwizard.unilever.com/api/v1/questions',
      GET_TRANSLATED_ANSWERS: '//campaignwizard.unilever.com/api/v1/answers',
      GET_AUTOPOPULATED_WEBSITETYPE:
        '//campaignwizard.unilever.com/api/v1/websitetypes',
      GET_AUTOPOPULATED_BUSINESS_GROUP:
        '//campaignwizard.unilever.com/api/v1/businessGroup'
    },
    CAMPAIGN_GET_USER_URL: '//campaignwizard.unilever.com/api/v1/user',
    CAMPAIGN_ADD_EDIT_USER_URL: '//campaignwizard.unilever.com/api/v1/user/add',
    CAMPAIGN_DELETE_USER_URL:
      '//campaignwizard.unilever.com/api/v1/user/delete',
    CONNECTED_WORLD_USER_URL: '/bin/ts/public/idm/user',
    CAMPAIGN_HISTORY_LOGS_URL: '//campaignwizard.unilever.com/api/v1/logs',
    REQUESTER_DOMAIN_URL: '//campaignwizard.unilever.com/api/v1/domains',
    REQUESTER_DELETE_DOMAIN_URL:
      '//campaignwizard.unilever.com/api/v1/domains/delete',
    VALIDATE_CAMPAIGN_NAME_URL:
      '//campaignwizard.unilever.com/api/v1/campaigns/validate',
    CAMPAIGN_AUTHOR_ACCESS: '//campaignwizard.unilever.com/api/v1/authAccess',
    VALIDATE_CAMPAIGN_AUTHOR_ACCESS:
      '//campaignwizard.unilever.com/api/v1/authAccess/validate',
    CAMPAIGN_PREFERENCE_CENTRE_UPLOAD_URL:
      '//campaignwizard.unilever.com/api/v1/admin/prefcentre',
    BRAND_CREATION_UPLOAD_URL:
      '//campaignwizard.unilever.com//api/v1/admin/createBrands',
    CAMPAIGN_DETAILS_UPLOAD_URL:
      '//campaignwizard.unilever.com/api/v1/campaigndetails/upload',
    CONFLUENCE_FORMAT_URL:
      'https://ce-platform.atlassian.net/wiki/spaces/IWG/pages/148114044/Ciab+BAU+to+Production+Non+D2',
    CONFLUENCE_ADOBE_CLASSIC_IMPLEMENTATION:
      'https://ce-platform.atlassian.net/wiki/spaces/IWG/pages/360416294/Campaign+Form+Auto-Generation+-+AEM+Implementation+And+Approach',
    CONFLUENCE_ADOBE_TRANSLATED_IMPLEMENTATION:
      'https://ce-platform.atlassian.net/wiki/spaces/UNI/pages/603751240/Auto-generated+Sign+UP+form+from+Campaign+Wizard+-+Adobe+Templated',
    CONFLUENCE_OTHER_PLATFORM_IMPLEMENTATION:
      'https://ce-platform.atlassian.net/wiki/spaces/IWG/pages/9576481/SignUp+Forms',
    CAMPAIGN_JSON_OUTPUT_URL: '//campaignwizard.unilever.com/api/v1/campaigns',
    CAMPAIGN_JSON_URL:
      'https://campaignwizard.unilever.com/campaign-wizard/campaign',
    CAMPAIGN_CLONE_URL:
      'https://campaignwizard.unilever.com/api/v1/campaigns/clone',
    FETCH_ALL_QUESTIONS:
      'https://campaignwizard.unilever.com/api/v1/questions/getAll',
    FETCH_ALL_ANSWERS:
      'https://campaignwizard.unilever.com/api/v1/answers/getAll',
    RELEASE_NOTE_URL: 'https://campaignwizard.unilever.com/api/v1/releasenote',
    QNA_CAMPAIGN_DETAILS:
      'https://campaignwizard.unilever.com/api/v1/reports/qna',
    FEEDBACKS_URL: 'https://campaignwizard.unilever.com/api/v1/feedbacks',
    EMAIL_BRIEFINGS:
      'https://campaignwizard.unilever.com/api/v1/emailbriefings',
    EMAIL_CORE_TEMPLATES_URL:
      'https://campaignwizard.unilever.com/api/v1/email-core-templates',
    BRAND_DEFAULTS_URL:
      'https://campaignwizard.unilever.com/api/v1/brandDefaults',
    BRAND_DEFAULTS_HISTORY_URL:
      'https://campaignwizard.unilever.com/api/v1/brand-defaults-history',
    EMAIL_TEMPLATE_EDIT_URL:
      'https://campaignwizard.unilever.com/api/v1/email-template-edit',
    CAMPAIGN_CONFIG_URL:
      'https://campaignwizard.unilever.com/api/v1/config/campaign',
    FETCH_DASHBOARD_MATRIX:
      'https://campaignwizard.unilever.com/api/v1/metrics/get',
    EMAIL_BEE_GET_ROWS_URL:
      'https://campaignwizard.unilever.com/api/v1/emailRowBlocks',
    SEND_TEST_EMAIL_URL:
      'https://campaignwizard.unilever.com/api/v1/emailbriefings/send/testEmail',
    EMAIL_TEMPLATE_EXPORT:
      'https://campaignwizard.unilever.com/api/v1/emailCSApi',
    APPROVER_BULK_UPLOAD:
      'https://campaignwizard.unilever.com/api/v1/user/approver/excel/upload',
    CAMPAIGN_ROUTINE_URL:
      'https://campaignwizard.unilever.com/api/v1/user/routineTask',
    CAMPAIGN_LOGOUT_TRACKING_URL:
      'https://campaignwizard.unilever.com/api/v1/campaignLog/logout',
    MAJOR_ENRICHMENT_REPORT:
      'https://campaignwizard.unilever.com/api/v1/reports/new-major-enrichment-questions',
    EMAIL_TEMPLATE_VALIDATION: `https://campaignwizard.unilever.com/api/v1/emailbriefings`,
    LIVE_EMAIL_TEMPLATES:
      'https://campaignwizard.unilever.com/api/v1/emailbriefings/liveEmailTemplates',
    OPT_OUT_BULK_UPLOAD:
      'https://campaignwizard.unilever.com/api/v1/smsBrandDetails/excel/upload',
    ADMIN_SETTINGS_HISTORY_LOGS:
      'https://campaignwizard.unilever.com/api/v1/adminSettingsHistory',
    GET_BPOS_COUNTRY_LANGUAGE_LIST:
      'https://campaignwizard.unilever.com/api/v1/mapBrandCountry/bpos/countryLanguage',
    GET_BPOS_COUNTRY_LIST:
      'https://campaignwizard.unilever.com/api/v1/mapBrandCountry/country/list',
    GET_BPOS_LANGUAGE_LIST:
      'https://campaignwizard.unilever.com/api/v1/mapBrandCountry/language/list',
    EXPORT_CONSENT_LIST:
      'https://campaignwizard.unilever.com/api/v1/mapBrandCountry/consent/export'
  },
  saasServiceUrls: {
    FORM_FIELDS_METADATA:
      'https://campaignwizard.unilever.com/api/v1/authoring',
    FORM_PREVIEW_ENDPOINT:
      'https://campaignwizard.unilever.com/api/v1/form/saveConfig/',
    FORM_PREVIEW_URL: 'https://forms-widget.unileversolutions.com/',
    SAAS_EDIT_CAMPAIGN_ENDPOINT:
      'https://campaignwizard.unilever.com/api/v1/editCampaign/'
  },
  communications: {
    FETCH_SMS_BRAND_DETAIL:
      'https://campaignwizard.unilever.com/api/v1/smsBrandDetails',
    POST_SMS_BRAND_DETAIL:
      'https://campaignwizard.unilever.com/api/v1/communications',
    POST_SMS_CAMPAIGN_DETAILS:
      'https://campaignwizard.unilever.com/api/v1/communicationdetails',
    GET_SAVED_SMS_DETAIL:
      'https://campaignwizard.unilever.com/api/v1/communications',
    GET_SMS_REVIEW_DETAIL:
      'https://campaignwizard.unilever.com/api/v1/communications',
    POST_SMS_CAMPAIGN_ADDITIONAL_INFO_DETAILS:
      'https://campaignwizard.unilever.com/api/v1/communicationdetails',
    UPDATE_SMS_CAMPAIGN_STATUS:
      'https://campaignwizard.unilever.com/api/v1/communications/updateStatus',
    VALIDATE_SMS_NAME:
      'https://campaignwizard.unilever.com/api/v1/communications/validate',
    SMS_CAMPAIGN_LIST_SERVICE_URL:
      'https://campaignwizard.unilever.com/api/v1/communications/',
    UPDATE_FILE_UPLOAD_AND_SMS_CAMPAIGN_STATUS:
      'https://campaignwizard.unilever.com/api/v1/communications/updateFileUploadAndStatus',
    DOWNLOAD_SMS_COST_ESTIMATE:
      'https://campaignwizard.unilever.com/api/v1/communications/download/costEstimate',
    DOWNLOAD_SMS_PURCHASE_ORDER:
      'https://campaignwizard.unilever.com/api/v1/communications/download/purchaseOrder'
  },

  consentsApi: {
    //HOST: "https://te22ak6y44.execute-api.eu-west-2.amazonaws.com/test3/",
    HOST: 'https://campaignwizard.unilever.com',
    URL: '/api/v1/consents'
  },
  featureFlags: {
    EDIT_FOR_SAAS: true,
    ENABLE_EPSILON_JOURNEY: true,
    ENABLE_GUIDELINES: true,
    ENABLE_CONTENT_INTEREST_AREAS: false,
    ENABLE_QNA_GUIDELINES_CHECKBOX: true,
    ENABLE_NEW_QNA_FOR_SAAS: true,
    ENABLE_NEW_QNA_FOR_NONSAAS: true,
    ENABLE_MASTER_DATA_ONLY_CAMPAIGN: true,
    ENABLE_SINGLE_PAGE_FIELD_SELECTION: true,
    ENABLE_ENTERPRISE_CONSENT: true,
    ENABLE_CAMPAIGN_STATUS_TIMELINE: true,
    EDITABLE_CONSENTS: true,
    ENABLE_FEEDBACK_FORM: true,
    ENABLE_FEEDBACK_EXPORT_LINK: false,
    ENABLE_CAMPAIGN_EMAIL: true,
    ENABLE_BRAND_DEFAULTS: true,
    ENABLE_EMAIL_PRIVILEGED_ACCESS: false,
    ENABLE_AEM_FORM_OPTION: false,
    EMAIL_APPROVAL_REQUIRED: true,
    EMAIL_EXECUTION_REQUIRED: true,
    EMAIL_UPLOAD_IMAGE_ENABLED: false,
    ENABLE_EMAIL_COLLECTION_BUTTON: true,
    ENABLE_HIDE_EMAIL_EDITOR_DEFAULT_ROWS: true,
    ENABLE_CAMPAIGN_ID_GOVERNANCE: true,
    ENABLE_SMS_APPROVAL_JOURNEY: true,
    ENABLE_SMS_UNICODE_DETECTION: true
  },
  RELEASENOTE_PAGE: '/campaign-wizard/releasenotes',
  CONSUMERDATA_ENRICHMENT_PAGE: '/campaign-wizard/consumerdataenrichment',
  CAMPAIGN_URL: 'https://campaignwizard.unilever.com/campaign-wizard/campaign/',
  BEE_PLUGIN_CLIENT_ID: process.env.REACT_APP_BEE_PLUGIN_CLIENT_ID,
  BEE_PLUGIN_CLIENT_SECRET: process.env.REACT_APP_BEE_PLUGIN_CLIENT_SECRET,
  APPROVER_LIST_PAGE: '/campaign-wizard/approvers',
  APPROVER_LIST_ADMIN_PAGE: '/campaign-wizard/user',
  TAB_BASE_URL: 'https://assets.unileversolutions.com/v1/',
  EMAIL_COLLECTION_URL:
    'https://campaignwizard.unilever.com/campaign-wizard/emailbriefing/email-collection',
  TinyMCEapiKey: process.env.REACT_APP_TINY_MCE_KEY // Binay's key
};

export default constants;
