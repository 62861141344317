import React from 'react';

export function EmailSummaryCard({
  icon,
  title,
  children,
  showBottomText,
  bottomIcon,
  label,
  style = {}
}) {
  return (
    <div className="email-summary-card" style={style}>
      <div className="email-summary-card__image-container">
        <img src={icon} alt={`${title} block`} />
      </div>

      <div className="email-summary-card__content">
        <div>
          <p className="email-summary-card__title">{title}</p>
          {children}
        </div>

        {showBottomText && (
          <EmailSummaryCardIconAndText
            svgIcon={bottomIcon}
            className="abtest-icon-image"
            label={label}
          />
        )}
      </div>
    </div>
  );
}

export function EmailSummaryText({ children, className = '' }) {
  return <p className={`email-summary-card__text ${className}`}>{children}</p>;
}

export function EmailSummaryHeading({ children }) {
  return <p className="email-summary-card__heading one-line">{children}</p>;
}

export function EmailSummaryCardIconAndText({
  svgIcon: SvgIcon,
  className = '',
  label
}) {
  return (
    <span className="mt-10 d-flex">
      <span className="mr-1">
        <SvgIcon />
      </span>
      <span className="font-weight-bold icon-text">{label}</span>
    </span>
  );
}
