import * as yup from 'yup';
import CharCountInputWrapper from 'components/molecules/CharCountInput/CharCountWraper';
import CharCountTextareaWrapper from 'components/molecules/CharCountTextarea/CharCountTextareaWrapper';
import EmojiCountWrapper from 'components/molecules/CharCountInput/EmojiCountWrapper';
// import constants from '../../constants';
import { get } from 'lodash';
import { getYupSchema } from 'selectors';

const formSpecificProperties = ({ onPrevious, onDraft, isEdited }) => ({
  //formType: 'wizard',
  submitBtnClass: '',
  previousBtnLabel: 'Back',
  onPrevious,
  onDraft,
  noteComponent:
    'Note: Sender name and email address will be as they are configured in the email execution engine, i.e. Adobe Campaign Standard',
  formType: 'custombuttons',
  submitBtnLabel: isEdited ? 'Save and back to review' : 'Next'
});

const validateMaxChar = value => {
  const charCountAdjust = (value.match(/\[First Name\]/g) || []).length * 2;
  return value.length <= 60 + charCountAdjust;
};

const subjectLineTooltipText =
  'Create subject lines that capture consumer attention, invoke interest and curiosity. Highlight the most important message of your email in max 60 characters, personalize with recipient first name wherever possible and A/B test to learn and optimize. To ensure your subject lines do not cause your email to be flagged as spam, avoid use of too many punctuations, exclamations, question marks, all capitalized letters and extreme promotional language';
const preheaderTooltipText =
  'Preheaders are short snippets of muted text that appear below or next to the email subject line and add context, specially on mobile devices where subject lines tend to be shorter. Create short and action oriented preheaders that complement but do not repeat the subject line. In the absence of preheaders, recipients may end up viewing email text as preheaders, which in usual cases could be something like "View this email in browser"';

const getABSubjectLines = (emailBrief, formData) => {
  const isABTestEnabled = get(emailBrief, 'abTesting.performABTests', false);
  const abTestElements = get(emailBrief, 'abTesting.abTestElements');
  const noOfVaraints = get(emailBrief, 'abTesting.noOfABVariants');
  if (isABTestEnabled && abTestElements === 'subjectLine') {
    const subjectFields = [
      {
        type: 'custom',
        name: 'subject',
        component: EmojiCountWrapper,
        id: 'subject',
        label: 'Subject line | Variant A',
        labelTooltip: subjectLineTooltipText,
        defaultValue: get(formData, 'firstAttempt.subject', ''),
        maxLength: '60',
        helpText: '41 chars, 7 words recommended',
        rules: yup
          .string()
          .required('Please enter subject line')
          .test('max-custom', 'Max 60 chars are allowed', validateMaxChar)
          .nullable()
      },
      {
        type: 'custom',
        name: 'subjectVariantB',
        component: EmojiCountWrapper,
        id: 'subjectVariantB',
        label: 'Subject line | Variant B',
        labelTooltip: subjectLineTooltipText,
        defaultValue: get(formData, 'firstAttempt.subjectVariantB', ''),
        maxLength: '60',
        helpText: '41 chars, 7 words recommended',
        rules: yup
          .string()
          .required('Please enter subject line')
          .test('max-custom', 'Max 60 chars are allowed', validateMaxChar)
          .nullable()
      }
    ];

    if (noOfVaraints === '3') {
      subjectFields.push({
        type: 'custom',
        name: 'subjectVariantC',
        component: EmojiCountWrapper,
        id: 'subjectVariantC',
        label: 'Subject line | Variant C',
        labelTooltip: subjectLineTooltipText,
        defaultValue: get(formData, 'firstAttempt.subjectVariantC', ''),
        maxLength: '60',
        helpText: '41 chars, 7 words recommended',
        rules: yup
          .string()
          .required('Please enter subject line')
          .test('max-custom', 'Max 60 chars are allowed', validateMaxChar)
          .nullable()
      });
    }

    return subjectFields;
  } else {
    return [
      {
        type: 'custom',
        name: 'subject',
        component: EmojiCountWrapper,
        id: 'subject',
        label: 'Subject',
        labelTooltip: subjectLineTooltipText,
        defaultValue: get(formData, 'firstAttempt.subject', ''),
        maxLength: '60',
        helpText: '41 chars, 7 words recommended',
        rules: yup
          .string()
          .required('Please enter subject line')
          .test('max-custom', 'Max 60 chars are allowed', validateMaxChar)
          .nullable()
      }
    ];
  }
};

const getFollowUpABSubjectLines = (emailBrief, formData) => {
  const isABTestEnabled = get(emailBrief, 'abTesting.performABTests', false);
  const abTestElements = get(emailBrief, 'abTesting.abTestElements');
  const noOfVaraints = get(emailBrief, 'abTesting.noOfABVariants');
  if (isABTestEnabled && abTestElements === 'subjectLine') {
    const subjectFields = [
      {
        type: 'custom',
        name: 'followUpSubject',
        component: EmojiCountWrapper,
        id: 'followUp-subject',
        label: 'Subject line | Variant A',
        labelTooltip: subjectLineTooltipText,
        subText:
          'Specify the follow up subject line to be used if Variant A wins in the test',
        defaultValue: get(formData, 'followUp.subject', ''),
        maxLength: '60',
        helpText: '41 chars, 7 words recommended',
        rules: yup
          .string()
          .required('Please enter subject line')
          .test('max-custom', 'Max 60 chars are allowed', validateMaxChar)
          .nullable()
      },
      {
        type: 'custom',
        name: 'followUpSubjectB',
        component: EmojiCountWrapper,
        id: 'followUpSubjectB',
        label: 'Subject line | Variant B',
        labelTooltip: subjectLineTooltipText,
        subText:
          'Specify the follow up subject line to be used if Variant B wins in the test',
        defaultValue: get(formData, 'followUp.subjectVariantB', ''),
        maxLength: '60',
        helpText: '41 chars, 7 words recommended',
        rules: yup
          .string()
          .required('Please enter subject line')
          .test('max-custom', 'Max 60 chars are allowed', validateMaxChar)
          .nullable()
      }
    ];

    if (noOfVaraints === '3') {
      subjectFields.push({
        type: 'custom',
        name: 'followUpSubjectC',
        component: EmojiCountWrapper,
        id: 'followUpSubjectC',
        label: 'Subject line | Variant C',
        labelTooltip: subjectLineTooltipText,
        subText:
          'Specify the follow up subject line to be used if Variant C wins in the test',
        defaultValue: get(formData, 'followUp.subjectVariantC', ''),
        maxLength: '60',
        helpText: '41 chars, 7 words recommended',
        rules: yup
          .string()
          .required('Please enter subject line')
          .test('max-custom', 'Max 60 chars are allowed', validateMaxChar)
          .nullable()
      });
    }

    return subjectFields;
  } else {
    return [
      {
        type: 'custom',
        name: 'followUpSubject',
        component: EmojiCountWrapper,
        id: 'followUp-subject',
        label: 'Subject',
        labelTooltip: subjectLineTooltipText,
        defaultValue: get(formData, 'followUp.subject', ''),
        maxLength: '60',
        helpText: '41 chars, 7 words recommended',
        rules: yup
          .string()
          .required('Please enter subject line')
          .test('max-custom', 'Max 60 chars are allowed', validateMaxChar)
          .nullable()
      }
    ];
  }
};

export const firstAttemptSchema = (
  handleSubmit,
  formData,
  handleFormExit,
  { isInFlyout = true, history, onPrevious, onDraft, emailBriefing } = {}
) => {
  const isEdited =
    history &&
    history.location &&
    history.location.state &&
    history.location.state.isEdited;

  const onSubmit = (data, { user }, { backToReview } = {}) => {
    const dataToSend = {
      ...data,
      subject: encodeURIComponent(data.subject),
      subjectVariantB: data.subjectVariantB
        ? encodeURIComponent(data.subjectVariantB)
        : undefined,
      subjectVariantC: data.subjectVariantC
        ? encodeURIComponent(data.subjectVariantC)
        : undefined,
      userEmail: user.emailId,
      userName: user.name
    };
    handleSubmit(dataToSend, 'firstAttempt', { backToReview });
  };

  const flyoutSpecificProperties = isInFlyout
    ? {
        formType: 'simple',
        submitBtnLabel: 'Save',
        submitBtnClass: `btn-block ${isInFlyout ? 'd-none' : ''}`
      }
    : formSpecificProperties({
        onPrevious,
        isEdited,
        onDraft: (data, _, { user }) => {
          const dataToSend = {
            ...data,
            subject: encodeURIComponent(data.subject),
            subjectVariantB: data.subjectVariantB
              ? encodeURIComponent(data.subjectVariantB)
              : undefined,
            subjectVariantC: data.subjectVariantC
              ? encodeURIComponent(data.subjectVariantC)
              : undefined,
            userEmail: user.emailId,
            userName: user.name
          };
          onDraft(dataToSend);
        }
      });
  return {
    onSecondaryClick: onSubmit,
    onSubmitSecondary: () => {},
    isEdit: isEdited,
    secondaryBtnLabel: 'Next',
    disableFormError: false,
    ...flyoutSpecificProperties,
    fields: [
      // {
      //   type: 'custom',
      //   name: 'subject',
      //   component: EmojiCountWrapper,
      //   id: 'subject',
      //   label: 'Subject',
      //   defaultValue: get(formData, 'firstAttempt.subject', ''),
      //   maxLength: '60',
      //   helpText: '41 chars, 7 words recommended',
      //   rules: yup
      //     .string()
      //     .trim()
      //     .required('Please enter subject line')
      //     .max(60, 'Max 60 chars are allowed')
      //     .nullable()
      // },
      ...getABSubjectLines(emailBriefing, formData),
      {
        type: 'custom',
        name: 'preheader',
        component: CharCountTextareaWrapper,
        id: 'preheader',
        label: 'Preheader',
        labelTooltip: preheaderTooltipText,
        defaultValue: get(formData, 'firstAttempt.preheader', ''),
        maxLength: '100',
        rows: '3',
        helpText: '30-80 chars recommended',
        rules: yup
          .string()
          .required('Please enter email preheader')
          .max(100, 'Max 100 chars are allowed')
          .nullable()
      }
    ],
    getSchema() {
      if (this.fields) {
        return getYupSchema(this.fields);
      }
      return null;
    },
    onSubmit: (data, { user }) =>
      onSubmit(data, { user }, { backToReview: isEdited }),
    onFormExit: (data, fieldSchema, formstate) => {
      handleFormExit({ data, fieldSchema, formstate }, 'firstAttempt');
    },
    formErrorMessage: 'Please enter missing values'
  };
};

export const followUpSchema = (handleSubmit, formData, handleFormExit) => {
  return {
    formType: 'simple',
    submitBtnLabel: 'Save',
    submitBtnClass: 'btn-block',
    disableFormError: true,
    fields: [
      {
        type: 'custom',
        name: 'subject',
        component: CharCountInputWrapper,
        id: 'subject',
        label: 'Subject',
        defaultValue: get(formData, 'followUp.subject', ''),
        maxLength: '60',
        helpText: '41 characters, 7 words recommended',
        rules: yup
          .string()
          .required('Please enter subject line')
          .nullable()
      },
      {
        type: 'custom',
        name: 'preheader',
        component: CharCountTextareaWrapper,
        id: 'preheader',
        label: 'Preheader',
        labelTooltip: preheaderTooltipText,
        defaultValue: get(formData, 'followUp.preheader', ''),
        maxLength: '100',
        rows: '3',
        helpText: '30-80 characters recommended',
        rules: yup
          .string()
          .required('Please enter email preheader')
          .nullable()
      }
    ],
    onSubmit: (data, { user }) => {
      handleSubmit(data, 'followUp');
    },
    onFormExit: (data, fieldSchema, formstate) => {
      handleFormExit({ data, fieldSchema, formstate }, 'followUp');
    }
  };
};

export const firstAndFollowUpSchema = (
  handleSubmit,
  formData,
  handleFormExit,
  { isInFlyout = true, history, onPrevious, onDraft, emailBriefing } = {}
) => {
  const isEdited =
    history &&
    history.location &&
    history.location.state &&
    history.location.state.isEdited;

  const onSubmit = (data, { user }, { backToReview } = {}) => {
    const dataToSend = {
      ...data,
      firstAttemptSubject: encodeURIComponent(data.subject),
      subjectVariantB: data.subjectVariantB
        ? encodeURIComponent(data.subjectVariantB)
        : undefined,
      subjectVariantC: data.subjectVariantC
        ? encodeURIComponent(data.subjectVariantC)
        : undefined,
      followUpSubject: encodeURIComponent(data.followUpSubject),
      followUpSubjectB: data.followUpSubjectB
        ? encodeURIComponent(data.followUpSubjectB)
        : undefined,
      followUpSubjectC: data.followUpSubjectC
        ? encodeURIComponent(data.followUpSubjectC)
        : undefined,
      userEmail: user.emailId,
      userName: user.name
    };
    handleSubmit(dataToSend, 'followUp', { backToReview });
  };

  const flyoutSpecificProperties = isInFlyout
    ? {
        formType: 'simple',
        submitBtnLabel: 'Save',
        submitBtnClass: `btn-block ${isInFlyout ? 'd-none' : ''}`
      }
    : formSpecificProperties({
        onPrevious,
        onDraft: (data, _, { user }) => {
          const dataToSend = {
            ...data,
            firstAttemptSubject: encodeURIComponent(data.subject),
            subjectVariantB: data.subjectVariantB
              ? encodeURIComponent(data.subjectVariantB)
              : undefined,
            subjectVariantC: data.subjectVariantC
              ? encodeURIComponent(data.subjectVariantC)
              : undefined,
            followUpSubject: encodeURIComponent(data.followUpSubject),
            followUpSubjectB: data.followUpSubjectB
              ? encodeURIComponent(data.followUpSubjectB)
              : undefined,
            followUpSubjectC: data.followUpSubjectC
              ? encodeURIComponent(data.followUpSubjectC)
              : undefined,
            userEmail: user.emailId,
            userName: user.name
          };
          onDraft(dataToSend);
        },
        isEdited
      });
  return {
    onSecondaryClick: onSubmit,
    onSubmitSecondary: () => {},
    isEdit: isEdited,
    secondaryBtnLabel: 'Next',
    disableFormError: false,
    ...flyoutSpecificProperties,
    fields: [
      {
        type: 'label',
        label: 'Main send',
        id: 'firstSendLabel',
        labelClassName: 'font-weight-bold',
        descriptionText:
          'Specify subject and preheader for the first email to be sent to your consumers.'
      },
      // {
      //   type: 'custom',
      //   name: 'firstAttemptSubject',
      //   component: EmojiCountWrapper,
      //   id: 'firstAttempt-subject',
      //   label: 'Subject',
      //   defaultValue: get(formData, 'firstAttempt.subject', ''),
      //   maxLength: '60',
      //   helpText: '41 chars, 7 words recommended',
      //   rules: yup
      //     .string()
      //     .trim()
      //     .required('Please enter subject line')
      //     .max(60, 'Max 60 chars are allowed')
      //     .nullable()
      // },
      ...getABSubjectLines(emailBriefing, formData),
      {
        type: 'custom',
        name: 'firstAttemptPreheader',
        component: CharCountTextareaWrapper,
        id: 'firstAttempt-preheader',
        label: 'Preheader',
        labelTooltip: preheaderTooltipText,
        defaultValue: get(formData, 'firstAttempt.preheader', ''),
        maxLength: '100',
        rows: '3',
        helpText: '30-80 chars recommended',
        rules: yup
          .string()
          .required('Please enter email preheader')
          .max(100, 'Max 100 chars are allowed')
          .nullable()
      },
      {
        type: 'label',
        label: 'Follow up',
        id: 'followUpLabel',
        labelClassName: 'font-weight-bold',
        descriptionText:
          'You have chosen to send a follow up email to non-openers. We recommend tweaking the subject line to boost open rates',
        toolTipText:
          'We will setup your campaign to automatically trigger a follow up email to consumers who do not open your email in the 1st attempt. The email body content of the follow up will remain the same as the original email but you are required to enter slightly different subject line that may resonate with non-openers and encourage them to open the follow up email.'
      },
      // {
      //   type: 'custom',
      //   name: 'followUpSubject',
      //   component: EmojiCountWrapper,
      //   id: 'followUp-subject',
      //   label: 'Subject',
      //   defaultValue: get(formData, 'followUp.subject', ''),
      //   maxLength: '60',
      //   helpText: '41 chars, 7 words recommended',
      //   rules: yup
      //     .string()
      //     .trim()
      //     .required('Please enter subject line')
      //     .max(60, 'Max 60 chars are allowed')
      //     .nullable()
      // },
      ...getFollowUpABSubjectLines(emailBriefing, formData),
      {
        type: 'custom',
        name: 'followUpPreheader',
        component: CharCountTextareaWrapper,
        id: 'followUp-preheader',
        label: 'Preheader',
        labelTooltip: preheaderTooltipText,
        defaultValue: get(formData, 'followUp.preheader', ''),
        maxLength: '100',
        rows: '3',
        helpText: '30-80 chars recommended',
        rules: yup
          .string()
          .required('Please enter email preheader')
          .max(100, 'Max 100 chars are allowed')
          .nullable()
      }
    ],
    getSchema() {
      if (this.fields) {
        return getYupSchema(this.fields);
      }
      return null;
    },
    onSubmit: (data, { user }) =>
      onSubmit(data, { user }, { backToReview: isEdited }),
    onFormExit: (data, fieldSchema, formstate) => {
      handleFormExit({ data, fieldSchema, formstate }, 'followUp');
    },
    formErrorMessage: 'Please enter missing values'
  };
};
