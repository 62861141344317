import {
  FETCH_EMAIL_CORE_TEMPLATES,
  FETCH_EMAIL_CORE_TEMPLATES_SUCCESS,
  FETCH_EMAIL_CORE_TEMPLATES_FAILURE,
  DELETE_EMAIL_CORE_TEMPLATE,
  FETCH_LIVE_EMAIL_TEMPLATES,
  FETCH_LIVE_EMAIL_TEMPLATES_SUCCESS,
  FETCH_LIVE_EMAIL_TEMPLATES_FAILURE,
  DELETE_EMAIL_CORE_TEMPLATE_SUCCESS,
  DELETE_EMAIL_CORE_TEMPLATE_FAILURE
} from '../../actionTypes';
import {
  getEmailCoreTemplates as getEmailCoreTemplatesService,
  getLiveEmailTemplates as getLiveEmailTemplatesService,
  deleteEmailCoreTemplate as deleteEmailCoreTemplateService
} from '../../services/emailCoreTemplates';

export const getEmailCoreTemplates = (query, pageNumber = 1) => {
  return async function(dispatch) {
    try {
      dispatch({ type: FETCH_EMAIL_CORE_TEMPLATES });
      const result = await getEmailCoreTemplatesService(query, pageNumber);
      dispatch({ type: FETCH_EMAIL_CORE_TEMPLATES_SUCCESS, payload: result });
    } catch (error) {
      if (error.message !== 'canceled') {
        dispatch({
          type: FETCH_EMAIL_CORE_TEMPLATES_FAILURE,
          payload: { message: error.message }
        });
      }
    }
  };
};

export const getLiveEmailTemplates = (query, pageNumber = 1, uid) => {
  return async function(dispatch) {
    try {
      dispatch({ type: FETCH_LIVE_EMAIL_TEMPLATES });
      const result = await getLiveEmailTemplatesService(
        query,
        pageNumber ? pageNumber : 1,
        uid
      );
      dispatch({ type: FETCH_LIVE_EMAIL_TEMPLATES_SUCCESS, payload: result });
    } catch (error) {
      if (error.message !== 'canceled') {
        dispatch({
          type: FETCH_LIVE_EMAIL_TEMPLATES_FAILURE,
          payload: { message: error.message }
        });
      }
    }
  };
};

export function deleteEmailCoreTemplate(id) {
  return async function(dispatch) {
    try {
      dispatch({ type: DELETE_EMAIL_CORE_TEMPLATE });
      await deleteEmailCoreTemplateService(id);
      dispatch({ type: DELETE_EMAIL_CORE_TEMPLATE_SUCCESS, payload: id });
    } catch (error) {
      dispatch({
        type: DELETE_EMAIL_CORE_TEMPLATE_FAILURE,
        payload: { message: error.message }
      });
    }
  };
}
