import React from 'react';
import {
  fetchQnANotificationTileData,
  getCampaignStatus,
  getCampaignStatusText,
  getFormatedDateNew,
  getNewCampaignTypeName,
  isQnAAvailableInCampaign
} from '../selectors/index';
import constant from '../constants';
import ReactTooltip from 'react-tooltip';
import BrandCampSummary from '../images/BrandCampSummary.png';
import QnASummary from '../images/Data_Summary.png';
import Summary from '../images/Summary.png';
import constants from '../constants/config.js';
import refresh from '../images/refresh.png';
import { epsilonMarket } from '../selectors/index';

const MasterDataSummaryComponent = props => {
  const {
    brand,
    country,
    language,
    websiteType,
    submissionDate,
    campaignDetail,
    incentiveType,
    lastEditDate,
    uid
  } = props.data;

  let { campaignType, campaignId, type, isMasterData, status } = props.data;

  const { isCommunication, campaignConfig } = props;
  let campaignTypeName = campaignType;
  if (!isCommunication) {
    campaignTypeName = getNewCampaignTypeName(campaignType, incentiveType);
    if (campaignTypeName === 'Master data request') {
      type = 'Not applicable';
    }
  }

  let requestorEmail = campaignDetail && campaignDetail.requestersEmail;
  let isCampaignInfraEnabled =
    campaignDetail &&
    campaignDetail.campaignProductionUrl &&
    campaignDetail.campaignInfraDetails &&
    campaignDetail.campaignInfraDetails.isCampaignInfraSetupDone !== undefined
      ? campaignDetail.campaignInfraDetails.isCampaignInfraSetupDone
      : '';

  const requestType =
    (campaignDetail && campaignDetail.offlineInjection) ||
    campaignDetail.masterDataInjection === 'offlineInjection'
      ? 'Master data for offline ingestion'
      : (campaignDetail && campaignDetail.customInjection) ||
        campaignDetail.masterDataInjection === 'customInjection'
      ? 'Master data for custom form build'
      : '';
  let showQnATile = isQnAAvailableInCampaign(props.data);
  let cwStatus = getCampaignStatus(status);
  let statusText = getCampaignStatusText(props.data);
  let isCampaignTypeSASS =
    (props.data &&
      props.data.websiteType &&
      props.data.websiteType.name === 'Adobe SaaS') ||
    (props.data && props.data.isPlatformAgnostic) ||
    props.data.isMasterData;

  return (
    <>
      <img
        src={
          props.isBrandSummary
            ? BrandCampSummary
            : props.isQnASummary
            ? QnASummary
            : Summary
        }
        alt={
          props.isBrandSummary
            ? BrandCampSummary
            : props.isQnASummary
            ? QnASummary
            : Summary
        }
        className="summary-component-img"
        height="60px"
        width="60px"
      />
      <div
        className={`summary-component ${
          incentiveType ? 'summary-component--summary-component-height' : ''
        } ${
          props.isQnASummary
            ? 'summary-component-qna'
            : !props.isBrandSummary && !props.isQnASummary
            ? 'summary-component--summary-component-form-section ml-4'
            : 'ml-4'
        } 
         mb-20`}
      >
        <>
          <div
            className={`summary-component-message mt-4`}
            style={{
              padding: lastEditDate !== undefined ? '0% 3%' : '1% 3%'
            }}
          >
            <div className={'summary-component-icon'}>
              <div className="row">
                <div className="col-sm-9">
                  <div className="cw-heading--saascampaign mt-3 mb-3">
                    Master data summary
                  </div>
                </div>
              </div>
              {isMasterData && cwStatus == 'Completed' && (
                <div className="alert alert-success w-100">
                  <p>Your master data IDs are generated and available below</p>
                </div>
              )}
              <div className="row mb-1">
                <div className="col-sm-4">
                  <div className="">
                    <strong>Request type</strong>
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="">{requestType}</div>
                </div>
              </div>

              {constant.featureFlags.ENABLE_CAMPAIGN_ID_GOVERNANCE &&
              !epsilonMarket(country && country.code) ? (
                <div className="row mb-1">
                  <div className="col-sm-4">
                    <div className="">
                      <strong>{`${campaignConfig.LABEL_SIGN_UP_ID}`}</strong>
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <div>
                      <span>{campaignId}</span>
                      &nbsp;
                      <ReactTooltip
                        id="acquisitionIdToolTip"
                        place="right"
                        type="info"
                        multiline={true}
                        className="cw-toolip custom-cw-toolip"
                      />
                      <span
                        className="cw-icon cw-icon--help"
                        data-tip={`Campaign IDs are now known as ${campaignConfig.LABEL_SIGN_UP_ID}s to better explain the purpose of the field to uniquely identify the  initiative through which a consumer signed up`}
                        currentitem="false"
                        data-for="acquisitionIdToolTip"
                      >
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="row mb-1">
                <div className="col-sm-4">
                  <div className="">
                    <strong>Q&A IDs</strong>
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="">
                    {showQnATile ? 'Available below' : 'Not applicable'}
                  </div>
                </div>
              </div>
              {isCampaignInfraEnabled !== '' ? (
                <div className="row mb-1">
                  <div className="col-sm-4 mt-1">
                    <div className="">
                      <strong>Infra Status</strong>
                    </div>
                  </div>
                  <div className="col-sm-8">
                    {!isCampaignInfraEnabled ? (
                      <span>
                        <span style={{ color: 'red' }}> Disabled</span>
                        <a
                          href={constants.KANA_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          &nbsp; Raise UNA &nbsp;
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </span>
                    ) : (
                      <span> Enabled </span>
                    )}
                    <ReactTooltip
                      place="right"
                      id="infraStatusValue"
                      type="info"
                      multiline={true}
                      className="cw-tooltip custom-cw-toolip"
                    />
                    <span
                      className="cw-icon cw-icon--help"
                      data-for="infraStatusValue"
                      data-tip={
                        isCampaignInfraEnabled
                          ? websiteType &&
                            websiteType.name === constants.WEBSITETYPE_SAAS
                            ? constants.SAAS_ENABLED_INFRA_STATUS_TOOLTIP_TEXT
                            : constants.NONSAAS_ENABLED_INFRA_STATUS_TOOLTIP_TEXT
                          : constants.DISABLED_INFRA_STATUS_TOOLTIP_TEXT
                      }
                    >
                      {'          '}
                      <i className="fas fa-question-circle"></i>
                    </span>
                    <ReactTooltip
                      id={'refresh-status'}
                      place="right"
                      type="info"
                      multiline={true}
                      className="cw-tooltip"
                    />
                    <img
                      data-tip={'Refresh status'}
                      style={{ cursor: 'pointer' }}
                      data-for="refresh-status"
                      src={refresh}
                      onClick={props.handleRefresh}
                      className="refresh-icon"
                      alt="refresh"
                      width={20}
                    />
                  </div>
                </div>
              ) : null}
              {isCampaignInfraEnabled !== '' && isCampaignInfraEnabled ? (
                <div className="row mb-1">
                  <div className="col-sm-4 mt-1">
                    <div className="">
                      <strong>V3 Data collection API endpoint</strong>
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <span>
                      {isCampaignTypeSASS &&
                      (statusText === 'Completed' ||
                        statusText === 'Processing') &&
                      isCampaignInfraEnabled
                        ? campaignDetail &&
                          campaignDetail.campaignInfraDetails &&
                          campaignDetail.campaignInfraDetails.v3ApiEndpoint
                        : ''}
                    </span>
                    <ReactTooltip
                      place="right"
                      id={'V3 Data collection API endpoint'}
                      type="info"
                      multiline={true}
                      className="cw-tooltip custom-cw-toolip"
                    />
                    <span
                      className="cw-icon cw-icon--help"
                      data-for="V3 Data collection API endpoint"
                      data-tip={
                        isCampaignTypeSASS
                          ? isMasterData
                            ? constants.MASTER_DATA_SAAS_V3_ENDPOINT_TOOLTIP_TEXT
                            : constants.SAAS_V3_ENDPOINT_TOOLTIP_TEXT
                          : constants.NONSAAS_V3_ENDPOINT_TOOLTIP_TEXT
                      }
                    >
                      {'          '}
                      <i className="fas fa-question-circle"></i>
                    </span>
                  </div>
                </div>
              ) : null}
              {isCampaignInfraEnabled !== '' && isCampaignInfraEnabled ? (
                <div className="row mb-1">
                  <div className="col-sm-4 mt-1">
                    <div className="">
                      <strong>V3 Data collection API key</strong>
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <span>
                      {isCampaignTypeSASS &&
                      (statusText === 'Completed' ||
                        statusText === 'Processing') &&
                      isCampaignInfraEnabled
                        ? campaignDetail &&
                          campaignDetail.campaignInfraDetails &&
                          campaignDetail.campaignInfraDetails.xApiKey
                        : ''}
                    </span>
                    <ReactTooltip
                      place="right"
                      id={'V3 Data collection API key'}
                      type="info"
                      multiline={true}
                      className="cw-tooltip custom-cw-toolip"
                    />
                    <span
                      className="cw-icon cw-icon--help"
                      data-for="V3 Data collection API key"
                      data-tip={
                        isCampaignTypeSASS
                          ? isMasterData
                            ? constants.MASTER_DATA_SAAS_V3_API_KEY_TOOLTIP_TEXT
                            : constants.SAAS_V3_API_KEY_TOOLTIP_TEXT
                          : constants.NONSAAS_V3_API_KEY_TOOLTIP_TEXT
                      }
                    >
                      {'          '}
                      <i className="fas fa-question-circle"></i>
                    </span>
                  </div>
                </div>
              ) : null}

              <div className="row mb-1">
                <div className="col-sm-4">
                  <div className="">
                    <strong>Guidance</strong>
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="">
                    <a
                      href={
                        (campaignDetail && campaignDetail.offlineInjection) ||
                        campaignDetail.masterDataInjection ===
                          'offlineInjection'
                          ? constant.MASTER_DATA_OFFLINE_GUIDE_LINK
                          : (campaignDetail &&
                              campaignDetail.customInjection) ||
                            campaignDetail.masterDataInjection ===
                              'customInjection'
                          ? constant.MASTER_DATA_CUSTOM_GUIDE_LINK
                          : ''
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {(campaignDetail && campaignDetail.offlineInjection) ||
                      campaignDetail.masterDataInjection === 'offlineInjection'
                        ? 'Collecting Offline Data Into the UMC'
                        : (campaignDetail && campaignDetail.customInjection) ||
                          campaignDetail.masterDataInjection ===
                            'customInjection'
                        ? 'V3 API documentation'
                        : ''}{' '}
                      &nbsp;
                      <i className="fas fa-external-link-alt"></i>
                    </a>
                  </div>
                </div>
              </div>

              {lastEditDate !== undefined ? (
                <div className="row mb-1">
                  <div className="col-sm-4">
                    <div className="">
                      <strong>Last Edited On</strong>
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <div className="">{getFormatedDateNew(lastEditDate)}</div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default MasterDataSummaryComponent;
