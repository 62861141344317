import React from 'react';
import { Controller, useWatch } from 'react-hook-form';

import Input from './Input';

import constants from 'constants/config';

export const EnhancedTextArea = ({
  name,
  id,
  defaultValue,
  control,
  label,
  errors,
  maxLength,
  helpText,
  subText,
  setValue,
  labelTooltip,
  flow,
  isDynamicCoupon,
  placeholderTooltipText,
  optOutTextLength,
  insertingURLFollowup,
  insertingURLMain,
  setInsertingURLMain,
  setInsertingURLFollowup,
  customHelpComponent,
  customHelpComponentProps,
  detectUnicodeContent,
  optOutText,
  watch,
  smsDetails
}) => {
  const watchValues = useWatch({
    control,
    name: watch,
    defaultValue: { shortURL: constants.SAMPLE_BITLY_URL, fullURL: '' }
  });
  console.log(watchValues);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Input
          {...field}
          label={label}
          id={id}
          errors={errors}
          maxLength={maxLength}
          helpText={helpText}
          defaultValue={defaultValue}
          subText={subText}
          labelTooltip={labelTooltip}
          setValue={setValue}
          showEmojis={false}
          showCharactorCount={true}
          flow={flow}
          isDynamicCoupon={isDynamicCoupon}
          placeholderTooltipText={placeholderTooltipText}
          optOutTextLength={optOutTextLength}
          insertingURLMain={insertingURLMain}
          insertingURLFollowup={insertingURLFollowup}
          setInsertingURLMain={setInsertingURLMain}
          setInsertingURLFollowup={setInsertingURLFollowup}
          customHelpComponent={customHelpComponent}
          customHelpComponentProps={customHelpComponentProps}
          detectUnicodeContent={detectUnicodeContent}
          optOutText={optOutText}
          watchValues={watchValues}
          smsDetails={smsDetails}
        />
      )}
    />
  );
};
