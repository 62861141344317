import React, { useState } from 'react';
import groupIcon from '../images/groupIcon.png';
import ExternalLinkIcon from '../images/external-link.png';
import ReactHtmlParser from 'react-html-parser';

const GuideLinesInfo = props => {
  const [expand, setExpand] = useState(props.defaultCollapse || false);
  const { noSubTitleLineBreak, noUlLineBreak } = props;

  let handleAccordionExpandCollapse = () => {
    setExpand(!expand);
  };

  return (
    <div
      className={`accordion saas-form-field-accordion mb-15 ${props.data.containerClass}`}
    >
      <div className="card" style={{ border: 0 }}>
        <div
          className={props.data.titleWrapperClass}
          style={{ cursor: 'pointer' }}
          onClick={() => handleAccordionExpandCollapse()}
        >
          {props.data && props.data.title ? (
            <span
              className={
                props.data.titleClass
                  ? props.data.titleClass
                  : 'cw-heading--primary'
              }
            >
              {props.data.title}
            </span>
          ) : (
            ''
          )}
          <span
            type="button"
            className={`sms-guideline-accordion-arrow ${expand} ${props.data.cheveronClass}`}
          >
            <i
              className={
                expand === false
                  ? 'fas fa-chevron-down transformIcon-180'
                  : 'fas fa-chevron-down'
              }
            ></i>
          </span>
        </div>

        <div
          className={
            expand === false
              ? `collapse show ${props.data.bodyClass}`
              : 'collapse'
          }
        >
          <div className="info-grey--small">
            {props.showMasterIcon ? (
              <>
                <div style={{ color: '#E8A100' }}>
                  <img
                    src={groupIcon}
                    alt={groupIcon}
                    width="25"
                    height="25"
                  ></img>
                  {` This campaign includes new master data requests.`}
                </div>
                <br />
              </>
            ) : null}
            {props.data && props.data.subTitle ? (
              <>
                <br />
                <p>{props.data.subTitle}</p>
                <br />
              </>
            ) : noSubTitleLineBreak ? null : (
              <br />
            )}
            <ul>
              {props.data.guideLine.map((elem, index, array) => {
                if (elem.replaceText) {
                  return (
                    <>
                      <li>
                        {elem.heading ? (
                          <span className="font-weight-bold guideline-heading">
                            {elem.heading} :{' '}
                          </span>
                        ) : null}
                        {ReactHtmlParser(elem.text)}
                      </li>
                      {noUlLineBreak ? null : index !== array.length - 1 ? (
                        <br />
                      ) : null}
                    </>
                  );
                }

                return (
                  <>
                    <li>
                      {elem.strongText ? (
                        <strong>{elem.strongText}</strong>
                      ) : null}
                      {elem.heading ? (
                        <span className="font-weight-bold guideline-heading">
                          {elem.heading} :{' '}
                        </span>
                      ) : null}
                      {elem.text
                        ? elem.richText
                          ? ReactHtmlParser(elem.text)
                          : elem.text
                        : null}
                      {elem.subText ? (
                        <>
                          <ul className="ml-20">
                            {elem.subText.map(e => {
                              return (
                                <li
                                  className="item_guide_li"
                                  style={{ color: '#000000 !important' }}
                                >
                                  {e.strongText ? (
                                    <strong>{e.strongText}</strong>
                                  ) : null}
                                  {e.text}
                                  {e.isKnowMore ? (
                                    <a
                                      className={
                                        'alert-knowmore-message guidelines-ext-link'
                                      }
                                    >
                                      {e.knowMoreText}
                                    </a>
                                  ) : null}
                                </li>
                              );
                            })}
                          </ul>
                        </>
                      ) : null}
                      {elem.externalLink ? (
                        <>
                          <a
                            href={elem.externalLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={
                              'alert-knowmore-message guidelines-ext-link'
                            }
                          >
                            {elem.externalLinkText}
                            <div className="external-link-wrapper">
                              <img
                                src={ExternalLinkIcon}
                                alt="External Link"
                                className="external-link-icon"
                              />
                            </div>
                          </a>
                        </>
                      ) : null}
                      {elem.isKnowMore ? (
                        <a
                          className={
                            'alert-knowmore-message guidelines-ext-link'
                          }
                        >
                          {elem.knowMoreText}
                        </a>
                      ) : null}
                    </li>
                    {noUlLineBreak ? null : index !== array.length - 1 ? (
                      <br />
                    ) : null}
                  </>
                );
              })}
            </ul>
            {props.noLineBreak ? '' : <br />}
            {props.data.notes &&
              props.data.notes.map((note, index) => (
                <p key={index} className={note.className ? note.className : ''}>
                  {note.text}
                </p>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuideLinesInfo;
