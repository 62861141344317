import React from 'react';
import ReactTooltip from 'react-tooltip';

import {
  EmailSummaryCard,
  EmailSummaryHeading,
  EmailSummaryText
} from '../EmailSummaryCard/EmailSummaryCard';
import CampaignIcon from '../../../images/campaign.png';
import DeliveryIcon from '../../../images/delivery.png';
import AudienceIcon from '../../../images/audience.png';
import externalLink from 'images/external-link.png';
import DeliveryLabel from 'images/delivery-label-icon.svg';
import { ReactComponent as ResendCouponIcon } from '../../../images/resend-icon.svg';
import _ from 'lodash';
import {
  textCapitalization,
  getSMSDeliveryCondition,
  getSubmittedSMSCampaignScheduleDates
} from 'selectors';
import constants from 'constants/config';

export default function SMSSummaryCards({ className = '', smsCampaign }) {
  if (!smsCampaign) return null;

  const {
    brand,
    country,
    name,
    communicationDetail = {},
    smsType,
    audienceDefinition,
    deliverySchedule,
    status,
    brandId,
    countryId,
    shareCostEstimate,
    nmiAssignedToRequestor
  } = smsCampaign;

  const resendEmail =
    deliverySchedule?.sendAfter?.value &&
    deliverySchedule?.sendAfter?.value !== 'DontSend';
  const consumerCount = deliverySchedule?.consumerCount;
  const audienceCount = shareCostEstimate?.audienceCount;
  const showCostFileField =
    status === constants.SMS_STATUS.AWAITING_PURCHASE_ORDER ||
    status === constants.SMS_STATUS.AWAITING_SETUP ||
    status === constants.SMS_STATUS.SCHEDULED ||
    status === constants.SMS_STATUS.LIVE ||
    status === constants.SMS_STATUS.SUBMITTED ||
    (status === constants.SMS_STATUS.AWAITING_INFO && !nmiAssignedToRequestor);
  const expectedVolume =
    communicationDetail && communicationDetail.expectedVolume;
  const dateObject = getSubmittedSMSCampaignScheduleDates(
    communicationDetail && communicationDetail.smsDetails
  );

  const deliveryLabelTooltip = () => (
    <>
      <i
        className="fas fa-info-circle ml-1"
        data-for="value-tooltip"
        data-tip="Delivery label can be used to filter the SMS campaign<br/> performance metrics on the campaign tracking dashboard"
        style={{ cursor: 'pointer', fontSize: 'var(--text-base-size)' }}
      ></i>
      <ReactTooltip
        id="value-tooltip"
        place="right"
        type="info"
        multiline={true}
        className="cw-tooltip cw-email-tooltip saas_new_master_tooltip"
      />
    </>
  );

  return (
    <>
      <div className={`d-flex mb-30 ${className}`}>
        <EmailSummaryCard
          icon={CampaignIcon}
          title="Campaign"
          style={{ flex: 1.4 }}
        >
          <EmailSummaryHeading>{name || 'Untitled'}</EmailSummaryHeading>
          <EmailSummaryText className="email-summary-card__bold">
            {constants.SMS_TYPE_LABELS[smsType]}
          </EmailSummaryText>
          <EmailSummaryText>
            {brandId?.name || textCapitalization(brand) || ''}
            ,&nbsp;
            {countryId?.name || textCapitalization(country) || ''}
          </EmailSummaryText>
        </EmailSummaryCard>

        <EmailSummaryCard
          icon={DeliveryIcon}
          title="Delivery"
          showBottomText={resendEmail}
          bottomIcon={ResendCouponIcon}
          label="Resend to non-openers"
          style={{ flex: 1, marginLeft: 12 }}
        >
          {status === constants.SMS_STATUS.SUBMITTED ? (
            <EmailSummaryText key={dateObject.date1} className="one-line">
              {dateObject.date1}
            </EmailSummaryText>
          ) : (
            getSMSDeliveryCondition(deliverySchedule).map(text => (
              <EmailSummaryText key={text} className="one-line">
                {text}
              </EmailSummaryText>
            ))
          )}
        </EmailSummaryCard>

        <EmailSummaryCard
          icon={AudienceIcon}
          title="Audience"
          showBottomText={false}
          style={{ flex: 1, marginLeft: 12 }}
        >
          <>
            <EmailSummaryText className="line-clamp-3">
              {audienceDefinition?.sendSMSTo?.type?.label}
            </EmailSummaryText>
            <EmailSummaryText className="mt-2">
              {(consumerCount || audienceCount || expectedVolume) &&
              showCostFileField ? (
                <div className="consumer-count-container">
                  <span className="consumer-count">
                    {consumerCount
                      ? consumerCount
                      : audienceCount
                      ? audienceCount
                      : expectedVolume}
                  </span>{' '}
                  consumers
                  <span
                    className="icon"
                    data-tip="This is the expected count of consumers to whom your SMS will be sent, as provided by ACS team. For actual performance metrics, refer to the Power BI reports"
                    data-for="smsConsumerCount"
                  >
                    &nbsp;<i className="fas fa-question-circle"></i>
                  </span>
                  <ReactTooltip
                    id="smsConsumerCount"
                    place="bottom"
                    type="info"
                    multiline={true}
                    className="cw-tooltip cw-email-tooltip"
                  />
                </div>
              ) : null}
            </EmailSummaryText>
          </>
        </EmailSummaryCard>
      </div>

      {status === constants.SMS_STATUS.LIVE && (
        <div className="mt-2 mb-3">
          <EmailSummaryCard
            icon={DeliveryLabel}
            title="Track your SMS performance"
          >
            <EmailSummaryText className="font-weight-bold my-3">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://app.powerbi.com/groups/me/apps/120c9b97-4133-47e9-8e38-97345453c93e/reports/b6741911-8a36-406b-ab37-325df0caf1e5/ReportSectionab5cf0116397112fce51?experience=power-bi&bookmarkGuid=Bookmark7ea03df196770f9421ba"
                aria-label="PowerBI dashboard link"
              >
                ACS SMS campaign tracking report
                <div className="external-link-wrapper">
                  <img
                    src={externalLink}
                    alt="External Link"
                    className="external-link-icon"
                  />
                </div>
              </a>
            </EmailSummaryText>

            <EmailSummaryText>
              <div className="email-summary-card__title">Delivery labels</div>
              {typeof deliverySchedule?.deliveryLabel === 'string' ? (
                <>
                  {/* <strong>Main send: </strong> */}
                  {deliverySchedule?.deliveryLabel}
                  {deliveryLabelTooltip()}
                </>
              ) : (
                deliverySchedule?.deliveryLabel?.map(label => (
                  <p>
                    {/* <strong>
                      {constants.deliveryLablesToCaptionMap[label.type]}
                    </strong>
                    :  */}
                    {label.value} {deliveryLabelTooltip()}
                  </p>
                ))
              )}
            </EmailSummaryText>
          </EmailSummaryCard>
        </div>
      )}
    </>
  );
}
