import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { NavLink } from 'react-router-dom';
import {
  fetchUserData,
  fetchApproverFilterData
} from '../../actions/user-management';
import ApproverContent from 'components/ApproverContent';
import constant from '../../constants';

class UserManagement extends Component {
  constructor(props) {
    super(props);
    this._sortUserData = this._sortUserData.bind(this);
  }
  componentDidMount() {
    this.props.fetchUserData('approver');
    this.sortKey = 'lastUpdatedDate';
    this.sortBy = 'desc';
    document
      .querySelector('[data-type="lastUpdatedDate"]')
      .classList.add('current-sorted');
  }
  _sortUserData(
    e,
    type,
    selectedCountryArr,
    selectedCampaignFilter,
    selectedBusinessFilter,
    searchText
  ) {
    let classname = e.target.className;
    let updatedClassName;
    document
      .querySelectorAll('.current-sorted')
      .forEach(el => el.classList.remove('current-sorted'));
    if (classname.indexOf('--asc') >= 0) {
      this.sortBy = 'asc';
      updatedClassName = 'cw-sort cw-sort--desc cell current-sorted';
    } else {
      this.sortBy = 'desc';
      updatedClassName = 'cw-sort cw-sort--asc cell current-sorted';
    }
    this.sortKey = e.currentTarget.getAttribute('data-type');
    const filter = {
      selectedCountry: selectedCountryArr,
      selectedCampaign: selectedCampaignFilter,
      selectedBusiness: selectedBusinessFilter,
      sortBy: this.sortBy,
      sortKey: this.sortKey
    };
    if (
      type === 'approver' &&
      (selectedCountryArr.length ||
        selectedCampaignFilter.length ||
        selectedBusinessFilter.length)
    ) {
      this.props.fetchApproverFilterData('approver', filter, searchText);
    } else {
      this.props.fetchUserData(type, this.sortKey, this.sortBy, '', searchText);
    }
    e.target.className = updatedClassName;
  }

  render() {
    Modal.setAppElement('#root');
    let {
      userData,
      asyncInProgress,
      campaignConfig,
      businessGroup
    } = this.props;
    return (
      <>
        <div className="cw-section cw-overview ml-20">
          <div className="col-sm-12 mb-10">
            <div className="row mb-10">
              <div className="col-sm-6">
                <NavLink
                  className="cw-color--primary cw-breadcrum"
                  to={'/campaign-wizard'}
                >
                  <i className="fas fa-angle-left"></i> Home
                </NavLink>
              </div>
            </div>
            <div className="row mb-20">
              <div className="col-sm-6">
                <h2 className="cw-heading--secondary d-flex align-items-center mb-10">
                  <span className="mr-3">Approvers list</span>
                </h2>
              </div>
            </div>
            <div className="row mb-30">
              <div className="col-sm-12">
                <p>
                  <strong>To add or remove approver</strong>
                  {''}
                  <a
                    href={constant.KANA_PAGE}
                    target="_blank"
                    className="footer_link"
                    rel="noreferrer"
                  >
                    raise an UNA ticket&nbsp;
                    <i className="fas fa-external-link-alt"></i>
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="col-sm-12">
            <ApproverContent
              panelType="approver"
              userData={userData}
              asyncInProgress={asyncInProgress}
              sortClick={this._sortUserData}
              businessGroup={businessGroup}
              sortBy={this.sortBy}
              sortKey={this.sortKey}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  userData: state.user.userData || {},
  asyncInProgress: state.user.asyncInProgress,
  businessGroup: state.user.businessGroup
});

export default connect(mapStateToProps, {
  fetchUserData,
  fetchApproverFilterData
})(UserManagement);
