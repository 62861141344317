import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import FormAuthoring from './FormAuthoring';
import FormAuthoringGrouped from './FormAuthoringGrouped';
import Loader from '../../components/Loader';
import SatisfactoryRatingsInput from 'components/SatisfactoryRatingsInput';
import ServiceError from '../ServiceError';
import NotFound from '../NotFound';
import RegularCommunications from './../RegularCommunications';
import {
  getFormatedDate,
  checkCampaignLanguage,
  isQuestionMandatory,
  checkMarketSupportedForDoubleOptin,
  showAutoGenerateFlag,
  epsilonMarket,
  getCampaignStatus,
  getCampaignStatusSteps
} from '../../selectors/index';
import { cancelCampaignChanges } from '../../actions/overview';
import { getBrandCountries } from '../../actions/brandDetails';
import { fetchHistoryLogs } from '../../actions/history';
import { editedCampaignDetails } from '../../actions/app';
import { openFeedbackForm, submitFeedback } from '../../actions/feedbackForm';
import { get, isEmpty } from 'lodash';

import Optins from '../Optins';
import briefingDoc from '../../resources/UStudio_brief.xlsx';
import constant from '../../constants';
import {
  getReviewCampaignDetails,
  submitReviewData,
  updateCampaignDetails,
  submitCampaignDetails
} from '../../actions/review';
import AuthorAccessReview from '../campaign-authoring-access/Review';
import QnAList from './QnAList';
import Alert from '../Alert';
import BreadCrumb from '../BreadCrumb';
import CampaignCancelModel from '../CampaignCancelModel';
import Modal from 'react-modal';
import CampaignDetailsInfo from '../CampaignDetailsInfo';
import Select from 'react-select';
import StatusTimeline from '../status-timeline';
import axios from 'axios';
import classNames from 'classnames';
class ReviewAndSubmit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matchProps: props.match,
      isCancelModalOpen: false,
      iConfirm: false,
      isEditedChecked: false,
      feedback: {},
      feedbackCommentError: false
    };
    this.scrollToTop = React.createRef();
    this.formElements = {
      additionalInfo: this.props.additionalInfo || '',
      editComments: this.props.editComments || '',
      uid: ''
    };
    this.feedbackCommentOptions = [
      { label: 'Suggestion', value: 'suggestion' },
      { label: 'Compliment', value: 'compliment' },
      { label: 'Complaint', value: 'complaint' },
      { label: 'New request', value: 'newFeatureRequest' }
    ];
    this._showCampaignOverview = this._showCampaignOverview.bind(this);
    this._handlebackButton = this._handlebackButton.bind(this);
    this._handleCampaignType = this._handleCampaignType.bind(this);
    this._handleBrandDetail = this._handleBrandDetail.bind(this);
    this._handleCampaignDetail = this._handleCampaignDetail.bind(this);
    this._handleQuestionsAnswers = this._handleQuestionsAnswers.bind(this);
    this._handleSubmitButton = this._handleSubmitButton.bind(this);
    this._handleAdditionalConsent = this._handleAdditionalConsent.bind(this);
    this._handleInputChange = this._handleInputChange.bind(this);
    this._handleCheckbox = this._handleCheckbox.bind(this);
    this._openIsCancelModal = this._openIsCancelModal.bind(this);
    this._closeIsCancelModal = this._closeIsCancelModal.bind(this);
    this._cancelSaasCampaign = this._cancelSaasCampaign.bind(this);
    this._handleEditCheckbox = this._handleEditCheckbox.bind(this);
    this._handleCheckboxConfirm = this._handleCheckboxConfirm.bind(this);
    this._handleFeedbackInputChange = this._handleFeedbackInputChange.bind(
      this
    );
    this._handleFeedbackCommentType = this._handleFeedbackCommentType.bind(
      this
    );
    this._handleCombinedValidation = this._handleCombinedValidation.bind(this);
    this._shouldShowFeedbackSection = this._shouldShowFeedbackSection.bind(
      this
    );
  }
  componentDidMount() {
    this.campaignId = this.props.match.params.id;
    this.props.getReviewCampaignDetails(this.campaignId);
    this.props.getBrandCountries(this.campaignId);
    this.props.fetchHistoryLogs(this.campaignId);
    this.nonEnglishLanguageText = '';
  }

  componentWillReceiveProps(nextProps, nextState) {
    let isEdited = this.props.campaign && this.props.campaign.isEdited;
    if (nextProps.isSubmitted && !isEdited) {
      this.scrollToTop.current &&
        this.scrollToTop.current.scrollIntoView({ behavior: 'smooth' });
    }
  }
  _showCampaignOverview(event) {
    event.preventDefault();
    this.props.history.push('/campaign-wizard/campaign/' + this.campaignId);
  }
  _handleBrandDetail() {
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.campaignId + '/branddetails'
    );
  }
  _handleCampaignType() {
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.campaignId + '/type'
    );
  }
  _handleCampaignDetail() {
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.campaignId + '/details'
    );
  }
  _handleQuestionsAnswers() {
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.campaignId + '/questionsanswers'
    );
  }
  _handleAdditionalConsent() {
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.campaignId + '/consentpreferences'
    );
  }

  _shouldShowFeedbackSection() {
    return isEmpty(this.props.feedbacks);
  }

  _submitReview(countrySelected, isEdited, editComments) {
    this.props.submitCampaignDetails(
      this.formElements,
      this.props.userName,
      this.props.userEmail
    );
    let isCampaignTypeSASS =
      (this.props.campaign &&
        this.props.campaign.websiteType &&
        this.props.campaign.websiteType.name === 'Adobe SaaS') ||
      this.props.campaign.isPlatformAgnostic;
    let editCampaign = isEdited && isCampaignTypeSASS;
    this.props.submitReviewData(
      this.campaignId,
      this.props.userName,
      this.props.userEmail,
      this.props.editCampaign,
      this.props.editCampaignDetails,
      countrySelected,
      editCampaign,
      editComments
    );
  }

  _handleCombinedValidation() {
    const isFeedbackValid = this._validfeedbackRatings();
    return isFeedbackValid;
  }

  _handleSubmitButton(event) {
    event.preventDefault();
    // this._validateEditComments();
    let isEdited = this.props.campaign && this.props.campaign.isEdited;
    let editComments = this.props.editComments;
    let countrySelected =
      this.props.campaign &&
      this.props.campaign.country &&
      this.props.campaign.country.code;

    let isCampaignTypeSASS =
      (this.props.campaign &&
        this.props.campaign.websiteType &&
        this.props.campaign.websiteType.name === 'Adobe SaaS') ||
      this.props.campaign.isPlatformAgnostic ||
      (this.props.campaign && this.props.campaign.isMasterData);

    this.formElements['additionalInfo'] =
      document.getElementById('additionalInfo') &&
      document.getElementById('additionalInfo').value;
    this.formElements['uid'] = this.campaignId;

    if (isEdited && this._validateEditComments()) {
      this.formElements['isEdited'] = isEdited;
      this._submitReview(countrySelected, isEdited, editComments);
    }

    if (!isEdited && isCampaignTypeSASS && this._handleCombinedValidation()) {
      if (this._shouldShowFeedbackSection()) {
        let feedbackData = {
          ratings: get(this.state, 'feedback.rw_ratings'),
          campaignRequestType: 'Sign up form requests',
          commentType: get(this.state, 'feedback.rw_commentType.value'),
          comments: get(this.state, 'feedback.rw_comments'),
          campaignUid: this.campaignId,
          feedbackProvider: this.props.authorizedUserData._id
        };
        this.props.submitFeedback(feedbackData, (error, result) => {
          if (error) {
            console.log(error);
          } else {
            console.log(result);
          }
        });
      }
      this._submitReview(countrySelected, isEdited);
    } else if (!isEdited && !isCampaignTypeSASS) {
      this._submitReview(countrySelected, isEdited);
    }
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.campaignId + '/review'
    );
  }
  _handlebackButton(event) {
    let { campaign } = this.props;
    let country = campaign.country && campaign.country.code;
    let nextRoute =
      this.props.campaign.type === 'Simple sign-up'
        ? 'consentpreferences'
        : this.props.questionAnswerList &&
          this.props.questionAnswerList.length >= 2
        ? 'sorting'
        : 'questionsanswers';
    let incentiveRoute =
      this.props.campaign &&
      this.props.campaign.campaignType &&
      this.props.campaign.campaignType.toLowerCase() === 'incentive';

    if (
      this.props.campaign &&
      this.props.campaign.websiteType &&
      this.props.campaign.websiteType.name !== 'Adobe SaaS' &&
      this.props.campaign.autogenerate
    ) {
      this.props.history.push(
        '/campaign-wizard/campaign/' +
          this.campaignId +
          '/' +
          (incentiveRoute
            ? this.props.campaign.type !== 'Simple sign-up'
              ? 'questionsanswers'
              : 'details'
            : nextRoute)
      );
    } else {
      this.props.history.push(
        '/campaign-wizard/campaign/' + this.campaignId + '/saas-sorting-page'
      );
      this.props.history.push(
        '/campaign-wizard/campaign/' +
          this.campaignId +
          `${
            epsilonMarket(country)
              ? `/saas-epsilon-masterdata`
              : `/saas-sorting-page`
          }`
      );
    }
  }
  _handleInputChange(event) {
    let detailsToUpdate = {
      [event.target.name]: event.target.value
    };
    this.formElements[event.target.name] = event.target.value;
    if (
      document.getElementById('editComments') &&
      document
        .getElementById('editComments')
        .classList.contains('cw-error-focus')
    ) {
      document
        .getElementById('editComments')
        .classList.remove('cw-error-focus');
      document.getElementById('editCommentId') &&
        document.getElementById('editCommentId').classList.add('cw-hidden');
    }
    this.props.updateCampaignDetails(detailsToUpdate);
    this.props.editedCampaignDetails(detailsToUpdate);
  }
  _handleCheckbox(event) {
    document.querySelector('#submit').disabled = !event.target.checked;
    if (event.target.checked)
      document
        .querySelector('#submit')
        .addEventListener('click', this._handleSubmitButton);
  }

  _handleCheckboxConfirm(event) {
    let isChecked = event.target.checked;
    if (isChecked) {
      this.setState({ iConfirm: true });
    } else {
      this.setState({ iConfirm: false });
    }
  }

  _handleFeedbackInputChange(event) {
    if (event.target.name === 'rw_ratings') {
      document.getElementById('ratingsFieldMessage').classList.add('cw-hidden');
      this.setState({ feedbackCommentError: false });
    }
    let feedback = {
      ...this.state.feedback,
      [event.target.name]: event.target.value
    };
    this.setState({ feedback });
  }

  _handleEditCheckbox(event) {
    let isChecked = event.target.checked;
    if (isChecked) {
      this.setState({ isEditedChecked: true });
    } else {
      this.setState({ isEditedChecked: false });
    }
  }

  _validateEditComments() {
    let isValid = true;
    if (
      document.getElementById('editComments') &&
      document.getElementById('editComments').value.trim().length === 0
    ) {
      document.getElementById('editComments').classList.add('cw-error-focus');
      document.getElementById('editCommentId') &&
        document.getElementById('editCommentId').classList.remove('cw-hidden');
      isValid = false;
    }

    return isValid;
  }

  _openIsCancelModal(event) {
    event.preventDefault();
    this.setState({
      isCancelModalOpen: true
    });
  }

  _closeIsCancelModal() {
    this.setState({
      isCancelModalOpen: false
    });
  }

  _cancelSaasCampaign(event) {
    event.preventDefault();
    this.setState({
      isCancelModalOpen: false
    });
    this.props.cancelCampaignChanges(this.campaignId);
    this.props.history.push('/campaign-wizard/campaign/' + this.campaignId);
  }

  _handleFeedbackCommentType(value) {
    let feedback = {
      ...this.state.feedback,
      rw_commentType: value
    };
    this.setState({ feedback });
  }

  _validfeedbackRatings() {
    if (!this._shouldShowFeedbackSection()) {
      return true;
    }
    let isValid = true;
    if (this.state.feedback.rw_ratings === undefined) {
      document
        .getElementById('ratingsFieldMessage')
        .classList.remove('cw-hidden');
      isValid = false;
    }
    if (
      this.state.feedback.rw_ratings <= '3' &&
      (!this.state.feedback.rw_comments ||
        this.state.feedback.rw_comments.trim().length == 0)
    ) {
      this.setState({ feedbackCommentError: true });
      isValid = false;
    }
    return isValid;
  }

  _handleRefresh = () => {
    let { campaign } = this.props;
    axios
      .post(constant.serviceUrls.CAMPAIGN_INFRA_API_CALL, campaign)
      .then(response => {
        this.campaignId = this.props.match.params.id;
        this.props.getReviewCampaignDetails(this.campaignId);
      });
  };
  _handleOnOpenFeedback = event => {
    event.preventDefault();
    this.props.openFeedbackForm({
      campaignUid: this.props.campaign.uid,
      userId: this.props.authorizedUserData._id,
      defaultCampaignRequest: 'form',
      showCampaignRequest: false
    });
  };

  incentivePromotigoOrSimpleSignUp = (campaign, historyLogs) => {
    return (
      <div className="cw-section ml-20">
        <div className="row">
          <div className="col-sm-8 cw-section--title mb-50">
            <NavLink
              className="cw-color--primary cw-breadcrum"
              to={'/campaign-wizard/dashboard'}
            >
              <i className="fas fa-angle-left"></i> Campaigns
            </NavLink>
            <h2 className="cw-heading--secondary mb-4 mt-20">
              Campaign submitted
            </h2>
            <>
              <p className="mb-1">
                Your request for master data has been submitted for approval.
                {campaign &&
                  campaign.campaignType &&
                  campaign.campaignType.toLowerCase() === 'incentive' &&
                  ((campaign.campaignDetail &&
                    campaign.campaignDetail.incentiveItPartner ==
                      'promotigo') ||
                    campaign.incentivePromotionalPartner === 'promotigo') && (
                    <p>
                      Incentive based campaigns also require Promotigo setup.
                      Please visit{' '}
                      <a target="_blank" href="https://campaign.promotigo.com">
                        Promotigo <i className="fas fa-external-link-alt"></i>
                      </a>{' '}
                      to setup your incentive campaign when we notify you via an
                      email. You will receive a Promotigo form ID that you will
                      need to update to your Campaign Wizard request. Please
                      ensure that all specifications mentioned in your Campaign
                      Wizard request match with the details you provide in
                      Promotigo at all times, failing which your form may not
                      work correctly.
                    </p>
                  )}
                <br />
                Once your request is completed, you will find all the required
                Master data IDs on your campaign page.{' '}
              </p>
              {constant.featureFlags.ENABLE_CAMPAIGN_STATUS_TIMELINE && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="mt-20">
                      <p>
                        Your request will go through a series of steps, as
                        indicated below.
                      </p>
                      <StatusTimeline
                        steps={getCampaignStatusSteps(campaign, historyLogs)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
          <div className="col-md-4">
            <div className="btn-box-ml-auto mt-50">
              <button
                className="btn btn-primary mt-75 mb-20"
                onClick={this._showCampaignOverview}
              >
                View campaign details
              </button>
            </div>

            {constant.featureFlags.ENABLE_FEEDBACK_FORM && (
              <Alert
                alertType="feedbackFormMesg"
                onFeedbackClick={this._handleOnOpenFeedback}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      campaign,
      campaignDetails,
      questionAnswerList,
      asyncInProgress,
      isSubmitted,
      campaignAccessEmails,
      country,
      campaignEditLog,
      isLoading,
      historyLogs,
      isPlatformAgnostic
    } = this.props;
    let isEdited = campaign && campaign.isEdited;
    let editedCampaign = campaign && campaign.isEdited;

    let isQnA =
      campaign && campaign.type === 'Sign-up with Questions & Answers';
    let isWebsiteTypeforSignUp = campaign.websiteType
      ? campaign.websiteType.name === 'Standalone' ||
        campaign.websiteType.name === 'Adobe Classic' ||
        campaign.websiteType.name === 'Adobe Templated'
      : false;
    // let isSignUpRequired = isQnA && isWebsiteTypeforSignUp;
    let cwStatus = getCampaignStatus(campaign.status);
    let countrySelected =
      this.props.campaign &&
      this.props.campaign.country &&
      this.props.campaign.country.code;
    let days =
      campaign &&
      campaign.campaignType &&
      campaign.campaignType.toLowerCase() === 'incentive'
        ? '21'
        : '15';
    let campaignLanguage =
      campaign && campaign.language && campaign.language.name;

    let isCampaignTypeSASS =
      (campaign && campaign.isPlatformAgnostic) ||
      (campaign &&
        campaign.websiteType &&
        campaign.websiteType.name === 'Adobe SaaS');

    let isCampaignTypeStandalone =
      campaign &&
      campaign.autogenerate &&
      (campaign &&
      campaign.websiteType &&
      campaign.websiteType.name === 'Standalone'
        ? true
        : false);
    let isCampaignNonEnglish = checkCampaignLanguage(campaignLanguage);
    let isCampaignIncentive =
      campaign && campaign.campaignType === 'Incentive' ? true : false;

    let marketCode = country && country.value.split('-')[0];
    let allowedMarketsForDoubleOptin = constant.allowedMarketsForDoubleOptin;
    let isMarketSupported = checkMarketSupportedForDoubleOptin(
      marketCode,
      allowedMarketsForDoubleOptin
    );
    let isPromotigoType =
      isCampaignIncentive && isWebsiteTypeforSignUp ? true : false;
    let isSimpleSignUp =
      campaign && campaign.campaignType === 'Direct messaging' ? true : false;
    if (asyncInProgress) {
      return <Loader />;
    }
    // else if (isLoading) {
    //    return <Loader />;
    //  }
    else if (
      this.props.serviceError &&
      (this.props.serviceErrorStatus === 500 ||
        this.props.serviceErrorStatus === 401)
    ) {
      return <ServiceError />;
    } else if (
      this.props.serviceError &&
      this.props.serviceErrorStatus === 404
    ) {
      return <NotFound />;
    } else {
      let isCampaignTypeSASS =
        (campaign &&
          campaign.websiteType &&
          campaign.websiteType.name === 'Adobe SaaS') ||
        campaign.isPlatformAgnostic ||
        (campaign && campaign.isMasterData);
      isEdited = isEdited && isCampaignTypeSASS;
      return (
        <div className="col-sm-12" ref={this.scrollToTop}>
          {/* Success Message */}
          {isEdited && isSubmitted ? (
            <div className="cw-section ml-20">
              <div className="row">
                <h2 className="cw-heading--secondary mb-4">Campaign Edited</h2>
              </div>
              <div className="row">
                <p className="mb-4">
                  Changes to your form have been saved and are now live.
                </p>
              </div>
              <div className="row">
                <button
                  className="btn btn-primary"
                  onClick={this._showCampaignOverview}
                >
                  View Campaign
                </button>
              </div>
            </div>
          ) : (
            <>
              {isSubmitted && !isEdited ? (
                isSubmitted &&
                (isCampaignIncentive || isSimpleSignUp) &&
                campaign &&
                campaign.isMasterData ? (
                  <>
                    {this.incentivePromotigoOrSimpleSignUp(
                      campaign,
                      historyLogs
                    )}
                  </>
                ) : (
                  <div className="cw-section ml-20">
                    <div className="row mb-30">
                      <div className="col-sm-6">
                        <NavLink
                          className="cw-color--primary cw-breadcrum"
                          to={'/campaign-wizard/dashboard'}
                        >
                          <i className="fas fa-angle-left"></i> Campaigns
                        </NavLink>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-8 cw-section--title mb-5">
                        <h2 className="cw-heading--secondary mb-4">
                          Campaign submitted
                        </h2>
                        {isCampaignTypeSASS ? (
                          epsilonMarket(countrySelected) ? (
                            <>
                              <p>Your sign up form request is now complete.</p>
                              <p>
                                On your{' '}
                                <Link
                                  to={`/campaign-wizard/campaign/${this.campaignId}`}
                                >
                                  campaign page
                                </Link>
                                , you will find a ready to use form widget along
                                with an integration guide to help you embed the
                                form on your website.
                              </p>
                              <p className="mt-10">
                                You can also track all your campaign requests
                                through the{' '}
                                <Link to={`/campaign-wizard/dashboard`}>
                                  campaigns dashboard
                                </Link>
                              </p>
                            </>
                          ) : (
                            <div className="review-submitted-text">
                              <p>
                                Your data capture campaign request has been
                                submitted for approval.
                              </p>
                              {campaign &&
                                campaign.campaignType &&
                                campaign.campaignType.toLowerCase() ===
                                  'incentive' && (
                                  <p className="mt-10 mb-10">
                                    Incentive based campaigns also require
                                    Promotigo setup. Please visit{' '}
                                    <a
                                      target="_blank"
                                      href="https://campaign.promotigo.com"
                                    >
                                      Promotigo{' '}
                                      <i className="fas fa-external-link-alt"></i>
                                    </a>{' '}
                                    to setup your incentive campaign when we
                                    notify you via an email. You will receive a
                                    Promotigo form ID that you will need to
                                    update to your Campaign Wizard request.
                                    Please ensure that all specifications
                                    mentioned in your Campaign Wizard request
                                    match with the details you provide in
                                    Promotigo at all times, failing which your
                                    form may not work correctly.
                                  </p>
                                )}

                              <p>
                                Once your request is completed, you will find a
                                ready to use form widget on your{' '}
                                <Link
                                  to={`/campaign-wizard/campaign/${this.campaignId}`}
                                >
                                  campaign page
                                </Link>
                                , along with an integration guide to help you
                                embed the form on your website.
                              </p>
                              {constant.featureFlags
                                .ENABLE_CAMPAIGN_STATUS_TIMELINE &&
                                !epsilonMarket(countrySelected) && (
                                  <div className="mt-20 mb-20">
                                    <p>
                                      Your campaign will go through a series of
                                      steps, as indicated below before it is
                                      ready for use.
                                    </p>

                                    <StatusTimeline
                                      steps={getCampaignStatusSteps(
                                        campaign,
                                        historyLogs
                                      )}
                                    />
                                  </div>
                                )}
                              <p className="mt-10">
                                You can also track all your campaign requests
                                through the{' '}
                                <Link to={`/campaign-wizard/dashboard`}>
                                  campaigns dashboard
                                </Link>
                                .
                              </p>
                            </div>
                          )
                        ) : epsilonMarket(countrySelected) ? (
                          <>
                            <p className="mb-4">
                              You sign-up campaign request is now complete.
                            </p>
                            <button
                              className="btn btn-primary"
                              onClick={this._showCampaignOverview}
                            >
                              View campaign details
                            </button>
                            <br />
                            <br />
                            <p>
                              You will have received an email confirmation. You
                              can also view your requests in the{' '}
                              <a href="/campaign-wizard/dashboard">
                                campaign dashboard
                              </a>
                              .
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="cw-heading--tertiary mb-23">
                              What happens next
                            </p>

                            <p className="mb-4">
                              Your campaign has been sent for approval.
                              <br />
                              <br />
                              Once approved, we’ll get back to you with all the
                              details you’ll need to author your campaign.
                              <br />
                              {campaign &&
                                campaign.campaignType &&
                                campaign.campaignType.toLowerCase() ===
                                  'incentive' && (
                                  <>
                                    <br />
                                    <b>Promotigo</b>
                                    <br />
                                    Once you receive your IDs (on campaign
                                    approval) visit{' '}
                                    <a
                                      target="_blank"
                                      href="https://campaign.promotigo.com"
                                    >
                                      Promotigo{' '}
                                      <i className="fas fa-external-link-alt"></i>
                                    </a>{' '}
                                    to setup your incentives campaign.
                                    <br />
                                    <br />
                                    Please make sure the samples added in your
                                    campaign request are the same as samples you
                                    add in Promotigo portal. In any case, you
                                    will have another opportunity to modify
                                    samples in your CW ticket when we notify you
                                    to add Promotigo ID.
                                    <br />
                                  </>
                                )}
                              <br />
                              Track the progress of your campaign with the{' '}
                              <a href="/campaign-wizard/dashboard">
                                campaign dashboard
                              </a>
                              .
                            </p>

                            <button
                              className="btn btn-primary"
                              onClick={this._showCampaignOverview}
                            >
                              View campaign details
                            </button>

                            {constant.featureFlags
                              .ENABLE_CAMPAIGN_STATUS_TIMELINE &&
                              !epsilonMarket(countrySelected) && (
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="mt-20">
                                      <p>
                                        Your campaign will go through a series
                                        of steps, as indicated below before it
                                        is ready for use.
                                      </p>

                                      <StatusTimeline
                                        steps={getCampaignStatusSteps(
                                          campaign,
                                          historyLogs
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                          </>
                        )}
                      </div>
                      <div className="col-md-4">
                        {isCampaignTypeSASS && (
                          <div className="btn-box-ml-auto">
                            <button
                              className="btn btn-primary mt-75 mb-20"
                              onClick={this._showCampaignOverview}
                            >
                              View campaign details
                            </button>
                          </div>
                        )}

                        {constant.featureFlags.ENABLE_FEEDBACK_FORM && (
                          <Alert
                            alertType="feedbackFormMesg"
                            onFeedbackClick={this._handleOnOpenFeedback}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <div className="cw-section ml-20">
                  <div className="row">
                    {/* <BreadCrumb
                  match={this.state.matchProps}
                  editCampaign={
                    this.props.editCampaign || this.props.isCampaignEdited
                    }
                  hideBreadCrumb={this.props.approverEditCampaign}
                  campaignType={campaign && campaign.campaignType}
                /> */}
                    <div className="col-sm-8 cw-section--title mb-30">
                      <h2 className="cw-heading--secondary mb-3">
                        Confirm and submit campaign
                      </h2>
                      <p>Check the details before you submit your campaign.</p>
                    </div>
                  </div>
                  {/* <Alert
                        alertType="saasUpdateMessage"
                        textMessage={this.saasUpdateMessage}
              /> */}

                  <div className="row">
                    <div className="col-sm-8">
                      <div className="cw-section--content">
                        <div className="cw-campaign--review">
                          {/* Brand Details */}
                          <div className="cw-campaign--review-section mb-40">
                            {/* {isCampaignTypeSASS ? (
                              <Alert
                                alertType="saasUpdateMessage"
                                textMessage={
                                  constant.SAAS_MESSAGES.previewInfoMessage
                                }
                                isButton={true}
                                previewUrl={this.props.previewUrl}
                              />
                            ) : (
                              ''
                            )} */}
                            <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center">
                              <h3 className="cw-heading--senary">
                                Brand Details
                              </h3>
                              {!isEdited ? (
                                <button
                                  className="btn btn-outline-secondary ml-auto"
                                  onClick={this._handleBrandDetail}
                                >
                                  Edit
                                </button>
                              ) : null}
                            </div>
                            <div className="cw-campaign--review-section-content">
                              <div className="cw-striped-profile-row">
                                <div className="row">
                                  <div className="col-sm-4">
                                    <strong>Brand</strong>
                                  </div>
                                  <div className="col-sm-8 cw-text--quinary">
                                    {campaign.brand
                                      ? campaign.brand.name +
                                        ' (' +
                                        campaign.brand.code +
                                        ')'
                                      : ''}
                                  </div>
                                </div>
                              </div>
                              <div className="cw-striped-profile-row">
                                <div className="row">
                                  <div className="col-sm-4">
                                    <strong>Country</strong>
                                  </div>
                                  <div className="col-sm-8 cw-text--quinary">
                                    {campaign.country
                                      ? campaign.country.name
                                      : ''}
                                  </div>
                                </div>
                              </div>
                              <div className="cw-striped-profile-row">
                                <div className="row">
                                  <div className="col-sm-4">
                                    <strong>Language</strong>
                                  </div>
                                  <div className="col-sm-8 cw-text--quinary">
                                    {campaign.language
                                      ? campaign.language.name
                                      : ''}
                                  </div>
                                </div>
                              </div>
                              <div className="cw-striped-profile-row">
                                <div className="row">
                                  <div className="col-sm-4">
                                    <strong>Website Type</strong>
                                  </div>
                                  <div className="col-sm-8 cw-text--quinary">
                                    {campaign.websiteType
                                      ? campaign.websiteType.name
                                      : ''}
                                  </div>
                                </div>
                              </div>
                              <div className="cw-striped-profile-row"></div>
                            </div>
                          </div>
                          {/* Campaign Type */}
                          <div className="cw-campaign--review-section mb-40">
                            <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center">
                              <h3 className="cw-heading--senary">
                                Campaign Type
                              </h3>
                              {!isEdited ? (
                                <button
                                  className="btn btn-outline-secondary ml-auto"
                                  onClick={this._handleCampaignType}
                                >
                                  Edit
                                </button>
                              ) : null}
                            </div>
                            <div className="cw-campaign--review-section-content">
                              <div className="cw-striped-profile-row">
                                <div className="row">
                                  <div className="col-sm-4">
                                    <strong>Campaign Type</strong>
                                  </div>
                                  <div className="col-sm-8 cw-text--quinary">
                                    {campaign
                                      ? campaign.campaignType + ' campaign'
                                      : ''}
                                  </div>
                                </div>
                              </div>
                              {campaign &&
                              campaign.campaignType === 'Incentive' ? (
                                <div className="cw-striped-profile-row">
                                  <div className="row">
                                    <div className="col-sm-4">
                                      <strong>Incentive</strong>
                                    </div>
                                    <div className="col-sm-8 cw-text--quinary">
                                      {campaign ? campaign.incentiveType : ''}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              <div className="cw-striped-profile-row">
                                <div className="row">
                                  <div className="col-sm-4">
                                    <strong>Form Type</strong>
                                  </div>
                                  <div className="col-sm-8 cw-text--quinary">
                                    {campaign ? campaign.type : ''}
                                  </div>
                                </div>
                              </div>
                              <div className="cw-striped-profile-row"></div>
                            </div>
                          </div>
                          {/* Campaign Details */}
                          <CampaignDetailsInfo
                            isCampaignTypeSASS={isCampaignTypeSASS}
                            previewUrl={this.props.previewUrl}
                            cwStatus={cwStatus}
                            campaign={campaign}
                            campaignDetails={campaignDetails}
                            panelref={this.collapsePanel}
                            collapseHandlerIconref={this.collapseHandlerIcon}
                            collapseHandlerTextref={this.collapseHandlerText}
                            handleCampaignDetail={this._handleCampaignDetail}
                            isEditButton={true}
                            isConsentAndCompliance={true}
                            isCampaignType={false}
                            isCampaignAuthoring={false}
                            handleRefresh={this._handleRefresh}
                            isEdited={isEdited}
                            campaignConfig={this.props.campaignConfig}
                          />
                          {/* <div className="cw-campaign--review-section mb-40">
                        <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center">
                          <h3 className="cw-heading--senary">
                            Campaign Details
                          </h3>
                          <button
                            className="btn btn-outline-secondary ml-auto"
                            onClick={this._handleCampaignDetail}
                          >
                            Edit
                          </button>
                        </div>
                        <div className="cw-campaign--review-section-content">
                          <div className="cw-striped-row">
                            <div className="row">
                              <div className="col-sm-4">
                                <strong>Campaign Name</strong>
                              </div>
                              <div className="col-sm-8 cw-text--quinary">
                                {campaign.name}
                              </div>
                            </div>
                          </div>
                          <div className="cw-striped-row">
                            <div className="row">
                              <div className="col-sm-4">
                                <strong>Campaign Description</strong>
                              </div>
                              <div className="col-sm-8 cw-text--quinary">
                                {campaignDetails.description}
                              </div>
                            </div>
                          </div>
                          {campaignDetails && campaignDetails.brandUrl ? (
                            <div className="cw-striped-row">
                              <div className="row">
                                <div className="col-sm-4">
                                  <strong>Brand Url</strong>
                                </div>
                                <div className="col-sm-8">
                                  {campaignDetails && campaignDetails.brandUrl
                                    ? campaignDetails.brandUrl
                                    : ""}
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="cw-striped-row">
                            <div className="row">
                              <div className="col-sm-4">
                                <strong>Start Date</strong>
                              </div>
                              <div className="col-sm-8 cw-text--quinary">
                                {getFormatedDate(campaignDetails.startDate)} UTC
                              </div>
                            </div>
                          </div>

                          <div className="cw-striped-row">
                            <div className="row">
                              <div className="col-sm-4">
                                <strong>End Date</strong>
                              </div>
                              <div className="col-sm-8 cw-text--quinary">
                                {campaignDetails.isAlwaysOn
                                  ? "No end date"
                                  : getFormatedDate(campaignDetails.endDate) +
                                  " UTC"}
                              </div>
                            </div>
                          </div>
                          {showAutoGenerateFlag(campaign) ? (
                            <div className="cw-striped-row">
                              <div className="row">
                                <div className="col-sm-4">
                                  <strong>
                                    Do you need a signup form in AEM for this
                                    campaign?
                                  </strong>
                                </div>
                                <div className="col-sm-8 cw-text--quinary">
                                  {campaign && campaign.autogenerate
                                    ? "Yes"
                                    : "No, I will create my own form or I don’t need a form"}
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {!epsilonMarket(countrySelected) ? (
                            <div>
                              <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center clearfix">
                                <p className="cw-heading--saascampaign mt-20">
                                  Campaign Tracking
                                </p>
                              </div>
                              <div>
                                <div className="cw-striped-row">
                                  <div className="row">
                                    <div className="col-sm-4">
                                      <strong>Unique Campaign ID? </strong>
                                    </div>
                                    <div className="col-sm-8 cw-text--quinary">
                                      {campaignDetails.isCustomTrackingId !==
                                        undefined
                                        ? campaignDetails &&
                                          campaignDetails.isCustomTrackingId
                                          ? "Reuse existing provided by brand"
                                          : campaignDetails.isTrackingRequired !==
                                            undefined
                                            ? campaignDetails &&
                                              campaignDetails.isTrackingRequired
                                              ? "Yes"
                                              : "No"
                                            : ""
                                        : campaignDetails.isTrackingRequired !==
                                          undefined
                                          ? campaignDetails &&
                                            campaignDetails.isTrackingRequired
                                            ? "Yes"
                                            : "No"
                                          : ""}
                                    </div>
                                  </div>
                                </div>
                                {campaignDetails.isCustomTrackingId !==
                                  undefined &&
                                  campaignDetails.isCustomTrackingId ? (
                                  <div className="cw-striped-row">
                                    <div className="row">
                                      <div className="col-sm-4">
                                        <strong>Campaign ID</strong>
                                      </div>
                                      <div className="col-sm-8 cw-text--quinary">
                                        {this.props.campaign.campaignId}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          ) : null}

                          <div>
                            <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center clearfix">
                              <p className="cw-heading--saascampaign mt-20">
                                DPC/PRA Confirmation
                              </p>
                            </div>
                            <div>
                              <div className="cw-striped-row">
                                <div className="row">
                                  <div className="col-sm-4">
                                    <strong>DPC/PRA Reference ID</strong>
                                  </div>
                                  <div className="col-sm-8 cw-text--quinary">
                                    {campaignDetails.dpiaReferenceNo}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {this.props.campaign &&
                            this.props.campaign.websiteType &&
                            this.props.campaign.websiteType.name &&
                            this.props.campaign.websiteType.name !==
                            "Adobe SaaS" ? (
                            <div>
                              <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center clearfix">
                                <p className="cw-heading--saascampaign mt-20">
                                  Communication Channels
                                </p>
                              </div>
                              <div>
                                <div className="cw-striped-row">
                                  <div className="row">
                                    <div className="col-sm-4">
                                      <strong>Consent Channel</strong>
                                    </div>
                                    <div className="col-sm-7">
                                      {campaignDetails.emailChannel
                                        ? "Email"
                                        : ""}
                                      {campaignDetails.smsChannel &&
                                        campaignDetails.emailChannel
                                        ? ","
                                        : ""}
                                      {campaignDetails.smsChannel ? "SMS" : ""}
                                    </div>
                                  </div>
                                </div>

                                {campaignDetails.emailChannel !== undefined &&
                                  campaignDetails.emailConfirmationFlag !==
                                  undefined ? (
                                  campaignDetails.emailChannel &&
                                    (campaignDetails.emailConfirmationFlag ||
                                      !campaignDetails.emailConfirmationFlag) ? (
                                    <div className="cw-striped-row">
                                      <div className="row">
                                        <div className="col-sm-4">
                                          <strong>
                                            Do you need an email campaign for
                                            this request?
                                          </strong>
                                        </div>
                                        <div className="col-sm-8">
                                          {campaignDetails.emailConfirmationFlag !==
                                            undefined &&
                                            campaignDetails.emailChannel !==
                                            undefined
                                            ? campaignDetails &&
                                              campaignDetails.emailConfirmationFlag &&
                                              campaignDetails.emailChannel
                                              ? "Yes"
                                              : "No"
                                            : ""}
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )}

                                {campaignDetails.emailChannel !== undefined ? (
                                  campaignDetails.emailChannel &&
                                    campaignDetails.emailConfirmationFlag ? (
                                    <div className="cw-striped-row">
                                      <div className="row">
                                        <div className="col-sm-4">
                                          <strong>Email senders name</strong>
                                        </div>
                                        <div className="col-sm-8">
                                          {campaignDetails.emailSenderName}
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  <div className="cw-striped-row">
                                    <div className="row">
                                      <div className="col-sm-4">
                                        <strong>Email senders name</strong>
                                      </div>
                                      <div className="col-sm-8">
                                        {campaignDetails.emailSenderName}
                                      </div>
                                    </div>
                                  </div>
                                )}

                                {campaignDetails.emailConfirmationFlag !==
                                  undefined ? (
                                  campaignDetails.emailConfirmationFlag ? (
                                    <div className="cw-striped-row">
                                      <div className="row">
                                        <div className="col-sm-4">
                                          <strong>Reply to email name</strong>
                                        </div>
                                        <div className="col-sm-8">
                                          {campaignDetails.replyToEmailName}
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )}

                                {campaignDetails.emailConfirmationFlag !==
                                  undefined ? (
                                  campaignDetails.emailConfirmationFlag ? (
                                    <div className="cw-striped-row">
                                      <div className="row">
                                        <div className="col-sm-4">
                                          <strong>
                                            Reply to email address
                                          </strong>
                                        </div>
                                        <div className="col-sm-8">
                                          {campaignDetails.replyToEmailAdderess}
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ) : null}

                          {this.props.campaign &&
                            this.props.campaign.websiteType &&
                            this.props.campaign.websiteType.name &&
                            this.props.campaign.websiteType.name !==
                            "Adobe SaaS" ? (
                            <div>
                              <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center clearfix">
                                <p className="cw-heading--saascampaign mt-20">
                                  Consent and Compliance
                                </p>
                              </div>
                              <div>
                                <div className="cw-striped-row">
                                  <div className="row">
                                    <div className="col-sm-4">
                                      <strong>Brand Cookie Policy</strong>
                                    </div>
                                    <div className="col-sm-8 cw-text--quinary">
                                      {campaignDetails.cookiePolicyUrl}
                                    </div>
                                  </div>
                                </div>
                                <div className="cw-striped-row">
                                  <div className="row">
                                    <div className="col-sm-4">
                                      <strong>Brand Privacy Policy</strong>
                                    </div>
                                    <div className="col-sm-8 cw-text--quinary">
                                      {campaignDetails.privacyPolicyUrl}
                                    </div>
                                  </div>
                                </div>
                                <div className="cw-striped-row">
                                  <div className="row">
                                    <div className="col-sm-4">
                                      <strong>Campaign T&Cs</strong>
                                    </div>
                                    <div className="col-sm-8 cw-text--quinary">
                                      {campaignDetails.tncUrl}
                                    </div>
                                  </div>
                                </div>

                                <div className="cw-striped-row">
                                  <div className="row">
                                    <div className="col-sm-4">
                                      <strong>Minimum legal age of consent</strong>
                                    </div>
                                    <div className="col-sm-8 cw-text--quinary">
                                      {campaignDetails.ageOfConsent}
                                    </div>
                                  </div>
                                </div>
                                {isMarketSupported ? (
                                  <div className="cw-striped-row">
                                    <div className="row">
                                      <div className="col-sm-4">
                                        <strong>Double opt-in</strong>
                                      </div>
                                      <div className="col-sm-8 cw-text--quinary">
                                        {campaignDetails.doubleOptinReq
                                          ? "Yes"
                                          : "No"}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {campaignDetails.doubleOptinReq ? (
                                  <div className="cw-striped-row">
                                    <div className="row">
                                      <div className="col-sm-4">
                                        <strong>Re-direction URL</strong>
                                      </div>
                                      <div className="col-sm-8 cw-text--quinary">
                                        {campaignDetails.redirectionUrl}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                <div className="cw-striped-row">
                                  <div className="row">
                                    <div className="col-sm-4">
                                      <strong>Disclaimer Text</strong>
                                    </div>
                                    <div className="col-sm-8 cw-text--quinary text-break">
                                      {campaignDetails.disclaimerText}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div> */}

                          {/* Campaign authoring access*/}

                          {this.props.autogenerate &&
                          isCampaignTypeStandalone ? (
                            <AuthorAccessReview
                              campaignAccessEmails={campaignAccessEmails}
                              campaignID={this.props.match.params.id}
                              history={this.props.history}
                            />
                          ) : null}

                          {!isCampaignTypeSASS ? (
                            <div className="cw-campaign--review-section mb-60">
                              {!isCampaignIncentive && !isCampaignTypeSASS ? (
                                <RegularCommunications
                                  campaign={campaign}
                                  campaignDetails={campaignDetails}
                                  mainDivClass="col-sm-8"
                                  colLabelClass="col-sm-4"
                                  colValClass="col-sm-8"
                                  disabledFlag={false}
                                  regularCommunicationLabel="Regular Communication"
                                  draftFlag={false}
                                  showEditButton={true}
                                  buttonClickEvent={
                                    this._handleAdditionalConsent
                                  }
                                  optins={this.props.optins}
                                  showComponent={true}
                                ></RegularCommunications>
                              ) : null}
                            </div>
                          ) : null}

                          {/* Questions & answers */}
                          <QnAList
                            campaignLanguage={campaignLanguage}
                            handleQuestionsAnswers={
                              this._handleQuestionsAnswers
                            }
                            isCampaignNonEnglish={isCampaignNonEnglish}
                            campaignId={this.campaignId}
                            qnaList={questionAnswerList}
                            showQnaList={
                              campaign.type ===
                                'Sign-up with Questions & Answers' &&
                              !isCampaignTypeSASS
                            }
                            isPromotigoType={isPromotigoType}
                            colWidth={`col-sm-4`}
                            showAwaitingQid={false}
                            isEditButtonShow={true}
                            accordianClass={`col-sm-8`}
                            newIconClass={`col-sm-3`}
                            campaign={campaign}
                            campaignDetails={campaignDetails}
                          />
                          {isCampaignTypeSASS &&
                          constant.featureFlags
                            .ENABLE_SINGLE_PAGE_FIELD_SELECTION ? (
                            <FormAuthoringGrouped
                              ishideQnA={
                                campaign.type !==
                                'Sign-up with Questions & Answers'
                              }
                              isEditButton={true}
                              campaignId={this.props.match.params.id}
                              consentPage={'/saas-consent-compliance'}
                              qnaPage={`${
                                !epsilonMarket(countrySelected)
                                  ? '/saasqna'
                                  : '/saas-epsilon-qna'
                              }`}
                              profilePage={'/saas-profile-details'}
                              genericPage={'/saas-generic-details'}
                              showMasterIcon={true}
                              accordianClass={`col-sm-8`}
                              newIconClass={`col-sm-3`}
                              colWidth={`col-sm-4`}
                              isMasterData={campaign && campaign.isMasterData}
                              campaignConfig={this.props.campaignConfig}
                            />
                          ) : (
                            ''
                          )}

                          {isCampaignTypeSASS &&
                          !constant.featureFlags
                            .ENABLE_SINGLE_PAGE_FIELD_SELECTION ? (
                            <FormAuthoring
                              ishideQnA={
                                campaign.type !==
                                'Sign-up with Questions & Answers'
                              }
                              isEditButton={true}
                              campaignId={this.props.match.params.id}
                              consentPage={'/saas-consent-compliance'}
                              qnaPage={`${
                                !epsilonMarket(countrySelected)
                                  ? '/saasqna'
                                  : '/saas-epsilon-qna'
                              }`}
                              profilePage={'/saas-profile-details'}
                              genericPage={'/saas-generic-details'}
                              showMasterIcon={true}
                              accordianClass={`col-sm-8`}
                              newIconClass={`col-sm-3`}
                              colWidth={`col-sm-4`}
                            />
                          ) : (
                            ''
                          )}

                          {/* Additonal information*/}
                          <div className="cw-campaign--review-section mb-10">
                            <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center">
                              <h3 className="cw-heading--senary">
                                Additional Information
                              </h3>
                            </div>
                            <div className="cw-striped-row-additional">
                              <div className="row">
                                <textarea
                                  className="form-control"
                                  ref="additionalInfo"
                                  rows="4"
                                  maxLength="300"
                                  name="additionalInfo"
                                  id="additionalInfo"
                                  placeholder="Provide any additional information that is relevant to this campaign request."
                                  value={this.props.additionalInfo || ''}
                                  onChange={this._handleInputChange}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          {isEdited && isCampaignTypeSASS ? (
                            <>
                              <div className="cw-campaign--review-section mb-10">
                                <div className="cw-campaign--review-section-header mb-1 d-flex align-items-center">
                                  <h3 className="cw-heading--senary">
                                    Reason for editing
                                  </h3>
                                </div>
                                <div className="cw-striped-row-additional">
                                  <div className="row">
                                    <textarea
                                      className="form-control"
                                      ref="editComments"
                                      rows="4"
                                      maxLength="300"
                                      name="editComments"
                                      id="editComments"
                                      placeholder=""
                                      value={this.props.editComments || ''}
                                      onChange={this._handleInputChange}
                                    ></textarea>
                                    <span
                                      id="editCommentId"
                                      className="cw-error cw-hidden"
                                    >
                                      <i className="fas fa-exclamation-triangle mr-10"></i>
                                      Please specify the reason for editing
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {/* : null}
                      {isCampaignTypeSASS && isEdited? ( */}
                              <div className="form-check mb-30">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value="true"
                                  ref="iConfirmEdit"
                                  id="iConfirmEdit"
                                  name="iConfirmEdit"
                                  onChange={this._handleEditCheckbox}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="iConfirmEdit"
                                >
                                  I understand that once I submit the form, all
                                  changes will reflect on my final form output.
                                  If my form is already live on my website,
                                  consumers will also be able to see the changes
                                </label>
                              </div>
                            </>
                          ) : null}
                          {this._shouldShowFeedbackSection() &&
                            !isEdited &&
                            isCampaignTypeSASS && (
                              <div className="cw-campaign--review-section mb-10">
                                <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center">
                                  <h3 className="cw-heading--senary">
                                    Rate your experience
                                  </h3>
                                </div>

                                <div className="form-group mb-20">
                                  <label>
                                    Please rate your experience using Campaign
                                    Wizard
                                  </label>
                                  <SatisfactoryRatingsInput
                                    name="rw_ratings"
                                    onChange={this._handleFeedbackInputChange}
                                    value={this.state.feedback.rw_ratings}
                                    onBlur={() => {}}
                                    idPrefix="rw_"
                                  />
                                  <span
                                    id="ratingsFieldMessage"
                                    className="cw-error cw-hidden"
                                  >
                                    <i className="fas fa-exclamation-triangle mr-10"></i>
                                    Please rate your experience
                                  </span>
                                </div>

                                <div className="form-group mb-20"></div>

                                <div className="form-group">
                                  <label>
                                    Please provide comments so we can action on
                                    your input &#40;Optional&#41;
                                  </label>
                                </div>
                                <div className="form-group">
                                  <label>Comment type</label>
                                  <Select
                                    className={'cw-select--custom'}
                                    name={'rw_commentType'}
                                    label={'rw_commentType'}
                                    placeholder={'Please select'}
                                    options={this.feedbackCommentOptions}
                                    onChange={this._handleFeedbackCommentType}
                                    value={this.state.feedback.rw_commentType}
                                  />
                                </div>

                                <div className="form-group">
                                  <textarea
                                    className={classNames({
                                      'form-control': true,
                                      'cw-error-focus': this.state
                                        .feedbackCommentError
                                    })}
                                    name="rw_comments"
                                    id="rw_comments"
                                    rows="3"
                                    maxLength="500"
                                    data-required="true"
                                    placeholder="Please provide your suggestions, complaints, compliments or new feature requests"
                                    onChange={this._handleFeedbackInputChange}
                                    value={
                                      this.state.feedback.rw_comments || ''
                                    }
                                    onBlur={() => {}}
                                  ></textarea>
                                  {this.state.feedbackCommentError && (
                                    <span
                                      id="ratingsFieldMessage"
                                      className="cw-error"
                                    >
                                      <i className="fas fa-exclamation-triangle mr-10"></i>
                                      Please enter your comments
                                    </span>
                                  )}
                                </div>
                              </div>
                            )}
                          {isCampaignTypeSASS &&
                          this.props.campaign &&
                          !this.props.campaign.isMasterData ? (
                            <div className="form-check mb-30">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value="true"
                                ref="iconfirm"
                                id="iconfirm"
                                name="iconfirm"
                                onChange={this._handleCheckboxConfirm}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="iconfirm"
                              >
                                I confirm I have checked and validated the form
                                <a
                                  href={this.props.previewUrl}
                                  target={'_blank'}
                                >
                                  {} preview
                                </a>{' '}
                                before submitting the form request
                              </label>
                            </div>
                          ) : null}

                          {/* CTA */}
                          <div className="cw-form--action-cta">
                            <button
                              type="button"
                              className="btn btn btn-outline-secondary mr-3"
                              onClick={this._handlebackButton}
                            >
                              Back
                            </button>
                            {isCampaignTypeSASS && !isEdited ? (
                              <button
                                type="submit"
                                id="submit"
                                className="btn btn-primary"
                                disabled={
                                  this.props.campaign &&
                                  this.props.campaign.isMasterData
                                    ? false
                                    : !this.state.iConfirm
                                    ? true
                                    : false
                                }
                                onClick={this._handleSubmitButton}
                              >
                                Submit
                              </button>
                            ) : null}
                            {isCampaignTypeSASS && isEdited ? (
                              <button
                                type="submit"
                                id="submit"
                                className="btn btn-primary"
                                disabled={
                                  !this.state.iConfirm ||
                                  !this.state.isEditedChecked
                                    ? true
                                    : false
                                }
                                onClick={this._handleSubmitButton}
                              >
                                Submit
                              </button>
                            ) : null}
                            {!isCampaignTypeSASS ? (
                              <button
                                type="submit"
                                id="submit"
                                className="btn btn-primary"
                                onClick={this._handleSubmitButton}
                              >
                                Submit
                              </button>
                            ) : null}

                            {isCampaignTypeSASS && campaign.isEdited ? (
                              <a
                                href="javascript:void(0)"
                                className="cw-cancel-edit mt-40"
                                onClick={event =>
                                  this._openIsCancelModal(event)
                                }
                              >
                                Cancel changes
                              </a>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    {isCampaignTypeSASS ? (
                      <div className="col-sm-4 cns-campaign">
                        {isCampaignTypeSASS &&
                        this.props.campaign &&
                        !this.props.campaign.isMasterData ? (
                          <div className="btn-box-ml-auto mb-20">
                            <a
                              className="btn btn-primary preview-form-btn "
                              href={this.props.previewUrl}
                              target="_blank"
                              disabled={isLoading}
                            >
                              Preview Form
                              {isLoading && (
                                <div
                                  className="preview-loader ml-10"
                                  role="status"
                                >
                                  <span className="sr-only"></span>
                                </div>
                              )}
                            </a>
                          </div>
                        ) : null}
                        {this.props.campaign &&
                        !this.props.campaign.isMasterData ? (
                          <div>
                            <Alert
                              alertType="ReviewNewUpdateMessage"
                              textMessage={
                                constant.SAAS_MESSAGES.reviewPageUpdateMessage
                              }
                              heading={'New'}
                            />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    ) : null}
                  </div>
                  <Modal
                    scrollable={true}
                    isOpen={this.state.isCancelModalOpen}
                    onRequestClose={this._closeIsCancelModal}
                    className="cw-modal cw-modal--branddetail"
                    contentLabel="Campaign Wizard 2.0"
                    style={{
                      overlay: {
                        backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
                      }
                    }}
                  >
                    <CampaignCancelModel
                      closeIsCancelModal={this._closeIsCancelModal}
                      isCancelContinue={this._cancelSaasCampaign}
                      data={[]}
                    />
                  </Modal>
                </div>
              )}
            </>
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  campaignDetails: state.review.campaignDetails,
  additionalOptinList: state.campaignDetails.additionalOptinList,
  additionalConsentFlag: state.campaignDetails.additionalConsentFlag,
  campaign: state.review.campaign,
  questionAnswerList: state.review.questionAnswerList,
  asyncInProgress: state.review.asyncInProgress,
  serviceError: state.review.serviceError,
  serviceErrorStatus: state.review.serviceErrorStatus,
  isSubmitted: state.review.isSubmitted,
  additionalInfo: state.review.additionalInfo,
  campaignAccessEmails: state.review.campaignAccessEmails,
  editCampaign: state.overview.editCampaign,
  editCampaignDetails: state.app.editCampaignDetails,
  autogenerate: state.campaignDetails.autogenerate,
  country: state.brandDetails.country,
  previewUrl: state.preview.previewUrl,
  isLoading: state.preview.isLoading,
  editComments: state.review.editComments,
  historyLogs: state.historyLogs,
  feedbacks: state.feedbackForm.feedbacks,
  campaignConfig: state.app.campaignConfig
});

export default connect(mapStateToProps, {
  getReviewCampaignDetails,
  submitReviewData,
  updateCampaignDetails,
  submitCampaignDetails,
  editedCampaignDetails,
  getBrandCountries,
  cancelCampaignChanges,
  openFeedbackForm,
  fetchHistoryLogs,
  submitFeedback
})(ReviewAndSubmit);
