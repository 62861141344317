import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import InlineButton from '../InlineButton/InlineButton';
import externalLink from '../../../images/external-link.png';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import constants from 'constants/config';
const { consentTypes } = constants;

const ConsentAvailabilityMessage = ({
  textBeforeLink,
  textAfterLink,
  openModal,
  elem
}) => (
  <div
    className={classNames('alert alert-warning  d-flex', {
      'mt-15':
        elem && elem.optInType !== consentTypes.multiChannelUnileverConsent
    })}
  >
    <i className="fas fa-exclamation-circle pt-5 mr-10"></i>
    <div>
      {textBeforeLink}
      <a
        href="https://unilever.service-now.com/cex?id=sc_cat_item&sys_id=0394a8b71b28555021f3b8c8dc4bcbb9"
        target="_blank"
        rel="noopener noreferrer"
      >
        UNA
      </a>
      <div className="external-link-wrapper">
        <img
          src={externalLink}
          alt="External Link"
          className="external-link-icon"
        />
      </div>
      {ReactHtmlParser(textAfterLink)}
      <InlineButton onClick={openModal}>Learn more</InlineButton>
    </div>
  </div>
);

ConsentAvailabilityMessage.propTypes = {
  textBeforeLink: PropTypes.string,
  textAfterLink: PropTypes.string,
  openModal: PropTypes.func
};

export default ConsentAvailabilityMessage;
