import EmailCampaignScheduleDetails from 'pages/EmailCampaignScheduleDetails';
import React, { useState } from 'react';
import Modal from 'react-modal';
import constants from 'constants/config';
import RequestMoreInfoIcon from 'images/requestMoreInfoIcon';
import RequestMoreInfoIconTransparent from 'images/requestMoreInfoIconTransparent';
import EmailCampaignRequestInfo from 'pages/EmailCampaignRequestInfo';

const EmailRequestInfoModel = ({
  modalIsOpen,
  closeModal,
  emailBriefing,
  pathUid
}) => {
  const { summary, requestInfoText } = emailBriefing;
  const campaignName = (summary && summary.campaignName) || 'Untitled';
  const [isActive, setIsActive] = useState(false);

  const dismissModal = () => {
    setIsActive(false);
    closeModal(false);
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={dismissModal}
        className="cw-modal cw-modal--schedule-email-model"
        contentLabel="Schedule Modal"
        style={{
          overlay: {
            backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
          }
        }}
      >
        <button
          className="cw-modal--close"
          title="Close"
          onClick={dismissModal}
        >
          <i className="fas fa-times"></i>
        </button>

        <div className="d-flex email-request-more-info-modal-wrapper">
          <div className="email-request-more-info-icon send-test-email__icon">
            <RequestMoreInfoIconTransparent />
          </div>
          <div>
            <h3 className="cw-heading--senary">Request more info</h3>
            <p className="mb-10 mt-10 email-summary-font ">
              {' '}
              Please describe the information you need in detail
            </p>

            <div className="form-group d-flex requester-switch">
              <label className="switch-saas">
                <input
                  type="checkbox"
                  name={`assignBackToRequestor`}
                  checked={isActive}
                  onChange={() => {
                    setIsActive(!isActive);
                  }}
                />
                <div className="slider-saas round"></div>
              </label>
              <label className="ml-10" htmlFor={`assignBackToRequestor`}>
                <span>Assign campaign back to requestor</span>{' '}
              </label>
            </div>

            <EmailCampaignRequestInfo
              pathUid={pathUid}
              closeModal={closeModal}
              emailBriefing={emailBriefing}
              toggle={isActive}
              setToggle={setIsActive}
            />
            {/* <p className="email-type-desc-font">
              This campaign will be assigned back to the requestor so that can
              can provide the missing information and will remain in 'Awaiting
              info' state.
            </p>
            <div className="d-flex justify-content-end mt-20">
              <button
                type="button"
                onClick={closeModal}
                className="btn btn-outline-primary mb-10 cw-print-hide mr-3"
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-primary mb-10 cw-print-hide text-nowrap"
              >
                Submit
              </button>
            </div> */}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EmailRequestInfoModel;
