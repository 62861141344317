import {
  GET_SMS_OVERVIEW_DETAIL,
  SMS_SERVICE_ERROR,
  SMS_ASYNC_IN_PROGRESS
} from '../../actionTypes';

const initialState = {
  smsDetailsOverview: [],
  smsServiceError: null,
  isLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SMS_OVERVIEW_DETAIL: {
      let smsDetailsOverview = {
        smsDetailsOverview: action.smsDetailsOverview
      };
      return {
        ...state,
        ...smsDetailsOverview
      };
    }
    case SMS_SERVICE_ERROR: {
      let smsServiceError = {
        smsServiceError: action.smsServiceError
      };
      return {
        ...state,
        ...smsServiceError
      };
    }
    case SMS_ASYNC_IN_PROGRESS: {
      let isLoading = {
        isLoading: action.isLoading
      };
      return {
        ...state,
        ...isLoading
      };
    }
    default:
      return state;
  }
};
