// SMS Campaign Details Reducer
import {
  GET_SMS_CAMPAIGN_DETAILS,
  UDPATE_SMS_CAMPAIGN_DETAILS,
  GET_FETCH_SMS_CAMPAIGN_DETAILS,
  FETCH_SMS_CAMPAIGN_DETAILS_FOR_COUNTRY,
  SUBMIT_SMS_CAMPAIGN_DETAILS,
  SMS_ASYNC_IN_PROGRESS,
  SMS_SYSTEM_ERROR,
  VALIDATE_SMS_CAMPAIGN_NAME,
  RESET_SMS_CAMPAIGN_DETAILS,
  SUBMIT_SMS_CAMPAIGN_TYPE
} from '../../actionTypes';

const initialState = {
  brand: null,
  country: null,
  language: null,
  uid: null,
  smsOverview: null,
  name: null,
  targetAudience: null,
  expectedVolume: null,
  smsDetails: [
    {
      smsContent: '',
      optOutText: '',
      broadcastDate: '',
      broadcastTime: ''
    }
  ],
  dpcFlag: false,
  dpcPraReferenceNo: null,
  additionalSMSRequired: false,
  smsBrandDetails: {},
  smsBrandCampaignDetails: {},
  smsCampaignType: null,
  optOutText: null,
  isLoading: false,
  response: null,
  systemError: null,
  isSMSNameValid: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SMS_CAMPAIGN_DETAILS: {
      let smsCampaignDetails = {
        smsCampaignDetails: action.smsCampaignDetails
      };

      return {
        ...state,
        ...smsCampaignDetails
      };
    }
    case UDPATE_SMS_CAMPAIGN_DETAILS: {
      let smsOverview = action.payload;
      let name = action.payload;
      let targetAudience = action.payload;
      let expectedVolume = action.payload;
      let dpcFlag = action.payload;
      let dpcPraReferenceNo = action.payload;
      let additionalSMSRequired = action.payload;

      return {
        ...state,
        ...smsOverview,
        ...name,
        ...targetAudience,
        ...expectedVolume,
        ...dpcFlag,
        ...dpcPraReferenceNo,
        ...additionalSMSRequired
      };
    }

    case GET_FETCH_SMS_CAMPAIGN_DETAILS: {
      let brand = action.smsBrandDetails && action.smsBrandDetails.brand;
      let uid = action.smsBrandDetails && action.smsBrandDetails.uid;
      let country = action.smsBrandDetails && action.smsBrandDetails.country;
      let language = action.smsBrandDetails && action.smsBrandDetails.language;

      let smsBrandDetails = { smsBrandDetails: action.smsBrandDetails };
      return {
        ...state,
        ...smsBrandDetails,
        ...brand,
        ...uid,
        ...country,
        ...language
      };
    }

    case FETCH_SMS_CAMPAIGN_DETAILS_FOR_COUNTRY: {
      let smsBrandCampaignDetails = {
        smsBrandCampaignDetails: action.smsBrandCampaignDetails
      };

      let optOutText = {
        optOutText: action.optOutText
      };
      return {
        ...state,
        ...smsBrandCampaignDetails,
        ...optOutText
      };
    }

    case SMS_ASYNC_IN_PROGRESS: {
      let isLoading = { isLoading: action.isLoading };
      return {
        ...state,
        ...isLoading
      };
    }

    case SUBMIT_SMS_CAMPAIGN_DETAILS: {
      let response = { response: action.response };
      return {
        ...state,
        ...response
      };
    }
    case SMS_SYSTEM_ERROR: {
      let systemError = { systemError: action.err };
      return {
        ...state,
        ...systemError
      };
    }

    case VALIDATE_SMS_CAMPAIGN_NAME: {
      let isSMSNameValid = { isSMSNameValid: action.isSMSNameValid };
      return {
        ...state,
        ...isSMSNameValid
      };
    }

    case VALIDATE_SMS_CAMPAIGN_NAME: {
      let isSMSNameValid = { isSMSNameValid: action.isSMSNameValid };
      return {
        ...state,
        ...isSMSNameValid
      };
    }

    case RESET_SMS_CAMPAIGN_DETAILS: {
      return { ...initialState };
    }

    case SUBMIT_SMS_CAMPAIGN_TYPE: {
      return {
        ...state,
        smsCampaignType: action.data
      };
    }

    default:
      return state;
  }
};
