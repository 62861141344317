import React, { useEffect, useState } from 'react';
import { Input } from '../../atoms/Input';
import { FieldError } from '../../atoms/FieldError';
import { get } from 'lodash';
import classNames from 'classnames';

export const FormEmailRadioInputV2 = ({
  name,
  type,
  rules,
  register,
  label,
  errors,
  options,
  defaultValue,
  getValues,
  setValue,
  subLabel,
  ...props
}) => {
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  const [selectedValue, setSelectedValue] = useState(defaultValue || {});
  useEffect(() => {
    if (setValue && defaultValue) {
      setSelectedValue(defaultValue);
      setValue(name, defaultValue, { shouldTouch: true });
    }
  }, [defaultValue]);

  const onChange = elem => {
    setValue(name, { label: elem.label, value: elem.value });
    setSelectedValue({ label: elem.label, value: elem.value });
  };

  return (
    <div className="form-group email-secondary-theme">
      <label htmlFor={props.id}>
        {label}
        <div style={{ fontSize: 14, color: 'var(--color-grey-number)' }}>
          {subLabel
            ? subLabel
            : `We will use this info to generate cross brand inspiration as well as track content themes to email engagement`}
        </div>
      </label>
      <div className="themes-container d-flex flex-wrap">
        {options.map(
          ({
            labelClass,
            helpText,
            defaultChecked,
            imageIcon,
            isEmailView,
            description,
            ...option
          }) => (
            <label
              htmlFor={option.id}
              className={classNames('theme-item d-flex align-items-center', {
                selected: selectedValue.value === option.value
              })}
              key={option.value}
            >
              <div className="mb-0">
                <input
                  type="radio"
                  name={name}
                  {...option}
                  onChange={() => onChange(option)}
                  // defaultChecked={defaultValue === option.value}
                  checked={selectedValue.value === option.value}
                />

                <div className="label-text d-flex justify-content-center align-items-center">
                  <img
                    src={imageIcon}
                    alt={option.label}
                    className="content-theme-icon"
                  />
                  <p className="theme-label">{option.label}</p>
                </div>
              </div>
            </label>
          )
        )}
      </div>

      {!props.disableError && <FieldError errors={errors} name={name} />}
    </div>
  );
};
