import React, { useEffect, useState } from 'react';
import axios from 'axios';
import constant from '../constants';
import SearchIcon from '../images/find.png';
import FilterIcon from '../images/filters.png';
import Autocomplete from 'react-autocomplete';
import ReactTooltip from 'react-tooltip';
import { UnstyledButton } from './atoms/UnstyledButton';

const AdvancedSearch = props => {
  const {
    placeholder,
    searchEvent,
    email,
    getLabel,
    campaignConfig,
    hideToolTip,
    className,
    toggleFilters = () => {}
  } = props;
  const [selectedItem, setSelectedItem] = useState(null);
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const fetchSuggestions = input => {
    axios
      .get(
        (email
          ? constant.serviceUrls.EMAIL_BRIEFINGS
          : constant.serviceUrls.SERVICE_URL.CAMPAIGN_FIND_URL) +
          '?query=' +
          input
      )
      .then(response => {
        if (response.data) {
          const options = (email ? response.data.data.data : response.data).map(
            data => ({
              label: getLabel ? getLabel(data) : data.name,
              ...data
            })
          );
          setSuggestions([...options]);
        }
      });
  };

  const onChange = e => {
    setQuery(e.target.value);
    fetchSuggestions(e.target.value);
    setSelectedItem(null);
  };

  const onSelect = (val, item) => {
    setQuery(val);
    setSelectedItem(item);
  };

  const handleSearch = () => {
    if (email) {
      searchEvent(query);
    } else if (selectedItem || query) {
      if (selectedItem) {
        const { campaignId = '', name = '' } = selectedItem;
        searchEvent([campaignId, name]);
      } else {
        searchEvent(['', '', query]);
      }
    }
  };

  const getItemValue = item => item.label;

  useEffect(() => {
    props.value !== undefined && setQuery(props.value);
  }, [props.value]);

  return (
    <div
      className={`cw-listing d-flex flex-grow-1 align-items-center ${className}`}
    >
      {/* <img className="filter-icon" src={FilterIcon} alt="Filter icon" /> */}
      <ReactTooltip
        id="filterIconTooltip"
        place="right"
        type="info"
        className={`cw-toolip custom-cw-toolip w-auto ${
          email ? 'cw-email-tooltip' : ''
        }`}
      />
      <span data-tip="Filters" data-for="filterIconTooltip">
        <UnstyledButton
          className="filter-icon filter-icon-btn"
          onClick={toggleFilters}
        >
          <i className="fas fa-filter"></i>
        </UnstyledButton>
      </span>

      <div className="d-flex flex-grow-1 align-items-center">
        <Autocomplete
          inputProps={{
            id: 'campaignIdAutocomplete',
            className: 'search-input cross',
            placeholder:
              placeholder ||
              `Search using campaign name or ${
                campaignConfig ? campaignConfig.LABEL_SIGN_UP_ID : 'Campaign ID'
              }`,
            onKeyPress: e => {
              if (e.key === 'Enter' && (selectedItem || query)) {
                handleSearch();
              }
            }
          }}
          wrapperProps={{
            className: 'search-input-wrapper'
          }}
          autoHighlight={false}
          getItemValue={getItemValue}
          items={suggestions}
          value={query || props.value}
          onChange={onChange}
          onSelect={onSelect}
          renderMenu={SuggestionMenu}
          renderInput={props => (
            <div className="input-clear">
              <input {...props} />
              {query.trim().length > 0 && (
                <span
                  className="input-clear__cross"
                  onClick={() => {
                    onChange({ target: { value: '' } });
                  }}
                >
                  <i className="fas fa-times"></i>
                </span>
              )}
            </div>
          )}
          renderItem={SuggestionItem}
        />
        <button className="search-button" onClick={handleSearch}>
          <img src={SearchIcon} alt="Search" />
        </button>
        {hideToolTip ? (
          ''
        ) : (
          <>
            <ReactTooltip
              id="advanceSearchToolTip"
              place="right"
              type="info"
              multiline={true}
              className="cw-toolip custom-cw-toolip"
            />
            <span
              className="cw-icon cw-icon--help ml-2"
              data-tip={`Campaign IDs are now known as ${campaignConfig &&
                campaignConfig.LABEL_SIGN_UP_ID}s to better explain the purpose of the field to uniquely identify the  initiative through which a consumer signed up`}
              currentitem="false"
              data-for="advanceSearchToolTip"
            >
              <i className="fas fa-question-circle"></i>
            </span>
          </>
        )}
      </div>
    </div>
  );
};

const SuggestionItem = (item, isHighlighted) => {
  return (
    <div
      className={`suggestion-item  ${isHighlighted ? 'item-highlighted' : ''}`}
      key={item._id}
    >
      <p>{item.label}</p>
      <p>{item.campaignId}</p>
    </div>
  );
};

const SuggestionMenu = children => {
  return children && children.length ? (
    <div className="menu">{children}</div>
  ) : (
    <></>
  );
};

export default AdvancedSearch;
