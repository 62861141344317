import React, { Component } from 'react';

class PreviewServiceError extends Component {
  render() {
    return (
      <div className="col-sm-12">
        <div className="cw-section">
          <h2 className="cw-heading--secondary mb-30">
            {this.props.match.params.code}
          </h2>
          {this.props.match.params.code >= 400 &&
          this.props.match.params.code < 500 ? (
            <div>
              <p class="alert alert-danger mb-40">
                <strong>
                  Something went wrong. Please try again. If the issue persists
                  then please raise an UNA ticket.
                </strong>
              </p>{' '}
            </div>
          ) : (
            <div>
              <p class="alert alert-danger mb-40">
                <strong>
                  Service you requested is not available right now. Please try
                  again after some time and if the issue persists then please
                  raise an UNA ticket.
                </strong>
              </p>{' '}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default PreviewServiceError;
