// Masterdata Reducer
import {
  GET_CAMPAIGN,
  ASYNC_IN_PROGRESS,
  REDIRECT,
  SET_MASTERDATA,
  UDPATE_CAMPAIGN_MASTERDATA,
  UPDATE_MD_QUESTIONS,
  UPDATE_MD_ANSWERS,
  SAVE_DRAFT,
  UPDATE_BRAND_OPTION_MASTER_DATA,
  UPDATE_ADDITIONAL_OPTINS,
  UPDATE_UNILEVER_OPTION_MASTER_DATA,
  UPDATE_UNILEVER_ID_MASTER_DATA,
  RESET_CAMPAIGN_ID,
  SET_CAMPAIGNID
} from '../actionTypes';

const initialState = {
  asyncInProgress: false,
  campaignDetails: {},
  optins: [],
  brandoptin: {},
  unileveroptin: {},
  unileverid: {},
  additionalOptins: [],
  profileStore: {},
  questionAnswerList: [],
  redirect: false,
  updated: false,
  draftSaved: false,
  campaignIdUpdated: false,
  newcampaignId: ''
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CAMPAIGN: {
      let campaignSections = {
        campaign: action.campaign,
        campaignDetails: action.campaign.campaignDetail,
        optins:
          (action &&
            action.campaign &&
            action.campaign.campaignDetail &&
            action.campaign.campaignDetail.optins) ||
          [],
        brandoptin:
          (action &&
            action.campaign &&
            action.campaign.campaignDetail &&
            action.campaign.campaignDetail.optins &&
            action.campaign.campaignDetail.optins[0]) ||
          {},
        unileveroptin:
          (action.campaign.campaignDetail &&
            action.campaign.campaignDetail.optins &&
            action.campaign.campaignDetail.optins[1]) ||
          {},
        unileverid:
          (action.campaign.campaignDetail &&
            action.campaign.campaignDetail.optins &&
            action.campaign.campaignDetail.optins[2]) ||
          {},
        profileStore:
          (action.campaign.campaignDetail &&
            action.campaign.campaignDetail.profileStore) ||
          {},
        additionalOptins:
          (action.campaign.campaignDetail &&
            action.campaign.campaignDetail.additionalOptinList) ||
          [],
        questionAnswerList:
          (action.campaign.campaignDetail &&
            action.campaign.campaignDetail.questionAnswerList) ||
          [],
        isTrackingRequired:
          (action.campaign.campaignDetail &&
            action.campaign.campaignDetail.isTrackingRequired) ||
          null
      };

      let campaign = { ...campaignSections, ...action.campaign };
      return { ...state, ...campaign };
    }
    case ASYNC_IN_PROGRESS: {
      let asyncInProgress = { asyncInProgress: action.isLoading };
      return { ...state, ...asyncInProgress };
    }
    case REDIRECT: {
      let redirect = { redirect: action.redirect };
      return { ...state, ...redirect };
    }
    case SET_MASTERDATA: {
      let updated = { updated: action.updated };
      return { ...state, ...updated };
    }
    case SAVE_DRAFT: {
      let draftSaved = { draftSaved: action.draftSaved, updated: false };
      return { ...state, ...draftSaved };
    }
    case UDPATE_CAMPAIGN_MASTERDATA: {
      let updated = action.campaignDetails;
      return { ...state, ...updated };
    }
    case UPDATE_BRAND_OPTION_MASTER_DATA: {
      let updatedBrandOptin = { ...state.brandoptin, ...action.brandoptin };
      let brandoptin = { brandoptin: updatedBrandOptin };
      return { ...state, ...brandoptin };
    }
    case UPDATE_UNILEVER_OPTION_MASTER_DATA: {
      let updatedUnileverOptin = {
        ...state.unileveroptin,
        ...action.unileveroptin
      };
      let unileveroptin = { unileveroptin: updatedUnileverOptin };
      return { ...state, ...unileveroptin };
    }

    case UPDATE_UNILEVER_ID_MASTER_DATA: {
      let updatedUnileverID = {
        ...state.unileverid,
        ...action.unileverid
      };
      let unileverid = { unileverid: updatedUnileverID };
      return { ...state, ...unileverid };
    }

    case UPDATE_MD_QUESTIONS: {
      let qid = action.details.qtype ? 'translatedId' : 'id';
      return {
        ...state,
        questionAnswerList: state.questionAnswerList.map(
          (question, queindex) => {
            if (queindex === action.details.qno - 1) {
              return { ...question, [qid]: action.details.id };
            }
            return { ...question };
          }
        )
      };
    }
    case UPDATE_MD_ANSWERS: {
      let anstype = action.details.anstype ? 'translatedvalue' : 'value';
      let updatestate = {
        ...state,
        questionAnswerList: state.questionAnswerList.map(
          (question, queindex) => {
            if (queindex === action.details.qno - 1) {
              if (action.details.ano) {
                question.answers = question.answers.map((answer, ansindex) => {
                  if (ansindex === action.details.ano - 1) {
                    return { ...answer, [anstype]: action.details.id };
                  }
                  return { ...answer };
                });
              } else {
                return { ...question, id: action.details.id };
              }
            }
            return { ...question };
          }
        )
      };
      return updatestate;
    }
    case UPDATE_ADDITIONAL_OPTINS: {
      let additionalOptinList = {
        additionalOptinList: [...action.additionalOptinList]
      };
      return { ...state, ...additionalOptinList };
    }
    case RESET_CAMPAIGN_ID: {
      return { ...state, ...{ campaignIdUpdated: true } };
    }
    case SET_CAMPAIGNID: {
      return { ...state, ...{ campaignId: action.camapignId } };
    }
    default:
      return state;
  }
}
