import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormBuilder } from '../../components/organisms/FormBuilder';
import Loader from '../../components/Loader';
import formSchema from './emailCampaignScheduleSchema';
import ProgressBar from 'components/molecules/ProgressBar';
import { getEmailCampaignDetails } from 'actions/emailbriefing/emailCampaignDetails';
import { CustomButtons } from 'pages/EmailCampaignAudience';
import constants from 'constants/config';

const EmailCampaignSchedule = props => {
  const {
    isLoading,
    uid,
    deliverySchedule,
    emailType,
    abTesting
  } = useSelector(state => state.emailCampaignDetails);

  const { authorizedUserData } = useSelector(state => state.authorized);
  const emailDesign = useSelector(state => state.emailDesign);

  const dispatch = useDispatch();

  useEffect(() => {
    const pathUid = props.match.params.id;
    console.log('pathUid: ', pathUid);
    if (pathUid) {
      // fetch from email details from the server
      // dispatch(getEmailType(pathUid))
      dispatch(getEmailCampaignDetails(pathUid));
    }
  }, []);

  const compProps = {
    history: props.history,
    match: props.match,
    defaultValues: deliverySchedule || {},
    emailDesign,
    emailType,
    abTesting
  };
  return (
    <div className="col-sm-12 skypink">
      <div className="cw-section">
        {/* <BreadCrumb match={props.match} emailBriefing /> */}
        <ProgressBar
          customClass={'cw-email-progress-custom'}
          pageNo={constants.EMAIL_JOURNEY_PAGE_NUMBERS.SCHEDULE}
        />
        <div className="cw-section--title mb-30">
          <h2 className="cw-heading--secondary cw-email-heading">
            Email schedule
          </h2>
        </div>
        <div className="row">
          {isLoading ? <Loader /> : null}
          <div className="col-md-8">
            <FormBuilder
              schema={formSchema(dispatch, compProps)}
              user={authorizedUserData}
              customButtonsComp={CustomButtons}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailCampaignSchedule;
