import React, { forwardRef, useEffect, useState } from 'react';
import { Input } from 'components/atoms/Input';
import { FieldError } from 'components/atoms/FieldError';
import { get } from 'lodash';
import { useWatch, Controller } from 'react-hook-form';
import { FormDatePicker } from 'components/molecules/FormDatePicker';
import { FormReactSelect } from 'components/molecules/FormReactSelect';
import moment from 'moment';
import classNames from 'classnames';

const ABSpecificFields = ({
  control,
  watchInput,
  errors,
  name,
  dateFormat,
  register,
  setValue,
  labelClass,
  defaultValue,
  trigger
}) => {
  const defaultType = get(defaultValue, `${name}.performABTests`);
  const performAbTests = useWatch({
    control,
    name: watchInput,
    defaultValue: defaultType
  });

  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);
  const currentDate = moment();

  return performAbTests ? (
    <div className="ml-33 pt-0 cw-form-nested mb-20">
      <div className="form-group">
        <label htmlFor="abTestElement">
          A/B test elements{' '}
          <span className="email-text-font d-block">
            Only one variable should be tested at a time to attribute
            performance to the selected variable
          </span>
        </label>

        <div>
          <div className="form-check mb-10" aria-live="polite">
            <Input
              name={`${name}.abTestElements`}
              type="radio"
              {...(register && register(`${name}.abTestElements`))}
              id="subjectLine"
              value="subjectLine"
            />
            <label htmlFor="subjectLine" className="form-check-label">
              <p>Subject line</p>
            </label>
          </div>
        </div>
        <div>
          <div className="form-check mb-10" aria-live="polite">
            <Input
              name={`${name}.abTestElements`}
              {...(register && register(`${name}.abTestElements`))}
              id="emailContent"
              value="emailContent"
              type="radio"
            />
            <label htmlFor="emailContent" className="form-check-label">
              <p>Email content</p>
            </label>
          </div>
        </div>
        <FieldError errors={errors} name={`${name}.abTestElements`} />
      </div>

      <div className="form-group">
        <label htmlFor="noOfABVariants">Number of AB variants</label>
        <div>
          <div className="form-check mb-10" aria-live="polite">
            <Input
              name={`${name}.noOfABVariants`}
              type="radio"
              {...(register && register(`${name}.noOfABVariants`))}
              id="2Variants"
              value="2"
            />
            <label htmlFor="2Variants" className="form-check-label">
              <p>2 variants</p>
            </label>
          </div>
        </div>
        <div>
          <div className="form-check mb-10" aria-live="polite">
            <Input
              name={`${name}.noOfABVariants`}
              {...(register && register(`${name}.noOfABVariants`))}
              id="3Variants"
              value="3"
              type="radio"
            />
            <label htmlFor="3Variants" className="form-check-label">
              <p>3 variants</p>
            </label>
          </div>
        </div>
        <FieldError errors={errors} name={`${name}.noOfABVariants`} />
      </div>

      <div className="form-group">
        <label htmlFor="abTestSplits">AB test split</label>
        <div>
          <div className="form-check mb-10" aria-live="polite">
            <Input
              name={`${name}.abTestSplits`}
              type="radio"
              {...(register && register(`${name}.abTestSplits`))}
              id="50:50"
              value="50:50"
            />
            <label htmlFor="50:50" className="form-check-label">
              50:50
              <span className="email-text-font d-block">
                Email variants will be sent to 2 randomly selected sample splits
                from your audience
              </span>
            </label>
          </div>
        </div>
        <div>
          <div className="form-check mb-10" aria-live="polite">
            <Input
              name={`${name}.abTestSplits`}
              {...(register && register(`${name}.abTestSplits`))}
              id="10:10:80"
              value="10:10:80"
              type="radio"
            />
            <label htmlFor="10:10:80" className="form-check-label">
              10:10:80{' '}
              <span className="email-text-font d-block">
                Email variants will be sent to 2 randomly selected sample splits
                that are 10% each of your audience size. After the test
                duration, the winning variant will be sent to all the remaining
                audience
              </span>
            </label>
          </div>
        </div>
        <FieldError errors={errors} name={`${name}.abTestSplits`} />
      </div>

      <FormReactSelect
        id={'testDuration'}
        name={`${name}.testDuration`}
        label={'Test duration'}
        placeholder={'Select test duration'}
        control={control}
        errors={errors}
        defaultValue={get(defaultValue, 'testDuration')}
        options={[
          {
            label: '1 day',
            value: '1'
          },
          {
            label: '2 days',
            value: '2'
          }
        ]}
        setValue={setValue}
      />

      <div className="form-group">
        <label htmlFor="winCriteria">
          Win criteria{' '}
          <span className="email-text-font d-block">
            Which metric will determine the winning recipe?
          </span>
        </label>
        <div>
          <div className="form-check mb-10" aria-live="polite">
            <Input
              name={`${name}.winCriteria`}
              type="radio"
              {...(register && register(`${name}.winCriteria`))}
              id="openRate"
              value="openRate"
            />
            <label htmlFor="openRate" className="form-check-label">
              <p>Open rate</p>
            </label>
          </div>
        </div>
        <div>
          <div className="form-check mb-10" aria-live="polite">
            <Input
              name={`${name}.winCriteria`}
              type="radio"
              {...(register && register(`${name}.winCriteria`))}
              id="clickThroughRate"
              value="clickThroughRate"
            />
            <label htmlFor="clickThroughRate" className="form-check-label">
              <p>Click through rate</p>
            </label>
          </div>
        </div>
        <FieldError errors={errors} name={`${name}.winCriteria`} />
      </div>
    </div>
  ) : null;
};

export const SaveDisplayConditionInput = ({
  name,
  rules,
  register,
  label,
  errors,
  setValue,
  control,
  defaultValue,
  emailType,
  savedDisplayCondition,
  assignedVariants,
  ...props
}) => {
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);
  const rowDisplayConditionLabel = savedDisplayCondition.id;

  useEffect(() => {
    if (savedDisplayCondition.id) {
      setValue(`${name}`, get(savedDisplayCondition, `id`));
    }
  }, [savedDisplayCondition]);

  const checkVariantStatus = (variantOption, displayOption) => {
    if (rowDisplayConditionLabel === variantOption) {
      const rowCount = assignedVariants[variantOption].rowId.length;
      if (rowCount > 1) {
        return (
          <span className="email-text-font d-block">
            {`The current selected tile and ${rowCount - 1} other${
              rowCount > 2 ? 's are' : ' is'
            } saved as Variant ${displayOption}`}
          </span>
        );
      } else {
        return (
          <span className="email-text-font d-block">
            {`The current selected tile is saved as Variant ${displayOption}`}
          </span>
        );
      }
    }
    if (assignedVariants[variantOption].isAssigned) {
      const rowCount = assignedVariants[variantOption].rowId.length;
      if (rowCount > 1) {
        return (
          <span className="email-text-font d-block">
            {`${rowCount} other tile${rowCount >= 2 ? 's' : ''} ${
              rowCount >= 2 ? 'are' : 'is'
            } already saved as variant ${displayOption}`}
          </span>
        );
      } else {
        return (
          <span className="email-text-font d-block">
            {`Another tile is already saved as variant ${displayOption}`}
          </span>
        );
      }
    }
    return (
      <span className="email-text-font d-block">
        {`No tile is saved as variant ${displayOption}`}
      </span>
    );
  };

  return (
    <div className="form-group " aria-live="polite">
      <div>
        <div className="form-check mb-10" aria-live="polite">
          <Input
            name={`${name}`}
            type="radio"
            {...(register && register(`${name}`))}
            id="variantA"
            value="variantA"
          />
          <label htmlFor="variantA" className="form-check-label">
            <p>Variant A</p>
            {checkVariantStatus('variantA', 'A')}
          </label>
        </div>
      </div>
      <div>
        <div className="form-check mb-10" aria-live="polite">
          <Input
            name={`${name}`}
            type="radio"
            {...(register && register(`${name}`))}
            id="variantB"
            value="variantB"
          />
          <label htmlFor="variantB" className="form-check-label">
            <p>Variant B</p>
            {checkVariantStatus('variantB', 'B')}
          </label>
        </div>
      </div>
      {props.showVariantC && (
        <div>
          <div className="form-check mb-10" aria-live="polite">
            <Input
              name={`${name}`}
              type="radio"
              {...(register && register(`${name}`))}
              id="variantC"
              value="variantC"
            />
            <label htmlFor="variantC" className="form-check-label">
              <p>Variant C</p>
              {checkVariantStatus('variantC', 'C')}
            </label>
          </div>
        </div>
      )}

      <FieldError errors={errors} name={`${name}`} />
    </div>
  );
};
