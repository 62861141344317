import React, { useRef } from 'react';
import ReactTooltip from 'react-tooltip';

import ActionExportIcon from 'images/action-export.png';
import DownloadIcon from 'images/download-image.svg';

const Action = ({ id, icon, onClick, title }) => (
  <>
    <ReactTooltip
      id={id}
      place="left"
      type="info"
      multiline={true}
      className="cw-tooltip cw-email-tooltip saas_new_master_tooltip"
    />
    <button
      onClick={onClick}
      className={'action-bar__action'}
      data-tip={title}
      data-for={id}
    >
      <img src={icon} alt={title} />
    </button>
  </>
);

export const BulkActionButtons = ({ download, upload }) => {
  const fileRef = useRef();
  return (
    <>
      <input
        type="file"
        ref={fileRef}
        id="excelFileInput"
        accept=".xlsx"
        onChange={upload}
        onClick={e => (e.target.value = '')}
        className="d-none"
      />
      <Action
        icon={ActionExportIcon}
        title="Upload opt-out text"
        id="save-email"
        onClick={() => fileRef.current.click()}
      />
      <Action
        icon={DownloadIcon}
        title="Export opt-out text"
        id="export-email"
        onClick={download}
      />
    </>
  );
};
