import React from 'react';

export const EpsilonBrandCorporateConsents = ({
  optInType,
  brandOptinCCID,
  corporateOptinCCID,
  _handleBrandCCID,
  _handleCorporateOptinCCID,
  myHealthMyDataOptinCCID,
  _handleMhmdOptinCCID,
  brandSmsOptinCCID,
  _handleBrandSmsOptinCCID
}) => {
  return (
    <>
      {[
        'combinedUnileverAndBrandEmailConsent',
        'brandEmailConsent',
        ''
      ].includes(optInType) && (
        <div
          className="form-group form-group-field-width"
          key={`brandOptinCCID`}
        >
          <label htmlFor={``}>
            <span>Brand Opt-in CCID</span>
            <span className="float-right  cw-icon cw-icon--help" data-tip="">
              <i className="fas fa-question-circle"></i>
            </span>
          </label>
          <input
            type="text"
            className="form-control"
            name={`brandOptinCCID`}
            id={`brandOptinCCID`}
            aria-describedby={``}
            data-required="true"
            value={brandOptinCCID}
            onChange={_handleBrandCCID}
            maxLength={16}
          />
          <div className="email-type-desc-font mt-2">
            Please enter the master data IDs from the Campaign Template (CT) you
            received from Epsilon.
          </div>

          <span
            id={`brandOptinCCID_err`}
            className="cw-error cw-hidden"
            data-control="brandOptinCCID"
          >
            <i className="fas fa-exclamation-triangle mr-10"></i>
            Please enter Brand Opt-in CCID
          </span>
          <span
            id={`brandOptinCCID_numErr`}
            className="cw-error cw-hidden"
            data-control="languageQuestionAnsId"
          >
            <i className="fas fa-exclamation-triangle mr-10"></i>
            Please enter a numeric value
          </span>
        </div>
      )}

      {[
        'combinedUnileverAndBrandEmailConsent',
        'unileverEmailConsent'
      ].includes(optInType) && (
        <div
          className="form-group form-group-field-width"
          key={`corporateOptinCCID`}
        >
          <label htmlFor={``}>
            <span>Corporate Opt-in CCID</span>
            <span className="float-right  cw-icon cw-icon--help" data-tip="">
              <i className="fas fa-question-circle"></i>
            </span>
          </label>
          <input
            type="text"
            className="form-control"
            name={`corporateOptinCCID`}
            id={`corporateOptinCCID`}
            aria-describedby={``}
            data-required="true"
            maxLength={16}
            defaultValue={corporateOptinCCID}
            onChange={_handleCorporateOptinCCID}
          />
          <div className="email-type-desc-font mt-2">
            Please enter the master data IDs from the Campaign Template (CT) you
            received from Epsilon.
          </div>
          <span
            id={`corporateOptinCCID_err`}
            className="cw-error cw-hidden"
            data-control="corporateOptinCCID"
          >
            <i className="fas fa-exclamation-triangle mr-10"></i>
            Please enter Corporate Opt-in CCID
          </span>
          <span
            id={`corporateOptinCCID_numErr`}
            className="cw-error cw-hidden"
            data-control="languageQuestionAnsId"
          >
            <i className="fas fa-exclamation-triangle mr-10"></i>
            Please enter a numeric value
          </span>
        </div>
      )}

      {['myHealthMyDataConsent'].includes(optInType) && (
        <div
          className="form-group form-group-field-width"
          key={`myHealthMyDataOptinCCID`}
        >
          <label htmlFor={``}>
            <span>MHMD Opt-in CCID</span>
            <span className="float-right  cw-icon cw-icon--help" data-tip="">
              <i className="fas fa-question-circle"></i>
            </span>
          </label>
          <input
            type="text"
            className="form-control"
            name={`myHealthMyDataOptinCCID`}
            id={`myHealthMyDataOptinCCID`}
            aria-describedby={``}
            data-required="true"
            maxLength={16}
            defaultValue={myHealthMyDataOptinCCID}
            onChange={_handleMhmdOptinCCID}
          />
          <div className="email-type-desc-font mt-2">
            Please enter the master data IDs from the Campaign Template (CT) you
            received from Epsilon.
          </div>
          <span
            id={`myHealthMyDataOptinCCID_err`}
            className="cw-error cw-hidden"
            data-control="myHealthMyDataOptinCCID"
          >
            <i className="fas fa-exclamation-triangle mr-10"></i>
            Please enter MHMD Opt-in CCID
          </span>
          <span
            id={`myHealthMyDataOptinCCID_numErr`}
            className="cw-error cw-hidden"
            data-control="languageQuestionAnsId"
          >
            <i className="fas fa-exclamation-triangle mr-10"></i>
            Please enter a numeric value
          </span>
        </div>
      )}

      {['brandSMSConsent'].includes(optInType) && (
        <div
          className="form-group form-group-field-width"
          key={`brandSmsOptinCCID`}
        >
          <label htmlFor={``}>
            <span>Brand Opt-in CCID (SMS)</span>
            <span className="float-right  cw-icon cw-icon--help" data-tip="">
              <i className="fas fa-question-circle"></i>
            </span>
          </label>
          <input
            type="text"
            className="form-control"
            name={`brandSmsOptinCCID`}
            id={`brandSmsOptinCCID`}
            aria-describedby={``}
            data-required="true"
            maxLength={16}
            defaultValue={brandSmsOptinCCID}
            onChange={_handleBrandSmsOptinCCID}
          />
          <div className="email-type-desc-font mt-2">
            Please enter the master data IDs from the Campaign Template (CT) you
            received from Epsilon.
          </div>
          <span
            id={`brandSmsOptinCCID_err`}
            className="cw-error cw-hidden"
            data-control="brandSmsOptinCCID"
          >
            <i className="fas fa-exclamation-triangle mr-10"></i>
            Please enter Brand SMS Opt-in CCID
          </span>
          <span
            id={`brandSmsOptinCCID_numErr`}
            className="cw-error cw-hidden"
            data-control="languageQuestionAnsId"
          >
            <i className="fas fa-exclamation-triangle mr-10"></i>
            Please enter a numeric value
          </span>
        </div>
      )}
    </>
  );
};
