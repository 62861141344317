import React, { useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';

import { CustomButtons } from 'pages/EmailCampaignAudience';
import { FormBuilder } from 'components/organisms/FormBuilder';
import Loader from 'components/Loader';
import ProgressBar from 'components/molecules/ProgressBar';
import EmailCustomAudienceGuildline from './AudienceGuidelinesModal';

import formSchema from './smsAudienceSchema';
import { getEmailCampaignDetails } from 'actions/emailbriefing/emailCampaignDetails';
import { retrieveSmsCampaignInformation } from 'actions/communications/communicationCampaignDetails.js';

import constants from '../../constants/config';

const transformData = data => {
  if (isEmpty(data)) return {};

  const clonedData = { ...data };
  const sendSMSToType = get(clonedData, 'sendSMSTo.type.value');
  if (sendSMSToType && sendSMSToType === 'consumersLinkedToCampaignIds') {
    const campaignIds = get(clonedData, 'sendSMSTo.details.campaignIds');

    clonedData.sendSMSTo.details.campaignIds = campaignIds.map(
      (campaign, index) => {
        if (typeof campaign.value === 'string') return campaign;
        return { id: `field_${index + 1}`, value: campaign };
      }
    );
  }
  return clonedData;
};

const SMSAudience = props => {
  const [isEdit, setEdit] = useState(false);
  const [isCustomAudienceModel, setIsCustomAudienceModel] = useState(false);

  const communicationCampaignDetails = useSelector(
    state => state.communicationCampaignDetails
  );
  const campaignConfig = useSelector(state => state.app.campaignConfig);
  const { authorizedUserData } = useSelector(state => state.authorized);

  const dispatch = useDispatch();

  const pathUid = props.match.params.id;

  useEffect(() => {
    dispatch(getEmailCampaignDetails(pathUid));
    if (pathUid) {
      setEdit(true);
      dispatch(retrieveSmsCampaignInformation(pathUid));
    }
  }, []);

  const smsBrandDetails =
    communicationCampaignDetails &&
    communicationCampaignDetails.smsBrandDetails;

  const { isLoading } = communicationCampaignDetails;
  const { uid, audienceDefinition } = smsBrandDetails;

  if (!pathUid) {
    return (
      <Redirect to={`/campaign-wizard/${constants.SMS_ROUTES.NEW_CAMPAIGN}`} />
    );
  }

  const compProps = {
    history: props.history,
    match: props.match,
    defaultValues: transformData(audienceDefinition) || {},
    campaignConfig,
    smsBrandDetails,
    openCustomAudienceModal: () => setIsCustomAudienceModel(true)
  };

  const isSMSDataEmpty = isEmpty(smsBrandDetails);

  return (
    <div className="col-sm-12 skypink">
      <div className="cw-section">
        <ProgressBar
          customClass={'cw-email-progress-custom'}
          totalPage={constants.SMS_PROGRESS_BAR_TOT_PAGE}
          pageNo={constants.SMS_JOURNEY_PAGE_NUMBERS.AUDIENCE}
        />

        <div className="cw-section--title mb-30">
          <h2 className="cw-heading--secondary cw-email-heading">
            SMS audience
          </h2>
        </div>
        <div className="row">
          {isLoading ? <Loader /> : null}
          <div className="col-md-8">
            {!isLoading && !isSMSDataEmpty && (
              <FormBuilder
                schema={formSchema(dispatch, compProps)}
                customButtonsComp={CustomButtons}
                user={authorizedUserData}
                isEdit={isEdit}
              />
            )}
          </div>
        </div>
      </div>

      <Modal
        scrollable={true}
        isOpen={isCustomAudienceModel}
        onRequestClose={() => setIsCustomAudienceModel(false)}
        className="cw-modal cw-modal--audience-guildline"
        contentLabel="Campaign Wizard 2.0"
        style={{
          overlay: {
            backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
          }
        }}
      >
        <EmailCustomAudienceGuildline
          closeIsEditModal={() => setIsCustomAudienceModel(false)}
          data={constants.SMS_CUSTOM_AUDIENCE_GUIDELINE}
          isEmailCampaign={false}
          title={'Steps to create custom audience'}
        />
      </Modal>
    </div>
  );
};

export default SMSAudience;
