import React from 'react';
import { getSMSCostAndMoreInfoRequestOptions } from 'selectors';
import { get } from 'lodash';
import { FormReactSelect } from '../FormReactSelect';
import { Controller, useWatch } from 'react-hook-form';
import { FormLabel } from '../FormLabel';
import { FormInput } from '../FormInput';
import { FormTextArea } from '../FormTextArea';
import SmsCostFileDropZone from '../SmsCostFileDropZone';
import { Input } from 'components/atoms/Input';

const SmsCostAndRequestMoreInfoInput = ({
  control,
  watchInput,
  errors,
  name,
  dateFormat,
  register,
  setValue,
  labelClass,
  defaultValue,
  trigger,
  emailType,
  onFileUpload,
  setError,
  clearErrors
}) => {
  // const defaultType = get(defaultValue, 'type');
  const { options, defaultOption } = getSMSCostAndMoreInfoRequestOptions();
  const selectedFlow = useWatch({
    control,
    name: `${name}.selectedFlow`
  });
  const defaultType = get(defaultValue, 'smsTrigger.type');
  const assignBackToRequestor = useWatch({
    control,
    name: `${name}.assignBackToRequestor`
  });
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);
  let subLabelField = '';
  if (selectedFlow && selectedFlow.value === 'shareCostEstimate') {
    subLabelField =
      'Share an estimate of audience count and SMS costs with requestor of the campaign so that they can create a Purchase Order.';
  } else if (selectedFlow && selectedFlow.value === 'requestMoreInfo') {
    subLabelField =
      'Ask for any missing/incomplete information or corrections to the campaign.';
  }

  return (
    <div className="pt-2 mb-10">
      <FormReactSelect
        id={`${name}.selectedFlow`}
        name={`${name}.selectedFlow`}
        label={`What do you want to do`}
        placeholder={'Please select'}
        control={control}
        errors={errors}
        defaultValue={defaultOption}
        options={options}
        setValue={setValue}
        isCustomOption
        isInModal={true}
      />
      <FormLabel
        id={`smsCostRequestInfoSubLable`}
        label={subLabelField}
        key={`smsCostRequestInfoSubLable`}
        className={`mt-n2 email-type-desc-font`}
      />

      {selectedFlow?.value === 'shareCostEstimate' && (
        <>
          <FormInput
            id={`${name}.audienceCount`}
            type="number"
            name={`${name}.audienceCount`}
            label="Audience count"
            key="audienceCount"
            register={register}
            errors={errors}
            defaultValue={get(defaultValue, `${name}.audienceCount`)}
            setValue={setValue}
            disabled={defaultType === 'dynamicTrigger'}
          />
          {defaultType === 'dynamicTrigger' ? (
            <FormLabel
              id={`audienceCountInfo`}
              label={'Not available for SMS with dynamic triggers.'}
              key={`audienceCountInfo`}
              className={`mt-n2 email-type-desc-font`}
            />
          ) : null}

          <Controller
            id={`${name}.acsCostFile`}
            name={`${name}.acsCostFile`}
            label={`Upload cost estimate`}
            control={control}
            errors={errors}
            setValue={setValue}
            //defaultValue={defaultValue}
            render={({ field }) => (
              <SmsCostFileDropZone
                {...field}
                label={`Upload cost estimate`}
                onFileUpload={onFileUpload}
                errors={errors}
                setError={setError}
                clearErrors={clearErrors}
              />
            )}
          />
          <div className="form-group " aria-live="polite">
            <label
              htmlFor={`${name}.additionalcomments`}
            >{`Additional comments`}</label>
            <div>
              <FormTextArea
                errors={errors}
                name={`${name}.additionalcomments`}
                setValue={setValue}
                register={register}
                placeholder={'Please explain the reason'}
              />
              <FormLabel
                id={`additionalcommentsInfo`}
                label={
                  'Please use this field to request any changes to campaign or provide additional info.'
                }
                key={`additionalcommentsInfo`}
                className={`mt-n2 email-type-desc-font`}
              />
              <FormLabel
                id={`additionalcommentsInfo1`}
                label={
                  'The campaign requestor will be notified of the cost estimates via an email.'
                }
                key={`additionalcommentsInfo1`}
                className={`mt-n2 email-type-desc-font`}
              />
            </div>
          </div>
        </>
      )}
      {selectedFlow?.value === 'requestMoreInfo' && (
        <div>
          <p className="mb-10 mt-10 email-summary-font ">
            {' '}
            Please describe the information you need in detail
          </p>
          <div className="form-group d-flex mt-10 mb-1">
            <label className="switch-saas">
              <Controller
                control={control}
                name={`${name}.assignBackToRequestor`}
                defaultValue={false}
                render={({ field: { onChange, onBlur, value, ref, name } }) => (
                  <Input
                    ref={ref}
                    name={name}
                    checked={value}
                    type="checkbox"
                    id="assignBackToRequestor"
                    onChange={e => {
                      onChange(e);
                    }}
                  />
                )}
              />
              <div className="slider-saas round"></div>
            </label>
            <label className="ml-20" htmlFor={`${name}.assignBackToRequestor`}>
              <span>Assign campaign back to requestor</span>{' '}
            </label>
          </div>
          <div className="form-group " aria-live="polite">
            <label
              htmlFor={`${name}.requestInfoText`}
            >{`Describe your issue`}</label>
            <div>
              <FormTextArea
                errors={errors}
                name={`${name}.requestInfoText`}
                register={register}
                rows={'5'}
                setValue={setValue}
                placeholder="Please describe your issue here"
              />
              <FormLabel
                id={`assignBackToRequestorLable`}
                label={
                  assignBackToRequestor
                    ? "This campaign will be assigned back to the requestor so that they can provide the missing information and will remain in 'Awaiting info' state."
                    : 'The campaign will remain assigned to ACS team in Awaiting info state, while they wait for information to be received by contributors.'
                }
                key={`assignBackToRequestorLable`}
                className={`mt-n2 email-type-desc-font`}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SmsCostAndRequestMoreInfoInput;
