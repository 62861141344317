import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { get } from 'lodash';

import { Input } from '../../atoms/Input';
import EmailCouponGuidelines from 'components/molecules/EmailCouponGuidelines';
import { CouponFields } from './CouponFields';

export const EmailAttributes = ({
  name,
  rules,
  register,
  label,
  errors,
  setValue,
  control,
  defaultValue,
  emailType,
  ...props
}) => {
  const [isGuidelinesModalOpen, setIsGuidelinesModalOpen] = useState(false);

  useEffect(() => {
    if (defaultValue && defaultValue.emailAttributes) {
      setValue(
        `${name}.emailCoupon`,
        get(defaultValue, `${name}.emailCoupon.couponApplied`)
      );
      setValue(
        `${name}.emailCouponType`,
        get(defaultValue, `${name}.emailCoupon.type`)
      );
      setValue(
        `${name}.couponCode`,
        get(defaultValue, `${name}.emailCoupon.code`)
      );
      setValue(
        `${name}.kanaTicketNumber`,
        get(defaultValue, `${name}.emailCoupon.kanaTicketNumber`)
      );
      setValue(
        `${name}.shareCouponFileViaKana`,
        get(defaultValue, `${name}.emailCoupon.shareCouponFileViaKana`)
      );
      setValue(
        `${name}.couponFileUpload`,
        get(defaultValue, `${name}.emailCoupon.couponFileUpload`)
      );
    }
  }, [defaultValue?.emailAttributes]);

  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  const handleGuidelinesModal = e => {
    e.preventDefault();
    setIsGuidelinesModalOpen(true);
  };

  return (
    <div className="form-group " aria-live="polite">
      <label htmlFor={props.id}>
        <strong>{label}</strong>
      </label>
      <div>
        <div className="form-group d-flex mt-20 mb-1">
          <label className="switch-saas">
            <Controller
              control={control}
              name={`${name}.emailCoupon`}
              defaultValue={get(
                defaultValue,
                `${name}.emailCoupon.couponApplied`
              )}
              render={({ field: { onChange, onBlur, value, ref, name } }) => (
                <Input
                  ref={ref}
                  name={name}
                  checked={value}
                  type="checkbox"
                  id="emailCoupon"
                  onChange={e => {
                    onChange(e);
                  }}
                />
              )}
            />
            <div className="slider-saas round"></div>
          </label>
          <label className="ml-20" htmlFor={`emailCoupon`}>
            Include coupons in email
          </label>
        </div>

        <p className="email-type-desc-font mb-3">
          You will be required to provide your own coupon codes that your
          recipient consumers will be able to redeem on your preferred retailer
          channels.&nbsp;
          <button
            className="btn btn-primary-link"
            onClick={handleGuidelinesModal}
          >
            View coupon guidelines
          </button>
        </p>

        <CouponFields
          control={control}
          errors={errors}
          defaultValue={defaultValue}
          rules={rules}
          trigger={props.trigger}
          setValue={setValue}
          name={name}
          register={register}
          watchInput={[
            `${name}.emailCoupon`,
            `${name}.emailCouponType`,
            `${name}.couponCode`,
            `${name}.kanaTicketNumber`,
            `${name}.couponFileUpload`,
            `${name}.shareCouponFileViaKana`
          ]}
          toggleShowDynamicCouponAlert={props.toggleShowDynamicCouponAlert}
          setError={props.setError}
          clearErrors={props.clearErrors}
          user={props.user}
        />

        <EmailCouponGuidelines
          isOpen={isGuidelinesModalOpen}
          handleCloseForm={() => setIsGuidelinesModalOpen(false)}
        />
      </div>
    </div>
  );
};
