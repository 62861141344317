import React from 'react';

export const PageRestrict = ({ uid }) => {
  let url = '';
  if (
    (uid && window.location.pathname.includes('/new-email-campaign')) ||
    window.location.pathname.includes('/emailbriefing/')
  ) {
    url = `/campaign-wizard/emailbriefing/${uid}`;
  } else if (uid && window.location.pathname.includes('/campaign/')) {
    url = `/campaign-wizard/campaign/${uid}`;
  } else if (uid && window.location.pathname.includes('/communications/')) {
    url = `/campaign-wizard/communications/${uid}`;
  }

  return (
    <div className="cw-section">
      <h2 className="cw-heading--secondary mb-30">Page not found</h2>
      <p>
        Sorry, you cannot access this page. This may be due to the following
        reasons
      </p>
      <ul className="ml-33 mt-10">
        <li>You do not have access to this page</li>
        <li>You do not have roles and permissions to view this page</li>
        <li>Page is invalid</li>
        <li>Page has been deleted</li>
      </ul>
      {uid ? (
        <button className="btn btn-primary mt-20 mr-10">
          <a href={url} className="text-white">
            View campaign
          </a>
        </button>
      ) : (
        ''
      )}
      <button className="btn btn btn-outline-secondary mt-20">
        <a href="/campaign-wizard">Back to home</a>
      </button>
    </div>
  );
};
