import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Modal from 'react-modal';
import classNames from 'classnames';
import {
  getBrandCountries,
  getBrandByCountry,
  getBrandDetails,
  getProfileStore,
  updateBrandDetails,
  setProfileStoreType,
  resetProfileStoreInProgress,
  autoPopulateWebsiteType,
  resetFieldsOnLanguageChange,
  resetFieldsOnCountryChange,
  resetQnAConfirm
} from '../actions/brandDetails';
import {
  editedCampaignDetails,
  fetchEditedCampaignLogs,
  saveEditedCampaignLogs
} from '../actions/app';
import { removeConsentDataFromCampaign } from '../actions/campaignDetails';
import Loader from './Loader';
import ErrorNotification from './errorNotification';
import { scrollToTop } from '../selectors';
import BreadCrumb from './BreadCrumb';
import constant from '../constants';
import constants from 'constants/config';
import Alert from './Alert';
import BrandDetailsMessage from './BrandDetailsMessage';
import { epsilonMarket } from '../selectors';
import ReactTooltip from 'react-tooltip';
class BrandDetails extends Component {
  constructor(props) {
    super(props);
    this.isValid = false;
    this.state = {
      isModalOpen: false,
      isNewModalOpen: false,
      formSubmission: false,
      showMessageAtTop: false,
      matchProps: props.match
    };
    this.errorFields = [];
    this.updated = false;
    this._openModal = this._openModal.bind(this);
    this._closeModal = this._closeModal.bind(this);
    this._openNewModal = this._openNewModal.bind(this);
    this._closeNewModal = this._closeNewModal.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this._handleBrand = this._handleBrand.bind(this);
    this._handleCountries = this._handleCountries.bind(this);
    this._handleLanguages = this._handleLanguages.bind(this);
    this._handleWebsiteType = this._handleWebsiteType.bind(this);
    //this._handleWebsiteExtension = this._handleWebsiteExtension.bind(this);
    // REfs
    this.screenTop = React.createRef();
    this.errorMessage = React.createRef();
  }

  isNewLanguage = false;
  isNewCountry = false;
  isNewWebsiteType = false;
  componentDidMount() {
    this.UUID = this.props.match.params.id;
    this.props.getBrandCountries(this.UUID);
    this.props.getBrandDetails(this.UUID);
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.isCampaignEdited &&
      this.props.isCampaignEdited !== prevProps.isCampaignEdited
    ) {
      this.props.fetchEditedCampaignLogs(this.UUID);
    }
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.countryDataLoaded) {
      if (nextProps.brand && nextProps.country) {
        this.props.getBrandByCountry(
          nextProps.country.value + '-' + nextProps.countryId,
          this.UUID
        );
      }
    }
    if (nextProps.profileStoreExist && this.updated) {
      this.props.setProfileStoreType(
        nextProps.profileStoreType,
        this.UUID,
        this.props.userEmail
      );
      this.props.history.push(
        '/campaign-wizard/campaign/' + this.UUID + '/type'
      );
    }
  }

  _openModal(event) {
    event.preventDefault();
    this.setState({
      isModalOpen: true
    });
  }
  _openNewModal(event) {
    event.preventDefault();
    this.setState({
      isNewModalOpen: true
    });
  }

  _closeModal() {
    this.setState({
      isModalOpen: false
    });
  }
  _closeNewModal() {
    this.setState({
      isNewModalOpen: false
    });
  }

  _getBrandSelected(brandVal) {
    return brandVal;
  }
  _getCountrySelected(countryVal) {
    return countryVal;
  }
  _getLanguageSelected(languageVal) {
    return languageVal;
  }

  _autopopulateWebsiteType(brandValue) {
    let { country, language, brand, autopopulateWebsiteType } = this.props;
    let marketCode = country && country.value.split('-')[0];
    let brandID =
      this._getBrandSelected(brandValue) &&
      this._getBrandSelected(brandValue).value.split('-')[0];
    let languageCode = language && language.value.split('-')[0];
    this.props.autoPopulateWebsiteType(marketCode, brandID, languageCode);
    //this.props.updateBrandDetails({ websiteType: autopopulateWebsiteType });
  }
  _handleBrand(selectedOption) {
    const { removeConsentDataFromCampaign, match } = this.props;
    const campaignUid = match.params.id;
    this.props.resetProfileStoreInProgress();
    this.props.updateBrandDetails({ brand: selectedOption });
    this._validateField('brand');
    let countrySelected =
      this.props.country && this.props.country.value.split('-')[0];
    if (campaignUid) {
      removeConsentDataFromCampaign(campaignUid);
    }
    this._getBrandSelected(selectedOption);
    document.getElementById('brand').style.border = 'none';
  }

  /*_validateAllField() {
    let formElements = {
      country: this.props.country,
      brand: this.props.brand,
      language: this.props.language,
      websiteType: this.props.websiteType,
    };
    for (var key in formElements) {
      if (formElements.hasOwnProperty(key)) {
        if (formElements[key] !== null && formElements[key] !== undefined) {
          if (
            document.querySelector("[name='" + key + "']") &&
            document
              .querySelector("[name='" + key + "']")
              .parentElement.classList.contains("error")
          ) {
            document
              .querySelector("[name='" + key + "']")
              .parentElement.classList.remove("error");
          }
          if (
            !document
              .querySelector("[data-control='" + key + "']")
              .classList.contains("cw-hidden")
          ) {
            document
              .querySelector("[data-control='" + key + "']")
              .classList.add("cw-hidden");
          }

          document
            .getElementById(`${key}`)
            .classList.contains("cw-error-focus") &&
            document.getElementById(`${key}`).classList.remove("cw-error-focus");
          this.errorFields = this._filterErrorFields(this.errorFields, key);
        } else {
          if (
            document.querySelector("[name='" + key + "']") &&
            !document
              .querySelector("[name='" + key + "']")
              .parentElement.classList.contains("error")
          ) {
            document
              .querySelector("[name='" + key + "']")
              .parentElement.classList.add("error");
          }
          if (
            document.querySelector("[data-control='" + key + "']") &&
            document
              .querySelector("[data-control='" + key + "']")
              .classList.contains("cw-hidden")
          ) {
            document
              .querySelector("[data-control='" + key + "']")
              .classList.remove("cw-hidden");
          }

          
          document
            .getElementById(`${key}`)
            .classList.contains("cw-error-focus") ||
            document.getElementById(`${key}`).classList.add("cw-error-focus");

          this.errorFields = this._addErrorFields(this.errorFields, key);
        }
      }
    }
  }*/

  _handleCountries(selectedOption) {
    const { removeConsentDataFromCampaign, match } = this.props;
    const campaignUid = match.params.id;
    let newCountry = selectedOption && selectedOption.label;
    let oldCountry =
      this.props && this.props.country && this.props.country.label;
    this.isNewCountry = newCountry !== oldCountry ? true : false;
    this.props.resetProfileStoreInProgress();
    this.props.updateBrandDetails({ country: selectedOption });
    this._validateField('country');
    this.props.getBrandByCountry(selectedOption.value);
    this._getCountrySelected(selectedOption.value);
    // this._handleWebsiteType(selectedOption)
    let countrySelected = selectedOption && selectedOption.value.split('-')[0];
    let { epsilonTypeSites } = this.props;
    let adobeSaas = {
      label: epsilonTypeSites && epsilonTypeSites[0].name,
      value: 'undefined-' + (epsilonTypeSites && epsilonTypeSites[0]._id)
    };
    if (epsilonMarket(countrySelected)) {
      this.props.updateBrandDetails({ websiteType: adobeSaas });
      this.props.editedCampaignDetails({ websiteType: adobeSaas.value });
    }
    if (campaignUid) {
      removeConsentDataFromCampaign(campaignUid);
    }
  }

  _handleLanguages(selectedOption) {
    const { removeConsentDataFromCampaign, match } = this.props;
    const campaignUid = match.params.id;
    let newLang = selectedOption && selectedOption.label;
    let oldLanguage =
      this.props &&
      this.props.existingLanguage &&
      this.props.existingLanguage.label;
    this.isNewLanguage = newLang !== oldLanguage ? true : false;

    this.props.resetProfileStoreInProgress();
    this.props.updateBrandDetails({ language: selectedOption });
    this._validateField('language');
    this._getLanguageSelected(selectedOption);
    document.getElementById('language').style.border = 'none';
    if (campaignUid) {
      removeConsentDataFromCampaign(campaignUid);
    }
  }

  _handleWebsiteType(selectedOption) {
    let newWebsiteType = selectedOption && selectedOption.label;
    let oldWebsiteType =
      this.props &&
      this.props.existingWebsiteType &&
      this.props.existingWebsiteType.label;
    this.isNewWebsiteType = newWebsiteType !== oldWebsiteType ? true : false;
    this.props.resetProfileStoreInProgress();
    this.props.updateBrandDetails({ websiteType: selectedOption });
    this._validateField('websiteType');
    this.props.editedCampaignDetails({ websiteType: selectedOption.value });
    document.getElementById('websiteType').style.border = 'none';
  }

  _generateUUID() {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(
      c
    ) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid.split('-')[0];
  }

  _handleSubmit(event) {
    let { getProfileStore } = this.props;
    event.preventDefault();
    this._validateForm();
    if (this.isValid && !this.props.existingCampaign) {
      this.UUID = this._generateUUID();
      getProfileStore(
        this.props.brand,
        this.props.country,
        this.props.language,
        this.props.websiteType,
        this.props.websiteExtension,
        this.UUID
      );
      this.updated = true;
      scrollToTop();
    }
    if (this.isValid && this.props.existingCampaign) {
      this.updated = true;
      getProfileStore(
        this.props.brand,
        this.props.country,
        this.props.language,
        this.props.websiteType,
        this.props.websiteExtension,
        this.UUID
      );
      if (
        this.isNewLanguage
        // (this.isNewLanguage &&
        //   this.props.websiteType &&
        //   this.props.websiteType.label === "Adobe SaaS") ||
        // this.props.websiteType.label !== "Adobe SaaS"
      ) {
        this.props.resetFieldsOnLanguageChange(this.UUID);
      }
      if (
        this.isNewCountry
        //   &&
        //   this.props.websiteType &&
        //   this.props.websiteType.label === "Adobe SaaS") ||
        // this.props.websiteType.label !== "Adobe SaaS"
      ) {
        this.props.resetFieldsOnCountryChange(this.UUID);
      }
      if (this.isNewWebsiteType) {
        this.props.resetQnAConfirm(this.UUID);
      }
    }

    if (this.props.isCampaignEdited || this.props.editCampaign) {
      this.props.saveEditedCampaignLogs(
        this.UUID,
        this.props.editCampaignDetails
      );
    }
  }

  _validateField(field) {
    document.getElementById(`${field}`).classList.contains('cw-error-focus') &&
      document.getElementById(`${field}`).classList.remove('cw-error-focus');

    if (
      document
        .querySelector("[name='" + field + "']")
        .parentElement.classList.contains('error')
    ) {
      document
        .querySelector("[name='" + field + "']")
        .parentElement.classList.remove('error');
    }
    if (
      !document
        .querySelector("[data-control='" + field + "']")
        .classList.contains('cw-hidden')
    ) {
      document
        .querySelector("[data-control='" + field + "']")
        .classList.add('cw-hidden');
    }
  }

  _validateForm() {
    let formElements = {
      country: this.props.country,
      brand: this.props.brand,
      language: this.props.language,
      websiteType: this.props.websiteType
    };
    for (var key in formElements) {
      if (formElements.hasOwnProperty(key)) {
        if (formElements[key] !== null && formElements[key] !== undefined) {
          if (
            document.querySelector("[name='" + key + "']") &&
            document
              .querySelector("[name='" + key + "']")
              .parentElement.classList.contains('error')
          ) {
            document
              .querySelector("[name='" + key + "']")
              .parentElement.classList.remove('error');
          }
          if (
            !document
              .querySelector("[data-control='" + key + "']")
              .classList.contains('cw-hidden')
          ) {
            document
              .querySelector("[data-control='" + key + "']")
              .classList.add('cw-hidden');
          }

          document
            .getElementById(`${key}`)
            .classList.contains('cw-error-focus') &&
            document
              .getElementById(`${key}`)
              .classList.remove('cw-error-focus');
          this.errorFields = this._filterErrorFields(this.errorFields, key);
        } else {
          if (
            document.querySelector("[name='" + key + "']") &&
            !document
              .querySelector("[name='" + key + "']")
              .parentElement.classList.contains('error')
          ) {
            document
              .querySelector("[name='" + key + "']")
              .parentElement.classList.add('error');
          }
          if (
            document.querySelector("[data-control='" + key + "']") &&
            document
              .querySelector("[data-control='" + key + "']")
              .classList.contains('cw-hidden')
          ) {
            document
              .querySelector("[data-control='" + key + "']")
              .classList.remove('cw-hidden');
          }

          document
            .getElementById(`${key}`)
            .classList.contains('cw-error-focus') ||
            document.getElementById(`${key}`).classList.add('cw-error-focus');

          this.errorFields = this._addErrorFields(this.errorFields, key);
        }
      }
    }

    if (this.errorFields.length === 0) {
      this.isValid = true;
      this.setState({ showMessageAtTop: false });
    } else {
      this.setState({ showMessageAtTop: true });
      scrollToTop();
    }
  }

  _addErrorFields(array, itemToAdd) {
    return array.filter(field => field !== itemToAdd).concat([itemToAdd]);
  }

  _filterErrorFields(array, itemToRemove) {
    return array.filter(item => item !== itemToRemove);
  }

  _getBrands() {
    let { brands } = this.props;
    if (Object.keys(brands).length > 0) {
      return brands.map(function(brand) {
        return { value: brand.code + '-' + brand._id, label: brand.name };
      });
    }
  }

  _getCountries() {
    let { countries } = this.props;
    if (Object.keys(countries).length > 0) {
      return countries.map(function(brand) {
        return { value: brand.code + '-' + brand._id, label: brand.name };
      });
    }
  }

  _getLanguages() {
    let { languages } = this.props;
    if (Object.keys(languages).length > 0) {
      return languages.map(function(brand) {
        return { value: brand.code + '-' + brand._id, label: brand.name };
      });
    }
  }

  _getWebsiteTypes(isEpsilonMarket) {
    let { websiteTypes, epsilonTypeSites } = this.props;
    if (isEpsilonMarket) {
      if (Object.keys(epsilonTypeSites).length > 0) {
        return epsilonTypeSites.map(function(brand) {
          return { value: brand.code + '-' + brand._id, label: brand.name };
        });
      }
    } else {
      if (Object.keys(websiteTypes).length > 0) {
        return websiteTypes.map(function(brand) {
          return { value: brand.code + '-' + brand._id, label: brand.name };
        });
      }
    }
  }

  render() {
    let {
      country,
      brand,
      language,
      websiteType,
      autopopulateWebsiteType,
      epsilonTypeSites
    } = this.props;

    let {
      profileStoreInProgress,
      profileStoreExist,
      profileStoreError
    } = this.props;

    let newLang =
      this.props && this.props.language && this.props.language.label;
    let oldLanguage =
      this.props &&
      this.props.existingLanguage &&
      this.props.existingLanguage.label;
    this.isNewLanguage = newLang !== oldLanguage ? true : false;

    let newWebsiteType =
      this.props && this.props.websiteType && this.props.websiteType.label;
    let oldWebsiteType =
      this.props &&
      this.props.existingWebsiteType &&
      this.props.existingWebsiteType.label;
    this.isNewWebsiteType = newWebsiteType !== oldWebsiteType ? true : false;
    let websiteTypeIsSaas =
      this.props.websiteType && this.props.websiteType.label === 'Adobe SaaS';
    let saasMessageClass = classNames({
      'cw-hidden': !websiteTypeIsSaas
    });
    let countrySelected =
      this.props.country && this.props.country.value.split('-')[0];

    Modal.setAppElement('#root');
    return (
      <>
        {profileStoreInProgress ? <Loader /> : null}
        <div className="col-sm-12">
          <div className="cw-section ml-20" ref={this.screenTop}>
            <BreadCrumb
              match={this.state.matchProps}
              editCampaign={
                this.props.editCampaign || this.props.isCampaignEdited
              }
            />
            {profileStoreExist !== null && profileStoreExist === false ? (
              <div className="alert alert-danger" role="alert">
                <p>
                  <strong>Profile store does not exist.</strong> You cannot run
                  1-1 campaigns as Unilever cloud has not yet been rolled out to
                  your brand and country. For more information contact your
                  local PRM representative.
                </p>
              </div>
            ) : null}
            {/* TODO: Error message placeholder */}
            {!this.isValid && this.state.showMessageAtTop ? (
              <ErrorNotification />
            ) : null}
            {profileStoreError !== null ? (
              <div className="alert alert-danger" role="alert">
                <strong>There was a problem with the last request.</strong>
                <p>Error code:{profileStoreError} </p>
                <p>Please contact Gigya team for assistance.</p>
              </div>
            ) : null}

            {/* Alert message for editing of campaign */}
            <Alert
              type={'warning'}
              alertType={'nonEditableBrandDetails'}
              message={
                'To change country, brand or language please create a new campaign.'
              }
              showComponent={this.props.editCampaign}
            />

            {/* <Alert
              type={'warning'}
              alertType={'isWebsiteTypeAutopoulated'}
              showComponent={
                this.props.country &&
                this.props.brand &&
                this.props.language &&
                this.props.websiteType !== undefined &&
                this.props.autopopulateWebsiteType &&
                this.props.autopopulateWebsiteType.label === 'Adobe Classic' &&
                this.props.websiteCount !== undefined &&
                this.props.websiteCount === 1
              }
            /> */}

            <Alert
              type={'warning'}
              alertType={'isWebsiteTypeATAutopoulated'}
              showComponent={
                this.props.country &&
                this.props.brand &&
                this.props.language &&
                this.props.websiteType !== undefined &&
                this.props.autopopulateWebsiteType &&
                this.props.autopopulateWebsiteType.label ===
                  'Adobe Templated' &&
                this.props.websiteCount !== undefined &&
                this.props.websiteCount === 1
              }
            />

            <Alert
              type={'warning'}
              alertType={'isACATWebsiteTypeExists'}
              showComponent={
                this.props.websiteCount !== undefined &&
                this.props.websiteCount > 1
              }
            />

            <div className="cw-section--title mb-30">
              <h2 className="cw-heading--secondary">Enter Brand Details</h2>
              <p>
                Your brand details to ensure you can request a campaign using
                this service.
              </p>
              <p>
                <a
                  href="#modal"
                  onClick={this._openModal}
                  title="Can't find your brand?"
                >
                  Can't find your brand or country?
                </a>
              </p>
            </div>
            <div className="row">
              {this.props.isLoading ? <Loader /> : null}
              <div className="col-sm-7">
                <div className="cw-section--content">
                  <form
                    method="GET"
                    action=""
                    className="cw-form"
                    onSubmit={this._handleSubmit}
                  >
                    <div className="form-group">
                      <label htmlFor="country">Country</label>
                      <Select
                        className={'cw-select--custom'}
                        name={'country'}
                        label={'Country'}
                        id={'country'}
                        placeholder={'Select option'}
                        options={this._getCountries()}
                        onChange={this._handleCountries}
                        isDisabled={
                          !this.props.countriesLoaded || this.props.editCampaign
                        }
                        isLoading={!this.props.countriesLoaded}
                        value={
                          this.props.country
                            ? {
                                label: this.props.country.label,
                                value: this.props.country.value
                              }
                            : null
                        }
                      />
                      <span
                        className="cw-error cw-hidden"
                        data-control="country"
                      >
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Please select country.
                      </span>
                    </div>

                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Brand Name</label>
                      <Select
                        className={'cw-select--custom'}
                        name={'brand'}
                        label={'Brand'}
                        id={'brand'}
                        placeholder={'Select option'}
                        options={this._getBrands()}
                        onChange={this._handleBrand}
                        isDisabled={
                          !this.props.country || this.props.editCampaign
                        }
                        isLoading={profileStoreInProgress}
                        value={
                          this.props.brand
                            ? {
                                label: this.props.brand.label,
                                value: this.props.brand.value
                              }
                            : null
                        }
                      />
                      <span className="cw-error cw-hidden" data-control="brand">
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Please select brand.
                      </span>
                    </div>
                    <div className="form-group">
                      <label htmlFor="language">Language</label>
                      <Select
                        className={'cw-select--custom'}
                        name={'language'}
                        label={'Language'}
                        id={'language'}
                        placeholder={'Select option'}
                        options={this._getLanguages()}
                        onChange={this._handleLanguages}
                        isDisabled={
                          !this.props.country || this.props.editCampaign
                        }
                        isLoading={profileStoreInProgress}
                        value={
                          this.props.language
                            ? {
                                label: this.props.language.label,
                                value: this.props.language.value
                              }
                            : this.props.countrylanguage
                        }
                      />
                      <span
                        className="cw-error cw-hidden"
                        id={'language'}
                        data-control="language"
                      >
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Please select language.
                      </span>
                    </div>
                    <div className="form-group">
                      <label htmlFor="language">Website type</label>
                      {/* <Alert
                        alertType="saasUpdateKnownmore"
                        textMessage={
                          constant.SAAS_MESSAGES.brandDetailsInfoMessage
                        }
                        openModal={this._openNewModal}
                        isKnowMore={true}
                        modelText={'Know more'}
                      /> */}
                      <Select
                        className={'cw-select--custom'}
                        name={'websiteType'}
                        label={'Website type'}
                        id="websiteType"
                        placeholder={'Select option'}
                        options={this._getWebsiteTypes(
                          epsilonMarket(countrySelected)
                        )}
                        onChange={this._handleWebsiteType}
                        isDisabled={!this.props.country}
                        isLoading={profileStoreInProgress}
                        value={
                          this.props.websiteType &&
                          this.props.websiteType.label !== undefined
                            ? {
                                label: this.props.websiteType.label,
                                value: this.props.websiteType.value
                              }
                            : null
                        }
                      />
                      <span
                        className="cw-error cw-hidden"
                        data-control="websiteType"
                      >
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Please select website type.
                      </span>
                    </div>
                    {/*<div className={`mb-15 ${saasMessageClass}`}>
                    <ReactTooltip
                                  place="right"
                                  type="info"
                                  multiline={true}
                                  className="cw-tooltip"
                                />
                      <div className="alert alert-warning" style={{    lineHeight: "1.5em"}}>
                        <p>
                            Temporarily, a {<a 
                            href={constants.KANA_URL}
                            target="_blank"
                            rel="noopener noreferrer">
                            <u>UNA</u>&nbsp;<i className="fas fa-external-link-alt"></i></a>} 
                            &nbsp;ticket needs to be raised to enable data collection V3 API to accept consumer data from the website which will contain your sign-up form. This is a mandatory step, required once per website (URL) – brand x market combination.
                            &nbsp;<span
                                className="cw-icon cw-icon--help"
                                data-tip={constants.BRAND_DETAILS_TOOLTIP_TEXT}
                                currentitem="false"
                              >
                              <i className="fas fa-info-circle"></i>
                            </span>
                        </p>
                      </div>
                      
                            </div>*/}
                    <div>
                      <button type="submit" className="btn btn-primary">
                        Next
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-sm-4 offset-md-1">
                <div className="brand-detail-alert">
                  <Alert
                    alertType="saasUpdateKnownmore"
                    textMessage={constant.SAAS_MESSAGES.newBrandDetailsMessage}
                    className={true}
                    // openModal={this._openNewModal}
                    isKnowMore={true}
                    modelText={'Know more'}
                  />
                </div>
              </div>
            </div>

            <Modal
              isOpen={this.state.isModalOpen}
              onRequestClose={this._closeModal}
              className="cw-modal cw-modal--branddetail"
              contentLabel="Can't find your brand?"
              style={{
                overlay: {
                  backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
                }
              }}
            >
              <button
                className="cw-modal--close"
                title="Close"
                onClick={this._closeModal}
              >
                <i className="fas fa-times"></i>
              </button>
              <h3 className="cw-heading--primary mb-20">
                Can't find your brand or country?
              </h3>
              <p className="mb-10">
                If you are an active brand and market combination, you should
                find yourself listed in our tool.
                <br /> If you can’t find your brand or market, raise an
                <a href={constant.KANA_URL} target="_blank" rel="noreferrer">
                  {` UNA ticket `}
                  <i className="fas fa-external-link-alt"></i>
                </a>
              </p>
              {/* <p className="branddetails_items_list mb-10">
                <strong>Step 1:</strong> Send an email to &nbsp;
                <a
                  href={
                    'mailto:MRD.ES-MDE@unilever.com?Subject=' +
                    encodeURIComponent(
                      constant.EMAIL_SUBJECT_BRAND_COUNTRY_CREATION
                    )
                  }
                >
                  MRD.ES-MDE@unilever.com
                </a>
                &nbsp; for adding your brand and country to the Brand POS rules.
                The following details should be provided
                <ul className="ml-20">
                  <li>Brand name and brand code (if available)</li>
                  <li>Country</li>
                  <li>Reasons on why this is needed, if and as applicable</li>
                </ul>
              </p>
              <p className="mb-10">
                <strong>Step 2:</strong> Once Brand POS rules are created, raise
                an UNA ticket against the category “GIGYA sign up not working”
                and assign it to &nbsp; <br />
                <a
                  href={
                    'https://udhd.service-now.com/sys_user_group.do?sys_id=dda981c8db20945095e53445f39619ed'
                  }
                  target="_blank"
                >
                  Consumer Profile-Unilever Partner-L2
                </a>
                . <br />
                Attach the details shared by MRD.ES-MDE team, as part of step 1,
                in the UNA ticket
              </p>
              <p className="mb-10">
                <strong>Step 3:</strong>
                &nbsp;CPP team will channel this information to Campaign Wizard
                team after creating the brand profile store in GIGYA
              </p>
              <p className="mb-10">
                <strong>Step 4: </strong> Campaign Wizard team will then
                configure the brand-market in the tool and will also generate a
                Preference Centre in AEM platforms
              </p> */}
            </Modal>

            <Modal
              scrollable={true}
              isOpen={this.state.isNewModalOpen}
              onRequestClose={this._closeNewModal}
              className="cw-modal cw-modal--branddetail_new_overlay cw-modal--scrollbar_new_overlay"
              contentLabel="Campaign Wizard 2.0"
              style={{
                overlay: {
                  backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
                }
              }}
            >
              <BrandDetailsMessage closeNewModal={this._closeNewModal} />
            </Modal>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  activeStep: state.app.activeStep,
  brands: state.brandDetails.brands,
  countries: state.brandDetails.countries,
  languages: state.brandDetails.languages,
  websiteTypes: state.brandDetails.websiteTypes,
  profileStoreType: state.brandDetails.profileStoreType,
  profileStoreExist: state.brandDetails.profileStoreExist,
  brand: state.brandDetails.brand,
  country: state.brandDetails.country,
  language: state.brandDetails.language,
  websiteType: state.brandDetails.websiteType,
  existingCampaign: state.brandDetails.existingCampaign,
  brandDetailSuccess: state.brandDetails.brandDetailSuccess,
  profileStoreError: state.brandDetails.profileStoreError,
  profileStoreInProgress: state.brandDetails.profileStoreInProgress,
  countriesLoaded: state.brandDetails.countriesLoaded,
  countrylanguage: state.brandDetails.countrylanguage,
  isLoading: state.brandDetails.isLoading,
  countryId: state.brandDetails.countryId,
  countryDataLoaded: state.brandDetails.countryDataLoaded,
  isCampaignEdited: state.brandDetails.isCampaignEdited,
  editCampaign: state.overview.editCampaign,
  editCampaignDetails: state.app.editCampaignDetails,
  autopopulateWebsiteType: state.brandDetails.autopopulateWebsiteType,
  websiteCount: state.brandDetails.websiteCount,
  existingLanguage: state.brandDetails.existingLanguage,
  epsilonTypeSites: state.brandDetails.epsilonTypeSites,
  existingWebsiteType: state.brandDetails.existingWebsiteType
});

export default connect(mapStateToProps, {
  getBrandCountries,
  getBrandByCountry,
  getBrandDetails,
  getProfileStore,
  updateBrandDetails,
  removeConsentDataFromCampaign,
  setProfileStoreType,
  resetProfileStoreInProgress,
  editedCampaignDetails,
  fetchEditedCampaignLogs,
  saveEditedCampaignLogs,
  autoPopulateWebsiteType,
  resetFieldsOnLanguageChange,
  resetFieldsOnCountryChange,
  resetQnAConfirm
})(BrandDetails);
