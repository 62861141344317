import axios from 'axios';
import {
  GET_CAMPAIGNS_LIST,
  SERVICE_ERROR_DASHBOARD,
  FETCHING_CAMPAIGNS,
  SET_DASHBOARD_NOTIFICATION_MESSAGE,
  GET_DASHBOARD_BRAND_COUNTRIES,
  FETCHING_DASHBOARD_BRANDS,
  GET_DASHBOARD_BRAND_LIST,
  FETCHING_DASHBOARD_WEBSITE_TYPES,
  GET_DASHBOARD_WEBSITE_TYPE_LIST,
  GET_DASHBOARD_MATRIX,
  GET_DASHBOARD_MATRIX_DATA,
  FETCH_CONSENT_REQUEST,
  FETCH_CONSENT_SUCCESS,
  FETCH_CONSENT_FAILURE,
  FETCH_COUNTRY_SUCCESS,
  FETCH_LANGUAGE_SUCCESS
} from '../actionTypes';
import constant from '../constants';
import { getNewCampaignTypeName } from '../selectors/index';
import _ from 'lodash';

export function fetchCampaignsList(
  page = 1,
  perPage = 10,
  sortKey = 'lastUpdatedDate',
  sortBy = 'desc',
  body = {}
) {
  return function(dispatch) {
    dispatch({
      type: FETCHING_CAMPAIGNS,
      showLoader: true
    });
    axios({
      method: 'POST',
      url:
        constant.serviceUrls.CAMPAIGN_LIST_SERVICE_URL +
        '/list' +
        '?page=' +
        page +
        '&perPage=' +
        perPage +
        '&sortKey=' +
        sortKey +
        '&sortBy=' +
        sortBy,
      data: body
    })
      .then(response => {
        let campaigns = response.data.campaigns;
        campaigns.forEach(element => {
          element.campaignType = getNewCampaignTypeName(
            element.campaignType,
            element.incentiveType
          );
        });
        let totalCount = response.data.totalrecords;
        let pageNumber = response.data.page;
        let recordShowStart = response.data.recordShowStart;
        let recordShowto = response.data.recordShowto;
        dispatch({
          type: GET_CAMPAIGNS_LIST,
          campaigns,
          totalCount,
          pageNumber,
          recordShowStart,
          recordShowto
        });
      })
      .catch(error => {
        dispatch({ type: SERVICE_ERROR_DASHBOARD, error });
      });
  };
}

export function getBrandCountries() {
  return function(dispatch) {
    dispatch({
      type: FETCHING_CAMPAIGNS,
      isLoading: true
    });

    axios.get(constant.serviceUrls.SERVICE_URL.COUNTRIES).then(response => {
      let countryDetails = response.data;
      let countryDetailsSorted = _.orderBy(
        countryDetails.countries,
        ['name'],
        ['asc']
      );

      dispatch({
        type: GET_DASHBOARD_BRAND_COUNTRIES,
        countryDetailsSorted
      });
    });
  };
}

export function getBrands() {
  return function(dispatch) {
    dispatch({
      type: FETCHING_DASHBOARD_BRANDS,
      asyncInProgress: true
    });

    axios.get(constant.serviceUrls.SERVICE_URL.BRANDS_LIST).then(response => {
      let brandsList = response.data;
      let brandsListSorted = _.orderBy(brandsList.brands, ['name'], ['asc']);

      dispatch({
        type: GET_DASHBOARD_BRAND_LIST,
        brandsListSorted
      });
      dispatch({
        type: FETCHING_DASHBOARD_BRANDS,
        asyncInProgress: false
      });
    });
  };
}

export const setNotificationMessage = (
  message,
  linkedTo,
  notificationType,
  campaignName,
  notificationMessageComp
) => ({
  type: SET_DASHBOARD_NOTIFICATION_MESSAGE,
  message,
  linkedTo,
  notificationType,
  campaignName,
  notificationMessageComp
});

export function getWebsiteTypes() {
  return function(dispatch) {
    dispatch({
      type: FETCHING_DASHBOARD_WEBSITE_TYPES,
      asyncInProgress: true
    });
    axios
      .get(constant.serviceUrls.SERVICE_URL.GET_AUTOPOPULATED_WEBSITETYPE)
      .then(response => {
        let websiteTypeLists = response.data;
        let websiteListSorted = _.orderBy(websiteTypeLists, ['name']);

        dispatch({
          type: GET_DASHBOARD_WEBSITE_TYPE_LIST,
          websiteListSorted
        });
        dispatch({
          type: FETCHING_DASHBOARD_WEBSITE_TYPES,
          asyncInProgress: false
        });
      });
  };
}

export function getDashboardMatrix(startDate, endDate) {
  return function(dispatch) {
    dispatch({
      type: GET_DASHBOARD_MATRIX,
      asyncInProgress: true
    });
    axios
      .get(
        `${constant.serviceUrls.FETCH_DASHBOARD_MATRIX}?startDate=${startDate}&endDate=${endDate}`
      )
      .then(response => {
        let campaignMatrix = response.data;

        dispatch({
          type: GET_DASHBOARD_MATRIX_DATA,
          campaignMatrix
        });
        dispatch({
          type: GET_DASHBOARD_MATRIX,
          asyncInProgress: false
        });
      });
  };
}

export const fetchConsentData = data => {
  return async dispatch => {
    dispatch({
      type: FETCH_CONSENT_REQUEST
    });
    try {
      const response = await axios.post(
        constant.serviceUrls.GET_BPOS_COUNTRY_LANGUAGE_LIST,
        {
          country: data && data.countryIds ? data.countryIds : [],
          language: data && data.languageIds ? data.languageIds : [],
          status: data && data.statusValues ? data.statusValues : [],
          sortBy: data && data.sortBy ? data.sortBy : '',
          sortKey: data && data.sortKey ? data.sortKey : ''
        }
      );
      dispatch({
        type: FETCH_CONSENT_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: FETCH_CONSENT_FAILURE,
        payload: error
      });
    }
  };
};

export const fetchCountryList = () => {
  return async dispatch => {
    try {
      const response = await axios.get(
        constant.serviceUrls.GET_BPOS_COUNTRY_LIST
      );
      dispatch({
        type: FETCH_COUNTRY_SUCCESS,
        payload: response.data && response.data.countryList
      });
    } catch (error) {}
  };
};

export const fetchLanguageList = () => {
  return async dispatch => {
    try {
      const response = await axios.get(
        constant.serviceUrls.GET_BPOS_LANGUAGE_LIST
      );
      dispatch({
        type: FETCH_LANGUAGE_SUCCESS,
        payload: response.data && response.data.langList
      });
    } catch (error) {}
  };
};

export const getConsentsForCountryAndLang = (
  country,
  language,
  callback = () => {}
) => {
  axios
    .get(
      constant.consentsApi.HOST +
        constant.consentsApi.URL +
        '?country=' +
        country +
        '&language=' +
        language
    )
    .then(response => {
      callback(null, response.data);
    })
    .catch(error => {
      callback(error, null);
    });
};
