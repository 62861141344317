import {
  GET_AUTHORIZED_USER_DATA,
  USER_AUTHORIZED,
  USER_NOT_AUTHORIZED,
  ASYNC_IN_PROGRESS_AUTHORIZATION,
  GET_CAMPAIGN,
  GET_EMAIL_CAMPAIGN,
  ASYNC_EMAIL_IN_PROGRESS_AUTHORIZATION,
  GET_SMS_CAMPAIGN_DETAILS,
  ASYNC_SMS_IN_PROGRESS_AUTHORIZATION
} from '../actionTypes';

const initialState = {
  asyncInProgress: false,
  authorizedUserData: {},
  userAuthorized: false,
  userNotAuthorized: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_AUTHORIZED_USER_DATA: {
      let userData = {
        authorizedUserData: action.userData || {},
        userAuthorized: action.userAuthorized
      };
      return { ...state, ...userData };
    }
    case ASYNC_IN_PROGRESS_AUTHORIZATION: {
      let asyncInProgress = { asyncInProgress: action.isLoading };
      return { ...state, ...asyncInProgress };
    }
    case USER_AUTHORIZED: {
      let userAuthorized = { userAuthorized: action.userAuthorized };
      return { ...state, ...userAuthorized };
    }
    case USER_NOT_AUTHORIZED: {
      let userNotAuthorized = { userNotAuthorized: action.userNotAuthorized };
      return { ...state, ...userNotAuthorized };
    }

    case GET_CAMPAIGN: {
      let campaignAccessData = {
        isEdited: action.campaign.isEdited,
        status: action.campaign.status,
        isCampaignValid: action.campaign ? true : false
      };
      return { ...state, campaignAccessData };
    }

    case GET_EMAIL_CAMPAIGN: {
      let campaignAccessData = {
        status:
          action.campaign && action.campaign.status
            ? action.campaign.status
            : '',
        isCampaignValid: action.campaign ? true : false,
        emailCollaborators:
          action.campaign && action.campaign.emailCollaborators,
        requestersEmail: action.campaign && action.campaign.requestersEmail,
        nmiAssignedToRequestor:
          action.campaign && action.campaign.nmiAssignedToRequestor
            ? action.campaign.nmiAssignedToRequestor
            : false
      };
      return { ...state, campaignAccessData };
    }

    case ASYNC_EMAIL_IN_PROGRESS_AUTHORIZATION: {
      let emailAsyncInProgress = { emailAsyncInProgress: action.isLoading };
      return { ...state, ...emailAsyncInProgress };
    }

    case GET_SMS_CAMPAIGN_DETAILS: {
      let campaignAccessData = {
        status:
          action.campaign && action.campaign.status
            ? action.campaign.status
            : '',
        isCampaignValid: action.campaign ? true : false,
        requestersEmail: action.campaign && action.campaign.requestorsEmail,
        emailCollaborators:
          action.campaign && action.campaign.emailCollaborators,
        requestorsEmail: action.campaign && action.campaign.requestorsEmail,
        nmiAssignedToRequestor:
          action.campaign && action.campaign.nmiAssignedToRequestor
            ? action.campaign.nmiAssignedToRequestor
            : false
      };
      return { ...state, campaignAccessData };
    }

    case ASYNC_SMS_IN_PROGRESS_AUTHORIZATION: {
      let smsAsyncInProgress = { smsAsyncInProgress: action.isLoading };
      return { ...state, ...smsAsyncInProgress };
    }

    default:
      return state;
  }
}
