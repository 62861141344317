import React, { useState, useEffect, useRef } from 'react';
import { Popover } from 'react-tiny-popover';
import { SketchPicker } from 'react-color';
import './ColourPicker.css';
import PlusButton from 'components/atoms/PlusButton/PlusButton';
import Color from 'components/atoms/Color/Color';
import { LinkButton } from 'components/atoms/LinkButton';
import { useOnClickOutside } from 'hooks/useOnClickOutside';

const ColourPicker = ({ value = [], onChange, name }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedColour, setSelectedColour] = useState('#ddd');
  const [colorName, setColorName] = useState('');
  const [colorNameError, setColorNameError] = useState(false);

  useEffect(() => {
    setColorNameError(colorNameError && colorName.length < 1);
  }, [colorName]);

  const pickerRef = useRef();
  useOnClickOutside(pickerRef, () => setIsPopoverOpen(false));

  const addColour = name => {
    if (value.length < 10 && !value.includes(selectedColour)) {
      onChange([...value, { code: selectedColour, name }]);
      setColorName('');
    }
  };

  const onRemove = color => {
    const colorIndex = value.findIndex(c => c === color);
    if (colorIndex > -1) {
      value.splice(colorIndex, 1);
      onChange([...value]);
    }
  };

  const getColourPickerView = () => {
    return (
      <div
        className="color-picker-container"
        style={{
          borderRadius: 4,
          boxShadow: '0 16px 24px 0 rgba(48,49,51,.1)',
          backgroundColor: 'white',
          position: 'relative'
        }}
        ref={pickerRef}
      >
        <button
          className="color-picker-close"
          onClick={() => {
            setIsPopoverOpen(false);
          }}
        >
          <i className="fa fa-times-circle" aria-hidden="true"></i>
        </button>
        <SketchPicker
          color={selectedColour}
          onChangeComplete={c => setSelectedColour(c.hex)}
          className="sketch-picker-new"
        />
        <div
          className="d-flex flex-column"
          style={{ justifyContent: 'space-around', padding: '0px 10px 10px' }}
        >
          <input
            value={colorName}
            name={`${name}.colorName`}
            className={`form-control ${colorNameError && 'cw-error-focus'}`}
            placeholder="Specify colour name"
            onChange={e => setColorName(e.target.value)}
          />
          {colorNameError && (
            <span className="error cw-error mt-1">
              Please specify colour name
            </span>
          )}

          <button
            className="btn btn-primary color-picker-add-button mt-3"
            disabled={value.length >= 10}
            onClick={() => {
              if (!colorName) {
                setColorNameError(true);
              } else {
                addColour(colorName);
                setColorNameError(false);
              }
            }}
          >
            Add colour
          </button>
          <LinkButton
            className="color-picker-close-button"
            onClick={() => {
              setColorNameError(false);
              setIsPopoverOpen(false);
              setColorName('');
            }}
          >
            Close
          </LinkButton>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className="d-flex justify-content-start"
        style={{ alignItems: 'top' }}
      >
        <Popover
          isOpen={isPopoverOpen}
          positions={['right', 'top', 'bottom', 'left']} // preferred positions by priority
          content={getColourPickerView()}
        >
          {value.length >= 10 ? (
            <PlusButton disabled />
          ) : (
            <PlusButton onClick={() => setIsPopoverOpen(!isPopoverOpen)} />
          )}
        </Popover>

        {value.length === 0 ? (
          <div className="ml-2 empty-colors">
            Your preferred colours will appear here
          </div>
        ) : (
          <div className="ml-2 d-flex">
            {value.map(color => {
              return (
                <Color
                  key={color?.code || color}
                  color={color}
                  onRemove={onRemove}
                />
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default ColourPicker;
