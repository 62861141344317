import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { setSelectedTemplate } from '../../../actions/emailbriefing/selectedTemplate';
import { useHistory, useParams } from 'react-router-dom';
import searchblue from 'images/search_blue.png';
import Card from 'components/organisms/EmailBriefingsListMasonry/Card';
import { deleteEmailCoreTemplate } from 'actions/emailbriefing/emailCoreTemplates';
import CardBody from '../../organisms/CardBody/CardBody';
import ConfirmChangeTemplateModal from './ConfirmChangeTemplateModal';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { get } from 'lodash';
import constants from 'constants/config';

export const getEmailTemplateThumbail = template => {
  if (template.templateType === 'custom') {
    return `${template.thumbnail}`;
  } else {
    return template.thumbnail;
  }
};

const EmailTemplateThumbnail = props => {
  const {
    template = {},
    doesGivenTemplateExists,
    style = {},
    givenTemplate,
    handleTemplatePreview,
    userEmail,
    userName,
    isfallback,
    isSelectedTemplate,
    hideOverflow,
    hideOverlay,
    hideSelectBtn,
    hideNameDescirption,
    hideOverflowSelectBtn,
    isEmailTemplate,
    isEmailAdminOrACS
  } = props;
  const { title, category, name, creditCount } = template;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);

  const deleteMenuRef = useRef();
  useOnClickOutside(deleteMenuRef, () => setIsConfirmDelete(false));

  const handleConfirmDelete = () => {
    setIsConfirmDelete(true);
  };
  const handleCancelDelete = () => {
    setIsConfirmDelete(false);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const uid = params.id;

  const isBlankTemplate = name && name.trim() === 'blank-template';

  const handleSelect = () => {
    if (isSelectedTemplate || !doesGivenTemplateExists) {
      onSelect();
    } else {
      setIsModalOpen(true);
    }
  };

  const onSelect = () => {
    dispatch(
      setSelectedTemplate({
        coreTemplateId: template._id,
        uid,
        userEmail,
        userName,
        isEmailTemplate: !isBlankTemplate && isEmailTemplate
      })
    ).then(() => {
      closeModal();
      history.push(`/campaign-wizard/emailbriefing/${uid}/email-editor`);
    });
  };

  const onDelete = () => {
    const { template = {} } = props;
    dispatch(deleteEmailCoreTemplate(template._id));
  };

  const onPreview = () => {
    props.handleTemplatePreview(template);
  };

  const handleResume = () => {
    closeModal();
    history.push(`/campaign-wizard/emailbriefing/${uid}/email-editor`);
  };

  const canDelete = isEmailAdminOrACS
    ? true
    : get(props, 'template.createdBy.emailId') === userEmail;

  return (
    <>
      <div
        className={`card text-center email-template__card  ${
          hideOverlay
            ? ''
            : isBlankTemplate || isSelectedTemplate
            ? 'email-template__card--blank'
            : 'email-template__card--toggle'
        } `}
        style={{
          ...style,
          ...(isSelectedTemplate
            ? { height: 250 }
            : isfallback
            ? { width: '20%', marginRight: 15 }
            : {})
        }}
      >
        <ConfirmChangeTemplateModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          onSelect={onSelect}
          handleResume={handleResume}
        />

        {isBlankTemplate && !isSelectedTemplate ? null : (
          <Card
            template={template}
            noShadow
            thumbnail={getEmailTemplateThumbail(template)}
            title={template.title + ' template'}
          />
        )}
        {isConfirmDelete && (
          <div className="delete-template-overflow" ref={deleteMenuRef}>
            <div className="">
              <div className="confirm-mesg-text">
                {canDelete ? (
                  <p>Do you want to delete this template?</p>
                ) : (
                  <p>
                    You cannot delete a template created by another user. You
                    can raise an UNA ticket if you would like to request this to
                    be deleted with a reason
                  </p>
                )}
              </div>
              <div className="confirm-btn-container">
                {canDelete ? (
                  <button className="btn btn-primary mt-10" onClick={onDelete}>
                    Delete
                  </button>
                ) : (
                  <a
                    className="btn btn-primary mt-10"
                    rel="noopener noreferrer"
                    target="_blank"
                    href={constants.KANA_PAGE}
                  >
                    Raise UNA <i className="fas fa-external-link-alt"></i>
                  </a>
                )}

                <button
                  className="btn btn-outline-secondary mt-10"
                  onClick={handleCancelDelete}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        <CardBody
          title={title}
          category={category}
          creditCount={creditCount}
          isBlankTemplate={isBlankTemplate}
          isSelectedTemplate={isSelectedTemplate}
          primaryButton={
            isSelectedTemplate
              ? 'Resume'
              : isBlankTemplate
              ? 'Design now'
              : 'Select'
          }
          onSelect={isSelectedTemplate ? handleResume : handleSelect}
          onPreview={onPreview}
          onDelete={handleConfirmDelete}
          hideOverflow={hideOverflow}
          hideSelectBtn={hideSelectBtn}
          hideNameDescirption={hideNameDescirption}
          hideOverflowSelectBtn={hideOverflowSelectBtn}
          isEmailTemplate={!isBlankTemplate && isEmailTemplate}
        />
      </div>
      {props.isfallback && doesGivenTemplateExists ? (
        <EmailTemplateThumbnail
          style={{ width: '20%', marginRight: 15 }}
          isSelectedTemplate
          key={givenTemplate._id}
          type="template"
          doesGivenTemplateExists={doesGivenTemplateExists}
          template={givenTemplate}
          userEmail={userEmail}
          userName={userName}
          handleTemplatePreview={handleTemplatePreview}
          hideOverflow={hideOverflow}
          hideOverlay={hideOverlay}
          hideSelectBtn={hideSelectBtn}
          hideNameDescirption={hideNameDescirption}
          hideOverflowSelectBtn={hideOverflowSelectBtn}
        />
      ) : null}
      {props.isfallback ? (
        <div
          className={`card email-template__card no-record`}
          key={'blank-reset-card'}
        >
          <img className="card-img-left" src={searchblue} alt="Search icon" />
          <div className="card-body">
            <p className="card-text">
              {isEmailTemplate
                ? 'Sorry, no live emails match your search'
                : 'Sorry, no templates match your search.'}
            </p>
            <p className="card-text">
              {isEmailTemplate
                ? 'Try modifying your criteria or clear your filters to view all live emails available to be used as templates'
                : 'Try modifying your criteria or clear your filters to view all templates'}
            </p>
            <div className="text-left button-container">
              <button
                className={classNames('btn btn-outline-primary', {
                  wrap: isEmailTemplate
                })}
                onClick={props.onReset}
              >
                {isEmailTemplate
                  ? ' View all live emails available as templates'
                  : 'View all templates'}
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default EmailTemplateThumbnail;
