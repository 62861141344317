import {
  fetchCountryList,
  fetchLanguageList,
  getConsentsForCountryAndLang
} from 'actions/dashboard';
import classNames from 'classnames';
import { BreadCrumbItem } from 'components/BreadCrumb';
import Loader from 'components/Loader';
import ConsentDetailsTables from './ConsentTabular';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import NewUpdateAlert from 'components/molecules/NewUpdateAlert';
import { MutliGuideLineBtn } from 'components/organisms/SinglePageDesc/SinglePageDescription';
import Modal from 'react-modal';
import MultiChannelGuideline from 'components/MultiChannelGuideline';
import constants from 'constants/config';

const breadcrumbdata = [
  {
    path: '/campaign-wizard',
    title: 'Home'
  },
  {
    path: '/campaign-wizard/consent-statement',
    title: 'Consent dashboard'
  },
  {
    title: 'View consent statements'
  }
];

const ConsentDetailsPage = () => {
  const dispatch = useDispatch();
  const [consentData, setConsentData] = useState();
  const [isLoading, setIsloading] = useState(false);
  const [openMultiGuideline, setMultiguideline] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const countryParam = searchParams.get('country');
  const languageParam = searchParams.get('language');

  const { countries, languages } = useSelector(state => {
    return {
      countries: state.dashboard && state.dashboard.countryList,
      languages: state.dashboard && state.dashboard.languageList
    };
  });

  useEffect(() => {
    dispatch(fetchCountryList());
    dispatch(fetchLanguageList());
  }, []);

  useEffect(() => {
    if (countryParam && languageParam) {
      setIsloading(true);
      getConsentsForCountryAndLang(
        countryParam,
        languageParam,
        (error, result) => {
          setIsloading(false);
          if (error) {
            console.log('error: ', error);
          } else {
            console.log('result: ', result);
            setConsentData(result);
          }
        }
      );
    }
  }, [countryParam, languageParam]);

  //if (!data) return null;
  const getCountries = () => {
    if (countries && countries.length > 0) {
      return countries.map(function(country) {
        return { value: country._id, label: country.name, code: country.code };
      });
    }
  };

  const getLanguage = () => {
    if (languages && languages.length > 0) {
      return languages.map(function(lang) {
        return { value: lang._id, label: lang.name, code: lang.code };
      });
    }
  };

  const handleCountrySelect = selectedOption => {
    const params = new URLSearchParams({
      country: selectedOption.code,
      language: languageParam
    });
    history.replace({ pathname: location.pathname, search: params.toString() });
  };

  const handleLanguageSelect = selectedOption => {
    const params = new URLSearchParams({
      country: countryParam,
      language: selectedOption.code
    });
    history.replace({ pathname: location.pathname, search: params.toString() });
  };

  const isOpenMultiChannelGuideModel = () => {
    setMultiguideline(true);
  };

  const isCloseMultiChannelGuideModel = () => {
    setMultiguideline(false);
  };

  return (
    <>
      <div className="col-sm-12">
        <div className="cw-section cw-dashboard ml-20 mt-20">
          {breadcrumbdata && breadcrumbdata.length && (
            <BreadCrumbItem item={breadcrumbdata} />
          )}
          <div className="cw-listing--header mb-10 d-flex align-items-center justify-content-between">
            <h2 className="cw-heading--secondary mr-4 d-inline-block">
              View consent statements
            </h2>
          </div>
          <p>
            Review the DPO approved consent statements that are currently
            available in central records.
          </p>
        </div>
        <div className="cw-listing--content px-5">
          <div className="row mt-30 mb-30 cw-dashboard__filters">
            <div className="d-flex align-items-center col-sm-8 mb-10">
              {/* Country dropdown */}
              <div className="filter-item mr-10">
                <div className="form-group mb-0">
                  <label htmlFor="brandcountry">Country</label>
                  <Select
                    className={classNames('cw-select--custom')}
                    classNamePrefix="react-select"
                    options={getCountries()}
                    name={'brandcountry'}
                    label={'Country'}
                    id="brandcountry"
                    value={getCountries()?.find(
                      elem => elem.code === countryParam
                    )}
                    placeholder={'Country'}
                    hideSelectedOptions={false}
                    onChange={handleCountrySelect}
                  />
                </div>
              </div>

              {/* Language Filter */}
              <div className="filter-item mr-10">
                <div className="form-group mb-0">
                  <label htmlFor="language">Language</label>
                  <Select
                    className={classNames('cw-select--custom')}
                    classNamePrefix="react-select"
                    options={getLanguage()}
                    name={'language'}
                    label={'Language'}
                    id="language"
                    value={getLanguage()?.find(
                      elem => elem.code === languageParam
                    )}
                    placeholder={'Language'}
                    hideSelectedOptions={false}
                    onChange={handleLanguageSelect}
                  />
                </div>
              </div>
            </div>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            countryParam &&
            languageParam && (
              <div className="row">
                <div className="col-sm-8">
                  <ConsentDetailsTables
                    consentsData={consentData}
                    country={countryParam}
                    language={languageParam}
                  />
                </div>
                <div className="col-sm-4">
                  <NewUpdateAlert
                    className="mt-30"
                    title="Updating consents"
                    content={
                      <>
                        <p>
                          Unilever DPOs mandate that our forms use these
                          approved consent statements we auto-populate for you
                          from a central repository. If your consent statements
                          are missing or require any updates, please raise an{' '}
                          <a
                            href="https://unilever.service-now.com/cex?id=sc_cat_item&sys_id=0394a8b71b28555021f3b8c8dc4bcbb9"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            UNA <i className="fas fa-external-link-alt"></i>
                          </a>{' '}
                          ticket. You can also review our{' '}
                          <a href="#" onClick={isOpenMultiChannelGuideModel}>
                            detailed guidelines
                          </a>{' '}
                          on usage of consent statements and the process to
                          update.
                        </p>
                      </>
                    }
                  />
                </div>
              </div>
            )
          )}
        </div>
        <Modal
          scrollable={true}
          isOpen={openMultiGuideline}
          onRequestClose={isCloseMultiChannelGuideModel}
          className="cw-modal cw-modal--qnadetail"
          contentLabel="Campaign Wizard 2.0"
          style={{
            overlay: {
              backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
            }
          }}
        >
          <MultiChannelGuideline onClose={isCloseMultiChannelGuideModel} />
        </Modal>
      </div>
    </>
  );
};

export default ConsentDetailsPage;
