import React, { useState, useEffect, useRef } from 'react';
import ActionEditIcon from '../../../images/action-edit.png';
import ReactTooltip from 'react-tooltip';
import Loader from 'components/Loader';
import { Input } from '../../atoms/Input';
import classNames from 'classnames';

export default function SmsActionBar({
  onClickEdit,
  isLoading,
  showActions = false,
  showSMSParts,
  setShowSMSparts,
  showToggle
}) {
  const inputRef = useRef();
  const isEditFuc = typeof onClickEdit === 'function';
  return (
    <div className={`sms-action-bar ${showActions ? 'mb-15' : ''}`}>
      {isLoading && <Loader />}
      {isEditFuc && !showToggle ? (
        <>
          <button onClick={onClickEdit} className="btn sms-action-btn">
            <img src={ActionEditIcon} alt="Edit SMS" className="mr-2" />
            Edit SMS body
          </button>
        </>
      ) : null}
      {showToggle && !isEditFuc && (
        <div className="d-flex align-items-center pt-5">
          <label className="switch-saas">
            <Input
              ref={inputRef}
              name="showSmsPartsToggle"
              checked={showSMSParts}
              type="checkbox"
              id="showSmsPartsToggle"
              onChange={e => {
                setShowSMSparts(!showSMSParts);
              }}
            />
            <div className="slider-saas round"></div>
          </label>
          <label
            className="ml-10 font-text-small"
            htmlFor={`showSmsPartsToggle`}
          >
            Show SMS parts
          </label>
        </div>
      )}
      {showToggle && isEditFuc && (
        <div className="d-flex justify-content-between align-items-center">
          <Action
            id="editSmsBtn"
            icon={ActionEditIcon}
            onClick={onClickEdit}
            title="Edit SMS body"
          />
          <div className="d-flex align-items-center ml-20 pt-5">
            <label className="switch-saas">
              <Input
                ref={inputRef}
                name="showSmsPartsToggle"
                checked={showSMSParts}
                type="checkbox"
                id="showSmsPartsToggle"
                onChange={e => {
                  setShowSMSparts(!showSMSParts);
                }}
              />
              <div className="slider-saas round"></div>
            </label>
            <label
              className="ml-10 font-text-small"
              htmlFor={`showSmsPartsToggle`}
            >
              Show SMS parts
            </label>
          </div>
        </div>
      )}
    </div>
  );
}

function Action({ id, icon, onClick, title, className }) {
  return (
    <>
      <ReactTooltip
        id={id}
        place="bottom"
        type="info"
        multiline={true}
        className="cw-tooltip cw-email-tooltip saas_new_master_tooltip"
      />
      <button
        onClick={onClick}
        className={classNames('action-bar__action', className)}
        data-tip={title}
        data-for={id}
      >
        <img src={icon} alt={title} />
      </button>
    </>
  );
}
