import {
  EMAIL_DESIGN_ASYNC_INPROGRESS,
  SET_EMAIL_BREIFINGS,
  UPDATE_EMAIL_BREIFINGS_STORE,
  RESET_EMAIL_BREIFINGS
} from '../../actionTypes';

const initialState = {
  isLoading: false,
  emailBriefing: null,
  uid: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case EMAIL_DESIGN_ASYNC_INPROGRESS: {
      //let asyncInProgress = { asyncInProgress: action.isLoading };
      return { ...state, isLoading: action.isLoading };
    }
    case SET_EMAIL_BREIFINGS: {
      let emailBriefing = action.emailBriefing;
      return { ...state, emailBriefing };
    }

    case UPDATE_EMAIL_BREIFINGS_STORE: {
      let newEmailBrief = { ...state.emailBriefing, ...action.payload };
      return { ...state, emailBriefing: newEmailBrief };
    }

    case RESET_EMAIL_BREIFINGS: {
      return { ...state, emailBriefing: null };
    }

    default:
      return state;
  }
}
