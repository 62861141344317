import React from 'react';
import Modal from 'react-modal';
import constants from 'constants/config';

const DeleteRowModal = ({
  isOpen,
  onCloseModal,
  handleCloseModal,
  handleConfirmation,
  isUserRequestorOrApprover
}) => {
  Modal.setAppElement('#root');
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      className="cw-modal cw-modal--filePicker-info"
      contentLabel="delete row form"
      style={{
        overlay: {
          backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
        }
      }}
    >
      <div className="email-overlay-wrapper image-guideline">
        <span
          className="qna-guideline-close email-overlay-close-modal"
          onClick={handleCloseModal}
        ></span>
        <div>
          <div className="d-flex mb-20">
            <div className="ml-10">
              <div
                className={
                  'cw-heading--saascampaign send-test-email__title mb-20'
                }
              >
                Delete tile
              </div>
              <div className="scrollable email-overlay-scroll">
                <p>Do you want to delete this tile?</p>
                {isUserRequestorOrApprover && (
                  <>
                    <p>
                      Limited roles have this permission. Please{' '}
                      <a
                        href={constants.KANA_PAGE}
                        target="_blank"
                        rel="noreferrer"
                      >
                        raise an UNA ticket{' '}
                        <i className="fas fa-external-link-alt"></i>
                      </a>{' '}
                      along with brand name, country, tile name and screenshot.
                    </p>
                    <p className="mt-10">
                      Note that you can only request for your brand specific
                      tiles to be deleted.
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex flex-row-reverse">
            <button
              className="btn btn-outline-secondary"
              onClick={handleCloseModal}
            >
              {isUserRequestorOrApprover ? 'Close' : 'Cancel'}
            </button>
            {!isUserRequestorOrApprover && (
              <button
                className="btn btn-primary mr-10"
                onClick={handleConfirmation}
              >
                Delete
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteRowModal;
