import React, { Component } from 'react';
import {
  getFormatedDate,
  checkMarketSupportedForDoubleOptin,
  showAutoGenerateFlag,
  isAEMTypeCampaign,
  epsilonMarket,
  getCampaignStatus,
  getDateInDDmmmYYYY,
  getCampaignStatusText,
  getDateInDDmmmYYYYTime
} from '../selectors/index';
import constant from '../constants';
import constants from '../constants/config';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import refresh from '../images/refresh.png';

export default class CampaignDetailsInfo extends Component {
  constructor(props) {
    super(props);
    this._handleCollapse = this._handleCollapse.bind(this);
    this.collapseHandlerIcon = React.createRef();
    this.collapseHandlerText = React.createRef();
    this.collapsePanel = React.createRef();
  }

  _handleCollapse(event) {
    event.preventDefault();
    if (this.collapsePanel.current.classList.contains('show')) {
      this.collapsePanel.current.classList.remove('show');
      this.collapseHandlerIcon.current.classList.remove('fa-chevron-up');
      this.collapseHandlerIcon.current.classList.add('fa-chevron-down');
      this.collapseHandlerText.current.innerHTML = 'View more Campaign Details';
    } else {
      this.collapsePanel.current.classList.add('show');
      this.collapseHandlerText.current.innerHTML = 'View less Campaign Details';
      this.collapseHandlerIcon.current.classList.remove('fa-chevron-down');
      this.collapseHandlerIcon.current.classList.add('fa-chevron-up');
    }
  }

  render() {
    let { campaign, campaignDetails, campaignConfig } = this.props;
    let isClone = campaign && campaign.isClone;
    let websiteType =
      campaign && campaign.websiteType ? campaign.websiteType.name : '';
    let marketCode = campaign && campaign.country && campaign.country.code;
    let allowedMarketsForDoubleOptin = constant.allowedMarketsForDoubleOptin;
    let isCampaignTypeSASS =
      (campaign &&
        campaign.websiteType &&
        campaign.websiteType.name === 'Adobe SaaS') ||
      (campaign && campaign.isPlatformAgnostic) ||
      campaign.isMasterData;

    let statusText = getCampaignStatusText(campaign, this.props.userrole);

    let campaignWebsiteType =
      campaign && campaign.websiteType && campaign.websiteType.name
        ? campaign.websiteType.name.toLowerCase()
        : '';

    let isAcAtStandAloneCampaign =
      campaignWebsiteType === 'standalone' ||
      campaignWebsiteType === 'adobe templated' ||
      campaignWebsiteType === 'adobe classic';
    let isMarketSupported = checkMarketSupportedForDoubleOptin(
      marketCode,
      allowedMarketsForDoubleOptin
    );

    let isCampaignInfraEnabled =
      campaignDetails &&
      campaignDetails.campaignProductionUrl &&
      campaignDetails.campaignInfraDetails &&
      campaignDetails.campaignInfraDetails.isCampaignInfraSetupDone
        ? campaignDetails.campaignInfraDetails.isCampaignInfraSetupDone
        : '';

    let campaignNonProdUrl =
      campaignDetails &&
      campaignDetails.campaignNonProdUrls &&
      campaignDetails &&
      campaignDetails.campaignNonProdUrls.length > 0
        ? campaignDetails &&
          campaignDetails.campaignNonProdUrls.map(ele => ele).join(', ')
        : '';

    let isEpsilonMarket =
      marketCode && epsilonMarket(marketCode) ? true : false;
    let branddetails = [
      {
        label: 'Brand',
        value:
          campaign && campaign.brand
            ? campaign.brand.name + ' (' + campaign.brand.code + ')'
            : ''
      },
      {
        label: 'Country',
        value: campaign && campaign.country ? campaign.country.name : ''
      },
      {
        label: 'Language',
        value: campaign && campaign.language ? campaign.language.name : ''
      },
      {
        label: 'Website Type',
        value: websiteType
      }
    ];
    let campaignTypedetails = [
      {
        label: 'Campaign Type',
        value:
          campaign && campaign.campaignType
            ? campaign.campaignType + ' campaign'
            : ''
      },
      {
        label: 'Incentive',
        value: campaign && campaign.incentiveType ? campaign.incentiveType : ''
      },
      {
        label: 'Form Type',
        value: campaign && campaign.type ? campaign.type : ''
      }
    ];
    let campaignTrackingdetails = [
      {
        label: 'Campaign Tracking',
        value:
          campaignDetails && campaignDetails.isCustomTrackingId !== undefined
            ? campaignDetails && campaignDetails.isCustomTrackingId
              ? `Reuse existing ${campaignConfig.LABEL_SIGN_UP_ID}`
              : campaignDetails &&
                campaignDetails.isTrackingRequired !== undefined
              ? campaignDetails && campaignDetails.isTrackingRequired
                ? `Yes, Unique ${campaignConfig.LABEL_SIGN_UP_ID}`
                : `No, Generic ${campaignConfig.LABEL_SIGN_UP_ID}`
              : ''
            : campaignDetails &&
              campaignDetails.isTrackingRequired !== undefined
            ? campaignDetails && campaignDetails.isTrackingRequired
              ? `Yes, Unique ${campaignConfig.LABEL_SIGN_UP_ID}`
              : `No, Generic ${campaignConfig.LABEL_SIGN_UP_ID}`
            : ''
      }
    ];
    let dpcConfirmationdetails = [
      {
        label: 'PRA Reference',
        value:
          campaignDetails && campaignDetails.dpiaReferenceNo
            ? `Yes, ${campaignDetails.dpiaReferenceNo}`
            : 'No'
      }
    ];
    let startDate =
      campaignDetails && campaignDetails.startDate
        ? getDateInDDmmmYYYY(campaignDetails.startDate)
        : null;
    let endDate =
      campaignDetails && campaignDetails.isAlwaysOn
        ? 'No end date, always on'
        : campaignDetails && campaignDetails.endDate
        ? getDateInDDmmmYYYYTime(campaignDetails.endDate)
        : '';
    let campainDates = `${startDate ? startDate + ' - ' : ''} ${
      endDate ? endDate : '-'
    } ${
      !campaignDetails.isAlwaysOn && startDate && endDate
        ? `(${moment(endDate).diff(moment(startDate), 'days') + 1} day${
            moment(endDate).diff(moment(startDate), 'days') > 0 ? 's' : ''
          })`
        : ''
    }`;

    let masterDataOfflineCustomInjection = [];
    let incentiveItPartner = [];
    let promotionalItPartner = [];
    let promotionValueMap = {
      promotigo: 'Promotigo (Global promotions partner)',
      cheetah: 'Cheetah',
      qualifio: 'Qualifio',
      other: 'Other'
    };

    const toPascalCase = str =>
      str
        .match(
          /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
        )
        .map(x => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
        .join('');
    if (
      campaign &&
      campaign.isMasterData &&
      campaign.campaignType.toLowerCase() === 'incentive' &&
      campaign.campaignDetail &&
      campaign.campaignDetail.incentiveItPartner &&
      !(
        campaign &&
        campaign.campaignDetail &&
        campaign.campaignDetail.masterDataInjection
      )
    ) {
      incentiveItPartner = [
        {
          label: 'Incentive IT partner',
          value: `${toPascalCase(
            campaign &&
              campaign.campaignDetail &&
              campaign &&
              campaign.campaignDetail.incentiveItPartner
          )}${
            campaign &&
            campaign.campaignDetail.vendorPartner &&
            campaign.campaignDetail.incentiveItPartner === 'other'
              ? ` - ${campaign.campaignDetail.vendorPartner}`
              : ''
          }`
        }
      ];
    }
    if (campaign && campaign.isMasterData) {
      masterDataOfflineCustomInjection = [
        {
          label: 'Request Type',
          value: `${`Master data ${
            campaign &&
            campaign.campaignDetail &&
            campaign.campaignDetail.offlineInjection
              ? 'required for offline ingestion'
              : campaign &&
                campaign.campaignDetail &&
                campaign.campaignDetail.customInjection
              ? 'required for custom integration or custom forms'
              : campaign &&
                campaign.campaignDetail &&
                campaign.campaignDetail.masterDataInjection ===
                  'offlineInjection'
              ? 'required for offline ingestion'
              : campaign &&
                campaign.campaignDetail &&
                campaign.campaignDetail.masterDataInjection ===
                  'customInjection'
              ? 'for custom form or integrations not supported by CW'
              : ''
          } ${
            campaign &&
            campaign.campaignDetail &&
            campaign.campaignDetail.masterDataCollectionPartner
              ? campaign.campaignDetail.masterDataCollectionPartner
              : ''
          } ${
            campaign &&
            campaign.campaignDetail &&
            campaign.campaignDetail.masterDataOtherCollectionPartner
              ? campaign.campaignDetail.masterDataOtherCollectionPartner
              : ''
          }`}`
        },
        ...incentiveItPartner
      ];
    }

    if (
      campaign &&
      campaign.campaignType &&
      campaign.campaignType.toLowerCase() === 'incentive' &&
      campaign.incentivePromotionalPartner
    ) {
      promotionalItPartner = [
        {
          label: 'Promotions Partner',
          value: `${
            promotionValueMap[campaign && campaign.incentivePromotionalPartner]
          }${
            campaign &&
            campaign.incentivePromotionalPartner &&
            campaign.incentivePromotionalPartner === 'other'
              ? ` - ${campaign.incentivePromotionalOtherPartner}`
              : ''
          }`
        }
      ];
    }

    let campaingdetails = [
      {
        label: 'Campaign Name',
        value: campaign && campaign.name
      },
      {
        label: 'Campaign Description',
        value: campaignDetails && campaignDetails.description
      },
      ...promotionalItPartner,
      ...masterDataOfflineCustomInjection,
      {
        label: 'Campaign Dates',
        value: campainDates
      },
      {
        label: 'Live website URL',
        value:
          campaignDetails && campaignDetails.campaignProductionUrl
            ? campaignDetails && campaignDetails.campaignProductionUrl
            : ''
      },
      {
        label: 'Non-Prod Website URL',
        value: campaignNonProdUrl
      }
    ];

    let redirectionUrlData = null;
    if (campaignDetails && campaignDetails.doubleOptinReq) {
      redirectionUrlData = {
        label: 'Re-direction URL',
        value: campaignDetails && campaignDetails.redirectionUrl
      };
    }

    let doubleOptInDataObject = null;
    if (isMarketSupported) {
      doubleOptInDataObject = {
        label: 'Double Opt-in',
        value: campaignDetails && campaignDetails.doubleOptinReq ? 'Yes' : 'No'
      };
    }
    let desclaimerText = [];
    if (campaignDetails && campaignDetails.disclaimerText) {
      desclaimerText = [
        {
          label: 'Disclaimer Text',
          value: campaignDetails && campaignDetails.disclaimerText
        }
      ];
    }

    let consentCompliancedetails = [
      // {
      //   label: 'Brand Url',
      //   value: campaignDetails && campaignDetails.brandUrl
      // },
      {
        label: 'Brand Cookie Policy',
        value: campaignDetails && campaignDetails.cookiePolicyUrl
      },
      {
        label: 'Brand Privacy Policy',
        value: campaignDetails && campaignDetails.privacyPolicyUrl
      },
      {
        label: 'Campaign T&Cs',
        value: campaignDetails && campaignDetails.tncUrl
      },
      {
        label: isCampaignTypeSASS
          ? 'Minimum Legal age of consent'
          : 'Age of consent',
        value: campaignDetails && campaignDetails.ageOfConsent
      },
      doubleOptInDataObject,
      redirectionUrlData,
      ...desclaimerText
      /*{
        label: "Requester's Email",
        value:
          campaignDetails && campaignDetails.requestersEmail
            ? campaignDetails.requestersEmail
            : "",
        nonSass: true,
      },*/
    ];

    let masterDataApiDocs = [];
    if (campaign && campaign.isMasterData && this.props.isOverviewPage) {
      masterDataApiDocs = [
        {
          label: 'API documentation',
          value:
            'https://ce-platform.atlassian.net/wiki/spaces/IWG/pages/9574713/API+documentation'
        }
      ];
    }

    let campaignInfraDetailsMap = [
      /*{
        label: 'Infra Status',
        value:
          isCampaignTypeSASS &&
          (statusText === 'Completed' || statusText === 'Processing') &&
          campaignDetails &&
          campaignDetails.campaignProductionUrl
            ? isCampaignInfraEnabled
              ? 'Enabled'
              : 'Disabled'
            : ''
      },*/
      ...masterDataApiDocs
      /*{
        label: 'V3 Data collection API endpoint',
        value:
          isCampaignTypeSASS &&
          (statusText === 'Completed' || statusText === 'Processing') &&
          isCampaignInfraEnabled
            ? campaignDetails &&
              campaignDetails.campaignInfraDetails &&
              campaignDetails.campaignInfraDetails.v3ApiEndpoint
            : ''
      },
      {
        label: 'V3 Data collection API key',
        value:
          isCampaignTypeSASS &&
          (statusText === 'Completed' || statusText === 'Processing') &&
          isCampaignInfraEnabled
            ? campaignDetails &&
              campaignDetails.campaignInfraDetails &&
              campaignDetails.campaignInfraDetails.xApiKey
            : ''
      }*/
    ];

    let additionalInfo = [];
    if (campaignDetails && campaignDetails.additionalInfo) {
      additionalInfo = [
        {
          label: 'Additional Information',
          value: campaignDetails && campaignDetails.additionalInfo
        }
      ];
    }
    let isMigrationRequest = [];
    if (
      isCampaignTypeSASS &&
      campaignDetails &&
      campaignDetails.isMigrationRequest != null
    ) {
      isMigrationRequest = [
        {
          label: 'Migration',
          value:
            campaignDetails && campaignDetails.isMigrationRequest
              ? 'Yes, this is a form migration request'
              : 'No, this is not a form migration request'
        }
      ];
    }
    let isAemFieldNeeded = [];
    // if (isAcAtStandAloneCampaign) {
    //   isAemFieldNeeded = [
    //     {
    //       label: "AEM form needed",
    //       value:
    //         campaign && campaign.autogenerate !== undefined
    //           ? campaign.autogenerate
    //             ? "Yes"
    //             : "No"
    //           : "",
    //     },
    //   ];
    // }

    let promotigoField = [];
    if (campaign && !campaign.isMasterData) {
      promotigoField = [
        {
          label: 'Promotigo Form ID',
          value:
            campaignDetails && campaignDetails.promotigoFormId
              ? campaignDetails.promotigoFormId
              : 'Awaiting'
        }
      ];
    }

    let instantWinLoseField = [];
    if (campaign && !campaign.isMasterData) {
      instantWinLoseField = [
        {
          label: 'Instant Win/Lose',
          value:
            campaignDetails && campaignDetails.instantWinLose ? 'Yes' : 'No'
        }
      ];
    }
    let collapsableCampaignInfo = [
      ...isAemFieldNeeded,
      ...additionalInfo,
      ...isMigrationRequest,
      ...promotigoField,
      {
        label: 'Samples',
        value:
          campaignDetails &&
          campaignDetails.samples &&
          campaignDetails.samples.length > 0 &&
          (campaign.status === '3' ||
            campaign.status === '12' ||
            campaign.status === '2' ||
            (isClone && campaign.status === '6') ||
            (isClone && campaign.status === '7'))
            ? (campaignDetails.samples + ',').substring(
                0,
                (campaignDetails.samples + ',').length - 1
              )
            : 'Awaiting'
      },
      ...instantWinLoseField
    ];

    if (
      websiteType === 'Standalone' &&
      campaign &&
      campaign.autogenerate &&
      this.props.isCampaignAuthoring
    ) {
      let authoringEmails =
        campaignDetails &&
        campaignDetails.users &&
        campaignDetails.users.length &&
        campaignDetails.users.map(data => data.email).join('\n');
      collapsableCampaignInfo = [
        ...collapsableCampaignInfo,
        {
          label: 'Campaign authoring access',
          value: authoringEmails
        }
      ];
    }

    let campaingdetailsHtml = campaingdetails.map((campaignData, index) => {
      return campaignData.value && campaignData.value !== '' ? (
        <div
          className="cw-striped-profile-row"
          key={'campaigndetails_' + index}
        >
          <div className="row">
            <div className="col-sm-4 ">
              <strong>{campaignData.label}</strong>
            </div>
            <div className={`col-sm-8 cw-text--quinary`}>
              {campaignData.label === 'Request Type' ? (
                <div>
                  <span>
                    Master data{' '}
                    {campaign &&
                    campaign.campaignDetail &&
                    campaign.campaignDetail.masterDataInjection ===
                      'offlineInjection'
                      ? 'required for offline ingestion'
                      : campaign &&
                        campaign.campaignDetail &&
                        campaign.campaignDetail.masterDataInjection ===
                          'customInjection'
                      ? 'for custom form or integrations not supported by CW'
                      : campaign &&
                        campaign.campaignDetail &&
                        campaign.campaignDetail.offlineInjection
                      ? 'required for offline ingestion'
                      : campaign &&
                        campaign.campaignDetail &&
                        campaign.campaignDetail.customInjection
                      ? 'required for custom integration or custom forms'
                      : ''}
                  </span>
                  <br />
                  <span>
                    {' '}
                    {campaign &&
                    campaign.campaignDetail &&
                    campaign.campaignDetail.masterDataCollectionPartner !=
                      'Other'
                      ? campaign.campaignDetail.masterDataCollectionPartner
                      : ''}{' '}
                    {campaign &&
                    campaign.campaignDetail &&
                    campaign.campaignDetail.masterDataOtherCollectionPartner
                      ? campaign.campaignDetail.masterDataOtherCollectionPartner
                      : ''}
                  </span>
                </div>
              ) : (
                campaignData.value
              )}
            </div>
          </div>
        </div>
      ) : null;
    });
    let campaingtrackingHtml = campaignTrackingdetails.map(
      (campaignData, index) => {
        return (
          <div
            className="cw-striped-profile-row"
            key={'campaigntracking_' + index}
          >
            <div className="row">
              <div className="col-sm-4 ">
                <strong>{campaignData.label}</strong>
              </div>
              <div className={`flex-row justify-content-start `}>
                {campaignData.value}

                {campaignData.label === 'Campaign Tracking' ? (
                  <>
                    {' '}
                    <ReactTooltip
                      id="campaignTrackingToolTip1"
                      place="right"
                      type="info"
                      multiline={true}
                      className="cw-toolip custom-cw-toolip"
                    />
                    <span
                      className="cw-icon cw-icon--help ml-1"
                      data-tip={`Campaign IDs are now known as ${this.props.campaignConfig.LABEL_SIGN_UP_ID}s to better explain the purpose of the field to uniquely identify the  initiative through which a consumer signed up`}
                      currentitem="false"
                      data-for="campaignTrackingToolTip1"
                    >
                      <i className="fas fa-question-circle"></i>
                    </span>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        );
      }
    );

    let dpcconformationHtml = dpcConfirmationdetails.map(
      (campaignData, index) => {
        return (
          <div
            className="cw-striped-profile-row"
            key={'dpcconformation_' + index}
          >
            <div className="row">
              <div className="col-sm-4 ">
                <strong>{campaignData.label}</strong>
              </div>
              <div className={`col-sm-8 cw-text--quinary`}>
                {campaignData.value}
              </div>
            </div>
          </div>
        );
      }
    );

    let consentComplianceHtml = consentCompliancedetails.map(
      (campaignData, index) => {
        if (campaignData) {
          return campaignData.value ? (
            <div
              className="cw-striped-profile-row"
              key={'dpcconformation_' + index}
            >
              <div className="row">
                <div className="col-sm-4 ">
                  <strong>{campaignData.label}</strong>
                </div>
                {campaignData.label === "Requester's Email" ? (
                  <div
                    className={`col-sm-8 cw-text--quinary`}
                    style={{ lineBreak: 'anywhere' }}
                  >
                    {campaignData.value}
                  </div>
                ) : (
                  <div className={`col-sm-8 cw-text--quinary`}>
                    {campaignData.value}
                  </div>
                )}
              </div>
            </div>
          ) : null;
        } else {
          return null;
        }
      }
    );

    let campaingTypeHtml = campaignTypedetails.map((campaignData, index) => {
      if (!(campaignData.label === 'Incentive' && !campaignData.value)) {
        return (
          <div className="cw-striped-profile-row" key={'details_' + index}>
            <div className="row">
              <div className="col-sm-4 ">
                <strong>{campaignData.label}</strong>
              </div>
              <div className={`col-sm-8 cw-text--quinary`}>
                {campaignData.value}
              </div>
            </div>
          </div>
        );
      }
    });

    let branddetailsHtml = branddetails.map((campaignData, index) => {
      return (
        <div className="cw-striped-profile-row" key={'branddetails_' + index}>
          <div className="row">
            <div className="col-sm-4 ">
              {campaignData.label === 'Brand' ? (
                <strong>
                  {campaignData.label}
                  <span className="font-weight-normal"> (code)</span>
                </strong>
              ) : (
                <strong>{campaignData.label}</strong>
              )}
            </div>
            <div className={`col-sm-8 `}>{campaignData.value}</div>
          </div>
        </div>
      );
    });

    let campaignTrackingHtml = campaignTrackingdetails.map(
      (campaignData, index) => {
        return (
          <div className="cw-striped-profile-row" key={'branddetails_' + index}>
            <div className="row">
              <div className="col-sm-4 ">
                {campaignData.label === 'Brand' ? (
                  <strong>
                    {campaignData.label}
                    <span className="font-weight-normal"> (code)</span>
                  </strong>
                ) : (
                  <strong>{campaignData.label}</strong>
                )}
              </div>
              <div
                className={`col-sm-8 flex-row justify-content-start cw-text--quinary`}
              >
                {campaignData.value}

                {campaignData.label === 'Campaign Tracking' ? (
                  <>
                    <ReactTooltip
                      id="campaignTrackingToolTip2"
                      place="right"
                      type="info"
                      multiline={true}
                      className="cw-toolip custom-cw-toolip"
                    />

                    <span
                      className="cw-icon cw-icon--help ml-1"
                      data-tip={`Campaign IDs are now known as ${this.props.campaignConfig.LABEL_SIGN_UP_ID}s to better explain the purpose of the field to uniquely identify the  initiative through which a consumer signed up`}
                      currentitem="false"
                      data-for="campaignTrackingToolTip2"
                    >
                      <i className="fas fa-question-circle"></i>
                    </span>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        );
      }
    );

    let campaignInfraDetailsHtml = campaignInfraDetailsMap.map(
      (campaignData, index) => {
        return campaignData.value && campaignData.value !== '' ? (
          <div>
            <div
              className="cw-striped-profile-row"
              key={'campaignInfraDetails_' + index}
            >
              <div className="row">
                <div className="col-sm-4 ">
                  {campaignData.label === 'Brand' ? (
                    <strong>
                      {campaignData.label}
                      <span className="font-weight-normal"> (code)</span>
                    </strong>
                  ) : (
                    <strong>{campaignData.label}</strong>
                  )}
                </div>
                <div className={`col-sm-8 cw-text--quinary`}>
                  <span>
                    {!isCampaignInfraEnabled &&
                    // !isCampaignTypeSASS &&
                    campaignData.label === 'Infra Status' &&
                    campaign.status === '3' ? (
                      <span>
                        <span style={{ color: 'red' }}>
                          {' '}
                          {campaignData.value}
                        </span>
                        <a
                          href={constants.KANA_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          &nbsp; Raise UNA &nbsp;
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </span>
                    ) : campaignData.label === 'API documentation' ? (
                      <span>
                        <a
                          href={campaignData.value}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {campaignData.value} &nbsp;
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </span>
                    ) : (
                      <span> {campaignData.value}</span>
                    )}

                    {campaignData.label ===
                    'V3 Data collection API endpoint' ? (
                      <>
                        <ReactTooltip
                          id={'V3 Data collection API endpoint'}
                          place="right"
                          type="info"
                          multiline={true}
                          className="cw-tooltip"
                        />
                        <span
                          className="cw-icon cw-icon--help"
                          data-for="V3 Data collection API endpoint"
                          data-tip={
                            isCampaignTypeSASS
                              ? campaign && campaign.isMasterData
                                ? constants.MASTER_DATA_SAAS_V3_ENDPOINT_TOOLTIP_TEXT
                                : constants.SAAS_V3_ENDPOINT_TOOLTIP_TEXT
                              : constants.NONSAAS_V3_ENDPOINT_TOOLTIP_TEXT
                          }
                        >
                          {'          '}
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </>
                    ) : null}

                    {campaignData.label === 'V3 Data collection API key' ? (
                      <>
                        <ReactTooltip
                          id={'V3 Data collection API key'}
                          place="right"
                          type="info"
                          multiline={true}
                          className="cw-tooltip custom-cw-toolip"
                        />
                        <span
                          className="cw-icon cw-icon--help"
                          data-for="V3 Data collection API key"
                          data-tip={
                            isCampaignTypeSASS
                              ? campaign && campaign.isMasterData
                                ? constants.MASTER_DATA_SAAS_V3_API_KEY_TOOLTIP_TEXT
                                : constants.SAAS_V3_API_KEY_TOOLTIP_TEXT
                              : constants.NONSAAS_V3_API_KEY_TOOLTIP_TEXT
                          }
                        >
                          {'          '}
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </>
                    ) : null}

                    {campaignData.label === 'Infra Status' ? (
                      campaign.status !== '1' && campaign.status !== '6' ? (
                        <>
                          <ReactTooltip
                            id={'help-status-text'}
                            place="right"
                            type="info"
                            multiline={true}
                            className="cw-tooltip custom-cw-toolip"
                          />
                          <span className="cw-icon cw-icon--help">
                            {'          '}
                            <i
                              data-for="help-status-text"
                              data-tip={
                                isCampaignInfraEnabled
                                  ? isCampaignTypeSASS
                                    ? constants.SAAS_ENABLED_INFRA_STATUS_TOOLTIP_TEXT
                                    : constants.NONSAAS_ENABLED_INFRA_STATUS_TOOLTIP_TEXT
                                  : constants.DISABLED_INFRA_STATUS_TOOLTIP_TEXT
                              }
                              className="fas fa-question-circle"
                              style={{ verticalAlign: 'middle' }}
                            ></i>
                          </span>
                          {!(this.props && this.props.isEdited) ? (
                            <>
                              <ReactTooltip
                                id={'refresh-status'}
                                place="right"
                                type="info"
                                multiline={true}
                                className="cw-tooltip"
                              />

                              <img
                                data-tip={'Refresh status'}
                                style={{ cursor: 'pointer' }}
                                data-for="refresh-status"
                                src={refresh}
                                onClick={this.props.handleRefresh}
                                className="refresh-icon"
                                alt="refresh"
                                width={20}
                              />
                            </>
                          ) : null}
                        </>
                      ) : null
                    ) : null}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : null;
      }
    );

    /*filter fields if Incentive campaigns */
    if (campaign && campaign.campaignType === 'Incentive') {
      collapsableCampaignInfo = collapsableCampaignInfo.filter(
        data => data.label !== 'Error page URL'
      );
    }
    if (
      (campaign && campaign.campaignType !== 'Incentive') ||
      (campaign.campaignType === 'Incentive' &&
        websiteType !== 'Adobe Templated' &&
        websiteType !== 'Adobe Classic' &&
        websiteType !== 'Standalone') ||
      (campaign && campaign.isPlatformAgnostic) ||
      (campaign && campaign.isMasterData)
    ) {
      collapsableCampaignInfo = collapsableCampaignInfo.filter(
        data => data.label !== 'Promotigo Form ID'
      );
    }
    if (
      (campaign && campaign.campaignType !== 'Incentive') ||
      (campaign.campaignType === 'Incentive' &&
        campaign &&
        campaign.incentiveType === 'Competitions') ||
      (campaign.campaignType === 'Incentive' &&
        websiteType !== 'Adobe Templated' &&
        websiteType !== 'Adobe Classic' &&
        websiteType !== 'Standalone') ||
      (campaign && campaign.isPlatformAgnostic) ||
      campaign.isMasterData
    ) {
      collapsableCampaignInfo = collapsableCampaignInfo.filter(
        data => data.label !== 'Samples'
      );
    }
    if (
      (campaign && campaign.campaignType !== 'Incentive') ||
      (campaign.campaignType === 'Incentive' &&
        campaign &&
        campaign.incentiveType !== 'Competitions') ||
      (campaign.campaignType === 'Incentive' &&
        websiteType !== 'Adobe Templated' &&
        websiteType !== 'Adobe Classic' &&
        websiteType !== 'Standalone') ||
      (campaign && campaign.isPlatformAgnostic) ||
      campaign.isMasterData
    ) {
      collapsableCampaignInfo = collapsableCampaignInfo.filter(
        data => data.label !== 'Instant Win/Lose'
      );
    }

    /*if (
       !isAEMTypeCampaign(campaign) ||
       !showAutoGenerateFlag(campaign)
       //||(campaign && campaign.type === 'Simple sign-up')
     ) {
       collapsableCampaignInfo = collapsableCampaignInfo.filter(
         (data) =>
           data.label !== "Do you need a signup form in AEM for this campaign ?"
       );
     }*/

    if (!isMarketSupported) {
      collapsableCampaignInfo = collapsableCampaignInfo.filter(
        data => data.label !== 'Double Opt-in'
      );
    }
    if (!(campaignDetails && campaignDetails.doubleOptinReq)) {
      collapsableCampaignInfo = collapsableCampaignInfo.filter(
        data => data.label !== 'Re-direction URL'
      );
    }
    let collapsablecampaingdetailsHtml = collapsableCampaignInfo.map(
      (campaignData, index) => {
        if (!(campaignData.label === 'Brand Url' && !campaignData.value)) {
          return (
            <div className={`cw-striped-profile-row`} key={'key_' + index}>
              <div className="row">
                <div className="col-sm-4 ">
                  <strong>{campaignData.label}</strong>
                </div>
                <div
                  className={`col-sm-8 cw-text--quinary flex-row justify-content-start ${
                    campaignData.label === 'Disclaimer text' ? 'text-break' : ''
                  }`}
                >
                  {campaignData.value}
                  {campaignData.label === 'Migration' ? (
                    <>
                      <ReactTooltip
                        id="Migration"
                        place="right"
                        type="info"
                        multiline={true}
                        className="cw-toolip custom-cw-toolip"
                      />
                      <span
                        className="cw-icon cw-icon--help ml-1"
                        data-tip={`Migration implies that the form currently exists on a brand website but is now being migrated to a new AEM SaaS website`}
                        currentitem="false"
                        data-for="Migration"
                      >
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          );
        }
      }
    );
    return (
      <>
        <div className="cw-campaign--review-section mb-40">
          <div className="cw-campaign--review-section mb-40">
            {/* <div className="mb-20">
            <h3 className="cw-heading--primary">Brand Details</h3>
          </div>
          <div className="cw-campaign--review-section-content">
            {branddetailsHtml}
          </div> */}
            <div className="cw-campaign--review-section-content summary-campaign-info"></div>
          </div>
          {/* {this.props && this.props.isCampaignType ?
            <div className="cw-campaign--review-section mb-40">
              <div className="mb-20">
                <h3 className="cw-heading--primary">Campaign Type</h3>
              </div>
              <div className="cw-campaign--review-section-content">
                {campaingTypeHtml}
              </div>
            </div> : null} */}

          <div className="cw-campaign--review-section mb-40">
            <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center">
              <h3 className="cw-heading--senary">Campaign Details</h3>
              {this.props.isEditButton ? (
                <button
                  className="btn btn-outline-secondary ml-auto"
                  onClick={this.props.handleCampaignDetail}
                >
                  Edit
                </button>
              ) : null}
            </div>
            <div className="cw-campaign--review-section-content">
              {campaingdetailsHtml}

              {collapsablecampaingdetailsHtml}
              {/* <div
              className="collapse"
              id="campaignDetailsCollapsed"
              ref={this.collapsePanel}
            > */}

              {!isEpsilonMarket ? (
                <div>
                  {/* <div className="mb-20">
                    <p className="cw-heading--saascampaign mt-20">
                      Campaign Tracking
                    </p>
                  </div> */}
                  {campaignTrackingHtml}{' '}
                  {campaignDetails &&
                  campaignDetails.isCustomTrackingId &&
                  campaignDetails.isCustomTrackingId !== undefined &&
                  campaignDetails.isCustomTrackingId ? (
                    <div className="cw-striped-profile-row">
                      <div className="row">
                        <div className="col-sm-4 ">
                          <strong>
                            {this.props.campaignConfig.LABEL_SIGN_UP_ID}
                          </strong>
                        </div>
                        <div className="flex-row justify-content-start ml-3">
                          {campaign.campaignId}{' '}
                          <ReactTooltip
                            id="campaignTrackingIdToolTip1"
                            place="right"
                            type="info"
                            multiline={true}
                            className="cw-toolip custom-cw-toolip"
                          />
                          <span
                            className="cw-icon cw-icon--help ml-1"
                            data-tip={`Campaign IDs are now known as ${this.props.campaignConfig.LABEL_SIGN_UP_ID}s to better explain the purpose of the field to uniquely identify the  initiative through which a consumer signed up`}
                            currentitem="false"
                            data-for="campaignTrackingIdToolTip1"
                          >
                            <i className="fas fa-question-circle"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}
              {/* <div className="mb-20">
                <p className="cw-heading--saascampaign mt-20">
                  DPC/PRA Confirmation
                </p>
              </div> */}
              {dpcconformationHtml}
              {/* {!isCampaignTypeSASS ? (
                <div className="mb-20">
                  <p className="cw-heading--saascampaign mt-20">
                    Communication Channels
                  </p>
                </div>
              ) : null} */}

              {!isCampaignTypeSASS ? (
                <div className="cw-striped-profile-row">
                  <div className="row">
                    <div className="col-sm-4 ">
                      <strong>Consent channel</strong>
                    </div>
                    <div className="col-sm-8 cw-text--quinary">
                      {campaignDetails && campaignDetails.emailChannel
                        ? 'Email'
                        : ''}
                      {campaignDetails &&
                      campaignDetails.smsChannel &&
                      campaignDetails &&
                      campaignDetails.emailChannel
                        ? ','
                        : ''}
                      {campaignDetails && campaignDetails.smsChannel
                        ? 'SMS'
                        : ''}
                    </div>
                  </div>
                </div>
              ) : null}
              {!isCampaignTypeSASS &&
              campaignDetails &&
              campaignDetails.emailChannel !== undefined &&
              campaignDetails.emailConfirmationFlag !== undefined ? (
                campaignDetails.emailChannel &&
                (campaignDetails.emailConfirmationFlag ||
                  !campaignDetails.emailConfirmationFlag) ? (
                  <div className="cw-striped-profile-row">
                    <div className="row">
                      <div className="col-sm-4 ">
                        <strong>Do you need an email campaign?</strong>
                      </div>
                      <div className="col-sm-8 cw-text--quinary">
                        {campaignDetails &&
                        campaignDetails.emailConfirmationFlag !== undefined &&
                        campaignDetails.emailChannel !== undefined
                          ? campaignDetails &&
                            campaignDetails.emailConfirmationFlag &&
                            campaignDetails.emailChannel
                            ? 'Yes'
                            : 'No'
                          : ''}
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {!isCampaignTypeSASS ? (
                campaignDetails &&
                campaignDetails.emailChannel !== undefined ? (
                  campaignDetails.emailChannel &&
                  campaignDetails.emailConfirmationFlag ? (
                    <div className="cw-striped-profile-row">
                      <div className="row">
                        <div className="col-sm-4 ">
                          <strong>Email senders name</strong>
                        </div>
                        <div className="col-sm-8 cw-text--quinary">
                          {campaignDetails && campaignDetails.emailSenderName
                            ? campaignDetails.emailSenderName
                            : ''}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )
                ) : (
                  <div className="cw-striped-profile-row">
                    <div className="row">
                      <div className="col-sm-4 ">
                        <strong>Email senders name</strong>
                      </div>
                      <div className="col-sm-8 cw-text--quinary">
                        {campaignDetails && campaignDetails.emailSenderName
                          ? campaignDetails.emailSenderName
                          : ''}
                      </div>
                    </div>
                  </div>
                )
              ) : (
                ''
              )}

              {!isCampaignTypeSASS &&
              campaignDetails &&
              campaignDetails.replyToEmailName ? (
                campaignDetails.emailChannel &&
                campaignDetails.emailConfirmationFlag ? (
                  <div className="cw-striped-profile-row">
                    <div className="row">
                      <div className="col-sm-4 ">
                        <strong>Reply to email name</strong>
                      </div>
                      <div className="col-sm-8 cw-text--quinary">
                        {campaignDetails.replyToEmailName}
                      </div>
                    </div>
                  </div>
                ) : null
              ) : (
                ''
              )}

              {!isCampaignTypeSASS &&
              campaignDetails &&
              campaignDetails.replyToEmailAdderess ? (
                campaignDetails.emailChannel &&
                campaignDetails.emailConfirmationFlag ? (
                  <div className="cw-striped-profile-row">
                    <div className="row">
                      <div className="col-sm-4 ">
                        <strong>Reply to email address</strong>
                      </div>
                      <div className="col-sm-8 cw-text--quinary">
                        {campaignDetails.replyToEmailAdderess}
                      </div>
                    </div>
                  </div>
                ) : null
              ) : (
                ''
              )}

              {!isCampaignTypeSASS && this.props.isConsentAndCompliance ? (
                <div>
                  {/* <div className="mb-20">
                    <p className="cw-heading--saascampaign mt-20">
                      Consent and Compliance
                    </p>
                  </div> */}
                  <div>{consentComplianceHtml}</div>
                </div>
              ) : null}
              {/* </div> */}

              {/* <a
              className="collapse-h pt-2"
              href="#campaignDetailsCollapsed"
              role="button"
              aria-expanded="false"
              aria-controls="campaignDetailsCollapsed"
             // onClick={this._handleCollapse}
            >
              <span ref={this.collapseHandlerText}>
                View more Campaign Details
              </span>{" "}
              <i
                className="fas fa-chevron-down ml-1"
                ref={this.collapseHandlerIcon}
              ></i>
            </a> */}
              {campaignInfraDetailsHtml}
              {this.props.isOverviewPage ? (
                <>
                  <div className="cw-striped-profile-row">
                    <div className="row">
                      <div className="col-sm-4 ">
                        <strong>Link to CW campaign</strong>
                      </div>
                      <div
                        className={`col-sm-8 cw-text--quinary`}
                        style={{ lineBreak: 'anywhere' }}
                      >
                        <a
                          href={constant.CAMPAIGN_URL + campaignDetails.uid}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {constant.CAMPAIGN_URL + campaignDetails.uid}&nbsp;
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="cw-striped-profile-row"></div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  }
}
