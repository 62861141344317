import React, { useEffect, useState } from 'react';
import { Input } from '../../atoms/Input';
import { FieldError } from '../../atoms/FieldError';
import { get } from 'lodash';
import classNames from 'classnames';

export const EmailSecondaryThemeInput = ({
  name,
  type,
  rules,
  register,
  label,
  errors,
  options,
  defaultValue,
  getValues,
  setValue,
  subLabel,
  ...props
}) => {
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  const [selectedValue, setSelectedValue] = useState(defaultValue || []);

  useEffect(() => {
    if (setValue && defaultValue) {
      setSelectedValue(defaultValue);
      setValue(name, defaultValue, { shouldTouch: true });
    }
  }, [defaultValue]);

  const onChange = elem => {
    let newValue = [...selectedValue];
    const foundIndex = newValue.findIndex(item => item.value === elem.value);

    if (foundIndex !== -1) {
      newValue.splice(foundIndex, 1);
    } else {
      newValue.push(elem);
    }

    const transformedValue = newValue.map(item => ({
      label: item.label,
      value: item.value
    }));

    setValue(name, transformedValue);
    setSelectedValue(transformedValue);
  };

  return (
    <div className="form-group email-secondary-theme">
      <label htmlFor={props.id}>
        {label}
        <div className="description mb-20">
          {subLabel
            ? subLabel
            : `Please select values as accurately as possible to help us effectively attribute your email to content based learnings`}
        </div>
      </label>
      <div className="themes-container d-flex flex-wrap">
        {options.map(
          ({
            labelClass,
            helpText,
            defaultChecked,
            imageIcon,
            isEmailView,
            description,
            ...option
          }) => {
            const checked = selectedValue.some(
              item => item.value === option.value
            );
            return (
              <label
                htmlFor={option.id}
                className={classNames('theme-item d-flex align-items-center', {
                  selected: checked
                })}
                key={option.value}
              >
                <div className="mb-0">
                  <input
                    type="checkbox"
                    name={name}
                    {...option}
                    onChange={() => onChange(option)}
                    // defaultChecked={defaultValue === option.value}
                    checked={checked}
                  />

                  <div className="label-text d-flex justify-content-center align-items-center">
                    <img
                      src={imageIcon}
                      alt={option.label}
                      className="theme-icon"
                    />
                    <p className="theme-label">{option.label}</p>
                  </div>
                </div>
              </label>
            );
          }
        )}
      </div>

      {!props.disableError && <FieldError errors={errors} name={name} />}
    </div>
  );
};
