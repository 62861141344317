import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { CustomButtons } from 'pages/EmailCampaignAudience';
import { FormBuilder } from 'components/organisms/FormBuilder';
import Loader from 'components/Loader';
import ProgressBar from 'components/molecules/ProgressBar';
import { retrieveSmsCampaignInformation } from 'actions/communications/communicationCampaignDetails';
import formSchema from './smsAttributesSchema';

import constants from '../../constants/config';

const SMSAttributes = props => {
  const [isEdit, setEdit] = useState(false);
  const communicationCampaignDetails = useSelector(
    state => state.communicationCampaignDetails
  );
  const { authorizedUserData } = useSelector(state => state.authorized);
  const dispatch = useDispatch();
  const pathUid = props.match.params.id;
  useEffect(() => {
    if (pathUid) {
      setEdit(true);
      // fetch from email details from the server
      dispatch(retrieveSmsCampaignInformation(pathUid));
    }
  }, []);

  const smsBrandDetails =
    communicationCampaignDetails &&
    communicationCampaignDetails.smsBrandDetails;
  //if(!smsBrandDetails) return null
  const { isLoading } = communicationCampaignDetails;
  const { uid } = smsBrandDetails;
  const compProps = {
    history: props.history,
    match: props.match,
    defaultValues: smsBrandDetails
  };

  return (
    <div className="col-sm-12 skypink">
      <div className="cw-section">
        <ProgressBar
          customClass={'cw-email-progress-custom'}
          totalPage={constants.SMS_PROGRESS_BAR_TOT_PAGE}
          pageNo={constants.SMS_JOURNEY_PAGE_NUMBERS.CAMPAIN_ATTRIBUTES}
        />
        <div className="cw-section--title mb-30">
          <h2 className="cw-heading--secondary cw-email-heading">
            Campaign attributes
          </h2>
        </div>
        <div className="row">
          {isLoading ? <Loader /> : null}
          <div className="col-md-8">
            <FormBuilder
              schema={formSchema(dispatch, compProps)}
              customButtonsComp={CustomButtons}
              user={authorizedUserData}
              isEdit={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SMSAttributes;
