import constants from 'constants/config';
import React from 'react';
import Modal from 'react-modal';

export default ({ isOpen, closeModal, deleteCampaign, allowDelete }) => {
  Modal.setAppElement('#root');

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => closeModal(false)}
      className="cw-modal cw-modal--test-email-model"
      style={{
        overlay: {
          backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
        }
      }}
    >
      <div className="email-overlay-wrapper">
        <div className="email-overlay-scroll">
          <div className="d-flex justify-content-between">
            <div className={'cw-heading--saascampaign send-test-email__title'}>
              Delete SMS campaign
            </div>
            <span
              className="qna-guideline-close"
              onClick={() => closeModal(false)}
            ></span>
          </div>

          <p className="mt-10">Do you want to delete this SMS campaign?</p>

          <div className="d-flex mt-20 justify-content-end">
            <button
              type="submit"
              onClick={() => {
                closeModal(false);
              }}
              className="btn btn-outline-secondary mr-10 cw-print-hide text-nowrap"
            >
              Close
            </button>
            <button
              type="submit"
              onClick={() => {
                if (allowDelete) {
                  deleteCampaign();
                  closeModal(false);
                }
              }}
              className="btn btn-primary cw-print-hide text-nowrap"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
