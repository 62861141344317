import classNames from 'classnames';
import constants from 'constants/config';
import React from 'react';

const ProgressBar = ({
  customClass,
  pageNo,
  totalPage = constants.EMAIL_PROGRESS_BAR_TOT_PAGE
}) => {
  const calPer = (100 / totalPage) * pageNo;
  return (
    <div className={classNames('progress mb-10 mt-40', customClass)}>
      <div
        className="progress-bar progress-bar-css"
        role="progressbar"
        style={{ width: `${calPer}%` }}
        aria-valuenow="100"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  );
};

export default ProgressBar;
