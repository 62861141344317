import * as yup from 'yup';
import constants from 'constants/config';
import { updateEmailCampaignDetails } from 'actions/emailbriefing/emailCampaignDetails';
import { getYupSchema, transformEmailBriefingPayload } from 'selectors';
import {
  SendEmailToInput,
  DisplaySendEmailToInput
} from 'components/molecules/SendEmailToInput';
import { get, set } from 'lodash';

function transformSendEmailTo(storedValue, newLabel) {
  const type = get(storedValue, 'type');
  if (type && type?.value === 'allConsentedContactableConsumers') {
    return set(storedValue, 'type.label', newLabel);
  } else {
    return storedValue;
  }
}

const audienceDefinitionSchema = (
  dispatch,
  {
    defaultValues = {},
    match,
    history,
    openCustomAudienceModal,
    emailDesign,
    campaignConfig,
    isCrossBrand
  }
) => {
  const isEdited =
    history &&
    history.location &&
    history.location.state &&
    history.location.state.isEdited;
  const onSubmit = (data, { user }, { backToReview } = {}) => {
    const paramUid = match.params.id;
    const dataToSend = {
      userEmail: user.emailId,
      userName: user.name,
      audienceDefinition: data
    };

    const transformedData = transformEmailBriefingPayload(
      dataToSend,
      'emailAudience'
    );

    dispatch(
      updateEmailCampaignDetails(paramUid, transformedData, (error, result) => {
        if (result) {
          const uid = match.params.id;
          const status = emailDesign?.emailBriefing?.status;
          if (isEdited && backToReview) {
            if (status === constants.EMAIL_STATUS.AWAITING_FOR_APPROVAL) {
              history.push(`/campaign-wizard/emailbriefing/${uid}/approval`);
            } else if (
              status === constants.EMAIL_STATUS.DRAFT ||
              status === constants.EMAIL_STATUS.REJECTED
            ) {
              history.push(`/campaign-wizard/emailbriefing/${uid}/review`);
            }
          } else {
            history.push(
              `/campaign-wizard/emailbriefing/${uid}/email-properties`
            );
          }
        }
        if (error) {
          console.log('OnSubmit error: ', error);
        }
      })
    );
  };

  const brandName = Array.isArray(emailDesign?.emailBriefing?.brand)
    ? 'emailBriefing.brand[0].name'
    : 'emailBriefing.brand.name';

  const allContactableConsumersLabel = isCrossBrand
    ? 'All consumers who signed up to atleast one of the selected brands in the market'
    : `All consumers who signed up to ${get(emailDesign, brandName)} in ${get(
        emailDesign,
        'emailBriefing.country.name'
      )}`;

  return {
    formType: 'custombuttons',
    submitBtnLabel: isEdited ? 'Save and back to review' : 'Next',
    onSecondaryClick: onSubmit,
    onSubmitSecondary: () => {},
    isEdit: isEdited,
    secondaryBtnLabel: 'Next',
    fields: [
      {
        type: 'custom',
        name: 'sendEmailTo',
        component: SendEmailToInput,
        displayComponent: DisplaySendEmailToInput,
        id: 'sendEmailTo',
        label: 'Send email to',
        campaignConfig: campaignConfig,
        defaultValue: transformSendEmailTo(
          defaultValues.sendEmailTo,
          allContactableConsumersLabel
        ),
        options: [
          {
            label: allContactableConsumersLabel,
            value: 'allConsentedContactableConsumers'
          },
          {
            label: `Consumers linked to ${campaignConfig &&
              campaignConfig.LABEL_SIGN_UP_ID}(s)`,
            value: 'consumersLinkedToCampaignIds'
          },
          {
            label: 'Custom criteria',
            value: 'customCriteria'
          }
        ],
        extraOptions: [
          {
            label:
              'All consumers who signed up to atleast one of the selected brands in the market',
            value: 'allConsentedContactableConsumers'
          }
        ],
        showExtraOptions: isCrossBrand,
        placeholder: 'Please select',
        rules: yup
          .object({
            type: yup
              .object({
                label: yup.string(),
                value: yup.string()
              })
              .required('Please select send email to')
              .default(undefined),
            details: yup
              .object()
              .when('type', {
                is: data =>
                  data && data.value === 'consumersLinkedToCampaignIds',
                then: yup.object({
                  campaignIds: yup
                    .array()
                    .test(
                      'is-min',
                      `Please enter ${campaignConfig &&
                        campaignConfig.LABEL_SIGN_UP_ID}`,
                      value => {
                        const lastElem = value[value.length - 1];
                        if (
                          value.length < 2 &&
                          (!lastElem || lastElem.value === '')
                        ) {
                          return false;
                        }
                        return true;
                      }
                    )
                    .test(
                      'is-campaignid-valid',
                      `Please enter a valid ${campaignConfig &&
                        campaignConfig.LABEL_SIGN_UP_ID} (example CN123456 or PN123456)`,
                      value => {
                        const lastElem = value[value.length - 1];
                        const customValidation = constants.regex.campaignId;
                        if (value.length >= 1) {
                          if (lastElem?.value.trim() !== '') {
                            if (
                              customValidation &&
                              !customValidation.test(lastElem?.value)
                            ) {
                              return false;
                            }
                          }
                          return true;
                        }
                        return true;
                      }
                    )
                })
              })
              .when('type', {
                is: data => data && data.value === 'customCriteria',
                then: yup.object({
                  customCriteriaList: yup.array().of(yup.string()),
                  criteriaCampaignIds: yup.array().when('customCriteriaList', {
                    is: data => data && data.includes('campaignIds'),
                    then: yup
                      .array()
                      .test(
                        'is-campaignid-valid',
                        `Please enter a valid ${campaignConfig &&
                          campaignConfig.LABEL_SIGN_UP_ID} (example CN123456 or PN123456)`,
                        value => {
                          if (value) {
                            const lastElem = value[value.length - 1];
                            const customValidation = constants.regex.campaignId;
                            if (value.length >= 1) {
                              if (lastElem?.value.trim() !== '') {
                                if (
                                  customValidation &&
                                  !customValidation.test(lastElem?.value)
                                ) {
                                  return false;
                                }
                              }
                              return true;
                            }
                            return true;
                          }
                          return true;
                        }
                      )
                  })
                })
              })
          })
          .required('Please select send email to')
          .default({ type: undefined, details: undefined }),
        openCustomAudienceModal
      },
      {
        type: 'textarea',
        name: 'additionalInformation',
        id: 'additionalInformation',
        label: 'Additional information',
        placeholder: 'Optional',
        isOptional: true,
        defaultValue: defaultValues.additionalInformation,
        rules: yup
          .string()
          .trim()
          .max(300, 'Max 300 characters are allowed'),
        maxLength: '300'
      }
    ],
    onSubmit: (data, { user }) =>
      onSubmit(data, { user }, { backToReview: isEdited }),
    onDraft: (data, validationSchema, { user }) => {
      const paramUid = match.params.id;
      const dataToSend = {
        userEmail: user.emailId,
        userName: user.name,
        audienceDefinition: data,
        status: emailDesign?.emailBriefing?.status === '8' ? '8' : '1'
      };

      const transformedData = transformEmailBriefingPayload(
        dataToSend,
        'emailAudience'
      );

      dispatch(
        updateEmailCampaignDetails(
          paramUid,
          transformedData,
          (error, result) => {
            if (result) {
              const uid = match.params.id;
              history.push(`/campaign-wizard/email-dashboard`);
            }
            if (error) {
              console.log('OnSubmit error: ', error);
            }
          },
          { isSaveAsDraft: true }
        )
      );
    },
    getSchema() {
      if (this.fields) {
        return getYupSchema(this.fields);
      }
      return null;
    },
    onPrevious: () => {
      const uid = match.params.id;
      history.push(`/campaign-wizard/emailbriefing/${uid}/email-schedule`);
    },
    previousBtnLabel: 'Back',
    draftText: 'Save and exit',
    formErrorMessage:
      'There was a problem with the form. Errors are listed below'
  };
};

export default audienceDefinitionSchema;
