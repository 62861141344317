import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProgressBar from 'components/molecules/ProgressBar';
import PageHeader from 'components/organisms/PageHeader/PageHeader';
import EmailSummaryCards from 'components/organisms/EmailSummaryCards/EmailSummaryCards';
import { getEmailBriefing } from 'actions/emailbriefing/emailDesign';
import EmailOverviewTables from 'components/organisms/EmailOverviewTables/EmailOverviewTables';
import DesignEmail from 'components/organisms/DesignEmail/DesignEmail';
import BackAndNext from 'components/organisms/BackAndNext/BackAndNext';
import { LinkButton } from 'components/atoms/LinkButton';
import { setNotificationMessage } from 'actions/dashboard';
import constants from 'constants/config';
import Card from 'components/organisms/EmailBriefingsListMasonry/Card';
import { updateEmailCampaignDetails } from 'actions/emailbriefing/emailCampaignDetails';
import TemplatePreviewOverlay from 'pages/EmailCampaignTemplate/templatePreviewOverlay';
import _ from 'lodash';

export default function DesignYourEmail({
  match,
  history,
  userName,
  userEmail
}) {
  const dispatch = useDispatch();
  const [isTemplatePreviewOpen, setIsTemplatePreviewOpen] = useState(false);
  const [selectedTemplateForPreview, setSelectedTemplateForPreview] = useState(
    false
  );

  useEffect(() => {
    const uid = match.params.id;
    dispatch(getEmailBriefing(uid));
  }, []);

  const emailProps = useSelector(state => ({
    historyLogs: state.historyLogs,
    emailBriefing: state.emailDesign.emailBriefing,
    campaignConfig: state.app.campaignConfig
  }));
  const templateExists = !_.isEmpty(
    emailProps?.emailBriefing?.emailTemplateEditId
  );
  const handleNextClick = () => {
    const uid = match.params.id;
    const dataToSend = {
      userEmail: userEmail,
      userName: userName
    };
    dispatch(
      updateEmailCampaignDetails(uid, dataToSend, (error, result) => {
        if (result) {
          history.push(
            `/campaign-wizard/emailbriefing/${uid}/${
              templateExists ? 'email-editor' : 'email-template'
            }`
          );
        }
        if (error) {
          console.log('OnSubmit error: ', error);
        }
      })
    );
  };

  const handleSaveAsDraft = () => {
    const dataToSend = {
      userEmail: userEmail,
      userName: userName,
      status: emailProps?.emailBriefing?.status === '8' ? '8' : '1'
    };

    dispatch(
      setNotificationMessage(
        constants.CAMPAIGN_SAVED_AS_DRAFT_TEXT,
        undefined,
        'warning'
      )
    );
    const paramUid = match.params.id;
    dispatch(
      updateEmailCampaignDetails(
        paramUid,
        dataToSend,
        (error, result) => {
          if (result) {
            history.push(`/campaign-wizard/email-dashboard`);
          }
          if (error) {
            console.log('OnSubmit error: ', error);
          }
        },
        { isSaveAsDraft: true }
      )
    );
  };

  if (!emailProps.emailBriefing) return null;

  const handleTemplatePreview = template => {
    setIsTemplatePreviewOpen(true);
    setSelectedTemplateForPreview(template);
  };

  const handleCloseTemplatePreview = () => {
    setIsTemplatePreviewOpen(false);
    setSelectedTemplateForPreview({});
  };

  return (
    <div className="col-sm-12 skypink">
      <div className="cw-section">
        <ProgressBar
          customClass={'cw-email-progress-custom'}
          pageNo={constants.EMAIL_JOURNEY_PAGE_NUMBERS.DESIGN_YOUR_EMAIL}
        />
        <PageHeader
          heading="Design your email"
          subHeading="You are almost there!"
        />
        <div className="d-flex">
          <div
            style={{
              flex: 1,
              marginRight: 40,
              minWidth: 0,
              flexDirection: 'column',
              display: 'flex'
            }}
          >
            <EmailSummaryCards emailProps={emailProps} />
            <EmailOverviewTables
              emailProps={emailProps}
              handleTemplatePreview={handleTemplatePreview}
              isDesignPage={true}
            />
            <BackAndNext
              onClickBack={() => {
                const uid = match.params.id;
                history.push(
                  `/campaign-wizard/emailbriefing/${uid}/email-properties`
                );
              }}
              onClickNext={handleNextClick}
              nextTitle="Next"
            />
            <LinkButton
              className="cw-formdraft-link mt-40"
              onClick={handleSaveAsDraft}
            >
              Save and exit
            </LinkButton>
          </div>
          {emailProps.emailBriefing.emailTemplateEditId?.thumbnail ? (
            <div style={{ flex: '.3' }}>
              <Thumbnail
                imageStyle={{ filter: 'opacity(25%)' }}
                button={{ label: 'Continue design', onClick: handleNextClick }}
                thumbnail={
                  emailProps.emailBriefing.emailTemplateEditId?.thumbnail
                }
              />
            </div>
          ) : (
            <DesignEmail onClick={handleNextClick} buttonLabel="Design email" />
          )}
        </div>
      </div>
      <TemplatePreviewOverlay
        isOpen={isTemplatePreviewOpen}
        selectedTemplateForPreview={selectedTemplateForPreview}
        handleCloseForm={handleCloseTemplatePreview}
        hideSelectBtn
      />
    </div>
  );
}

export function Thumbnail({ thumbnail, title, imageStyle, button }) {
  return (
    <div className="thumbnail">
      <Card
        style={{ overflow: 'visible' }}
        noShadow
        imageStyle={imageStyle}
        thumbnail={thumbnail}
        title={title}
        button={button}
      />
    </div>
  );
}
