import React, { useEffect, useState } from 'react';
import { Input } from '../../atoms/Input';
import { FieldError } from '../../atoms/FieldError';
import { get, isEmpty } from 'lodash';
import classNames from 'classnames';
import { useWatch } from 'react-hook-form';
import { FormReactSelect } from '../FormReactSelect';
import {
  getCountries,
  getBrandByCountry,
  getBrandDetails
} from 'services/emailBriefings';

const BrandSpecificDetails = ({
  control,
  watchInput,
  errors,
  name,
  setValue,
  defaultValue,
  emailBriefing,
  isInModal,
  savedRowData
}) => {
  const defaultType = defaultValue;
  const rowType = useWatch({
    control,
    name: watchInput,
    defaultValue: defaultType
  });
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);
  const [brandDefault, setBrandDefault] = useState(null);
  const [countryDefault, setCountryDefault] = useState(null);

  const isCrossBrand = rowType === 'crossBrand';
  const showCountryBrandInputs =
    rowType === 'brandSpecific' || rowType === 'crossBrand';

  useEffect(() => {
    if (!isEmpty(savedRowData) && savedRowData.country && savedRowData.brand) {
      setBrandDefault(get(savedRowData, 'brand'));
      setCountryDefault(get(savedRowData, 'country'));
    } else if (emailBriefing) {
      const { brand, isCrossBrand } = emailBriefing;

      const brandDetails = Array.isArray(brand)
        ? isCrossBrand
          ? brand.map(b => ({
              value: get(b, 'code'),
              label: get(b, 'name'),
              id: get(b, '_id')
            }))
          : {
              value: get(emailBriefing, 'brand[0].code'),
              label: get(emailBriefing, 'brand[0].name'),
              id: get(emailBriefing, 'brand[0]._id')
            }
        : {
            value: get(emailBriefing, 'brand.code'),
            label: get(emailBriefing, 'brand.name'),
            id: get(emailBriefing, 'brand._id')
          };

      setBrandDefault(brandDetails);
      setCountryDefault({
        value: get(emailBriefing, 'country.code'),
        label: get(emailBriefing, 'country.name'),
        id: get(emailBriefing, 'country._id')
      });
    }
  }, [emailBriefing, savedRowData]);

  useEffect(() => {
    if (isCrossBrand && !Array.isArray(brandDefault)) {
      setBrandDefault([brandDefault]);
    }
  }, [rowType]);

  return showCountryBrandInputs ? (
    <div className="form-group">
      <div className="row">
        <div className="col-md-12">
          <FormReactSelect
            id="country"
            name={`${name}.country`}
            label={'Country'}
            placeholder={'Please select'}
            control={control}
            errors={errors}
            defaultValue={countryDefault}
            options={[]}
            setValue={setValue}
            onLoad={(properties, setProperties) => {
              getCountries().then(response => {
                let countries = response.map(country => {
                  return {
                    value: country.code,
                    label: country.name,
                    id: country._id
                  };
                });
                setProperties({ ...properties, options: countries });
              });
            }}
            isInModal={isInModal}
          />
        </div>
        <div className="col-md-12">
          <FormReactSelect
            id="brand"
            name={`${name}.${isCrossBrand ? 'crossBrand' : 'brand'}`}
            label={'Brand'}
            placeholder={'Please select'}
            control={control}
            errors={errors}
            defaultValue={brandDefault}
            options={[]}
            isMulti={isCrossBrand}
            max={10}
            setValue={setValue}
            watchInput={`${name}.country`}
            defaultWatchValue={countryDefault}
            onWatch={(result, properties, setProperties) => {
              if (result) {
                const countryId = result.id;
                getBrandByCountry(countryId).then(res => {
                  if (res.brands) {
                    let brands = res.brands.map(country => {
                      return {
                        value: country.code,
                        label: country.name,
                        id: country._id
                      };
                    });
                    setProperties({ ...properties, options: brands });
                  }
                });
              }
            }}
            isInModal={isInModal}
          />
        </div>
      </div>
    </div>
  ) : null;
};

export const BeeRowTypeInput = ({
  name,
  rules,
  register,
  label,
  errors,
  setValue,
  control,
  defaultValue,
  savedRowData,
  ...props
}) => {
  const [selectError, setSelectError] = useState({});
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);
  useEffect(() => {
    const defaultRowtype = get(savedRowData, 'rowType');
    if (defaultRowtype) {
      setValue(`${name}.type`, defaultRowtype);
    }
  }, [savedRowData]);
  const userGroup = get(props, 'userInfo.group');
  const isRequestorOrApprover = ['requestor', 'approver'].includes(userGroup);
  const isRequestor = ['requestor'].includes(userGroup);
  const isApprover = ['approver'].includes(userGroup);
  const handleSelectError = () => {
    if (userGroup === 'requestor') {
      setSelectError({
        warning: {
          message:
            'Only admins and ACS roles have permissions to create generic templates. Cross brand templates can be created by approvers as well'
        }
      });
    } else if (userGroup === 'approver') {
      setSelectError({
        warning: {
          message:
            'Only admins and ACS roles have permissions to create generic templates'
        }
      });
    }
  };
  const clearSelectErrorMessage = () => {
    setSelectError({});
  };

  return (
    <>
      <div className="form-group" aria-live="polite">
        <label htmlFor={props.id}>{label}</label>
        <div className="d-flex context-radio-buttons">
          {!props.isUserRequestorOrApprover && (
            <div
              className="form-check mb-10"
              aria-live="polite"
              onClick={isRequestorOrApprover ? handleSelectError : undefined}
            >
              <Input
                name={`${name}.type`}
                type="radio"
                {...(register && register(`${name}.type`))}
                id="generic"
                value="generic"
                disabled={isRequestorOrApprover}
              />
              <label
                htmlFor="generic"
                className={classNames('form-check-label', {
                  'radio-option-disabled': isRequestorOrApprover
                })}
              >
                <p className={props.labelClass}>Generic</p>
              </label>
            </div>
          )}

          <div
            className="form-check mb-10"
            aria-live="polite"
            onClick={clearSelectErrorMessage}
          >
            <Input
              name={`${name}.type`}
              {...(register && register(`${name}.type`))}
              id="brandSpecific"
              value="brandSpecific"
              type="radio"
            />
            <label htmlFor="brandSpecific" className="form-check-label">
              <p className={props.labelClass}>Brand specific</p>
            </label>
          </div>

          {!props.isUserRequestorOrApprover && (
            <div
              className="form-check mb-10"
              aria-live="polite"
              onClick={
                isRequestor
                  ? handleSelectError
                  : isApprover
                  ? clearSelectErrorMessage
                  : undefined
              }
            >
              <Input
                name={`${name}.type`}
                {...(register && register(`${name}.type`))}
                id="crossBrand"
                value="crossBrand"
                type="radio"
                disabled={isRequestor}
              />
              <label
                htmlFor="crossBrand"
                className={classNames('form-check-label', {
                  'radio-option-disabled': isRequestor
                })}
              >
                <p className={props.labelClass}>Cross Brand</p>
              </label>
            </div>
          )}
        </div>
        <FieldError errors={selectError} name={`warning`} />
        {typeof errors[name]?.type === 'object' ? (
          <FieldError errors={errors} name={`${name}.type`} />
        ) : errors[name]?.message ? (
          <FieldError errors={errors} name={`${name}`} />
        ) : null}
      </div>
      <BrandSpecificDetails
        control={control}
        watchInput={`${name}.type`}
        errors={errors}
        name={name}
        defaultValue={defaultValue}
        setValue={setValue}
        emailBriefing={props.emailBriefing}
        isInModal={props.isInModal}
        savedRowData={savedRowData}
      />
    </>
  );
};

const BrandSpecificFilters = ({
  control,
  watchInput,
  errors,
  name,
  setValue,
  defaultValue,
  emailBriefing,
  isInModal,
  brandOptions,
  countryOptions
}) => {
  const defaultType = get(defaultValue, 'rowType');
  const rowType = useWatch({
    control,
    name: watchInput,
    defaultValue: defaultType
  });
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);
  const [brandDefault, setBrandDefault] = useState(null);
  const [countryDefault, setCountryDefault] = useState(null);

  const showCountryBrandInputs =
    rowType === 'brandSpecific' || rowType === 'crossBrand';

  useEffect(() => {
    if (emailBriefing) {
      const brandDetails = Array.isArray(emailBriefing.brand)
        ? {
            value: get(emailBriefing, 'brand[0].code'),
            label: get(emailBriefing, 'brand[0].name'),
            id: get(emailBriefing, 'brand[0]._id')
          }
        : {
            value: get(emailBriefing, 'brand.code'),
            label: get(emailBriefing, 'brand.name'),
            id: get(emailBriefing, 'brand._id')
          };

      setBrandDefault(brandDetails);
      setCountryDefault({
        value: get(emailBriefing, 'country.code'),
        label: get(emailBriefing, 'country.name'),
        id: get(emailBriefing, 'country._id')
      });
    }
  }, [emailBriefing]);

  const countryDefaultFilter = get(defaultValue, 'country');
  const brandDefaultFilter = get(defaultValue, 'brand');

  return showCountryBrandInputs ? (
    <div className="form-group">
      <div className="row">
        <div className="col-md-12">
          <FormReactSelect
            id="country"
            name={`country`}
            label={'Country'}
            placeholder={'Please select'}
            control={control}
            errors={errors}
            defaultValue={countryDefaultFilter || countryDefault}
            options={countryOptions}
            setValue={setValue}
            isInModal={isInModal}
          />
        </div>
        <div className="col-md-12">
          <FormReactSelect
            id="brand"
            name={`brand`}
            label={'Brand'}
            placeholder={'Please select'}
            control={control}
            errors={errors}
            defaultValue={brandDefaultFilter || brandDefault}
            options={brandOptions}
            setValue={setValue}
            isInModal={isInModal}
          />
        </div>
      </div>
    </div>
  ) : null;
};

export const BeeRowTypeFilter = ({
  name,
  rules,
  register,
  label,
  errors,
  setValue,
  control,
  defaultValue,
  ...props
}) => {
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  useEffect(() => {
    const defaultRowType = get(defaultValue, 'rowType');
    if (defaultRowType) {
      setValue(`${name}`, defaultRowType);
    } else if (get(props, 'emailBriefing.country.code')) {
      setValue(`${name}`, 'brandSpecific');
    } else {
      // do nothing
    }
  }, []);

  return (
    <>
      <Input
        name={`${name}`}
        {...(register && register(`${name}`))}
        id="brandSpecific"
        value="brandSpecific"
        type="hidden"
      />
      <BrandSpecificFilters
        control={control}
        watchInput={`${name}`}
        errors={errors}
        name={name}
        defaultValue={defaultValue}
        setValue={setValue}
        emailBriefing={props.emailBriefing}
        isInModal={props.isInModal}
        brandOptions={props.brandOptions}
        countryOptions={props.countryOptions}
      />
    </>
  );
};
