import React, { Component } from 'react';
import { connect } from 'react-redux';
import { textCapitalization } from '../../selectors/index';
import axios from 'axios';
import LineBreak from '../LineBreak';
import constant from '../../constants';
import { get } from 'lodash';
import moment from 'moment';
import { getSmsCampaignDetails } from '../../actions/communications/communicationReviewDetails';
import { getSMSReviewData } from '../../selectors/index';
import MobileDevicePreview from 'components/organisms/MobileDevicePreview';
import ProgressBar from 'components/molecules/ProgressBar';

class CommunicationsReviewAndSubmit extends Component {
  state = {
    uid: this.props.match.params.id,
    additionalInfo: ''
  };
  componentDidMount() {
    this.props.getSmsCampaignDetails(this.state.uid);
  }
  _handleBrandDetail = () => {
    this.props.history.push(
      '/campaign-wizard/communications/' +
        this.state.uid +
        '/communicationbrandpage'
    );
  };
  _handleSmsCampaignDetail = () => {
    this.props.history.push(
      '/campaign-wizard/communications/' +
        this.state.uid +
        '/communicationdetails'
    );
  };

  _handleSmsDesignDetail = () => {
    this.props.history.push(
      '/campaign-wizard/communications/' +
        this.state.uid +
        '/communicationdesign'
    );
  };

  _handleType = () => {
    this.props.history.push(
      `${constant.SMS_BASE_ROUTE}/${this.state.uid}/${constant.SMS_ROUTES.SMS_TYPE}`
    );
  };

  _handleSubmitButton = () => {
    let additionalInfo = this.state.additionalInfo.trim();
    if (additionalInfo === '') {
      this._updateCampaignStatus();
    } else {
      this._saveAdditionalInfo(this.state.uid, additionalInfo);
    }
  };

  _saveAdditionalInfo = (uid, additionalInfo) => {
    let campaignAdditionalInfoData = {};
    campaignAdditionalInfoData.uid = uid;
    campaignAdditionalInfoData.additionalInfo = additionalInfo;
    axios
      .post(
        constant.communications.POST_SMS_CAMPAIGN_ADDITIONAL_INFO_DETAILS,
        campaignAdditionalInfoData
      )
      .then(() => {
        this._updateCampaignStatus();
      });
  };
  _updateCampaignStatus = () => {
    let updateStatusPostObject = {
      status: constant.featureFlags.ENABLE_SMS_APPROVAL_JOURNEY ? '3' : '2',
      uid: this.state.uid,
      userEmail: this.props.userEmail,
      userName: this.props.userName
    };
    axios
      .post(
        constant.communications.UPDATE_SMS_CAMPAIGN_STATUS,
        updateStatusPostObject
      )
      .then(() => {
        this.props.history.push(
          '/campaign-wizard/communications/' +
            this.state.uid +
            '/communicationconfirmation'
        );
      });
  };

  _handlebackButton = event => {
    this.props.history.push(
      '/campaign-wizard/communications/' +
        this.state.uid +
        '/communicationdesign'
    );
  };
  _handleInputChange = event => {
    this.setState({ additionalInfo: event.target.value });
  };

  render() {
    let {
      creationDate,
      brand,
      country,
      smsType,
      language,
      name,
      communicationDetail
    } = this.props.smsBrandDetails;

    let mobilePreviewData = getSMSReviewData(this.props.smsBrandDetails);

    return (
      <>
        {this.props.smsBrandDetails &&
        communicationDetail &&
        communicationDetail.smsDetails ? (
          <div className="col-sm-12">
            <div className="cw-section">
              <ProgressBar
                customClass={'cw-email-progress-custom'}
                pageNo={constant.SMS_JOURNEY_PAGE_NUMBERS.REVIEW}
                totalPage={constant.SMS_PROGRESS_BAR_TOT_PAGE}
              />

              <div className="row">
                <div className="col-sm-10 cw-section--title mb-30">
                  <h2 className="cw-heading--secondary mb-3">
                    Review and submit campaign
                  </h2>
                  <p>Check the details before you submit your SMS campaign.</p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-7">
                  <div className="cw-section--content">
                    <div className="cw-campaign--review">
                      {/* Requestor Details - Start */}
                      <div className="cw-campaign--review-section mb-40">
                        <div className="cw-campaign--review-section-content">
                          <div className="cw-striped-row">
                            <div className="row">
                              <div className="col-sm-4">
                                <strong>Requestor's Email ID</strong>
                              </div>
                              <div className="col-sm-8 cw-text--quinary">
                                {this.props.userEmail}
                              </div>
                            </div>
                          </div>
                          <div className="cw-striped-row">
                            <div className="row">
                              <div className="col-sm-4">
                                <strong>SMS Campaign Reference ID</strong>
                              </div>
                              <div className="col-sm-8 cw-text--quinary">
                                {this.state.uid}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Requestor Details - End */}

                      {/* Brand Details - Start */}
                      <div className="cw-campaign--review-section mb-40">
                        <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center">
                          <h3 className="cw-heading--senary">Brand Details</h3>
                          <button
                            className="btn btn-outline-secondary ml-auto"
                            onClick={this._handleBrandDetail}
                          >
                            Edit
                          </button>
                        </div>
                        <div className="cw-campaign--review-section-content">
                          <div className="cw-striped-row">
                            <div className="row">
                              <div className="col-sm-4">
                                <strong>Brand</strong>
                              </div>
                              <div className="col-sm-8 cw-text--quinary">
                                {textCapitalization(brand)}
                              </div>
                            </div>
                          </div>
                          <div className="cw-striped-row">
                            <div className="row">
                              <div className="col-sm-4">
                                <strong>Country</strong>
                              </div>
                              <div className="col-sm-8 cw-text--quinary">
                                {textCapitalization(country)}
                              </div>
                            </div>
                          </div>
                          <div className="cw-striped-row">
                            <div className="row">
                              <div className="col-sm-4">
                                <strong>Language</strong>
                              </div>
                              <div className="col-sm-8 cw-text--quinary">
                                {textCapitalization(language)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Brand Details - End */}

                      {/* Type - Start */}
                      <div className="cw-campaign--review-section mb-40">
                        <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center">
                          <h3 className="cw-heading--senary">
                            SMS Campaign type
                          </h3>
                          <button
                            className="btn btn-outline-secondary ml-auto"
                            onClick={this._handleType}
                          >
                            Edit
                          </button>
                        </div>
                        <div className="cw-campaign--review-section-content">
                          <div className="cw-striped-row">
                            <div className="row">
                              <div className="col-sm-4">
                                <strong>SMS Type</strong>
                              </div>
                              <div className="col-sm-8 cw-text--quinary">
                                {constant.SMS_TYPE_LABELS[smsType]}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Type - End */}

                      {/* SMS Campaign Details - Start */}
                      <div className="cw-campaign--review-section mb-40">
                        <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center">
                          <h3 className="cw-heading--senary">
                            Campaign summary
                          </h3>
                          <button
                            className="btn btn-outline-secondary ml-auto"
                            onClick={this._handleSmsCampaignDetail}
                          >
                            Edit
                          </button>
                        </div>
                        <div className="cw-campaign--review-section-content">
                          <div className="cw-striped-row">
                            <div className="row">
                              <div className="col-sm-4">
                                <strong>SMS Campaign Name</strong>
                              </div>
                              <div className="col-sm-8 cw-text--quinary">
                                {name}
                              </div>
                            </div>
                          </div>
                          <div className="cw-striped-row">
                            <div className="row">
                              <div className="col-sm-4">
                                <strong>SMS Campaign Overview</strong>
                              </div>
                              <div className="col-sm-8 cw-text--quinary">
                                <LineBreak
                                  paragraphs={
                                    communicationDetail?.smsOverview || ''
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          {/* <div className="cw-striped-row">
                            <div className="row">
                              <div className="col-sm-4">
                                <strong>Target Audience</strong>
                              </div>
                              <div className="col-sm-8 cw-text--quinary">
                                <LineBreak
                                  paragraphs={
                                    communicationDetail.targetAudience
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="cw-striped-row">
                            <div className="row">
                              <div className="col-sm-4">
                                <strong>Expected Volume</strong>
                              </div>
                              <div className="col-sm-8 cw-text--quinary">
                                {communicationDetail.expectedVolume}
                              </div>
                            </div>
                          </div> */}
                          <div className="cw-striped-row">
                            <div className="row">
                              <div className="col-sm-4">
                                <strong>PRA Reference</strong>
                              </div>
                              <div className="col-sm-8 cw-text--quinary">
                                {communicationDetail.dpcPraReferenceNo}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* SMS Campaign Details - End  */}

                      {/* SMS Body and Target Date - Start */}
                      <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center">
                        <h3 className="cw-heading--senary">Design SMS</h3>
                        <button
                          className="btn btn-outline-secondary ml-auto"
                          onClick={this._handleSmsDesignDetail}
                        >
                          Edit
                        </button>
                      </div>
                      {communicationDetail.smsDetails.map(
                        (smsDetails, index) => (
                          <div
                            className="cw-campaign--review-section mb-40"
                            key={index}
                          >
                            <div className="cw-campaign--review-section-content">
                              <div className="cw-striped-row">
                                <div className="row">
                                  <div className="col-sm-4">
                                    <strong>SMS Body Content</strong>
                                  </div>
                                  <div className="col-sm-8 cw-text--quinary">
                                    <LineBreak
                                      paragraphs={smsDetails?.smsContent || ''}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="cw-striped-row">
                                <div className="row">
                                  <div className="col-sm-4">
                                    <strong>Opt-out Text</strong>
                                  </div>
                                  <div className="col-sm-8 cw-text--quinary">
                                    {smsDetails.optOutText}
                                  </div>
                                </div>
                              </div>
                              <div className="cw-striped-row">
                                <div className="row">
                                  <div className="col-sm-4">
                                    <strong>
                                      Target Broadcast Date &amp; Time
                                    </strong>
                                  </div>
                                  <div className="col-sm-8 cw-text--quinary">
                                    {smsDetails.broadcastDate +
                                      ' - ' +
                                      smsDetails.broadcastTime +
                                      ' UTC'}
                                  </div>
                                </div>
                              </div>

                              {index === 0 ? (
                                <div className="cw-striped-row">
                                  <div className="row">
                                    <div className="col-sm-4">
                                      <strong>
                                        Would you like to add an additional SMS
                                        in the same request?
                                      </strong>
                                    </div>

                                    <div className="col-sm-8 cw-text--quinary">
                                      {communicationDetail.additionalSMSRequired
                                        ? 'Yes'
                                        : 'No'}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        )
                      )}
                      {/* SMS Body and Target Date - End  */}

                      {/* Additonal information*/}
                      <div className="cw-campaign--review-section mb-40">
                        <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center">
                          <h3 className="cw-heading--senary">
                            Additional Information (optional)
                          </h3>
                        </div>
                        <div className="cw-striped-row">
                          <div className="row">
                            <textarea
                              className="form-control"
                              ref="additionalInfo"
                              rows="4"
                              maxLength="300"
                              name="additionalInfo"
                              id="additionalInfo"
                              placeholder="Provide any additional information that is relevant to this campaign request."
                              value={this.state.additionalInfo}
                              onChange={this._handleInputChange}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      {/* CTA */}
                      <div className="cw-form--action-cta">
                        <button
                          type="button"
                          className="btn btn btn-outline-secondary mr-3"
                          onClick={this._handlebackButton}
                        >
                          Back
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={this._handleSubmitButton}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-5">
                  <MobileDevicePreview data={mobilePreviewData} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  smsBrandDetails: state.communicationReviewDetails.smsBrandDetails
});

export default connect(mapStateToProps, { getSmsCampaignDetails })(
  CommunicationsReviewAndSubmit
);
