import EmailCampaignScheduleDetails from 'pages/EmailCampaignScheduleDetails';
import React, { useState } from 'react';
import Modal from 'react-modal';
import constants from 'constants/config';
const EmailScheduleModel = ({
  modalIsOpen,
  closeModal,
  emailBriefing,
  pathUid
}) => {
  const { summary, emailType, country, brand, isCrossBrand } = emailBriefing;
  const campaignName = (summary && summary.campaignName) || 'Untitled';
  const [success, setSuccess] = useState(false);

  const brandDisplay = isCrossBrand
    ? 'Cross brand'
    : Array.isArray(brand)
    ? brand[0].name
    : brand.name;

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="cw-modal cw-modal--schedule-email-model"
        contentLabel="Schedule Modal"
        style={{
          overlay: {
            backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
          }
        }}
      >
        <button className="cw-modal--close" title="Close" onClick={closeModal}>
          <i className="fas fa-times"></i>
        </button>
        {success ? (
          <>
            {' '}
            <h3 className="cw-heading--senary">Email campaign scheduled</h3>
            <p className="mb-10 mt-10 email-summary-font">
              {' '}
              Thank you for marking the campaign as scheduled.
            </p>
            <span className="mb-10">
              <strong className="email-summary-font">Whats next</strong>
            </span>
            <ul className="mb-10 ml-20">
              <li className="qna_guide_list">
                We will notify the requestor that the email workflow is setup
                and ready to go in ACS!
              </li>
              <li className="qna_guide_list">
                You will receive an email notification on the go live date to
                mark the campaign 'Live'
              </li>
            </ul>
            <button
              type="button"
              onClick={() => closeModal({ showAlertMessage: true })}
              className="btn btn btn-primary mt-30"
            >
              Close
            </button>
          </>
        ) : (
          <>
            <h3 className="cw-heading--senary">Mark campaign as scheduled</h3>
            <p className="mb-10 mt-10 email-summary-font">
              {' '}
              Please mark the campaign as scheduled if it has been setup in ACS
              to be auto-executed on the target date.
            </p>
            <p className="font-weight-bold email-summary-font">
              Campaign summary
            </p>
            <h2 className={`cw-email-heading`}>
              <span className="email-highlight campaign-break-word">
                {campaignName}
              </span>
            </h2>
            <p className="email-summary-font">
              {constants.emailType[emailType]}
            </p>
            <p className="email-summary-font">
              {country.name}, {brandDisplay}
            </p>
            <br />
            <EmailCampaignScheduleDetails
              pathUid={pathUid}
              closeModal={closeModal}
              emailBriefing={emailBriefing}
              setSuccess={setSuccess}
            />
          </>
        )}
      </Modal>
    </div>
  );
};

export default EmailScheduleModel;
