import axios from 'axios';
import {
  GET_AUTHORIZED_USER_DATA,
  USER_AUTHORIZED,
  USER_NOT_AUTHORIZED,
  ASYNC_IN_PROGRESS_AUTHORIZATION,
  GET_CAMPAIGN,
  GET_EMAIL_CAMPAIGN,
  ASYNC_EMAIL_IN_PROGRESS_AUTHORIZATION,
  GET_SMS_CAMPAIGN_DETAILS
} from '../actionTypes';
import constant from '../constants';
import { fetchFeedbacks } from '../actions/feedbackForm';
import moment from 'moment';
import { get } from 'lodash';

export function fetchUserData(userData) {
  return function(dispatch) {
    dispatch({
      type: ASYNC_IN_PROGRESS_AUTHORIZATION,
      isLoading: true
    });
    //let userEmail = userData ? userData['Profile-EmailAddress'] : '';
    let userEmail = get(userData, 'username');
    if (userEmail) {
      //Fetch User Data
      return axios
        .post(constant.serviceUrls.CAMPAIGN_GET_USER_URL, {
          emailId: userEmail?.toLowerCase()
        })
        .then(res => {
          let userData = res.data.data;
          localStorage.setItem('cwtoken', res.data.token);
          dispatch({
            type: GET_AUTHORIZED_USER_DATA,
            userData,
            userAuthorized: true
          });
          dispatch({ type: ASYNC_IN_PROGRESS_AUTHORIZATION, isLoading: false });
          dispatch({ type: USER_AUTHORIZED, userAuthorized: true });
          dispatch(
            fetchFeedbacks({
              startDate: moment()
                .subtract(constant.FEEDBACK_SUBMITTED_LAST_DAYS, 'days')
                .format('DD/MM/YYYY'),
              endDate: moment().format('DD/MM/YYYY'),
              providerId: userData._id
            })
          );
          return userData;
        })
        .catch(err => {
          dispatch({ type: USER_NOT_AUTHORIZED, userNotAuthorized: true });
          dispatch({ type: ASYNC_IN_PROGRESS_AUTHORIZATION, isLoading: false });
        });
    } else {
      dispatch({ type: USER_NOT_AUTHORIZED, userNotAuthorized: true });
      dispatch({ type: ASYNC_IN_PROGRESS_AUTHORIZATION, isLoading: false });
      return Promise.reject({ message: 'No user email found' });
      // return axios
      //   .get(constant.serviceUrls.CONNECTED_WORLD_USER_URL)
      //   .then(response => {
      //     let userEmail = response.data.Profile.EmailAddress;
      //     //dispatch({ type: ASYNC_IN_PROGRESS_AUTHORIZATION, isLoading: false });
      //     return axios
      //       .post(constant.serviceUrls.CAMPAIGN_GET_USER_URL, {
      //         emailId: userEmail
      //       })
      //       .then(resp => {
      //         let userData = resp.data;
      //         dispatch({
      //           type: GET_AUTHORIZED_USER_DATA,
      //           userData,
      //           userAuthorized: true
      //         });
      //         dispatch({ type: USER_AUTHORIZED, userAuthorized: true });
      //         dispatch({
      //           type: ASYNC_IN_PROGRESS_AUTHORIZATION,
      //           isLoading: false
      //         });
      //         dispatch(
      //           fetchFeedbacks({
      //             startDate: moment()
      //               .subtract(constant.FEEDBACK_SUBMITTED_LAST_DAYS, 'days')
      //               .format('DD/MM/YYYY'),
      //             endDate: moment().format('DD/MM/YYYY'),
      //             providerId: userData._id
      //           })
      //         );
      //       })
      //       .catch(err => {
      //         dispatch({ type: USER_NOT_AUTHORIZED, userNotAuthorized: true });
      //         dispatch({
      //           type: ASYNC_IN_PROGRESS_AUTHORIZATION,
      //           isLoading: false
      //         });
      //       });
      //   })
      //   .catch(err => {
      //     dispatch({ type: USER_NOT_AUTHORIZED, userNotAuthorized: true });
      //     dispatch({ type: ASYNC_IN_PROGRESS_AUTHORIZATION, isLoading: false });
      //   });
    }
  };
}

export function getCampaignDetails(campignId) {
  return function(dispatch) {
    dispatch({
      type: ASYNC_IN_PROGRESS_AUTHORIZATION,
      isLoading: true
    });
    axios
      .get(constant.serviceUrls.CAMPAIGN_DETAILS_SERVICE_URL + '/' + campignId)
      .then(response => {
        let campaign = response.data[0];
        dispatch({ type: ASYNC_IN_PROGRESS_AUTHORIZATION, isLoading: false });
        dispatch({ type: GET_CAMPAIGN, campaign, campaignLoaded: true });
      });
  };
}

export function getEmailCampaignDetails(campignId) {
  return function(dispatch) {
    dispatch({
      type: ASYNC_EMAIL_IN_PROGRESS_AUTHORIZATION,
      isLoading: true
    });
    axios
      .get(constant.serviceUrls.EMAIL_BRIEFINGS + '/' + campignId)
      .then(response => {
        let campaign = response.data.data;
        dispatch({
          type: ASYNC_EMAIL_IN_PROGRESS_AUTHORIZATION,
          isLoading: false
        });
        dispatch({ type: GET_EMAIL_CAMPAIGN, campaign, campaignLoaded: true });
      });
  };
}

export function logoutTracking(eventObj) {
  return function(dispatch) {
    axios
      .post(constant.serviceUrls.CAMPAIGN_LOGOUT_TRACKING_URL, eventObj)
      .then(response => {
        //let logout = response.data[0];
        localStorage.removeItem('cwtoken');
      })
      .catch(function(error) {
        console.log(error);
      });
  };
}
export function logoutUserState() {
  return function(dispatch) {
    dispatch({ type: ASYNC_IN_PROGRESS_AUTHORIZATION, isLoading: true });
    dispatch({
      type: GET_AUTHORIZED_USER_DATA,
      userData: {},
      userAuthorized: false
    });
    dispatch({ type: USER_AUTHORIZED, userAuthorized: false });
    dispatch({ type: ASYNC_IN_PROGRESS_AUTHORIZATION, isLoading: false });
  };
}

export function getSmsCampaignDetails(campignId) {
  return function(dispatch) {
    dispatch({
      type: ASYNC_EMAIL_IN_PROGRESS_AUTHORIZATION,
      isLoading: true
    });
    axios
      .get(
        constant.communications.SMS_CAMPAIGN_LIST_SERVICE_URL + '/' + campignId
      )
      .then(response => {
        let campaign = response.data;
        dispatch({
          type: ASYNC_EMAIL_IN_PROGRESS_AUTHORIZATION,
          isLoading: false
        });
        dispatch({
          type: GET_SMS_CAMPAIGN_DETAILS,
          campaign,
          campaignLoaded: true
        });
      });
  };
}
