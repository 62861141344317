import React, { useEffect, useState } from 'react';
import constants from 'constants/index';
import { Icon } from 'semantic-ui-react';

const EmailMultiSelectFilter = ({ id, ...props }) => {
  //const [showFilter, setShowFilter] = useState(props.collapse || false);

  const {
    filterListData,
    onChangeHandler,
    fieldname,
    heading,
    isActive,
    resetText
  } = props;
  // the value of the search field
  const [name, setName] = useState('');

  const USERS = filterListData;

  // the search result
  const [foundItem, setFoundItem] = useState(USERS.slice(0, 8));

  useEffect(() => {
    setFoundItem(USERS.slice(0, 8));
    //setName("");
  }, [USERS]);

  useEffect(() => {
    setName('');
  }, [resetText]);

  const filter = e => {
    const keyword = e.target.value;

    if (keyword !== '') {
      const results = USERS.filter(user => {
        return user.name.toLowerCase().includes(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      setFoundItem(results);
    } else {
      setFoundItem(USERS.slice(0, 8));
      // If the text field is empty, show all
    }
    setName(keyword);
  };

  return (
    <div className={' card email-template-filter__container ' + fieldname}>
      <div className="email-template-filter__header">
        <button className="btn btn-link btn-block" onClick={props.onShow}>
          {heading}{' '}
          {props.count ? (
            <span className="circle-singleline">{props.count}</span>
          ) : null}
          <span className="toggle-icon">
            {isActive ? (
              <Icon link name="chevron up" />
            ) : (
              <Icon link name="chevron down" />
            )}
          </span>
        </button>
      </div>
      <div className={isActive ? 'show' : 'hide'}>
        <div className="card-body">
          {!props.hideSearch ? (
            <div className="input-group mb-3 form-builder-container input-clear">
              <input
                type="text"
                value={name}
                onChange={filter}
                className="form-control search-textbox"
                placeholder={props.placeholder}
              />
              {name.trim().length > 0 && (
                <span
                  className="input-clear__cross"
                  onClick={() => {
                    filter({ target: { value: '' } });
                  }}
                >
                  <i className="fas fa-times"></i>
                </span>
              )}
            </div>
          ) : null}
          <div className="email-template-filter">
            <div className="email-template-filter__wrapper">
              <ul>
                {(fieldname === 'countries' || fieldname === 'brands') &&
                  (foundItem && foundItem.length > 0 ? (
                    foundItem.map(user => (
                      <li key={user._id} className="user">
                        <input
                          type="checkbox"
                          id={user._id}
                          name={fieldname}
                          value={user.code}
                          onChange={e => onChangeHandler(e, user)}
                          checked={props?.filters?.includes(user.code)}
                        />
                        <label htmlFor={user._id}>{user.name}</label>
                      </li>
                    ))
                  ) : (
                    <li key={'no-opt1'} className="user">
                      No option found
                    </li>
                  ))}
                {fieldname === 'rawCategories' &&
                  (foundItem && foundItem.length > 0
                    ? foundItem.map((item, val) => (
                        <li key={item.value + '_' + val} className="user">
                          <input
                            type="checkbox"
                            id={item.value}
                            name="rawCategories"
                            value={item.value}
                            onChange={e => onChangeHandler(e, item)}
                            checked={props.filters?.includes(item.value)}
                          />
                          <label htmlFor={item.value}>{item.label}</label>
                        </li>
                      ))
                    : null)}
                {fieldname === 'emailType' &&
                  (foundItem && foundItem.length > 0
                    ? foundItem.map((item, val) => (
                        <li key={item.value + '_' + val} className="user">
                          <input
                            type="checkbox"
                            id={item.value}
                            name="emailType"
                            value={item.value}
                            onChange={e => onChangeHandler(e, item)}
                            checked={props.filters?.includes(item.value)}
                          />
                          <label htmlFor={item.value}>{item.label}</label>
                        </li>
                      ))
                    : null)}
                {fieldname === 'templateContext' &&
                  (foundItem && foundItem.length > 0
                    ? foundItem.map((item, val) => (
                        <li key={item.id + '_' + val} className="user">
                          <input
                            type="checkbox"
                            id={item.value}
                            name={fieldname}
                            value={item.value}
                            onChange={e => onChangeHandler(e, item)}
                            checked={props.filters?.includes(item.value)}
                          />
                          <label htmlFor={item.value}>{item.label}</label>
                        </li>
                      ))
                    : null)}
                {fieldname === 'templateAttributes' &&
                  (foundItem && foundItem.length > 0
                    ? foundItem.map((item, val) => (
                        <li key={item.value + '_' + val} className="user">
                          <input
                            type="checkbox"
                            id={item.value}
                            name={fieldname}
                            value={item.value}
                            onChange={e => onChangeHandler(e, item)}
                            checked={props.filters?.includes(item.value)}
                          />
                          <label htmlFor={item.value}>{item.label}</label>
                        </li>
                      ))
                    : null)}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailMultiSelectFilter;
