import React, { useEffect } from 'react';
import { Input } from '../../atoms/Input';
import { FieldError } from '../../atoms/FieldError';
import { get } from 'lodash';
import classNames from 'classnames';

export const FormInput = ({
  name,
  rules,
  register,
  label,
  errors,
  setValue,
  defaultValue,
  customComponent: CustomComponent,
  ...props
}) => {
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue, { shouldTouch: true });
    }
  }, [defaultValue]);

  return (
    <div className="form-group" aria-live="polite">
      <div className={classNames('d-flex', props.labelWrapperClass)}>
        <label htmlFor={props.id}>{label}</label>
        {CustomComponent ? <CustomComponent /> : ''}
      </div>
      <Input
        name={name}
        {...props}
        {...(register && register(name, rules))}
        hasError={hasError}
        defaultValue={defaultValue}
      />
      <FieldError errors={errors} name={name} />
    </div>
  );
};
