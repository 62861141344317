import classNames from 'classnames';
import { Input } from 'components/atoms/Input';
import React from 'react';
import { components } from 'react-select';

const InputWithCustomOption = ({
  id,
  name,
  value,
  onChange,
  labelClass,
  label,
  className,
  subLabel
}) => {
  return (
    <div className={classNames('form-check', className)} aria-live="polite">
      <label
        htmlFor={id}
        className={classNames('form-check-label', labelClass)}
      >
        {label}
      </label>
      <span className="react-select-custom-sublabel">{subLabel}</span>
    </div>
  );
};

export const CustomOption = ({
  getStyles,
  isFocused,
  isSelected,
  children,
  innerProps,
  isDisabled,
  ...rest
}) => {
  const onClickMultiOption = e => {
    rest.selectOption({ ...rest.data });
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <components.Option
      {...rest}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={innerProps}
      isDisabled={isDisabled}
    >
      <div onClick={onClickMultiOption}>
        <InputWithCustomOption
          id={children}
          name={rest.selectProps.name}
          label={children}
          subLabel={rest.data.subLabel}
          value={isSelected}
          onChange={() => {}}
        />
      </div>
    </components.Option>
  );
};
