import React from 'react';
import classNames from 'classnames';
import constant from '../../../constants';

export function getEmailStatusClassName(status, { inPageHeader = false } = {}) {
  return classNames({
    badge: true,
    'mt-3': !!inPageHeader,
    'campaign-status-badge': !inPageHeader,
    'badge-success': String(status) === '2' || String(status) === '7',
    'badge-primary': String(status) === '1',
    'badge-warning': String(status) === '3',
    'badge-email-awaiting-setup':
      String(status) === '4' || String(status) === '9',
    'badge-email-schedule': String(status) === '6',
    'badge-email-reject': String(status) === '5',
    'badge-email-awaiting-info': String(status) === '8',
    'badge-email-awaiting-purchase_order': String(status) === '10'
  });
}

export default function EmailStatus({ status, small }) {
  const statusClassName = getEmailStatusClassName(status);
  return (
    <span
      style={
        small
          ? {
              height: 22,
              minWidth: 'unset',
              borderRadius: '.25rem',
              width: 'unset'
            }
          : {}
      }
      className={statusClassName}
    >
      {constant.emailStatus[status]}
    </span>
  );
}
